import {createElement} from "react";
import {Grid, Link} from "@mui/material";
import {Roles} from "../../domain/Roles";
import {User} from "../../domain/TenantUser";
import {gs} from "../../theme";
import {LabeledData} from "../LabeledData";
import {LabeledItem} from "../LabeledItem";
import {RoleDisplay} from "./RoleDisplay";
import {SitesProvider} from "../../domain/SitesProvider";
import {useTenantId} from "../TenantContextProvider";
import {useLocalization} from "../../i18n";
import {useAsync} from "react-async-hook";

interface UserDisplayProps {
    user: User,
}

export function UserDisplay({user}: UserDisplayProps) {
    const {t} = useLocalization();
    const tenantId = useTenantId();

    const {result: site} = useAsync(() => SitesProvider.get(tenantId,user.siteId || ''), [tenantId, user.siteId]);

    return (
        <Grid container spacing={gs}>
            <Grid item xs={12} sm={6}>
                <LabeledData label={t('users.name')}>
                    { `${user.lastName}, ${user.firstName}` }
                </LabeledData>
            </Grid>
            <Grid item xs={12} sm={6}>
                <LabeledData label={t('users.email')}>
                    <Link color="secondary" href={`mailto:${user.email}`}>{user.email}</Link>
                </LabeledData>
            </Grid>
            <Grid item xs={12} sm={6}>
                <LabeledData label={t('users.username')}>
                    {user.username}
                </LabeledData>
            </Grid>
            <Grid item xs={12} sm={6}>
                <LabeledItem label={t('users.role')}>
                    <RoleDisplay role={user.roles as Roles} size={'small'} />
                </LabeledItem>
            </Grid>
            <Grid item xs={12} sm={6}>
                <LabeledItem label={t('sites.singular')}>
                    {site?.name}
                </LabeledItem>
            </Grid>
        </Grid>
    );
}
