import {createElement, useEffect, useState} from "react";
import {Chart} from "react-google-charts";
import {DeliveryKpi} from "../../domain/Delivery";
import {useLocalization} from "../../i18n";

export interface OverdueTableProps {
	deliveries: DeliveryKpi[];
	chartType: string;
}

interface Data {
	recipient: string;
	count: number;
}

export function OverdueTable(props: OverdueTableProps) {
	const {t, e} = useLocalization();
	const [chartData, setChartData] = useState([]);

	let compute = (deliveries: DeliveryKpi[]) => {
		let overdueMap: Map<string, number> = new Map<string, number>();
		for (let delivery of deliveries) {
			if (delivery.tags?.find(t => t == "overdue")) {
				for (let recipient of delivery.recipients ?? []) {
					let old = overdueMap.get(recipient.recipientName) ?? 0;
					overdueMap.set(recipient.recipientName, old + 1);
				}
			}
		}
		let array: Data[] = [];
		for (let entry of overdueMap.entries()) {
			array.push({recipient: entry[0], count: entry[1]});
		}
		return array;
	};

	function getChartData(data: Data[]) {
		let result =  [];
		result.push([t("deliveries.recipients.singular"), t("dashboard.overdue")]);
		for (let u of data) {
			result.push([u.recipient, u.count]);
		}
		return result;
	}

	useEffect(() => {
		let columns = compute(props.deliveries);
		columns.sort((a, b) =>
			a.count == b.count
				? a.recipient.localeCompare(b.recipient)
				: a.count > b.count
				? -1
				: 1
		);
		let data = getChartData(columns);
		setChartData(data as any);
	}, props.deliveries);

	const options = {
		allowHtml: true,
		showRowNumber: true,
	};

	const formatters = [
		{
			type: "BarFormat" as const,
			column: 1,
			options: {
				width: 120,
			},
		},
	];



	if (chartData && chartData?.length > 1) {
		return (
			<Chart
				chartType={"Table"}
				width="100%"
				height="400px"
				data={chartData}
				options={options}
				formatters={formatters}
			/>
		);
	} else return <span></span>;
}
