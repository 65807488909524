import * as React from "react";
import {createElement, useEffect, useState} from "react";
import {Button, Grid, Typography} from "@mui/material";
import {BoxTypeInput} from "./BoxTypeInput";
import {useLocalization} from "../../i18n";


interface BoxFallbackTypesInputProps {
    boxTypes: string[];
    fallbackTypes: string[];
    selectedBoxType: string;
    selectedFallbackTypes: string[];
    onChange: (boxType: string, fallbackTypes: string[]) => void;
    onDeleteRow: () => void;
}

export function BoxFallbackTypesInput(props: BoxFallbackTypesInputProps) {
    const {t} = useLocalization();

    const [boxType, setBoxType] = useState<string>(props.selectedBoxType);
    const [fallbackTypes, setFallbackTypes] = useState<string[]>(props.selectedFallbackTypes);
    const [canAdd, setCanAdd] = useState<boolean>( canAddFallback(fallbackTypes.length));


    useEffect( () => {
        setFallbackTypes(props.selectedFallbackTypes)
        },[props.selectedFallbackTypes]
    )

    function canAddFallback (no: number) : boolean {
       let can = no < 7 && no <  props.fallbackTypes.length -1;
       return can;
    }

    function addFallback() {
        let available: string[] = unusedFallback(fallbackTypes.length)
        if (available.length) {
            let newFallbackTypes: string[] = [...fallbackTypes];
            newFallbackTypes.push(available[0]);
            setFallbackTypes(newFallbackTypes);
            setCanAdd(canAddFallback(newFallbackTypes.length));
            props.onChange(boxType,newFallbackTypes);
        }
    }

    function unusedFallback(index: number) {
        let result = [...props.fallbackTypes];
        let sliced = fallbackTypes.slice(0, index);
        result = result.filter(c => !sliced.includes(c) && c !== boxType)
        return result;
    }

    function handleBoxType(type: string) {
        setBoxType(type)
        props.onChange(type,fallbackTypes);
    }

    function handleFallbackType(index: number, value: string) {
        let newTypes = [...fallbackTypes];
        if (value) newTypes[index] = value; else newTypes.splice(index, 1);
        setFallbackTypes(newTypes);
        setCanAdd(newTypes.length < (props.fallbackTypes.length - 1));
        props.onChange(boxType,newTypes);

    }

    return (<Grid container alignItems="center"
                  justifyContent="center">
        <Grid item xs={1}>
            <BoxTypeInput boxTypes={props.boxTypes} onChange={handleBoxType}
                          label={t('reserveBox.forBoxtype')} value={boxType}/>
        </Grid>
        <Grid item xs={1}>
            <Typography variant="h6" align={'center'}>{"=>"}</Typography>
        </Grid>


        {fallbackTypes.map((value, index) => <Grid item xs={1} marginRight={1} key={index}>
            <BoxTypeInput boxTypes={unusedFallback(index)} onChange={value => {
                handleFallbackType(index, value)
            }}
                          label={`${t('reserveBox.fallback')} ${index + 1}`} value={value}/>
        </Grid>)}

        <Grid item style={{flexGrow: '1'}}/>
        <Grid item>
            <Button variant="outlined" sx={{mr: 2}} onClick={props.onDeleteRow}>
                {t('reserveBox.deleteBoxType')}
            </Button>
        </Grid>
        <Grid item>
            <Button variant="outlined" disabled={!canAdd}
                    onClick={() => {
                        addFallback()
                    }}>
                {t('reserveBox.addFallback')}
            </Button>
        </Grid>
    </Grid>)

}
