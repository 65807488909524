import {
	Box, Grid,
	Paper,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	TableSortLabel,
	Typography,
} from "@mui/material";
import {Breadcrumbs, ContainerLayout, useFlag} from "@variocube/app-ui";
import * as React from "react";
import {createElement, useCallback, useMemo, useState} from "react";
import {useAsync} from "react-async-hook";
import {useNavigate} from "react-router";
import treeSwing from "../../assets/undraw_tree_swing_646s.svg";
import {Cube, formattedUtilization} from "../../domain/Cube";
import {CubesProvider} from "../../domain/CubesProvider";
import {useLocalization} from "../../i18n";
import {gs} from "../../theme";
import {BlockSvg} from "../BlockSvg";
import {HelmetTitleWrapper} from "../HelmetTitleWrapper";
import {Loading} from "../Loading";
import {useTenantId, useTenantUser, useTenantUserRole} from "../TenantContextProvider";
import {CubeConnectedComponent} from "./CubeConnectedComponent";
import {CubeUtilizationComponent} from "./CubeUtilizationComponent";
import {BreadcrumbRouterLink} from "../BreadcrumbRouterLink";


export function CubesList() {
	const tenantId = useTenantId();
	const {t} = useLocalization();

	const user = useTenantUser();
	const {isAdmin} = useTenantUserRole();

	const {result: cubes} = useAsync(() => isAdmin ?  CubesProvider.list(tenantId)  :
		CubesProvider.listSiteCubes(tenantId, user.siteId), [tenantId]);


	return (
		<ContainerLayout>
			<HelmetTitleWrapper pageTitle={t("cubes.plural")} />
			<Grid container spacing={gs}>
				<Grid item xs={12}>
					<Breadcrumbs>
						<BreadcrumbRouterLink to={`/${tenantId}/cubes`}>{t("cubes.plural")}</BreadcrumbRouterLink>
					</Breadcrumbs>
				</Grid>
				<Grid item xs={12}>
					<Typography variant="h2">{t("cubes.plural")}</Typography>
				</Grid>
				<Grid item xs={12}>
					<Paper>
						{cubes == undefined
							&& (
								<Box p={5}>
									<Box p={2} />
									<Loading />
								</Box>
							)}
						{cubes != undefined
							&& <CubesTable cubes={cubes} />}
					</Paper>
				</Grid>
			</Grid>
		</ContainerLayout>
	);
}

type sortKeys = "name" | "connected" | "utilization";

interface CubesTableProps {
	cubes: Cube[];
}


function CubesTable({cubes}: CubesTableProps) {
	const [orderAsc, , , toggleOrderAsc] = useFlag(true);
	const [orderBy, setOrderBy] = useState<sortKeys>("name");

	const sorted = useMemo(() => cubes.sort((a, b) => compare(a, b, orderAsc, orderBy)), [cubes, orderAsc, orderBy]);

	const onSort = useCallback((sortKey: sortKeys) => {
		if (orderBy === sortKey) {
			toggleOrderAsc();
		} else {
			setOrderBy(sortKey);
		}
	}, [orderBy]);

	const {t} = useLocalization();

	return (
		<Table size="small">
			<TableHead>
				<TableRow>
					<TableCell>
						<TableSortLabel
							active={orderBy === "name"}
							direction={orderAsc ? "asc" : "desc"}
							onClick={() => onSort("name")}
						>
							{t("cubes.singular")}
						</TableSortLabel>
					</TableCell>
					<TableCell style={{width: "20%"}} align="center">
						<TableSortLabel
							active={orderBy === "connected"}
							direction={orderAsc ? "asc" : "desc"}
							onClick={() => onSort("connected")}
						>
							{t("cubes.connected")}
						</TableSortLabel>
					</TableCell>
					<TableCell style={{width: "20%"}}>
						<TableSortLabel
							active={orderBy === "utilization"}
							direction={orderAsc ? "asc" : "desc"}
							onClick={() => onSort("utilization")}
						>
							{t("cubes.utilization.label")}
						</TableSortLabel>
					</TableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				{sorted && sorted.length > 0 && sorted.map((cube) => {
					return <CubeRow cube={cube} key={cube.hostname} />;
				})}
				{sorted && sorted.length == 0
					&& (
						<TableRow>
							<TableCell colSpan={3}>
								<Box p={6}>
									<Typography variant="h6" align="center">{t("cubes.noCubes")}</Typography>
								</Box>
								<BlockSvg src={treeSwing} />
								<Box p={6} />
							</TableCell>
						</TableRow>
					)}
			</TableBody>
		</Table>
	);
}

function compare(a: Cube, b: Cube, asc: boolean, orderBy: sortKeys) {
	let aValue, bValue;
	switch (orderBy) {
		case "name":
			aValue = a.name;
			bValue = b.name;
			break;
		case "connected":
			aValue = a.connected ? "1" : "0";
			bValue = b.connected ? "1" : "0";
			break;
		case "utilization":
			aValue = formattedUtilization(a.utilization);
			bValue = formattedUtilization(b.utilization);
			break;
		default:
			throw new Error("You did not select a valid comparision field");
	}
	const v = aValue.localeCompare(bValue);
	if (asc) {
		return v;
	}
	return v * -1;
}

const CubeRow = ({cube}: { cube: Cube }) => {
	const navigate = useNavigate();
	const tenantId = useTenantId();
	return (
		<TableRow hover onClick={() => navigate(`/${tenantId}/cubes/${cube.hostname}`)} sx={{cursor: "pointer"}}>
			<TableCell>{cube.name}</TableCell>
			<TableCell align="center">
				<CubeConnectedComponent connected={cube.connected} />
			</TableCell>
			<TableCell>
				<CubeUtilizationComponent utilization={cube.utilization} />
			</TableCell>
		</TableRow>
	);
};
