import {Chip} from "@mui/material";
import {createElement} from "react";
import {DeliveryRecipientType} from "../../domain/Delivery";
import {useLocalization} from "../../i18n";

interface DeliveryRecipientTypeDisplayProps {
	deliveryRecipientType: DeliveryRecipientType;
}

export function DeliveryRecipientTypeDisplay(props: DeliveryRecipientTypeDisplayProps) {
	const {deliveryRecipientType} = props;
	const {e} = useLocalization();

	return (
		<Chip
			color={deliveryRecipientType == DeliveryRecipientType.Original ? "primary" : "secondary"}
			label={e(
				"deliveries.recipientDeliveryTypes",
				deliveryRecipientType,
			)}
		/>
	);
}
