import {HelmetTitleWrapper} from "../HelmetTitleWrapper";
import {gs} from "../../theme";
import {createElement, useCallback, useEffect, useState} from "react";
import {BreadcrumbItem, Breadcrumbs, ContainerLayout} from "@variocube/app-ui";
import {Alert, Grid, Typography} from "@mui/material";
import {useLocalization} from "../../i18n";
import {SimpleButton} from "../SimpleButton";
import {useNavigate} from "react-router";
import {useTenantId} from "../TenantContextProvider";
import {PhotoSummary} from "../../domain/PhotoInbox";
import {PhotoInboxProvider} from "../../domain/PhotoInboxProvider";
import {Loading} from "../Loading";
import {PhotoCard} from "./elements/PhotoCard";
import {WebSocketMessageConsumer} from "../WebSocketConnectionContextProvider";

interface PhotoInboxProps {

}

export function PhotoInbox(props: PhotoInboxProps) {
	const {t} = useLocalization();

	const navigate = useNavigate();
	const tenantId = useTenantId();

	const [photoSummaries, setPhotoSummaries] = useState<PhotoSummary[]>();

	const loadPhotos = useCallback(() => {
		PhotoInboxProvider.list(tenantId).then(setPhotoSummaries);
	}, [tenantId]);

	const handleDeletePhoto = useCallback((photoSummary: PhotoSummary) => {
		PhotoInboxProvider.deletePhoto(tenantId, photoSummary.index).then(() => loadPhotos());
	}, [tenantId]);

	useEffect(() => {
		loadPhotos();
	}, [tenantId]);

	return (
		<WebSocketMessageConsumer tenantId={tenantId} path="/photo-inbox" onMessage={loadPhotos}>
			<ContainerLayout>
				<HelmetTitleWrapper pageTitle={t("photoInbox.singular")} />
				<Grid container spacing={gs}>
					<Grid item xs={12}>
						<Breadcrumbs>
							<BreadcrumbItem>{t("photoInbox.singular")}</BreadcrumbItem>
						</Breadcrumbs>
					</Grid>
					<Grid item xs={12}>
						<Grid container spacing={gs}>
							<Grid item style={{flexGrow: 1}}>
								<Typography variant="h1">{t("photoInbox.singular")}</Typography>
							</Grid>
							<Grid item>
								<SimpleButton label={t("photoInbox.snap.title")} onClick={() => navigate(`/${tenantId}/photo-inbox/snap`)} />
							</Grid>
						</Grid>
					</Grid>
					{!photoSummaries &&
						<Grid item xs={12}>
							<Loading />
						</Grid>
					}
					{(photoSummaries && photoSummaries.length == 0) &&
						<Grid item xs={12}>
							<Alert severity="info">
								{t("photoInbox.noPhotos")}
							</Alert>
						</Grid>
					}
					{photoSummaries && photoSummaries
						.map((photoSummary) =>
							<Grid key={photoSummary.index} item xs={12} sm={6} md={4} lg={3}>
								<PhotoCard tenantId={tenantId} photoSummary={photoSummary} onDelete={handleDeletePhoto} />
							</Grid>
					)}
				</Grid>
			</ContainerLayout>

		</WebSocketMessageConsumer>
	);
}
