import {Box, Divider} from "@mui/material";
import * as React from "react";
import {createElement} from "react";

interface BoxedDividerProps {
	p?: number;
}

export function BoxedDivider(props: BoxedDividerProps) {
	const padding = props.p || 2;
	return (
		<Box paddingTop={padding} paddingBottom={padding}>
			<Divider />
		</Box>
	);
}
