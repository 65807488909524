import {createElement} from "react";
import * as React from "react";
import {QueueStatus} from "../../domain/Delivery";
import {Chip, Theme} from "@mui/material";
import {amber, blueGrey, green, orange} from "@mui/material/colors";
import {makeStyles} from "@mui/styles";
import {useLocalization} from "../../i18n";

interface EventStateDisplayProps {
    queueStatus: QueueStatus;
    size?: 'small' | 'medium';
}

export function EventStateDisplay({queueStatus, size}: EventStateDisplayProps) {
    const classes = useStyles();
    const className = classes[queueStatus];
    const {e} = useLocalization();
    return (
        <Chip label={e('deliveries.eventQueue.queueStatuses', queueStatus)} title={e('deliveries.eventQueue.queueStatuses', queueStatus)} size={size} className={className} />
    );
}

const useStyles = makeStyles((theme: Theme) => ({
    Created: {
        backgroundColor: blueGrey[50]
    },
    Succeeded: {
        backgroundColor: green[500],
        color: '#fff'
    },
    Failed: {
        backgroundColor: amber[500]
    }
}));
