import * as _ from "lodash";

export enum PrintablesEnum {
	Delivery = "Delivery",
	OrderId = "OrderId",
	Description = "Description",
	StorageCode = "StorageCode",
	Recipients = "Recipients",
	DeliveryBox = "DeliveryBox",
	Sender = "Sender,",
	Carrier = "Carrier",
	ParcelId = "ParcelId",
	CreatedAt = "CreatedAt",
	User = "User",
	Condition = "Condition",
	Signature = "Signature",
	Building = "Building",
	CreatedBy = "CreatedBy",
	CostCenter = "CostCenter",
	Header = "Header",
	Department = "Department",
	Weight = "Weight",
	Units = "Units",
	Notes = "Notes",
}

export interface IPrintViewConfig {
	barcode: {
		height: number | null;
		width: number | null;
		type?: "QRCode" | null;
	};
	printable: Printable[];
}

export class Printable {
	constructor(public key: string, public print: boolean, public label?: string) {
	}
}

export class PrintViewConfig {
	public static defaultConfig: IPrintViewConfig = {
		barcode: {
			height: 150,
			width: 5,
		},
		printable: [
			new Printable(PrintablesEnum.Delivery, true, "deliveries.singular"),
			new Printable(PrintablesEnum.OrderId, true, "deliveries.order.orderId"),
			new Printable(PrintablesEnum.Description, true, "deliveries.order.description"),
			new Printable(PrintablesEnum.StorageCode, true, "deliveries.storageCode"),
			new Printable(PrintablesEnum.Recipients, true, "deliveries.recipients.plural"),
			new Printable(PrintablesEnum.DeliveryBox, true, "cubes.boxes.singular"),
			new Printable(PrintablesEnum.Sender, true, "deliveries.sender"),
			new Printable(PrintablesEnum.Carrier, true, "deliveries.carrier"),
			new Printable(PrintablesEnum.ParcelId, true, "deliveries.parcelId"),
			new Printable(PrintablesEnum.CreatedAt, true, "deliveries.createdAt"),
			new Printable(PrintablesEnum.User, true, "users.singular"),
			new Printable(PrintablesEnum.Condition, true, "deliveries.condition"),
			new Printable(PrintablesEnum.Signature, false, "deliveries.signature"),
			new Printable(PrintablesEnum.Building, false, "deliveries.building"),
			new Printable(PrintablesEnum.CreatedBy, false, "deliveries.createdBy"),
			new Printable(PrintablesEnum.CostCenter, false, "dispatch.details.costCenter"),
			new Printable(PrintablesEnum.Header, true, "deliveries.header"),
			new Printable(PrintablesEnum.Department, false, "deliveries.department"),
			new Printable(PrintablesEnum.Weight, false, "deliveries.weight"),
			new Printable(PrintablesEnum.Units, false, "deliveries.units"),
			new Printable(PrintablesEnum.Notes, false, "deliveries.manualHandover.notes"),
		],
	};

	public static merge(json?: string): IPrintViewConfig {
		let result = JSON.parse(JSON.stringify(PrintViewConfig.defaultConfig));
		if (json) {
			try {
				let parsed = PrintViewConfig.fromJSON(json);
				_.merge(result, parsed);
			} catch (ex) { // failsafe!
				console.log("cannot parse printview settings, using defaults:" + json);
			}
		}
		return result;
	}

	public static toJSON(config: IPrintViewConfig): string {
		let reduced = config.printable.map(val => ({
			key: val.key,
			print: val.print,
		}));
		config.printable = reduced;
		let ret = JSON.stringify(config);
		return ret;
	}

	public static fromJSON(json: string) {
		let parsed = JSON.parse(json);
		return parsed;
	}
}
