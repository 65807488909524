import * as React from "react";
import {createElement} from "react";
import {makeStyles} from "@mui/styles";

interface DeliveryImageProps {
    src: string;
}

export function DeliveryImage({src}: DeliveryImageProps) {
    const classes = useStyles();
    return (<img className={classes.image} src={src} />);
}

const useStyles = makeStyles(theme => ({
    image: {
        marginLeft: 'auto',
        marginRight: 'auto',
        display: 'block',
        maxWidth: '100%',
        maxHeight: '100%'
    }
}));
