import {MenuItem, TextField} from "@mui/material";
import {createElement} from "react";
import {AppBoxFeature} from "../../domain/Cube";
import {useLocalization} from "../../i18n";

interface BoxFeatureSelectInputProps {
	boxFeatures: AppBoxFeature[];
	value: AppBoxFeature | undefined;
	onChange: (value: AppBoxFeature | undefined) => void;
	label: string;
	disabled?: boolean;
}

export function BoxFeatureSelectInput(props: BoxFeatureSelectInputProps) {
	const {t} = useLocalization();

	const handleChange = function(str: AppBoxFeature | undefined) {
		props.onChange(str);
	};

	return (
		<TextField
			disabled={props.disabled}
			variant="outlined"
			select
			label={props.label}
			value={props.value ?? ""}
			fullWidth
			onChange={(e) => handleChange(e.target.value as AppBoxFeature)}
		>
			<MenuItem key={"undefined"} value={undefined}>{t("none")}</MenuItem>
			{props.boxFeatures.map((s) => (
				<MenuItem key={s} value={s}>
					{t(`deliveries.create.recipientStep.featureBoxItem.${s}`)}
				</MenuItem>
			))}
		</TextField>
	);
}
