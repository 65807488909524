import * as React from "react";
import {useCallback, useState, createElement, useEffect} from "react";
import {ContainerLayout} from "@variocube/app-ui";
import {Box, Button, Grid, Link, Typography} from "@mui/material";
import {gs} from "../../theme";
import {BoxedPaper} from "../BoxedPaper";
import {VCLogo} from "../VCLogo";
import {SimpleTextInput} from "../SimpleTextInput";
import {UserType} from "../../domain/TenantUser";
import {TenantUserProvider} from "../../domain/TenantUserProvider";
import {Alert} from "@mui/lab";
import {TenantsProvider} from "../../domain/TenantsProvider";
import {useNavigate} from "react-router";
import {authConfig} from "../../auth/authConfig";
import {Loading} from "../Loading";
import {authProvider} from "../../domain/AuthProvider";
import {BlockSvg} from "../BlockSvg";
import loginError from "../../assets/undraw_access_denied_6w73.svg";
import {authCredentials} from "../../auth/authCredentials";
import {useLocalization} from "../../i18n";
import {SimpleCheckBox} from "../SimpleCheckBox";

export const ProcessTokenPath = "/auth/process-token";
export const LogoutSuccessPath = "/auth/logout/success";

const RememberMeKey = "variocube-logistics-last-used-username";

export function LoginFlow() {
    const {t} = useLocalization();
    const [username, setUsername] = useState<string>(localStorage.getItem(RememberMeKey) || '');
    const [validatingUsername, setValidatingUsername] = useState<boolean>(false);
    const [password, setPassword] = useState<string>('');
    const [loginError, setLoginError] = useState<boolean>(false);
    const [userType, setUserType] = useState<UserType>();
    const [rememberMe, setRememberMe] = useState<boolean>(localStorage.getItem(RememberMeKey) !== null);

    const handleUsernameChanged = useCallback((username: string) => {
        setUsername(username);
    }, [setUsername]);

    const handleValidateUsername = useCallback(() => {
        if(username) {
            setLoginError(false);
            setValidatingUsername(true);
            if(rememberMe) {
                localStorage.setItem(RememberMeKey, username.toLowerCase());
            }else {
                localStorage.removeItem(RememberMeKey);
            }
            TenantUserProvider.guessUserType(username.toLowerCase())
                .then((response) => {
                    setUserType(response.userType);
                    switch (response.userType) {
                        case UserType.Local:
                            break;
                        case UserType.OAuth2:
                            window.location.href = authConfig.loginUrl(ProcessTokenPath, response.providerName);
                            break;
                    }
                })
                .finally(() => setValidatingUsername(false));
        }
    }, [rememberMe, username]);

    const handleLogin = useCallback(() => {
        setLoginError(false);
        authCredentials.setRememberMe(rememberMe);
        authCredentials.login(username, password);
        TenantsProvider.list()
            .then(() => {
                window.location.href = '/';
            })
            .catch(() => {
                authCredentials.logout('Could not retrieve tenant list with the provided credentials.');
                setLoginError(true);
            });
    }, [rememberMe, username, password]);

    return (
        <ContainerLayout fixedWidth="sm">
            <Grid container spacing={gs}>
                <Grid item xs={12}>
                    <Box p={4} />
                </Grid>
                <Grid item xs={12}>
                    <BoxedPaper p={5} loading={validatingUsername}>
                        <Grid container spacing={gs}>
                            <Grid item xs={12}>
                                <Typography align="center">
                                    <VCLogo />
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h5" align="center">{t('login.title')}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h6" align="center">
                                    {t("login.continue")}
                                    {t("appName")}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <SimpleTextInput label={t('login.username')} disabled={Boolean(validatingUsername || userType)}
                                                 onEnter={handleValidateUsername} autoFocus
                                                 value={username} onChange={handleUsernameChanged} />
                            </Grid>
                            {userType == UserType.Local &&
                                <Grid item xs={12}>
                                    <SimpleTextInput label={t('login.password')} type="password"
                                                     onEnter={handleLogin} autoFocus error={loginError}
                                                     value={password} onChange={setPassword} />
                                </Grid>
                            }
                            {loginError &&
                                <Grid item xs={12}>
                                    <Alert severity="error">
                                        {t('login.failed')}
                                    </Alert>
                                </Grid>
                            }
                            {userType == UserType.Unknown &&
                                <Grid item xs={12}>
                                    <Alert severity="warning">
                                        {t('login.notConfigured')}
                                    </Alert>
                                </Grid>
                            }
                            <Grid item xs={12}>
                                <Grid container spacing={gs}>
                                    <Grid item style={{flexGrow: 1}}>
                                        {!userType &&
                                            <SimpleCheckBox label={t('login.rememberMe')} checked={rememberMe} onChange={setRememberMe} />
                                        }
                                        {userType &&
                                            <Button onClick={() => setUserType(undefined)}>{t('back')}</Button>
                                        }
                                    </Grid>
                                    <Grid item>
                                        {!userType &&
                                            <Button variant="contained" color="primary" disabled={!Boolean(username)} onClick={handleValidateUsername}>{t('next')}</Button>
                                        }
                                        {userType == UserType.Local &&
                                            <Button variant="contained" color="primary" disabled={!Boolean(password)} onClick={handleLogin}>{t('login.title')}</Button>
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </BoxedPaper>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={1}>
                        <Grid item style={{flexGrow: 1}}>
                        </Grid>
                        <Grid item>
                            <Link href="https://www.variocube.com/kontakt/"
                                  underline="hover">{t('imprint')}</Link>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </ContainerLayout>
    );
}

enum ProcessTokenState {
    Processing = "Processing",
    Success = "Success",
    Error = "Error"
}

interface ProcessTokenProps {

}

export function ProcessToken(props: ProcessTokenProps) {
    const [processTokenState, setProcessTokenState] = useState<ProcessTokenState>(ProcessTokenState.Processing);
    const [errorDescription, setErrorDescription] = useState<string | undefined>();

    const {t} = useLocalization();

    useEffect(() => {
        const search = document.location.search;
        const params = new URLSearchParams(search);
        const code = params.get("code");
        if (code) {
            console.log('Processing token code ' + code);
            authProvider.processToken(code, authConfig.oAuth2RedirectUri(ProcessTokenPath))
                .then((token) => {
                    authCredentials.setRememberMe(localStorage.getItem(RememberMeKey) !== null);
                    authCredentials.provideToken(token);
                    authProvider.setupTokenRefresh();
                    setProcessTokenState(ProcessTokenState.Success);
                })
                .catch((e) => {
                    setProcessTokenState(ProcessTokenState.Error);
                });
        } else {
            setProcessTokenState(ProcessTokenState.Error);
        }
        setErrorDescription(params.get("error_description") || undefined);
    }, []);

    switch (processTokenState) {
        case ProcessTokenState.Processing:
            return (
                <ContainerLayout>
                    <Box p={4}/>
                    <Grid container spacing={6}>
                        <Grid item xs={12}>
                            <Typography variant="h3" align="center">{t('login.processing')}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Loading/>
                        </Grid>
                    </Grid>
                </ContainerLayout>
            );
        case ProcessTokenState.Success:
            return (<AuthenticationSuccess />);
        case ProcessTokenState.Error:
            return (
                <AuthenticationError error={errorDescription} />
            );
    }
    return null;
}

interface AuthenticationSuccessProps {

}

function AuthenticationSuccess(props: AuthenticationSuccessProps) {
    useEffect(() => {
        window.location.href = '/';
    }, []);

    return (
        <ContainerLayout>
            <div>Success</div>
        </ContainerLayout>
    );
}

interface AuthenticationErrorProps {
    error?: string;
}

function AuthenticationError(props: AuthenticationErrorProps) {
    const {error} = props;
    const navigate = useNavigate();
    const {t} = useLocalization();
    return (
        <ContainerLayout>
            <BlockSvg src={loginError}/>
            <Box p={4}/>
            <Grid container spacing={gs}>
                <Grid item xs={12}>
                    <Typography variant="h5" align="center">{t('login.oauth2.failed')}</Typography>
                </Grid>
                {error &&
                <Grid item xs={12}>
                    <pre>{decodeURIComponent(error)}</pre>
                </Grid>
                }
                <Grid item xs={12}>
                    <Typography variant="body1" align="center">
                        <Button onClick={() => navigate('/')}>{t('retry')}</Button>
                    </Typography>
                </Grid>
            </Grid>
        </ContainerLayout>
    );
}
