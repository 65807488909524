import {enumKeys} from "./tools";

export enum Stage {
	Local = "Local",
	Dev = "Dev",
	Test = "Test",
	App = "App",
}

export function stageFromHostname(hostname: string): Stage {
	const keys = enumKeys(Stage);
	hostname = hostname.toLowerCase();

	switch (hostname) {
		case "inhouse.variocube.com":
		case "logistics.variocube.com":
		case "logistics.nbg1.variocube.com":
			return Stage.App;
	}

	if (hostname.startsWith("192.168.25.")) {
		return Stage.Local;
	}

	for (let key of keys) {
		// TODO a bit of a weak restriction
		if (hostname.indexOf(key.toLowerCase()) != -1) {
			return key as Stage;
		}
	}

	throw new Error(`The hostname ${hostname} does not contain a valid stage`);
}

export function stage(): Stage {
	return stageFromHostname(window.location.hostname);
}

export function isTestSystem(): boolean {
	switch (stage()) {
		case Stage.Local:
		case Stage.Dev:
		case Stage.Test:
			return true;
		default:
			return false;
	}
}
