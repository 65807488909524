export class DeliveryListStorage {
	static key = "deliveryListIds";
	ids: string[] = [];
	set(ids: string[]) {
		let json: string = JSON.stringify(ids);
		window.localStorage.setItem(DeliveryListStorage.key, json);
	}
	load() {
		let json: string | null = window.localStorage.getItem(DeliveryListStorage.key);
		try {
			if (json) {
				this.ids = JSON.parse(json);
			}
		} catch (ex) {
			console.log(`warning: cannot parse localStorage ${DeliveryListStorage.key}: ${ex}`);
		}
	}

	next(id: string): string {
		let result: string = "";
		let index = this.ids.findIndex((x: string) => x == id);
		if (index >= 0 && index < this.ids.length - 1) {
			result = this.ids[index + 1];
		}
		return result;
	}

	prev(id: string): string {
		let result: string = "";
		let index = this.ids.findIndex((x: string) => x == id);
		if (index > 0) {
			result = this.ids[index - 1];
		}
		return result;
	}
}
