import {createElement, useState} from "react";
import {IconButton, IconButtonProps} from "@mui/material";
import FlashlightOffIcon from '@mui/icons-material/FlashlightOff';
import FlashlightOnIcon from '@mui/icons-material/FlashlightOn';

export const TORCH_ENABLED_KEY = 'torch-enabled';

interface TorchButtonProps extends IconButtonProps {
	onStateChange: (enabled: boolean) => void;
}

export function TorchButton({onStateChange, ...props}: TorchButtonProps) {
	const [enabled, setEnabled] = useState(localStorage.getItem(TORCH_ENABLED_KEY) === 'true' ?? false);

	function handleEnabled() {
		setEnabled(true);
		localStorage.setItem(TORCH_ENABLED_KEY, 'true');
		onStateChange(true);
	}

	function handleDisabled() {
		setEnabled(false);
		localStorage.setItem(TORCH_ENABLED_KEY, 'false');
		onStateChange(false);
	}

	function handleToggle() {
		if (enabled) {
			handleDisabled();
		} else {
			handleEnabled();
		}
	}

	return (
		<IconButton {...props} onClick={handleToggle}>
			{enabled && (<FlashlightOnIcon />)}
			{!enabled && (<FlashlightOffIcon />)}
		</IconButton>
	)
}
