import {PhotoSummary} from "../../../domain/PhotoInbox";
import {createElement, useCallback, useEffect, useState} from "react";
import {Button, Card, CardActions, CardContent, CardMedia, IconButton, Typography} from "@mui/material";
import {PhotoInboxProvider} from "../../../domain/PhotoInboxProvider";
import {DateComponent} from "../../DateComponent";
import {useNavigate} from "react-router";
import {useLocalization} from "../../../i18n";
import {Delete} from "@mui/icons-material";
import {ConfirmDialog} from "@variocube/app-ui";
import fotoLoading from "../../../assets/nordwood-themes-F3Dde_9thd8-unsplash.jpg";

interface PhotoCardProps {
	tenantId: string;
	photoSummary: PhotoSummary;
	onDelete: (photoSummary: PhotoSummary) => void;
}

export function PhotoCard(props: PhotoCardProps) {
	const {tenantId, photoSummary, onDelete} = props;
	const {t} = useLocalization();
	const navigate = useNavigate();

	const [preview, setPreview] = useState<string>();
	const [confirmDelete, setConfirmDelete] = useState<boolean>(false);

	useEffect(() => {
		PhotoInboxProvider.preview(tenantId, photoSummary.index).then(setPreview);
	}, [tenantId, photoSummary]);

	const handleDelete = useCallback(async () => {
		onDelete(photoSummary);
		setConfirmDelete(false);
	}, []);

	return (
		<Card>
			<CardMedia sx={{aspectRatio: "4 / 3"}} image={preview || fotoLoading} />
			<CardContent>
				<Typography variant="body2">
					<DateComponent date={photoSummary.createdAt} />
				</Typography>
			</CardContent>
			<CardActions>
				<Button color="primary" size="small"
						onClick={() => navigate(`/${tenantId}/deliveries/create?photoInbox=${photoSummary.index}`)}>{t("photoInbox.create")}</Button>
				<IconButton onClick={() => setConfirmDelete(true)}>
					<Delete />
				</IconButton>
			</CardActions>
			<ConfirmDialog open={confirmDelete}
						   title={t("photoInbox.delete.title")} cancel={t("cancel")}
						   onClose={() => setConfirmDelete(false)} onConfirm={handleDelete}>
				{t("photoInbox.delete.message", {filename: photoSummary.filename})}
			</ConfirmDialog>
		</Card>
	);
}
