import {Api} from "../Api";
import {OcrRequest, OcrResponse} from "./Ocr";

export class OcrProvider {

    static ocr(imageData: string): Promise<OcrResponse> {
        const request: OcrRequest = {
            imageData: imageData
        };
        return Api.POST(`ocr`, request).then((raw) => {
            return raw as OcrResponse;
        });
    }
}
