import * as React from "react";
import {createElement, useCallback, useState} from "react";
import {ContainerLayout} from "@variocube/app-ui";
import {Box, Grid, List, ListItem, ListItemText, Paper, Theme, Typography} from "@mui/material";
import {gs} from "../../theme";
import {SimpleFileSelect} from "../SimpleFileSelect";
import {makeStyles} from "@mui/styles";
import {OcrProvider} from "../../domain/OcrProvider";
import {OcrResponse} from "../../domain/Ocr";
import {MatchingResult, textAnalysis} from "../../domain/textAnalysis";
import {RecipientsProvider} from "../../domain/RecipientsProvider";
import {useTenantId} from "../TenantContextProvider";
import {Recipient} from "../../domain/Delivery";

export function OcrTest() {
    const tenantId = useTenantId();

    const [filename, setFilename] = useState<string>('');
    const [imageData, setImageData] = useState<string>('');
    const [analyzing, setAnalyzing] = useState<boolean>(false);
    const [ocrResponse, setOcrResponse] = useState<OcrResponse>();
    const [matches, setMatches] = useState<MatchingResult[] | undefined>();
    const [recipients, setRecipients] = useState<Recipient[] | undefined>();

    const handleFile = useCallback((file: File) => {
        setFilename(file.name);
        const reader = new FileReader();
        reader.onloadend = () => {
            if(reader.result) {
                const dataUrl = reader.result as string
                setAnalyzing(true);
                setImageData(dataUrl);
                OcrProvider.ocr(dataUrl)
                    .then((response) => {
                        setOcrResponse(response);
                        setMatches(textAnalysis.matchTexts(response.blocks.map((b) => b.line)));
                        RecipientsProvider.lookupOcr(tenantId, response.blocks.map((b) => b.line))
                            .then((result) => {
                                if(result.recipients && result.recipients.length > 0) {
                                    setRecipients(result.recipients);
                                }
                            });
                    })
                    .finally(() => setAnalyzing(false));
            }
        };
        reader.readAsDataURL(file);
    }, []);

    const classes = useStyles();

    return (
        <ContainerLayout>
            <Grid container spacing={gs}>
                <Grid item xs={12}>
                    <Typography variant="h2">OCR Test</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={gs}>
                        <Grid item style={{flexGrow:1}}>
                            {imageData &&
                            <img className={analyzing ? classes.imageAnalyzing : classes.image} src={imageData} />
                            }
                        </Grid>
                        <Grid item>
                            <Grid container spacing={1}>
                                {filename &&
                                <Grid item>
                                    <Box p={1}>
                                        {filename}
                                    </Box>
                                </Grid>
                                }
                                <Grid item>
                                    <SimpleFileSelect onFile={handleFile} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                {ocrResponse &&
                <Grid item xs={4}>
                    <Paper>
                        <List>
                            {ocrResponse.blocks.map((b, index) =>
                                <ListItem key={index}>
                                    <ListItemText primary={b.line} />
                                </ListItem>
                            )}
                        </List>
                    </Paper>
                </Grid>
                }
                {matches &&
                <Grid item xs={4}>
                    <Paper>
                        <List>
                            {matches.map((m, index) =>
                                <ListItem key={index}>
                                    <ListItemText primary={m.match} secondary={m.pattern.patternType} />
                                </ListItem>
                            )}
                        </List>
                    </Paper>
                </Grid>
                }
                {recipients &&
                <Grid item xs={4}>
                    <Paper>
                        <List>
                            {recipients.map((r, index) =>
                                <ListItem key={index}>
                                    <ListItemText primary={r.recipientName} />
                                </ListItem>
                            )}
                        </List>
                    </Paper>
                </Grid>
                }
            </Grid>

        </ContainerLayout>
    );
}

const useStyles = makeStyles((theme: Theme) => ({
    image: {
        marginLeft: 'auto',
        marginRight: 'auto',
        display: 'block'
    },
    imageAnalyzing: {
        marginLeft: 'auto',
        marginRight: 'auto',
        display: 'block',
        filter: 'grayscale(100%)'
    }
}));
