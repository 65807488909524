import {Api} from "../Api";
import {Tenant} from "./TenantUser";

export class TenantsProvider {

    static list(): Promise<Tenant[]> {
        return Api.GET('tenants').then((response) => {
            return response as Tenant[];
        });
    }

    static async get(tenantId: string) {
        return await Api.GET(`tenants/${tenantId}`) as Tenant;
    }

}

