import {Autocomplete, CircularProgress} from "@mui/material";
import {ErrorAlert, TextField} from "@variocube/app-ui";
import {createElement, Fragment, useCallback, useEffect, useState} from "react";
import {useAsync} from "react-async-hook";
import {debounce} from "lodash";
import {useAuditLog} from "../../domain/audit-log-provider";
import {useLocalization} from "../../i18n";

interface AuditActorSelectProps {
	tenantId: string;
	value: string | null;
	onChange: (actor: string | null) => void;
}

export function AuditActorSelect({tenantId, value, onChange}: AuditActorSelectProps) {
	const {t} = useLocalization();
	const {getActors} = useAuditLog();
	const [search, setSearch] = useState('');
	const [auditActors, setAuditActors] = useState<string[]>([]);

	const {loading, error, result} = useAsync(async () => {
		return getActors(tenantId, search);
	}, [search]);

	useEffect(() => {
		if (result) {
			setAuditActors(result);
		}
	}, [result]);

	const handleChange = useCallback((event, auditActor) => onChange(auditActor), []);

	const handleSearchChange = debounce((value: string) => {
		setSearch(value);
	}, 300)

	return (
		<Fragment>
			<Autocomplete
				multiple={false}
				value={auditActors.find(actor => actor == value) ?? null}
				onChange={handleChange}
				filterOptions={x => x}
				options={auditActors}
				loading={loading}
				autoHighlight
				noOptionsText={search.length < 3 ? t('typeToSearch') : t('noOptions')}
				renderOption={(props, option) => (
					<li {...props}>
						{option}
					</li>
				)}
				renderInput={(params) => (
					<TextField
						{...params}
						label="Actor"
						onChange={handleSearchChange}
						InputProps={{
							...params.InputProps,
							endAdornment: (
								<Fragment>
									{loading ? <CircularProgress color="inherit" size={20} /> : null}
									{params.InputProps.endAdornment}
								</Fragment>
							),
						}}
					/>
				)}
			/>
			{error && <ErrorAlert error={error} />}
		</Fragment>
	);
}
