import {Grid, ListItemText, Typography} from "@mui/material";
import {createElement} from "react";
import {DeliveryNote} from "../../domain/Delivery";
import {gs} from "../../theme";
import {BoxedPaper} from "../BoxedPaper";
import {DateComponent, Display} from "../DateComponent";
import {UserNameDisplay} from "../UserNameDisplay";

export interface DeliveryNoteViewProps {
	note: DeliveryNote;
}

export function DeliveryNoteView(props: Readonly<DeliveryNoteViewProps>) {
	const {note} = props;

	return (
		<ListItemText
			primary={note.note}
			secondary={note.changeHistory
				&& (
					<Typography style={{fontSize: "small"}}>
						{note.changeHistory.createdBy && <UserNameDisplay username={note.changeHistory.createdBy} />} /
						{" "}
						<DateComponent date={note.changeHistory.createdAt} display={Display.Timestamp} />
					</Typography>
				)}
		/>
	);
}
