import {createElement, useState} from "react";
import {AppTenant} from "../../domain/TenantUser";
import {ContainerLayout} from "@variocube/app-ui";
import {Alert, Box, Button, Grid, List, ListItemButton, ListItemText, Paper, Typography} from "@mui/material";
import {Loading} from "../Loading";
import {TenantUserProvider} from "../../domain/TenantUserProvider";
import {useLocalization} from "../../i18n";
import {HelmetTitleWrapper} from "../HelmetTitleWrapper";
import {useNavigate} from "react-router";
import {useAsync} from "react-async-hook";

export function NewTenantSelection() {
    const navigate = useNavigate();
    const {t} = useLocalization();
    const [selected, setSelected] = useState<number>(0);

    const {loading, result: appTenants = []} = useAsync(() => TenantUserProvider.unconfigured(), []);

    function handleSelectTenant(index: number) {
        setSelected(index);
    }

    function create (id: string, name: string) : void  {
        TenantUserProvider.createTenant(id).then( tenant  =>
            navigate(`/${tenant.centerId}/settings/tenant/edit`)
        );
    }

    return (
        <ContainerLayout>
            <HelmetTitleWrapper pageTitle={t('tenants.new')} />
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h2" gutterBottom>{t('tenants.new')}</Typography>
                    <Typography variant="body1">{t('tenants.select')}</Typography>
                </Grid>
                <Grid item xs={12}>
                    {loading && <Loading/>}
                    <Paper>
                        {appTenants.length > 0 &&
                            <List>
                                {appTenants.map((aT: AppTenant, index:number) =>
                                    <ListItemButton key={aT.id}  selected={index==selected}
                                              onClick={() => handleSelectTenant(index) } >
                                        <ListItemText primary={ aT?.name ??  aT.id  }/>
                                    </ListItemButton>
                                )}
                            </List>
                        }
                        {appTenants.length == 0 &&
                            <Alert severity="error">{t('tenants.nothingToCreate')}</Alert>
                        }
                    </Paper>
                </Grid>
                {appTenants.length > 0 &&
                    <Grid item xs={12}>
                        <Paper>
                            <Box p={2} display="flex" flexDirection="row" alignItems="center">
                                <Box flexGrow={1}>
                                    <Typography><strong>{t('warning.title')}</strong></Typography>
                                    <Typography>{t('warning.hint')}</Typography>
                                </Box>
                                <Button variant="outlined"
                                        onClick={ () => create(appTenants[selected].id, appTenants[selected]?.name ??  appTenants[selected].id)}  >
                                    {t('tenants.new')}
                                </Button>
                            </Box>
                        </Paper>
                    </Grid>
                }
            </Grid>
        </ContainerLayout>
    );
}
