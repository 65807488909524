import {Chip} from "@mui/material";
import {ChipProps} from "@mui/material/Chip/Chip";
import * as React from "react";
import {createElement} from "react";
import {DeliveryState} from "../../domain/Delivery";
import {useLocalization} from "../../i18n";

interface DeliveryStateDisplayProps {
	deliveryState: DeliveryState;
	size?: "small" | "medium";
}

export function DeliveryStateDisplay({deliveryState, size}: DeliveryStateDisplayProps) {
	const {e} = useLocalization();
	return (
		<Chip
			label={e("deliveries.deliveryStates", deliveryState)}
			title={e("deliveries.deliveryStates", deliveryState)}
			size={size}
			color={COLORS[deliveryState]}
		/>
	);
}

const COLORS: Record<DeliveryState, ChipProps["color"]> = {
	[DeliveryState.Draft]: "error",
	[DeliveryState.Created]: "info",
	[DeliveryState.Reserved]: "info",
	[DeliveryState.Stored]: "warning",
	[DeliveryState.Returned]: "warning",
	[DeliveryState.PickedUp]: "success",
	[DeliveryState.Cancelled]: "error",
	[DeliveryState.ManualHandover]: "success",
	[DeliveryState.Shipped]: "success",
};
