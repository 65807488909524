import {createElement} from "react";
import {CircularProgress} from "@mui/material"; 
import {useLocalization} from "../i18n";

interface LoadingProps {
    message?: string;
}

export function Loading({message}: LoadingProps) {
    const {t} = useLocalization();
    return (
        <div>
            <div>
                <CircularProgress size={40} style={{display: 'block', margin: 'auto'}}/>
            </div>
            <p style={{textAlign: 'center'}}>{message || t('loading')}</p>
        </div>
    );
}
