import {Tooltip} from "@mui/material";
import {createElement, useEffect, useMemo, useState} from "react";
import {User} from "../domain/TenantUser";
import {TenantUserProvider} from "../domain/TenantUserProvider";
import {useTenantId} from "./TenantContextProvider";

interface UserNameDisplayProps {
	username: string;
	maxLen?: number;
}

export function UserNameDisplay(props: UserNameDisplayProps) {
	const {username, maxLen} = props;
	const tenantId = useTenantId();

	const [loading, setLoading] = useState<boolean>(false);
	const [user, setUser] = useState<User>();

	useEffect(() => {
		if (tenantId && username) {
			setLoading(true);
			TenantUserProvider.get(tenantId, username)
				.then(setUser)
				.finally(() => setLoading(false));
		}
	}, [tenantId, username]);

	const fullLengthDisplay = useMemo<string>(() => {
		if (loading) {
			return "...";
		}
		if (user) {
			return `${user.firstName} ${user.lastName}`;
		}
		return username;
	}, [loading, user, username]);

	const display = useMemo<string>(() => {
		if (maxLen && fullLengthDisplay.length > maxLen) {
			return `${fullLengthDisplay.substr(0, maxLen)}...`;
		}
		return fullLengthDisplay;
	}, [fullLengthDisplay, maxLen]);

	return (
		<Tooltip title={<span>{user ? `${user.firstName} ${user.lastName} (${username})` : username}</span>}>
			<span>{display}</span>
		</Tooltip>
	);
}
