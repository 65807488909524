import {useAsync} from "react-async-hook";
import {Navigate} from "react-router";
import {createElement} from "react";
import {TenantLoading} from "./TenantLoading";
import {TenantsProvider} from "../domain/TenantsProvider";

export function TenantAutoSelection() {
    const {loading, result} = useAsync(async () => {
        const tenants = await TenantsProvider.list();
        if (tenants.length == 1) {
            return tenants.pop();
        }
    }, []);

    if (loading) {
        return (
            <TenantLoading/>
        );
    }

    if (result) {
        return (
            <Navigate to={`/${result.centerId}`} />
        );
    }

    return (
        <Navigate to="/tenants"/>
    )
}