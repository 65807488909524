import * as React from "react";
import {createElement} from "react";
import {makeStyles} from "@mui/styles";
import {grey} from "@mui/material/colors";

interface CodeDisplayProps {
    code: string;
    inActive?: boolean;
}

export function CodeDisplay({code, inActive}: CodeDisplayProps) {
    const classes = useStyles();
    return (<span className={inActive ? classes.inActive : classes.code}>{code}</span>);
}

const useStyles = makeStyles(theme => ({
    code: {
        fontFamily: 'monospace'
    },
    inActive: {
        fontFamily: 'monospace',
        color: grey[500],
        textDecoration: 'line-through'
    }
}));
