export enum ChangeType {
    Create = "Create",
    Update = "Update",
    Delete = "Delete"
}

export interface DataChangeMessageBody {
    tenantId: string;
    id: string;
    changeType: ChangeType;
    entityName: string;
    entityId: string;
    path: string;
    display: string;
}

export enum WebsocketMessageType {
    Notification = "Notification",
    DataChange = "DataChange"
}

export interface WebsocketMessage {
    messageType: WebsocketMessageType;
    data: DataChangeMessageBody; // Or others
}

interface WebsocketMessageListener {
    listener: (message: WebsocketMessage) => boolean;
    filter?: WebsocketMessageType[];
}

export class WebsocketConnection {

    readonly websocketUrl: string;
    private websocket?: WebSocket;

    private connected: boolean;

    readonly listeners: WebsocketMessageListener[];
    private keepAliveInterval: any;

    constructor(websocketUrl: string) {
        this.websocketUrl = websocketUrl;
        this.listeners = [];
        this.connected = false;
    }

    connect() {
        this.websocket = new WebSocket(this.websocketUrl);

        this.websocket.onopen = (event) => {
            console.log(new Date().toISOString());
            console.log(event);
            this.connected = true;
            this.setupKeepAlive();
        };

        this.websocket.onclose = (event) => {
            console.log(new Date().toISOString());
            console.log(event);
            this.connected = true;
            this.cancelKeepAlive();
        };

        this.websocket.onerror = (event) => {
            console.log(event);
        };

        this.websocket.onmessage = (event) => {
            const message: WebsocketMessage = JSON.parse(event.data);
            console.log(`Received message: ${message.messageType} distributing to ${this.listeners.length} listeners`);
            let count = 0;
            for(let listener of this.listeners) {
                if(!listener.filter || listener.filter.includes(message.messageType)) {
                    if(!listener.listener(message)) {
                        console.log(`Listener ${count} returned false, stopping distribution`);
                        break;
                    }
                }
                count ++;
            }
        };
    }

    addListener(listener: (message: WebsocketMessage) => boolean, filter?: WebsocketMessageType[]): number {
        const nextListener: WebsocketMessageListener = {
            listener: listener,
            filter: filter
        };
        return this.listeners.push(nextListener) -1;
    }

    removeListener(index: number) {
        this.listeners.splice(index, 1);
    }

    setupKeepAlive() {
        this.keepAliveInterval = setInterval(() => {
            if(this.websocket && this.connected) {
                const payload = {
                    "messageType": "KeepAlive",
                    "createdAt": new Date().toISOString()
                };
                this.websocket.send(JSON.stringify(payload));
            }
        }, 5 * 60 * 1000);
    }

    cancelKeepAlive() {
        if(this.keepAliveInterval) {
            clearInterval(this.keepAliveInterval);
        }
    }

    close() {
        if(this.websocket) {
            this.websocket.close();
        }
    }

}
