import DeleteIcon from "@mui/icons-material/Delete";
import {
	Alert,
	Box,
	Button,
	Card,
	CardActions,
	CardContent,
	CardHeader,
	Chip,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	IconButton,
	List,
	ListItem,
	ListItemText,
	Paper,
	Snackbar,
	Typography,
} from "@mui/material";
import {BreadcrumbItem, Breadcrumbs, TextField, useFlag} from "@variocube/app-ui";
import {ContainerLayout} from "@variocube/app-ui";
import {createElement, Fragment, useCallback, useEffect, useMemo, useState} from "react";
import {useAsync} from "react-async-hook";
import {useParams} from "react-router";
import {Cube, CubeBox} from "../../domain/Cube";
import {CubesProvider} from "../../domain/CubesProvider";
import {DeliveriesProvider} from "../../domain/DeliveriesProvider";
import {useLocalization} from "../../i18n";
import {gs} from "../../theme";
import {BreadcrumbRouterLink} from "../BreadcrumbRouterLink";
import {ConfirmDialog} from "../ConfirmDialog";
import {HelmetTitleWrapper} from "../HelmetTitleWrapper";
import {LabeledData} from "../LabeledData";
import {Loading} from "../Loading";
import {useTenantId, useTenantUserRole} from "../TenantContextProvider";
import {BoxActions} from "./BoxActions";
import {CubeBoxAvailableComponent} from "./CubeBoxAvailableComponent";
import {CubeBoxLockStateComponent} from "./CubeBoxLockStateComponent";
import {OccupanciesList} from "./OccupanciesList";

export function BoxDetails() {
	const {t} = useLocalization();
	const tenantId = useTenantId();
	const {cubeId, boxNumber} = useParams<"cubeId" | "boxNumber">();

	const [cube, setCube] = useState<Cube>();
	const [box, setBox] = useState<CubeBox>();

	const [boxOpen, setBoxOpen] = useState<boolean>(false);
	const [boxEnable, setBoxEnable] = useState<boolean>(false);
	const [boxDisable, setBoxDisable] = useState<boolean>(false);

	const [showMaintenanceDialog, setShowMaintenanceDialog] = useState<boolean>(false);

	const [error, setError] = useState<string | undefined>();

	function load() {
		if (cubeId && boxNumber) {
			CubesProvider.get(tenantId, cubeId).then(setCube);
			CubesProvider.getBox(tenantId, cubeId, boxNumber).then(box => {
				setBox(box);
				getDeliveriesForBox(box);
			});
		}
	}

	function getDeliveriesForBox(cubebox: CubeBox) {
		cubebox.occupancies?.forEach(occupancy => {
			occupancy.deliveries = [];
			occupancy.deliveryIds?.forEach(deliveryId => {
				DeliveriesProvider.get(tenantId, deliveryId)
					.then((delivery) => {
						occupancy.deliveries?.push(delivery);
						setBox(undefined);
						setBox(cubebox);
					})
					.catch(() => {
						console.error("cannot get deliveries for deliveryId=", deliveryId);
					});
			});
		});
	}

	useEffect(() => {
		load();
	}, [tenantId, cubeId, boxNumber]);

	const handleOpenBox = useCallback(() => {
		if (tenantId && cube && box) {
			setError(undefined);
			CubesProvider.openBox(tenantId, cube.hostname, box.number).then(() => {
				setTimeout(() => {
					CubesProvider.getBox(tenantId, cube.hostname, box.number).then(setBox);
				}, 1000);
			}).catch((error) => {
				setError(error.toString());
			});
		}
		setBoxOpen(false);
	}, [tenantId, cube, box]);

	const handleEnableBox = useCallback(() => {
		if (cube && box) {
			CubesProvider.setBoxState(tenantId, cube.hostname, box.number, false).then(() => {
				CubesProvider.getBox(tenantId, cube.hostname, box.number).then(setBox);
			});
		}
		setBoxEnable(false);
	}, [tenantId, cube, box]);

	const handleDisableBox = useCallback(() => {
		if (cube && box) {
			CubesProvider.setBoxState(tenantId, cube.hostname, box.number, true).then(() => {
				CubesProvider.getBox(tenantId, cube.hostname, box.number).then(setBox);
			});
		}
		setBoxDisable(false);
	}, [tenantId, cube, box]);

	const handleCompleteMaintenance = useCallback(() => {
		if (cube && box) {
			CubesProvider.completeMaintenance(tenantId, cube.hostname, box.number).then(() => {
				CubesProvider.getBox(tenantId, cube.hostname, box.number)
					.then(setBox);
			});
			setShowMaintenanceDialog(false);
		}
	}, [tenantId, cube, box]);

	const hasOccupancies = useMemo<boolean>(
		() => box != undefined && box.occupancies != undefined && box.occupancies.length > 0,
		[box],
	);

	const {isAdmin} = useTenantUserRole();

	if (cube && box) {
		return (
			<ContainerLayout>
				<HelmetTitleWrapper pageTitle={`${cube.name} : ${box.number}`} />
				<Grid container spacing={gs}>
					<Grid item xs={12}>
						<Breadcrumbs>
							<BreadcrumbRouterLink to={`/${tenantId}/cubes`}>{t("cubes.plural")}</BreadcrumbRouterLink>
							<BreadcrumbRouterLink to={`/${tenantId}/cubes/${cube.hostname}`}>
								{cube.name}
							</BreadcrumbRouterLink>
							<BreadcrumbItem>{box.description}</BreadcrumbItem>
						</Breadcrumbs>
					</Grid>
					<Grid item xs={12}>
						<Grid container spacing={gs}>
							<Grid item style={{flexGrow: 1}}>
								<Typography variant="h2">{`${cube.name}: ${box.description}`}</Typography>
							</Grid>
							<Grid item>
								<BoxActions
									box={box}
									handleOpenBox={() => setBoxOpen(true)}
									handleEnableBox={() => setBoxEnable(true)}
									handleDisableBox={() => setBoxDisable(true)}
								/>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={isAdmin ? 9 : 12}>
						<Grid container spacing={gs}>
							{box.disabled && (
								<Grid item xs={12}>
									<Paper>
										<Alert
											severity="warning"
											action={
												<Button
													size="small"
													variant="contained"
													color="primary"
													onClick={() => setBoxEnable(true)}
												>
													{t("cubes.boxes.enableBox")}
												</Button>
											}
										>
											{t("cubes.boxes.disabledInfo")}
										</Alert>
									</Paper>
								</Grid>
							)}
							{box.maintenanceRequiredAt && (
								<Grid item xs={12}>
									<Paper>
										<Alert
											severity="warning"
											action={
												<Button
													size="small"
													variant="contained"
													color="primary"
													onClick={() => setShowMaintenanceDialog(true)}
												>
													{t("cubes.boxes.maintenanceEnd")}
												</Button>
											}
										>
											{t("cubes.boxes.maintenanceInfo")}
										</Alert>
									</Paper>
								</Grid>
							)}
							<Grid item xs={12}>
								<BoxHeader
									box={box}
								/>
							</Grid>
							{!hasOccupancies && (
								<Grid item xs={12}>
									<Paper>
										<Alert severity="info">{t("cubes.boxes.noOccupancies")}</Alert>
									</Paper>
								</Grid>
							)}
							{hasOccupancies && (
								<Grid item xs={12}>
									<OccupanciesList
										cube={cube}
										occupancies={box.occupancies}
										onReload={() => load()}
									/>
								</Grid>
							)}
						</Grid>
					</Grid>
					{isAdmin && (
						<Grid item xs={3}>
							{cubeId && boxNumber && (
								<BoxAccessKeys
									centerId={tenantId}
									cubeId={cubeId}
									boxNumber={boxNumber}
								/>
							)}
						</Grid>
					)}
				</Grid>
				<ConfirmDialog
					open={boxEnable}
					title={t("cubes.boxes.enableBox")}
					message={t("cubes.boxes.enableBoxInfo", {boxDescription: box.description})}
					confirmLabel={t("cubes.boxes.enableBox")}
					resolve={handleEnableBox}
					reject={() => setBoxEnable(false)}
				/>
				<ConfirmDialog
					open={boxDisable}
					title={t("cubes.boxes.disableBox")}
					message={t("cubes.boxes.disableBoxInfo", {boxDescription: box.description})}
					confirmLabel={t("cubes.boxes.disableBox")}
					resolve={handleDisableBox}
					reject={() => setBoxDisable(false)}
				/>
				<ConfirmDialog
					open={boxOpen}
					title={t("cubes.boxes.openBox")}
					message={t(hasOccupancies ? "cubes.boxes.openBoxInfoOccupied" : "cubes.boxes.openBoxInfoEmpty", {
						boxDescription: box.description,
					})}
					confirmLabel={t("cubes.boxes.openBox")}
					resolve={handleOpenBox}
					reject={() => setBoxOpen(false)}
				/>
				<ConfirmDialog
					open={showMaintenanceDialog}
					title={t("cubes.boxes.maintenanceEnd")}
					message={t("cubes.boxes.maintenanceDialogMessage")}
					confirmLabel={t("cubes.boxes.maintenanceEnd")}
					resolve={handleCompleteMaintenance}
					reject={() => setShowMaintenanceDialog(false)}
				/>
				<Snackbar open={error != undefined}>
					<Alert severity="error">{error}</Alert>
				</Snackbar>
			</ContainerLayout>
		);
	} else {
		return <Loading />;
	}
}

interface BoxHeaderProps {
	box: CubeBox;
}

function BoxHeader(props: BoxHeaderProps) {
	const {t} = useLocalization();
	const {box} = props;
	return (
		<Paper>
			<Box p={gs}>
				<Grid container spacing={gs}>
					<Grid item>
						<LabeledData label={t("cubes.boxes.lockState.label")}>
							<CubeBoxLockStateComponent lockState={box.lockStatus} />
						</LabeledData>
					</Grid>
					<Grid item style={box.maintenanceRequiredAt ? {} : {flexGrow: 1}}>
						<LabeledData label={t("cubes.boxes.available")}>
							<CubeBoxAvailableComponent available={!box.disabled} />
						</LabeledData>
					</Grid>
					{box.maintenanceRequiredAt
						&& (
							<Grid item style={{flexGrow: 1}}>
								<LabeledData label={t("cubes.boxes.maintenance")}>
									<Chip
										label={t("cubes.boxes.maintenanceRequired")}
									/>
								</LabeledData>
							</Grid>
						)}
				</Grid>
			</Box>
		</Paper>
	);
}

interface BoxAccessKeysProps {
	centerId: string;
	cubeId: string;
	boxNumber: string;
}

function BoxAccessKeys({centerId, cubeId, boxNumber}: BoxAccessKeysProps) {
	const {t} = useLocalization();

	const [selectKey, setSelectKey] = useState<string>();
	const [addDialog, setAddDialog, clearAddDialog] = useFlag(false);
	const [newKey, setNewKey] = useState("");

	const {result: keys, error, loading, execute} = useAsync(
		() => CubesProvider.getBoxAccessKeys(centerId, cubeId, boxNumber),
		[centerId, cubeId, boxNumber],
	);

	const addAccessKey = useCallback(async () => {
		if (newKey && keys) {
			try {
				await CubesProvider.setBoxAccessKeys(centerId, cubeId, boxNumber, keys.concat(newKey));
				await execute();
				clearAddDialog();
				setNewKey("");
			} catch (err) {
				console.error("Failed to add box access keys", err);
			}
		}
	}, [centerId, cubeId, boxNumber, newKey, keys, clearAddDialog, execute]);
	async function deleteAccessKey() {
		if (selectKey && keys) {
			try {
				await CubesProvider.setBoxAccessKeys(centerId, cubeId, boxNumber, keys.filter(k => k !== selectKey));
				await execute();
				setSelectKey(undefined);
			} catch (err) {
				console.error("Failed to delete box access keys", err);
			}
		}
	}

	const keyConflict = !!keys && keys.includes(newKey);
	return (
		<Fragment>
			<Card>
				<CardHeader title={t("cubes.boxes.accessKeys")} />
				<CardContent>
					{keys && (
						<Fragment>
							{keys.length > 0 && (
								<List dense>
									{keys.map(k => (
										<ListItem
											key={k}
											secondaryAction={
												<IconButton
													edge="end"
													color="error"
													onClick={() => setSelectKey(k)}
												>
													<DeleteIcon />
												</IconButton>
											}
										>
											<ListItemText primary={k} />
										</ListItem>
									))}
								</List>
							)}
							{keys.length === 0 && <Typography>{t("cubes.boxes.noAccessKeys")}</Typography>}
						</Fragment>
					)}
					{loading && <Loading />}
				</CardContent>
				<CardActions sx={{p: 2}}>
					<Button size="small" variant="outlined" color="primary" onClick={setAddDialog}>{t("add")}</Button>
				</CardActions>
			</Card>
			<ConfirmDialog
				open={!!selectKey}
				title={t("cubes.boxes.deleteAccessKeyTitle")}
				message={t("cubes.boxes.deleteAccessKeyPrompt", {accessKey: selectKey})}
				resolve={deleteAccessKey}
				reject={() => setSelectKey(undefined)}
			/>
			<Dialog
				open={addDialog}
				onClose={clearAddDialog}
				fullWidth
				maxWidth="sm"
			>
				<DialogTitle>{t("cubes.boxes.addAccessKeyTitle")}</DialogTitle>
				<DialogContent>
					<Box my={1} />
					<TextField
						fullWidth
						variant="outlined"
						label={t("cubes.boxes.addAccessKeyLabel")}
						value={newKey}
						onChange={setNewKey}
						error={keyConflict}
						helperText={keyConflict && t("cubes.boxes.accessKeysExists")}
					/>
				</DialogContent>
				<DialogActions>
					<Button variant="contained" color="primary" disabled={keyConflict} onClick={addAccessKey}>
						{t("save")}
					</Button>
					<Button onClick={clearAddDialog}>{t("cancel")}</Button>
				</DialogActions>
			</Dialog>
		</Fragment>
	);
}
