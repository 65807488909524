import {Autocomplete, CircularProgress} from "@mui/material";
import {ErrorAlert, TextField} from "@variocube/app-ui";
import {createElement, Fragment, useCallback} from "react";
import {useAsync} from "react-async-hook";
import {useAuditLog} from "../../domain/audit-log-provider";

interface AuditTypeSelectProps {
	tenantId: string;
	value: string | null;
	onChange: (type: string | null) => void;
}

export function AuditTypeSelect({tenantId, value, onChange}: AuditTypeSelectProps) {
	const {getTypes} = useAuditLog();

	const {loading, error, result: auditTypes} = useAsync(async () =>
		getTypes(tenantId)
		, [tenantId]
	);

	const handleChange = useCallback((_event, auditType) => onChange(auditType), []);

	return (
		<Fragment>
			<Autocomplete
				multiple={false}
				value={value}
				onChange={handleChange}
				options={auditTypes ?? []}
				loading={loading}
				autoHighlight
				renderOption={(props, option) => (
					<li {...props}>
						{option}
					</li>
				)}
				renderInput={(params) => (
					<TextField
						{...params}
						label="Type"
						InputProps={{
							...params.InputProps,
							endAdornment: (
								<Fragment>
									{loading ? <CircularProgress color="inherit" size={20} /> : null}
									{params.InputProps.endAdornment}
								</Fragment>
							),
						}}
					/>
				)}
			/>
			{error && <ErrorAlert error={error} />}
		</Fragment>
	);
}
