import {AppAuditLogEntry, createQueryString, Instant, Page, SpringPageable} from "@variocube/app-ui";
import {Paging} from "@variocube/app-ui/src/Paging/Paging";
import {Api} from "../Api";
import {
	CreateDeliveryRequest,
	Delivery,
	DeliveryFilterRequest,
	DeliveryKpi,
	DeliveryLearnedDetails,
	DeliveryNote,
	DeliveryQueueItem,
	DeliveryState,
	Handover,
	Recipient,
} from "./Delivery";
import {TemplateType} from "./TenantUser";

export class DeliveriesProvider {
	static async list(tenantId: string, paging: Paging, filter: DeliveryFilterRequest) {
		let response = await Api.GET(
			`tenants/${tenantId}/deliveries?${paging.toQueryString(this.makeDeliveryFilterQueries(filter))}`,
			{"Accept": "application/vnd.deliveries.paged+json"},
		);
		return response as Page<Delivery>;
	}

	static async resendNotifications(
		tenantId: string,
		filter: DeliveryFilterRequest,
		templateType: TemplateType,
	): Promise<number> {
		return await Api.GET(
			`tenants/${tenantId}/deliveries/resend-notifications?templateType=${templateType}&${
				this.makeDeliveryFilterQueries(filter)
			}`,
		);
	}

	static async listKpi(tenantId: string, filter: DeliveryFilterRequest) {
		let response = await Api.GET(`tenants/${tenantId}/deliveriesForKpi?${this.makeDeliveryFilterQueries(filter)}`);
		return response as DeliveryKpi[];
	}

	static async count(tenantId: string, filter: DeliveryFilterRequest) {
		let response = await Api.GET(`tenants/${tenantId}/deliveries/count?${this.makeDeliveryFilterQueries(filter)}`);
		return response as number;
	}

	static async getAuditLog(tenantId: string, deliveryId: string, pageable: SpringPageable) {
		return await Api.GET(
			`tenants/${tenantId}/deliveries/${deliveryId}/audit?${createQueryString(pageable)}`,
		) as Page<AppAuditLogEntry>;
	}

	private static makeDeliveryFilterQueries(filter: DeliveryFilterRequest) {
		const {
			creationTimeframe,
			storageTimeframe,
			pickupTimeframe,
			...queries
		} = filter;
		return createQueryString({
			ts: new Date().getTime(),
			...queries,
			createdFrom: creationTimeframe?.from,
			createdUntil: creationTimeframe?.until,
			storedFrom: storageTimeframe?.from,
			storedUntil: storageTimeframe?.until,
			pickedUpFrom: pickupTimeframe?.from,
			pickedUpUntil: pickupTimeframe?.until,
		});
	}

	static listRecipientDeliveries(tenantId: string, email: string, paging: Paging) {
		return Api.GET(`tenants/${tenantId}/recipient/${email}/deliveries${paging.toQueryString()}`, {
			Accept: "application/vnd.deliveries.paged+json",
		}).then((response) => {
			return response as Page<Delivery>;
		});
	}

	static listRecipientDeliveriesByPhone(tenantId: string, phone: string, paging: Paging) {
		return Api.GET(`tenants/${tenantId}/recipient/${phone}/deliveries${paging.toQueryString()}`, {
			Accept: "application/vnd.deliveries.paged+json",
		}).then((response) => {
			return response as Page<Delivery>;
		});
	}

	static computeDeliveryLearnedDetails(tenantId: string) {
		return Api.GET(`tenants/${tenantId}/deliveries/learned-details`).then(response =>
			response as DeliveryLearnedDetails
		);
	}

	static computeDeliveryTopSender(tenantId: string, needle: string) {
		return Api.GET(`tenants/${tenantId}/deliveries/top/sender?${createQueryString({needle: needle})}`).then(
			response => response as string[],
		);
	}

	static computeDeliveryTopCarrier(tenantId: string, needle: string) {
		return Api.GET(`tenants/${tenantId}/deliveries/top/carrier?${createQueryString({needle: needle})}`).then(
			response => response as string[],
		);
	}

	static computeDeliveryLearnedTags(tenantId: string) {
		return Api.GET(`tenants/${tenantId}/deliveries/learned-tags`).then(response => response as string[]);
	}

	static get(tenantId: string, uuid: string): Promise<Delivery> {
		return Api.GET(`tenants/${tenantId}/deliveries/${uuid}`).then((response) => {
			if (response.status == 404) {
				throw new Error(`Delivery not found with id ${tenantId}/${uuid}`);
			}
			return response as Delivery;
		});
	}

	static async getDeliveryImage(tenantId: string, uuid: string, type: "Recipient" | "Signature") {
		const response = await Api.GET_NOT_JSON(`tenants/${tenantId}/deliveries/${uuid}/images/${type}`);
		return response.blob();
	}

	static deleteDelivery(tenantId: string, uuid: string) {
		return Api.DELETE(`tenants/${tenantId}/deliveries/${uuid}`).then((response) => {
			return response as Delivery;
		});
	}

	static triggerCallback(tenantId: string, deliveryId: string, deliveryState: DeliveryState) {
		const request = {deliveryState: deliveryState};
		return Api.POST(`tenants/${tenantId}/deliveries/${deliveryId}/trigger-callback`, request).then((response) => {
			return response as Delivery;
		});
	}

	static triggerNextState(tenantId: string, deliveryId: string) {
		const request = {};
		return Api.POST(`tenants/${tenantId}/deliveries/${deliveryId}/next-state`, request).then((response) => {
			return response as Delivery;
		});
	}

	static async create(tenantId: string, request: CreateDeliveryRequest): Promise<Delivery> {
		return Api.POST(`tenants/${tenantId}/deliveries`, request);
	}

	static async eventQueue(tenantId: string, deliveryId: string): Promise<DeliveryQueueItem[]> {
		return Api.GET(`tenants/${tenantId}/deliveries/${deliveryId}/event-queue`)
			.then((response) =>
				response.sort((a: any, b: any) => a.created.localeCompare(b.created)).map(mapDeliveryQueueItem)
			);
	}

	static async markChecked(tenantId: string, deliveryId: string, username: string): Promise<void> {
		const request = {
			username: username,
		};
		return Api.POST(`public/tenants/${tenantId}/deliveries/${deliveryId}/mark-checked`, request, undefined, false);
	}

	static setManualHandover(tenantId: string, deliveryId: string, request: Handover) {
		return Api.POST(`tenants/${tenantId}/deliveries/${deliveryId}/manual-handover`, request);
	}

	static setShipped(tenantId: string, deliveryId: string, parcelId?: string, carrier?: string, trackingUrl?: string) {
		const request = {parcelId: parcelId, carrier: carrier, trackingUrl: trackingUrl};
		return Api.POST(`tenants/${tenantId}/deliveries/${deliveryId}/shipped`, request);
	}

	static setRecipients(
		tenantId: string,
		deliveryId: string,
		recipients: Recipient[],
		destination: string | undefined,
		draft: boolean,
		manualHandoverRequired: boolean,
	): Promise<Delivery> {
		const request = {recipients, draft, manualHandoverRequired, destination};
		return Api.POST(`tenants/${tenantId}/deliveries/${deliveryId}/recipients`, request);
	}

	static addRecipient(tenantId: string, deliveryId: string, recipient: Recipient): Promise<Delivery> {
		const request = {recipient};
		return Api.POST(`tenants/${tenantId}/deliveries/${deliveryId}/recipient/add`, request);
	}

	static sendReminder(tenantId: string, deliveryId: string, message?: string): Promise<Delivery> {
		const request = {message};
		return Api.POST(`tenants/${tenantId}/deliveries/${deliveryId}/send-reminder`, request);
	}

	static findByStorageCode(tenantId: string, storageCode: string): Promise<string> {
		return Api.GET(`tenants/${tenantId}/lookup-delivery?storageCode=${storageCode}`);
	}

	static setTags(tenantId: string, deliveryId: string, tags: string[]): Promise<Delivery> {
		const request = {tags};
		return Api.POST(`tenants/${tenantId}/deliveries/${deliveryId}/tags`, request);
	}

	static addNote(tenantId: string, deliveryId: string, deliverNote: DeliveryNote): Promise<Delivery> { 
		return Api.POST(`tenants/${tenantId}/deliveries/${deliveryId}/notes/add`, deliverNote);
	}

	static deleteNote(tenantId: string, deliveryId: string, deliverNoteId: string): Promise<Delivery> { 
		return Api.DELETE(`tenants/${tenantId}/deliveries/${deliveryId}/notes/${deliverNoteId}`);
	}

	static listTopNotes(tenantId: string): Promise<string[]>  {
		return Api.GET(`tenants/${tenantId}/topnotes`).then(
			response => response as string[],
		);
	}

}

function mapDeliveryQueueItem(i: any): DeliveryQueueItem {
	return {
		...i,
		created: Instant.from(i.created),
		retry: i.retry ? Instant.from(i.retry) : undefined,
		delivered: i.delivered ? Instant.from(i.delivered) : undefined,
		expired: i.expired ? Instant.from(i.expired) : undefined,
	};
}
