import {Card, CardContent, CardHeader, FormControlLabel, Grid, Switch, Typography} from "@mui/material";
import {createElement, useCallback, useState} from "react";
import {localPrintSettings} from "../../domain/localPrintSettings";
import {useLocalization} from "../../i18n";
import {gs} from "../../theme";
import {LHelpButton} from "../LHelpButton";

interface LocalPrintSettingsCardProps {
}

export function LocalPrintSettingsCard(props: LocalPrintSettingsCardProps) {
	const {t} = useLocalization();

	const [labelPrinter, setLabelPrinter] = useState<boolean>(localPrintSettings.labelPrinter);

	const handleLabelPrinterChange = useCallback((checked: boolean) => {
		setLabelPrinter(checked);
		localPrintSettings.labelPrinter = checked;
	}, []);

	return (
		<Card>
			<CardHeader title={t("tenants.printViewLocalSetting.title")} action={<LHelpButton helpPage="Settings" helpAnchor="Local_Print_Settings" language="" />} />
			<CardContent>
				<Grid container spacing={gs}>
					<Grid item xs={12}>
						<Typography variant="body1">
							{t("tenants.printViewLocalSetting.info")}
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<FormControlLabel
							label={t(
								"tenants.printViewLocalSetting.labelPrinter",
							)}
							control={
								<Switch
									checked={labelPrinter}
									onChange={(e) => handleLabelPrinterChange(e.target.checked)}
								/>
							}
						/>
					</Grid>
				</Grid>
			</CardContent>
		</Card>
	);
}
