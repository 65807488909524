import {Link} from "@mui/material";
import {TypographyProps} from "@mui/material/Typography";
import {createElement} from "react";
import {useNavigate} from "react-router";
import {Delivery} from "../../domain/Delivery";
import {useLocalization} from "../../i18n";
import {useTenantId, useTenantUserRole} from "../TenantContextProvider";

export const DeliveryBoxComponent = ({delivery, color}: { delivery: Delivery; color?: TypographyProps["color"] }) => {
	const navigate = useNavigate();
	const tenantId = useTenantId();
	const {isRecipient} = useTenantUserRole();
	const {t} = useLocalization();
	const manualHandover = t("deliveries.deliveryStates.ManualHandover");
	if (delivery.storage) {
		if (delivery.storage?.manualHandoverRequired) {
			return <span>{manualHandover}</span>;
		} else {
			const {destination, destinationDescription} = delivery.storage;
			if (delivery.storage.destination && delivery.delivery.boxNumber) {
				let destination = delivery.storage.destination;
				const {boxNumber} = delivery.delivery;
				let boxNumbers = boxNumber;
				if (delivery.delivery.boxNumbers && delivery.delivery.boxNumbers.length > 0) {
					boxNumbers = delivery.delivery.boxNumbers.join(", ");
				}
				if (!isRecipient) {
					return (
						<Link
							title={delivery.storage.destination}
							color={color}
							sx={{cursor: "pointer"}}
							onClick={() => navigate(`/${tenantId}/cubes/${destination}/boxes/${boxNumber}`)}
						>
							{`${destinationDescription}: ${boxNumbers}`}
						</Link>
					);
				} else {
					return <span>{`${destinationDescription}: ${boxNumbers}`}</span>;
				}
			}
			if (!isRecipient) {
				return (
					<Link
						title={delivery.storage.destination}
						color={color}
						sx={{cursor: "pointer"}}
						onClick={() => navigate(`/${tenantId}/cubes/${destination}`)}
					>
						{`${destinationDescription}`}
					</Link>
				);
			} else {
				return <span>{`${destinationDescription}`}</span>;
			}
		}
	}
	return <span />;
};
