import {
	Alert,
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	Paper,
	Stack,
	Typography
} from "@mui/material";
import { BreadcrumbItem, Breadcrumbs } from "@variocube/app-ui";
import { createElement, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { RecipientMutation } from "../../domain/Delivery";
import { RecipientsProvider } from "../../domain/RecipientsProvider";
import { useLocalization } from "../../i18n";
import { gs } from "../../theme";
import { BreadcrumbRouterLink } from "../BreadcrumbRouterLink";
import { ContainerLayout } from "@variocube/app-ui";
import { HelmetTitleWrapper } from "../HelmetTitleWrapper";
import { useTenant, useTenantUserRole } from "../TenantContextProvider";
import { RecipientForm } from "./RecipientForm";
import { useAsync } from "react-async-hook";
import { Loading } from "../Loading";

export function RecipientEdit() {
	const { recipientId } = useParams<"recipientId">();
	const navigate = useNavigate();

	const [showDeleteDialog, setShowDeleteDialog] = useState(false)
	const [alert, setAlert] = useState<string>();
	const [error, setError] = useState(false);
	const tenant = useTenant();
	const { t } = useLocalization();

	const { result: recipient, error: fetchError, loading } = useAsync(async () => !!recipientId ? RecipientsProvider.get(tenant.centerId, recipientId) : undefined, [tenant, recipientId])


	async function handleDelete() {
		if (recipient && !!recipient.id) {
			setError(false);
			setShowDeleteDialog(false);
			try {
				await RecipientsProvider.delete(tenant.centerId, recipient.id);
				navigate(`/${tenant.centerId}/recipients`);
			} catch (err) {
				console.error('failed to delete recipient', err);
				setError(true);
			}
		}
	}

	async function handleSave(mutation: RecipientMutation) {
		if (recipient?.id) {
			try {
				await RecipientsProvider.update(tenant.centerId, recipient.id, mutation);
				navigate(`/${tenant.centerId}/recipients/${recipient.id}`);
			} catch (err) {
				console.error('failed update recipient', err);
				setAlert(t("recipients.exists"));
			}
		}
	}

	return (
		<ContainerLayout>
			<HelmetTitleWrapper pageTitle={recipient?.recipientName ?? t('recipients.singular')} />
			<Grid container spacing={gs}>
				<Grid item xs={12}>
					<Breadcrumbs>
						<BreadcrumbRouterLink to={`/${tenant.centerId}/recipients`}>
							{t("recipients.plural")}
						</BreadcrumbRouterLink>
						<BreadcrumbItem>{recipient?.recipientName ?? t('recipients.singular')}</BreadcrumbItem>
						<BreadcrumbItem>{t("edit")}</BreadcrumbItem>
					</Breadcrumbs>
				</Grid>
				<Grid item flexGrow={1}>
					<Typography variant="h2">{recipientId}</Typography>
				</Grid>
				<Grid item xs={12}>
					{fetchError && <Alert severity="error" title={t('loadDataError.message')} />}
					{recipient && (
						<RecipientForm
							recipient={recipient}
							onSubmit={handleSave}
							alert={alert}
						/>
					)}
					{loading && <Loading />}
				</Grid>
				<Grid item xs={12}>
					<Paper>
						<Box p={2} display="flex" flexDirection="row" alignItems="center">
							<Box flexGrow={1}>
								<Typography>
									<strong>{t("warning.title")}</strong>
								</Typography>
								<Typography>{t("warning.hint")}</Typography>
							</Box>
							<Button variant="outlined" color="error" onClick={() => setShowDeleteDialog(true)}>
								{t("recipients.actions.delete")}
							</Button>
						</Box>
					</Paper>
				</Grid>
				<Dialog open={showDeleteDialog} fullWidth maxWidth="sm">
					<DialogTitle>{t("recipients.actions.delete")}</DialogTitle>
					<DialogContent>
						<Stack direction="column" spacing={3}>
							<Typography>{t("recipients.deletePrompt")}</Typography>
							{error && <Alert severity="error">{t('recipients.saveError')}</Alert>}
						</Stack>

					</DialogContent>
					<DialogActions>
						<Button variant="contained" color="primary" onClick={() => handleDelete()}>{t("yes")}</Button>
						<Button onClick={() => setShowDeleteDialog(false)}>{t("no")}</Button>
					</DialogActions>
				</Dialog>
			</Grid>
		</ContainerLayout>
	)
}
