import * as React from "react";
import {createElement, useCallback, useState} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid} from "@mui/material";
import {gs} from "../../theme";
import {SimpleTextInput} from "../SimpleTextInput";
import {useLocalization} from "../../i18n";

interface AddTagFragmentProps {
    open: boolean;
    onClose: () => void;
    onAdd: (tag: string) => void;
}

export function AddTagFragmentDialog(props: AddTagFragmentProps) {
    const {open, onClose, onAdd} = props;
    const {t} = useLocalization();

    const [tag, setTag] = useState<string>('');

    const handleClose = useCallback(() => {
        onClose();
    }, [onClose]);

    const handleConfirm = useCallback(() => {
        onAdd(tag);
    }, [tag, onAdd]);

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="lg">
            <DialogTitle>{t('tenants.tagFragments.add')}</DialogTitle>
            <DialogContent>
                <Grid container spacing={gs} sx={{paddingTop:'0.5em'}}>
                    <Grid item xs={12}>
                        <SimpleTextInput label={t('tenants.tagFragments.tag')} value={tag} onChange={setTag} />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={() => handleClose()}>{t('cancel')}</Button>
                <Button color="primary" variant="contained" onClick={handleConfirm}>{t('confirm')}</Button>
            </DialogActions>
        </Dialog>
    );
}
