import {createElement} from "react";
import {LHelpButton} from "../LHelpButton";
import {Box, Grid, Typography} from "@mui/material";

interface HeaderWithHelpKeyProps {
	title: string;
	helpPage: string;
	helpAnchor: string;
	language: string;
}

export function HeaderWithHelpKey(props: HeaderWithHelpKeyProps) {
	const {title, helpPage, helpAnchor, language} = props;

	return (
		<Grid container spacing={1}>
			<Grid item>
				<Box paddingTop={0.35}>
					<Typography variant="h6">{title}</Typography>
				</Box>
			</Grid>
			<Grid item>
				<LHelpButton helpPage={helpPage} helpAnchor={helpAnchor} language={language}  />
			</Grid>
		</Grid>
	);
}
