import {Box, Table, TableBody, TableCell, TableHead, TableRow, Typography} from "@mui/material";
import {Checkbox} from "@variocube/app-ui";
import {createElement, Fragment, useEffect, useState} from "react";
import {DeliveryConfig, DeliveryFieldConfig} from "../../domain/TenantUser";
import {useLocalization} from "../../i18n";

const defaultConfig = {enabled: true, mandatory: false};

interface TenantDeliveryConfigSettingProps {
	config: DeliveryConfig;
	onChange: (config: DeliveryConfig) => void;
}

export default function TenantDeliveryConfigSetting({config, onChange}: TenantDeliveryConfigSettingProps) {
	const {t} = useLocalization();

	const [enabled, setEnabled] = useState(config.enabled);
	const [parcelId, setParcelId] = useState<DeliveryFieldConfig>(config?.parameters?.parcelId ?? defaultConfig);
	const [condition, setCondition] = useState<DeliveryFieldConfig>(config?.parameters?.condition ?? defaultConfig);
	const [weight, setWeight] = useState<DeliveryFieldConfig>(config?.parameters?.weight ?? defaultConfig);
	const [units, setUnits] = useState<DeliveryFieldConfig>(config?.parameters?.units ?? defaultConfig);
	const [maxStorageTime, setMaxStorageTime] = useState<DeliveryFieldConfig>(
		config?.parameters?.maxStorageTime ?? defaultConfig,
	);
	const [sender, setSender] = useState<DeliveryFieldConfig>(config?.parameters?.sender ?? defaultConfig);
	const [carrier, setCarrier] = useState<DeliveryFieldConfig>(config?.parameters?.carrier ?? defaultConfig);
	const [description, setDescription] = useState<DeliveryFieldConfig>(
		config?.parameters?.description ?? defaultConfig,
	);
	const [deliveryNumber, setDeliveryNumber] = useState<DeliveryFieldConfig>(
		config?.parameters?.deliveryNumber ?? defaultConfig,
	);
	const [orderNumber, setOrderNumber] = useState<DeliveryFieldConfig>(
		config?.parameters?.orderNumber ?? defaultConfig,
	);
	const [foreignId, setForeignId] = useState<DeliveryFieldConfig>(config?.parameters?.foreignId ?? defaultConfig);
	const [callbackUrl, setCallbackUrl] = useState<DeliveryFieldConfig>(
		config?.parameters?.callbackUrl ?? defaultConfig,
	);
	const [tags, setTags] = useState<DeliveryFieldConfig>(config?.parameters?.tags ?? defaultConfig);

	const fields = [
		{field: "parcelId", value: parcelId, setter: setParcelId},
		{field: "condition", value: condition, setter: setCondition},
		{field: "weight", value: weight, setter: setWeight},
		{field: "units", value: units, setter: setUnits},
		{field: "maxStorageTime", value: maxStorageTime, setter: setMaxStorageTime},
		{field: "sender", value: sender, setter: setSender},
		{field: "carrier", value: carrier, setter: setCarrier},
		{field: "description", value: description, setter: setDescription},
		{field: "deliveryNumber", value: deliveryNumber, setter: setDeliveryNumber},
		{field: "orderNumber", value: orderNumber, setter: setOrderNumber},
		{field: "foreignId", value: foreignId, setter: setForeignId},
		{field: "callbackUrl", value: callbackUrl, setter: setCallbackUrl},
		{field: "tags", value: tags, setter: setTags},
	];

	useEffect(() => {
		onChange({
			enabled,
			parameters: {
				parcelId,
				condition,
				weight,
				units,
				maxStorageTime,
				sender,
				carrier,
				description,
				deliveryNumber,
				orderNumber,
				foreignId,
				callbackUrl,
				tags,
			},
		});
	}, [
		enabled,
		parcelId,
		condition,
		weight,
		units,
		maxStorageTime,
		sender,
		carrier,
		description,
		deliveryNumber,
		orderNumber,
		foreignId,
		callbackUrl,
		tags,
	]);

	return (
		<Fragment>
			<Typography variant="h5">{t("deliveries.config")}</Typography>
			<Box my={3} />

			<Checkbox label={t("deliveries.enabled")} value={enabled} onChange={setEnabled} />

			<Box my={3} />

			{enabled && (
				<Table size="small">
					<TableHead>
						<TableRow>
							<TableCell>Parameter</TableCell>
							<TableCell width="10%" align="center">{t("enabled")}</TableCell>
							<TableCell width="10%" align="center">{t("returns.mandatory")}</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{fields.map(({field, value, setter}, i) => (
							<TableRow key={"field-" + i}>
								<TableCell>{t("deliveries." + field as any)}</TableCell>
								<TableCell align="center">
									<Checkbox
										label={""}
										value={value.enabled}
										onChange={v => setter(current => ({...current, enabled: v}))}
									/>
								</TableCell>
								<TableCell align="center">
									<Checkbox
										label={""}
										checked={value.mandatory}
										disabled={!value.enabled}
										onChange={v => setter(current => ({...current, mandatory: v}))}
									/>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			)}
		</Fragment>
	);
}
