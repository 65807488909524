import {Grid, Typography} from "@mui/material";
import {createElement} from "react";
import {OutOfOffice} from "../../domain/OutOfOffice";
import {useLocalization} from "../../i18n";
import {gs} from "../../theme";
import {BooleanDisplay} from "../BooleanDisplay";
import {DateComponent} from "../DateComponent";
import {RecipientDisplay} from "../deliveries/RecipientDisplay";
import {LabeledData} from "../LabeledData";
import {LabeledItem} from "../LabeledItem";
import {OutOfOfficeRecipientsView} from "./OutOfOfficeRecipientsView";

export enum ViewMode {
	Recipient = "Recipient",
	Substitute = "Substitute",
}

interface OutOfOfficeViewProps {
	outOfOffice: OutOfOffice | undefined;
	viewMode: ViewMode;
}

export function OutOfOfficeView(props: OutOfOfficeViewProps) {
	const {outOfOffice, viewMode} = props;
	const {t} = useLocalization();

	if (outOfOffice && outOfOffice.active) {
		return (
			<Grid container spacing={1}>
				{viewMode == ViewMode.Recipient
					&& (
						<Grid item xs={12}>
							<Typography variant="overline">
								{t("recipients.outOfOfficeAssistant.title")}
							</Typography>
						</Grid>
					)}
				<Grid item xs={12}>
					<Grid container spacing={2}>
						{viewMode == ViewMode.Substitute && outOfOffice.recipientEmail
							&& (
								<LabeledItem label={t("deliveries.recipientDeliveryTypes.Original")}>
									<OutOfOfficeRecipientsView substituteEmail={outOfOffice.recipientEmail} />
								</LabeledItem>
							)}
						<LabeledItem label={t("enabled")}>
							<BooleanDisplay value={outOfOffice.active || false} />
						</LabeledItem>
						{(outOfOffice.from || outOfOffice.to)
							&& (
								<LabeledItem label={t("timeframe.title")}>
									<DateComponent date={outOfOffice.from} /> -{"  "}
									<DateComponent date={outOfOffice.to} />
								</LabeledItem>
							)}
						{viewMode == ViewMode.Recipient && outOfOffice && outOfOffice.substituteEmail
							&& (
								<LabeledItem label={t("deliveries.recipients.substitute")}>
									<OutOfOfficeRecipientsView substituteEmail={outOfOffice.substituteEmail} />
								</LabeledItem>
							)}
					</Grid>
				</Grid>
			</Grid>
		);
	}
	return <span></span>;
}
