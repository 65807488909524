import * as React from "react";
import {createElement} from "react";
import {ContainerLayout} from "@variocube/app-ui";
import {Box, Button, Grid, List, ListItemButton, ListItemText, Paper, Stack, Typography} from "@mui/material";
import {Roles} from "../../domain/Roles";
import {useLocalization} from "../../i18n";
import {HelmetTitleWrapper} from "../HelmetTitleWrapper";
import {Link, Link as RouterLink} from "react-router-dom";
import {gs} from "../../theme";
import {TenantsProvider} from "../../domain/TenantsProvider";
import {useAsync} from "react-async-hook";
import {TenantUserProvider} from "../../domain/TenantUserProvider";
import {TenantLoading} from "../TenantLoading";
import {TenantError} from "../TenantError";

export function TenantSelection() {
    const {t} = useLocalization();

    const {loading, error, result} = useAsync(async () => {
        const tenants = await TenantsProvider.list();
        const anyTenant = [...tenants].shift();
        if (anyTenant) {
            const user = await TenantUserProvider.me(anyTenant.centerId);
            const superuser = user.roles.indexOf(Roles.superuser) != -1;

            return {tenants, superuser};
        }
    }, []);

    if (loading) {
        return <TenantLoading/>;
    }

    if (error) {
        return <TenantError/>;
    }

    const {tenants, superuser} = result ?? {};

    return (
        <ContainerLayout>
            <HelmetTitleWrapper pageTitle={t('tenants.plural')} />
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Stack spacing={gs} direction="row" alignItems="start">
                        <Box flex={1}>
                            <Typography variant="h2" gutterBottom>{t('tenants.plural')}</Typography>
                            <Typography variant="body1">{t('tenants.select')}</Typography>
                        </Box>
                        {superuser && (
                            <Button variant="contained" color="primary" component={RouterLink} to="/tenants/new">
                                {t('tenants.new')}
                            </Button>
                        )}
                    </Stack>
                </Grid>
                <Grid item xs={12}>
                    {tenants &&
                        <Paper>
                            <List>
                                {tenants.map((tenant) =>
                                    <ListItemButton key={tenant.centerId} component={Link} to={`/${tenant.centerId}`}>
                                        <ListItemText primary={tenant.name || tenant.centerId} />
                                    </ListItemButton>
                                )}
                            </List>
                        </Paper>
                    }
                </Grid>
            </Grid>
        </ContainerLayout>
    );

}
