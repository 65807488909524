import {createContext} from "react";

interface IWizardStepContext {
	step: number;
	setStep: (step: number) => void;
	photoEnabled: boolean;
	ocrEnabled: boolean;
	formLocked: boolean;
	formReset: number;
	setFormReset: (count: number) => void;
}
export const WizardStepContext = createContext<IWizardStepContext>({
	step: 0,
	setStep: _ => {
		throw new Error('WizardStepContext not correctly initiate.')
	},
	photoEnabled: false,
	ocrEnabled: false,
	formLocked: false,
	formReset: 0,
	setFormReset: _ => {
		throw new Error('WizardStepContext not correctly initiate.')
	}
});
