import {createElement, useEffect, useMemo, useState} from "react";
import {Instant, RelativeTemporalFormat, TemporalFormat, useStorage} from "@variocube/app-ui";

export enum Display {
    Timestamp,
    Age,
    Date,
    Time,
}

type Properties = {
    date: Instant | string | number | undefined,
    display?: Display,
}

export function DateComponent({date, display: pDisplay}: Properties) {
    const [storedDisplay, setStoredDisplay] = useStorage('dateComponent.display', Display.Age);
	const [display, setDisplay] = useState(pDisplay ?? storedDisplay);

    const value = useMemo(() => {
        if (typeof date == "string") {
            return Instant.from(date);
        }
        else if (typeof date == "number") {
            return Instant.fromEpochMilliseconds(date);
        }
        else if (typeof date == undefined) {
            return undefined;
        }
        return date;
    }, [date])

	useEffect(() => {
		if (!pDisplay) {
			setDisplay(storedDisplay);
		}
	}, [storedDisplay])

    function handleClick() {
		// if display prop is set, ignore the display change
		if (pDisplay) {
			console.warn('display is forced with ' + pDisplay + ', ignore switch');
			return;
		}
		const newDisplay = display === Display.Age ? Display.Timestamp : Display.Age;
		setDisplay(newDisplay);
		setStoredDisplay(newDisplay);
    }

    return (
        <span onClick={handleClick} style={{cursor: pDisplay ? 'normal' : 'pointer', whiteSpace: 'nowrap'}}>
            {display == Display.Age && <RelativeTemporalFormat value={value} />}
            {display == Display.Date && <TemporalFormat value={value} dateStyle="short" />}
            {display == Display.Time && <TemporalFormat value={value} timeStyle="short" />}
            {display == Display.Timestamp && <TemporalFormat value={value} dateStyle="short" timeStyle="short" />}
        </span>
    );
}
