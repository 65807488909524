import {RingBuffer} from "./RingBuffer";

interface KeyDownEvent {
    code: string,
    keyCode: number,
    key: string;
    timestamp: number
}

export interface BarcodeBrowserConfig {
    maxKeyDelay: number,
    endChar: number[],
    debug: boolean
}

export interface BarCodeEvent {
    detail: string;
}

export const DEFAULT_BARCODE_BROWSER_CONFIG: BarcodeBrowserConfig = {
    maxKeyDelay: 15,
    endChar: [9, 13],
    debug: false
};

class BarcodeBrowser {

    readonly config: BarcodeBrowserConfig;
    private lastKeyDown?: KeyDownEvent;
    private ringBuffer: RingBuffer<KeyDownEvent>;

    constructor(config?: BarcodeBrowserConfig) {
        if(!config) {
            config = DEFAULT_BARCODE_BROWSER_CONFIG;
        }
        this.config = config;
        this.ringBuffer = new RingBuffer<KeyDownEvent>();
        window.addEventListener('keydown', (e) => {
            const keyDownEvent: KeyDownEvent = {
                code: e.code,
                keyCode: e.keyCode,
                key: e.key,
                timestamp: Date.now()
            };
            if(this.config.debug) {
                console.log('Tracked a keydown event', keyDownEvent);
            }
            if(!this.lastKeyDown || keyDownEvent.timestamp - this.lastKeyDown.timestamp <= this.config.maxKeyDelay) {
                if(this.config.debug && this.lastKeyDown) {
                    const diff = keyDownEvent.timestamp - this.lastKeyDown.timestamp;
                    console.log(`Last keydown event was set and is only ${diff} msec old`);
                }
                if(this.config.debug) {
                    console.log('Assuming barcode reader checking for end characters or recording keydown event.');
                }
                if(this.checkForEndOfCode(keyDownEvent)) {
                    this.fireBarcodeDetected();
                }else {
                    this.ringBuffer.put(keyDownEvent);
                    this.lastKeyDown = keyDownEvent;
                }
            }else{
                this.reset();
            }
        });
    }

    private reset() {
        this.lastKeyDown = undefined;
        this.ringBuffer.reset();
    }

    private checkForEndOfCode(keyEvent: KeyDownEvent): boolean {
        return this.config.endChar.filter((c) => c == keyEvent.keyCode).length > 0;
    }

    private fireBarcodeDetected() {
        let barcode = '';
        while (!this.ringBuffer.isEmpty()) {
            const e = this.ringBuffer.get();
            if(printableChar(e.keyCode)) {
                barcode += e.key;
            }
        }
        this.reset();
        if(barcode) {
            const event = new CustomEvent('barcode', { detail: barcode });
            window.dispatchEvent(event);
        }else{
            if(this.config.debug) {
                console.log('Detected barcode was empty, assuming detection error');
            }
        }
    }
}

const printableChar = (keycode: number): boolean => {
    if((keycode > 47 && keycode < 58)    || // number keys
        keycode == 32 || keycode == 13   || // spacebar & return key(s) (if you want to allow carriage returns)
        (keycode > 64 && keycode < 91)   || // letter keys
        (keycode > 95 && keycode < 112)  || // numpad keys
        (keycode > 185 && keycode < 193) || // ;=,-./` (in order)
        (keycode > 218 && keycode < 223)) {
        return true;
    }
    return false;
};

export const BarcodeBrowserInit = (config?: BarcodeBrowserConfig) => {
    (window as any).barcodeBrowser = new BarcodeBrowser(config);
};


