import {
	createElement,
	createContext,
	MutableRefObject,
	useRef,
	useState,
	PropsWithChildren,
	useContext,
	forwardRef, RefObject
} from "react";
import {useFlag} from "@variocube/app-ui";

interface ICameraRecordingContext {
	recording: boolean;
	setRecording: (value: boolean) => void;
	analyzingData: string;
	setAnalyzingData: (value: string) => void;
	analyzeBtnRef: RefObject<HTMLButtonElement>|null;
}

const CameraRecordingContext = createContext<ICameraRecordingContext>({
	recording: false,
	setRecording: _ => {
		throw new Error("CameraRecordingContext not correctly initialized.");
	},
	analyzingData: "",
	setAnalyzingData: _ => {
		throw new Error("CameraRecordingContext not correctly initialized.");
	},
	analyzeBtnRef: null
});

function CameraRecordingProvider({children, analyzeBtnRef}: PropsWithChildren<{ analyzeBtnRef: RefObject<HTMLButtonElement>|null }>) {
	const [recording, setRecording] = useFlag(false);
	const [analyzingData, setAnalyzingData] = useState("");

	return (
		<CameraRecordingContext.Provider
			value={{
				recording,
				setRecording,
				analyzingData,
				setAnalyzingData,
				analyzeBtnRef,
			}}
		>
			{children}
		</CameraRecordingContext.Provider>
	)
}

export function useCameraRecordingContext() {
	return {
		CameraRecordingProvider,
		...useContext(CameraRecordingContext)
	}
}
