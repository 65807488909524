import {ColumnType} from "@variocube/app-ui";
import {DeliveryFilterRequest} from "../domain/Delivery";

export class AppStorage<T> {

    constructor(private _key: string) {
    }

    get(): T|null {
        const value = localStorage.getItem(this._key);
        return value ? JSON.parse(value) as T : null;
    }

    set(value: T) {
        localStorage.setItem(this._key, JSON.stringify(value));
    }

    clear() {
        localStorage.removeItem(this._key);
    }
}

export const deliveryColumns = new AppStorage<ColumnType>('deliveries_columns');
export const deliveryPickupColumns = new AppStorage<ColumnType>('deliveries_pickup_columns');
export const deliveryShippedColumns = new AppStorage<ColumnType>('deliveries_shipped_columns');
export const shipmentColumns = new AppStorage<ColumnType>('shipments_columns');
export const recipientsColumns = new AppStorage<ColumnType>('recipients_columns');
export const recipientViewColumns = new AppStorage<ColumnType>('recipient_view_columns');
export const deliveryFilter = new AppStorage<DeliveryFilterRequest>('deliveries_filter');
export const dashboardFilter = new AppStorage<DeliveryFilterRequest>('dashboard_filter');
export const usersColumns = new AppStorage<ColumnType>('users_columns');
