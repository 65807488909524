import * as React from "react";
import {createElement, useEffect} from "react";
import {useState} from "react";
import {Box, Button, Grid, Typography, Divider} from "@mui/material";
import {BoxFallbackTypesInput} from "./BoxFallbackTypesInput";
import {ReserveBoxFallback} from "../../domain/Cube";
import {useLocalization} from "../../i18n";


interface BoxesFallbackTypesInputProps {
    boxTypes: string[];
    fallbacks: ReserveBoxFallback[];
    onSave: (fallback:  string) => void;
    onCancel: () => void;
}


export function BoxesFallbackTypesInput(props: BoxesFallbackTypesInputProps) {
    const {t} = useLocalization();

    const [canAddBox, setCanAddBox] = useState<boolean>(true)
    let [result, setResult]  = useState<ReserveBoxFallback[]> (props.fallbacks);

    useEffect(() => {
        setResult(props.fallbacks);
    }, [props.fallbacks]);

    function addBox() {
        let usedTypes: string[] = result.map(x => x.boxType);
        let availableTypes = props.boxTypes.filter(x =>  !usedTypes.includes(x));
        if (availableTypes.length > 0) {
            let dFB = props.boxTypes.filter((x => x !==availableTypes[0]))[0];
            let fB  : ReserveBoxFallback = {boxType: availableTypes[0], fallbackTypes: [dFB]}
            let newTypes = [...result]
            newTypes.push(fB);
            setResult(newTypes);
        }
        else
            setCanAddBox(false);
    }

    function onFallbackChange(index: number, boxType: string, fallbackTypes: string[]) {
        let rFB: ReserveBoxFallback = {boxType,fallbackTypes}
        let newTypes = [...result];
        newTypes[index] = rFB;
        setResult(newTypes)
    }

    function handleCancel() {
        props.onCancel();
    }

    function sanitize(messy: ReserveBoxFallback[]) {
        messy.forEach(row=> {
            row.fallbackTypes =  row.fallbackTypes.filter((x, index) => row.fallbackTypes.indexOf(x) === index);
        })
        let sanitized  = messy.filter(x => x.boxType && x.fallbackTypes.length)
        sanitized = sanitized.filter((row,index) =>
            result.findIndex(x => x.boxType === row.boxType ) === index)
        return sanitized;
    }

    function onDeleteRow(rowIndex: number){
        let rows = [...result];
        rows.splice(rowIndex, 1);
        setResult(rows);
    }

    function handleSave() {
       let sanitized = sanitize(result);
       props.onSave(JSON.stringify(sanitized));
       setResult(sanitized);
    }

    return (
        <Grid container spacing={0}>
            <Grid item style={{flexGrow: 1}}  sx={{ mb: 2 }}>
                <Typography variant="h6">{t('reserveBox.title')}</Typography>
            </Grid>
            <Grid item sx={{ mb: 2 }}>
                <Button variant="outlined" disabled = {!canAddBox}
                        onClick={() => addBox()}>
                    {t('reserveBox.addBoxtype')}
                </Button>

            </Grid>
            {(props.boxTypes.length && result) &&
                <React.Fragment>
                    {result.map((value, index) =>
                        <Grid item xs={12}  key={index}  >
                            <BoxFallbackTypesInput boxTypes={props.boxTypes} fallbackTypes={props.boxTypes}
                                                   onChange={ (boxType, fallbackTypes)  =>
                                                       onFallbackChange(index,boxType, fallbackTypes)}
                                                   selectedBoxType={value.boxType}
                                                   selectedFallbackTypes={value.fallbackTypes}
                                                   onDeleteRow={ () => onDeleteRow(index) }
                                                  />
                            {index < result.length  - 1 &&
                                 <Divider variant={"middle"}  sx={{ margin: 2 }} />
                            }
                        </Grid>
                    )}
                </React.Fragment>
            }

            <Grid item xs={12} style={{ display: 'flex', flexDirection: 'row' }} marginY={2}>
                <Box mx={3} style={{ flexGrow: 1 }} />
                <Button sx={{ mr: 2}} variant="outlined" onClick={() => handleCancel()}>{t('cancel')}</Button>
                <Button variant="contained" color="primary" onClick={handleSave} >{t('save')}</Button>
            </Grid>
        </Grid>
    )
}
