export enum PatternType {
    ParcelNumber = "ParcelNumber",
    CarrierName = "CarrierName",
    SenderName = "SenderName",
    Weight = "Weight"
}


interface Pattern {
    patternType: PatternType;
    regex: RegExp;
    carrierId?: string;
    senderName?: string;

}

export interface MatchingResult {
    pattern: Pattern;
    match: string;
}

class TextAnalysis {

    readonly patterns: Pattern[];

    constructor() {
        this.patterns = [{
            patternType: PatternType.ParcelNumber,
            /*     10 36103 05560859 02 4225 */
            regex: /\d\d \d\d\d\d\d \d\d\d\d\d\d\d\d \d\d \d\d/,
            carrierId: 'Post.at'
        }, {
            patternType: PatternType.CarrierName,
            regex: /DHL/,
            carrierId: 'DHL'
        }, {
            patternType: PatternType.CarrierName,
            regex: /Post/,
            carrierId: 'Post.at'
        }, {
            patternType: PatternType.SenderName,
            regex: /[Aa]mazon/,
            senderName: 'Amazon'
        }, {
            patternType: PatternType.Weight,
            regex: /[Gg]ew: \d[.,]\d\d/
        }];
    }

    matchText(text: string): MatchingResult[] {
        const results: MatchingResult[] = [];
        for(let pattern of this.patterns) {
            if(pattern.regex.test(text)) {
                results.push({
                    pattern: pattern,
                    match: text
                });
            }
        }
        return results;
    }

    matchTexts(texts: string[]): MatchingResult[] {
        const results: MatchingResult[] = [];
        for(let text of texts) {
            results.push(... this.matchText(text));
        }
        return results;
    }

}

export const textAnalysis = new TextAnalysis();
