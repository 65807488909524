import * as React from "react";
import {createElement, PropsWithChildren} from "react";
import {LabeledData} from "./LabeledData";
import {Grid, Typography} from "@mui/material";

interface LabeledItemProps extends PropsWithChildren<{ label: string, grow?: boolean }>{
}

export function LabeledItem(props: LabeledItemProps) {
    const {label, children, grow} = props;
    return (
        <Grid item style={ grow ? {flexGrow: 1} : undefined}>
            <LabeledData label={label}>
                <Typography variant="body1" component="div">{children}</Typography>
            </LabeledData>
        </Grid>
    );
}
