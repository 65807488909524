import {CubesProvider} from "../domain/CubesProvider";
import {createElement, Fragment, useEffect, useState} from "react";
import {useTenantId} from "./TenantContextProvider";

class CubeNameCache {

	readonly cache: Map<string, string> = new Map<string, string>();

	async get(tenantId: string, cubeId: string): Promise<string> {
		const cached = this.cache.get(cubeId);
		if (cached) {
			return cached;
		}
		try {
			const fetched = await CubesProvider.get(tenantId, cubeId);
			this.cache.set(cubeId, fetched.name);
			return fetched.name;
		} catch (e) {
			console.log(e);
			return cubeId;
		}
	}

}

const cubeNameCache = new CubeNameCache();

interface CubeNameDisplayProps {
	cubeId: string;
}

export function CubeNameDisplay(props: CubeNameDisplayProps) {
	const tenantId = useTenantId();
	const {cubeId} = props;

	const [name, setName] = useState<string>(cubeId);

	useEffect(() => {
		if(tenantId) {
			cubeNameCache.get(tenantId, cubeId).then(setName);
		}
	}, [tenantId, cubeId]);

	return (<Fragment>{name}</Fragment>);
}
