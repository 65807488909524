import * as React from "react";
import {CubeBox} from "../../domain/Cube";
import {createElement, useCallback, useState} from "react";
import {Button, ButtonGroup, Menu, MenuItem} from "@mui/material";
import {ExpandMore} from "@mui/icons-material";
import {useLocalization} from "../../i18n";
import {useFlag} from "@variocube/app-ui";

interface BoxActionsProps {
    box: CubeBox;
    handleOpenBox: () => void;
    handleEnableBox: () => void;
    handleDisableBox: () => void;
}

export function BoxActions(props: BoxActionsProps) {
    const {
        box,
        handleOpenBox,
        handleEnableBox,
        handleDisableBox
    } = props;

    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [open, setOpen, clearOpen] = useFlag(false);

    const {t} = useLocalization();

    const handleEnableBoxClick = useCallback(() => {
        clearOpen();
        handleEnableBox();
    }, [handleEnableBox]);

    const handleDisableBoxClick = useCallback(() => {
        clearOpen();
        handleDisableBox();
    }, [handleDisableBox]);

    return (
        <React.Fragment>
            <ButtonGroup variant="outlined" size="small" ref={setAnchorEl}>
                <Button onClick={handleOpenBox}>{t("cubes.boxes.openBox")}</Button>
                <Button onClick={setOpen}><ExpandMore/></Button>
            </ButtonGroup>
            <Menu anchorEl={anchorEl} open={open} keepMounted onClose={clearOpen}>
                {box.disabled &&
                    <MenuItem
                        key="enableBox"
                        onClick={handleEnableBoxClick}
                    >
                        {t("cubes.boxes.enableBox")}
                    </MenuItem>
                }
                {!box.disabled &&
                    <MenuItem
                        key="disableBox"
                        onClick={handleDisableBoxClick}
                    >
                        {t("cubes.boxes.disableBox")}
                    </MenuItem>
                }
            </Menu>
        </React.Fragment>
    );
}
