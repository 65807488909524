import {Api} from "../Api";
import {AppAuditLogEntry, createQueryString, SpringPage, SpringPageable} from "@variocube/app-ui";

export interface AuditLogFilter {
	from?: string;
	until?: string;
	search?: string;
	type?: string;
	actor?: string;
}

export function useAuditLog() {
	return {
		list: (tenantId: string, pageable: SpringPageable, filter: AuditLogFilter) =>
			Api.GET(`/tenants/${tenantId}/audit?${createQueryString(pageable, filter)}`)
				.then(response => response as SpringPage<AppAuditLogEntry>),
		getTypes: (tenantId: string) =>
			Api.GET(`/tenants/${tenantId}/audit/types`)
				.then(response => response as string[]),
		getActors: (tenantId: string, search: string|undefined = '') =>
			Api.GET(`/tenants/${tenantId}/audit/actors?${createQueryString({ search })}`)
				.then(response => response as string[])
	}
}
