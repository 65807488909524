import * as React from "react";
import {createContext, createElement, PropsWithChildren, useContext} from "react";
import {useAsync} from "react-async-hook";
import {useParams} from "react-router";
import {localPrintSettings} from "../domain/localPrintSettings";
import {TenantsProvider} from "../domain/TenantsProvider";
import {hasRole, Tenant, User} from "../domain/TenantUser";
import {TenantUserProvider} from "../domain/TenantUserProvider";
import {useLocalization} from "../i18n";
import {PrintViewConfig} from "./settings/PrintViewConfig";
import {TenantError} from "./TenantError";
import {TenantLoading} from "./TenantLoading";

interface TenantContextType {
	user?: User;
	tenant?: Tenant;
	canSwitchTenant: boolean;
}

const TenantContext = createContext<TenantContextType>({canSwitchTenant: false});

export function TenantContextProvider({children}: PropsWithChildren<{}>) {
	const tenantId = useTenantId();
	const {t} = useLocalization();

	const {loading, error, result} = useAsync(async () => {
		if (tenantId) {
			const [user, tenants] = await Promise.all([
				TenantUserProvider.me(tenantId),
				TenantsProvider.list(),
			]);
			const tenant = tenants.find(tenant => tenant.centerId == tenantId);
			if (!tenant) {
				console.error("Could not find current tenant in tenant list", {
					tenantId,
					tenants,
				});
				throw new Error("Could not find current tenant in tenant list.");
			}
			return {user, tenant, tenants};
		}
	}, [tenantId]);

	const {user, tenant, tenants} = result ?? {};

	const isSuperUser = Boolean(user && hasRole(user, "superuser"));
	const hasMultipleTenants = Boolean(tenants && tenants.length > 1);
	const canSwitchTenant = isSuperUser || hasMultipleTenants;

	if (loading) {
		return <TenantLoading />;
	}

	if (error) {
		return <TenantError />;
	}

	return (
		<TenantContext.Provider value={{user, tenant, canSwitchTenant}}>
			{children}
		</TenantContext.Provider>
	);
}

export function useTenantId() {
	const {tenantId} = useParams<"tenantId">();
	if (!tenantId) {
		throw new Error("Could not find `tenantId` in params.");
	}
	return tenantId;
}

export function useTenantUser() {
	const {user} = useContext(TenantContext);
	if (!user) {
		throw new Error("Could not find `user` in tenant context.");
	}
	return user;
}

export function useTenant() {
	const {tenant} = useContext(TenantContext);
	if (!tenant) {
		throw new Error("Could not find `tenant` in tenant context.");
	}
	return tenant;
}

export function useCanSwitchTenant() {
	return useContext(TenantContext).canSwitchTenant;
}

export function useTenantUserRole() {
	const user = useTenantUser();
	return {
		isAdmin: user && (hasRole(user, "admin") || hasRole(user, "superuser")),
		isAdminLight: user && hasRole(user, "adminlight"),
		isSuperUser: user && hasRole(user, "superuser"),
		isRecipient: user && hasRole(user, "recipient"),
		isCarrier: user && hasRole(user, "carrier"),
		isUser: user && (hasRole(user, "user") || !user.roles),
		isIt: user && (hasRole(user, "it"))
	};
}

export function usePrintViewConfig() {
	const tenant = useTenant();
	if (localPrintSettings.labelPrinter) {
		return PrintViewConfig.merge(tenant.printViewConfig);
	} else {
		return PrintViewConfig.merge(tenant.printViewConfigLarge);
	}
}
