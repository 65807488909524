import {createElement, useCallback, useMemo} from "react";
import {CubeSummary} from "../domain/Delivery";
import {MultiStringSelect} from "./MultiStringSelect";

interface CubeSelectInputProps {
	label: string;
	options: CubeSummary[];
	values: CubeSummary[];
	onChange: (newValue: CubeSummary[]) => void;
	onEnter?: () => void;
    onEscape?: () => void;
}

export function CubeSelectInput(props: CubeSelectInputProps) {
	const {label, options, values, onChange, onEnter, onEscape} = props;

	const mappedOptions = useMemo<string[]>(() => options.map((c) => c.hostname), [options]);
	const mappedValues = useMemo<string[]>(() => values.map((c) => c.hostname), [values]);

	const lookupLabel = useCallback((hostname: string) => {
		const cube = options.find((c) => c.hostname === hostname);
		if (cube) {
			return cube.name;
		}
		return hostname;
	}, [options]);

	const handleChange = useCallback((newValues: string[]) => {
		const newCubes: CubeSummary[] = [];
		for (const hostname of newValues) {
			const cube = options.find((c) => c.hostname === hostname);
			if (cube) {
				newCubes.push(cube);
			}
		}
		onChange(newCubes);
	}, [options, onChange]);

	return (
		<MultiStringSelect
			label={label}
			options={mappedOptions}
			values={mappedValues}
			lookupLabel={lookupLabel}
			onChange={handleChange}
			onEnter={onEnter}
			onEscape={onEscape}
		/>
	);
}
