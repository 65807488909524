import {Chip, Grid} from "@mui/material";
import {TemporalFormat, ZonedDateTime} from "@variocube/app-ui";
import {createElement, Fragment, useEffect, useState} from "react";
import {Cube} from "../../domain/Cube";
import {CubesProvider} from "../../domain/CubesProvider";
import {
	DeliveryCondition,
	DeliveryFilterRequest,
	DeliveryFilterTimeframe,
	DeliveryState,
	DeliveryType,
} from "../../domain/Delivery";
import {Site} from "../../domain/Site";
import {SitesProvider} from "../../domain/SitesProvider";
import {useLocalization} from "../../i18n";
import {DeliveryFilterDialog} from "../deliveries/DeliveryFilterDialog";
import {useTenantId, useTenantUser, useTenantUserRole} from "../TenantContextProvider";
import {AppStorage} from "../uis";

const baseFilter: DeliveryFilterRequest = {
	needle: "",
	siteIds: [] as string[],
	cubeIds: [] as string[],
	deliveryStates: [] as DeliveryState[],
	deliveryTypes: [] as DeliveryType[],
	deliveryConditions: [] as DeliveryCondition[],
	tags: [],
	creationTimeframe: {},
	pickupTimeframe: {},
	storageTimeframe: {},
};

interface DeliveryFilterEditProps {
	dialogOpen: boolean;
	onFilterChanged: (deliveryFilterRequest: DeliveryFilterRequest) => void;
	onDialogClosed: () => void;
	appStorage: AppStorage<DeliveryFilterRequest>;
}

export const DeliveryFilterEdit = (props: DeliveryFilterEditProps) => {
	const {e, t} = useLocalization();
	const tenantId = useTenantId();
	const user = useTenantUser();
	const {isAdmin} = useTenantUserRole();

	const [sites, setSites] = useState<Site[]>([]);
	const [cubes, setCubes] = useState<Cube[]>([]);
	const [editFilter, setEditFilter] = useState<boolean>(props.dialogOpen);

	const [filter, setFilter] = useState<DeliveryFilterRequest>({
		needle: "",
		siteIds: [],
		cubeIds: [],
		deliveryStates: [],
		deliveryTypes: [],
		deliveryConditions: [],
		tags: [],
		creationTimeframe: {},
		pickupTimeframe: {},
		storageTimeframe: {},
	});
	const [previousFilter, setPreviousFilter] = useState<DeliveryFilterRequest>();

	useEffect(() => setEditFilter(props.dialogOpen), [props.dialogOpen]);

	useEffect(() => {
		let filter = props.appStorage.get();
		if (filter == null) {
			props.appStorage.set(baseFilter);
			filter = baseFilter;
			if (user.siteId) {
				filter.siteIds.push(user.siteId);
			}
		}
		if (!isAdmin) {
			filter.siteIds = [user.siteId];
		}
		setFilter(filter);
		//init prev filter
		if (!previousFilter) {
			setPreviousFilter(filter);
		}
	}, [props.dialogOpen]);

	useEffect(() => {
		if (isAdmin) {
			CubesProvider.list(tenantId)
				.then(setCubes);
		} else {
			CubesProvider.listSiteCubes(tenantId, user.siteId)
				.then(setCubes);
		}
		SitesProvider.list(tenantId)
			.then(setSites);
	}, [tenantId]);

	const handleResetFilter = () => {
		const newFilter = {...baseFilter};
		updateFilter(newFilter);
	};

	const handleNeedleChange = (needle: string, applyFilter: boolean = false) => {
		if (filter) {
			const newFilter = {...filter, needle};
			updateFilter(newFilter, applyFilter);
		}
	};

	const handleSitesSelect = (sites: Site[], applyFilter: boolean = false) => {
		if (filter) {
			const siteIds = sites.map(s => s.siteId);
			const newFilter = {...filter, siteIds: siteIds};
			updateFilter(newFilter, applyFilter);
		}
	};

	const handelRemoveSite = (siteId: string, applyFilter: boolean = false) => {
		if (filter) {
			const newFilter = {...filter, siteIds: filter.siteIds.filter(s => s !== siteId)};
			updateFilter(newFilter, applyFilter);
		}
	};

	const handleCubesSelect = (cubeIds: string[], applyFilter: boolean = false) => {
		if (filter) {
			const newFilter = {...filter, cubeIds};
			updateFilter(newFilter, applyFilter);
		}
	};

	const handleStateSelect = (state: DeliveryState, selected: boolean, applyFilter: boolean = false) => {
		if (filter) {
			let deliveryStates = filter.deliveryStates || [];
			if (deliveryStates.find(s => s === state)) {
				if (!selected) deliveryStates = deliveryStates.filter(s => s !== state);
			} else {
				if (selected) deliveryStates = deliveryStates.concat(state);
			}
			const newFilter = {...filter, deliveryStates};
			updateFilter(newFilter, applyFilter);
		}
	};

	const handleTypeSelect = (type: DeliveryType, selected: boolean, applyFilter: boolean = false) => {
		if (filter) {
			let deliveryTypes = filter.deliveryTypes || [];
			if (deliveryTypes.find(s => s === type)) {
				if (!selected) deliveryTypes = deliveryTypes.filter(s => s !== type);
			} else {
				if (selected) deliveryTypes = deliveryTypes.concat(type);
			}
			const newFilter = {...filter, deliveryTypes};
			updateFilter(newFilter, applyFilter);
		}
	};

	const handleConditionSelect = (condition: DeliveryCondition, selected: boolean, applyFilter: boolean = false) => {
		if (filter) {
			let deliveryConditions = filter.deliveryConditions || [];
			if (deliveryConditions.find(c => c === condition)) {
				if (!selected) deliveryConditions = deliveryConditions.filter(c => c !== condition);
			} else {
				if (selected) deliveryConditions = deliveryConditions.concat(condition);
			}
			const newFilter = {...filter, deliveryConditions};
			updateFilter(newFilter, applyFilter);
		}
	};

	const handleCreationTimeframeChange = (timeframe: DeliveryFilterTimeframe, applyFilter: boolean = false) => {
		if (filter) {
			const newFilter = {...filter, creationTimeframe: timeframe};
			updateFilter(newFilter, applyFilter);
		}
	};

	const handleStorageTimeframeChange = (timeframe: DeliveryFilterTimeframe, applyFilter: boolean = false) => {
		if (filter) {
			const newFilter = {...filter, storageTimeframe: timeframe};
			updateFilter(newFilter, applyFilter);
		}
	};

	const handlePickedUpTimeframeChange = (timeframe: DeliveryFilterTimeframe, applyFilter: boolean = false) => {
		if (filter) {
			const newFilter = {...filter, pickupTimeframe: timeframe};
			updateFilter(newFilter, applyFilter);
		}
	};

	const handleTags = (tags: string[], applyFilter: boolean = false) => {
		if (filter) {
			const newFilter = {...filter, tags};
			updateFilter(newFilter, applyFilter);
		}
	};

	const handleStoredOverdue = () => {
		if (filter) {
			const newFilter = {...filter, tags: ["overdue"], deliveryStates: [DeliveryState.Stored]};
			updateFilter(newFilter, true);
			setEditFilter(false);
			props.onDialogClosed();
		}
	};

	const updateFilter = (filter: DeliveryFilterRequest, applyFilter: boolean = false) => {
		props.appStorage.set(filter);
		setFilter(filter);
		if (applyFilter)
			props.onFilterChanged(filter);
	};

	const cancelFilter = () => {
		if (previousFilter) {
			props.appStorage.set(previousFilter);
			setFilter(previousFilter);
		} 
	};

	const applyFilter = (filter: DeliveryFilterRequest) => {
		props.appStorage.set(filter);
		setFilter(filter);
		props.onFilterChanged(filter);
	};

	const filterIsFiltering = (filter: DeliveryFilterRequest) => {
		let result = Boolean(
			filter && (filter.deliveryStates && filter.deliveryStates.length > 0)
				|| (filter.tags && filter.tags.length > 0)
				|| (filter.deliveryConditions && filter.deliveryConditions.length > 0)
				|| (filter.deliveryTypes && filter.deliveryTypes.length > 0)
				|| (filter.siteIds && filter.siteIds.length > 0)
				|| (filter.cubeIds && filter.cubeIds.length > 0)
				|| filter.needle && filter.needle.length > 0
				|| filter.creationTimeframe?.from || filter.creationTimeframe?.until
				|| filter.storageTimeframe?.from || filter.storageTimeframe?.until
				|| filter.pickupTimeframe?.from || filter.pickupTimeframe?.until,
		);
		return result;
	};

	const lookupTagLabel = (tag: string) => {
		const reg: RegExp = /^\?\?.+\?\?$/;
		const lookup = t("tags." + tag as any);
		return (lookup && !reg.test(lookup)) ? lookup : tag;
	};

	const clearTag = (tag: string, applyFilter: boolean) => {
		if (filter) {
			const newFilter = {...filter, tags: filter.tags.filter(t => t !== tag)};
			updateFilter(newFilter, applyFilter);
		}
	};

	const filterOptions = (
		<Grid container spacing={1}>
			{!filterIsFiltering(filter)
				&& (
					<Grid item>
						<Chip variant="outlined" label={t("noFilter")} onClick={() => setEditFilter(true)} />
					</Grid>
				)}
			{filter?.needle && (
				<Grid item>
					<Chip
						label={`${t("fulltextSearch")}: ${filter.needle}`}
						onDelete={() => handleNeedleChange("", true)}
					/>
				</Grid>
			)}
			{filter?.tags?.map(tag => (
				<Grid item key={tag}>
					<Chip
						key={tag}
						label={lookupTagLabel(tag)}
						onDelete={() => clearTag(tag, true)}
					/>
				</Grid>
			))}
			{filter?.siteIds.map(siteId => (
				<Grid item key={siteId}>
					<Chip
						key={siteId}
						label={`${t("sites.singular")}: ${sites.find(site => site.siteId == siteId)?.name}`}
						onDelete={isAdmin
							? () => handelRemoveSite(siteId, true)
							: undefined}
					/>
				</Grid>
			))}
			{filter?.cubeIds.map(cubeId => (
				<Grid item key={cubeId}>
					<Chip
						key={cubeId}
						label={`${t("cubes.singular")}: ${cubes.find(cube => cube.hostname == cubeId)?.name}`}
						onDelete={() => handleCubesSelect(filter.cubeIds.filter((c) => c != cubeId), true)}
					/>
				</Grid>
			))}
			{filter?.deliveryStates.map(state => (
				<Grid item key={state}>
					<Chip
						key={state}
						label={`${t("state")}: ${e("deliveries.deliveryStates", state)}`}
						onDelete={() => handleStateSelect(state, false, true)}
					/>
				</Grid>
			))}
			{filter?.deliveryTypes.map(type => (
				<Grid item key={type}>
					<Chip
						key={type}
						label={`${t("deliveries.deliveryType")}: ${e("deliveries.deliveryTypes", type)}`}
						onDelete={() => handleTypeSelect(type, false, true)}
					/>
				</Grid>
			))}
			{filter?.deliveryConditions.map(condition => (
				<Grid item key={condition}>
					<Chip
						key={condition}
						label={`${t("deliveries.condition")}: ${e("deliveries.conditions", condition)}`}
						onDelete={() => handleConditionSelect(condition, false, true)}
					/>
				</Grid>
			))}
			{filter.creationTimeframe?.from && (
				<Grid item>
					<Chip
						label={
							<Fragment>
								{t("deliveries.createdAfter")}:{" "}
								<TemporalFormat value={ZonedDateTime.from(filter.creationTimeframe.from)} />
							</Fragment>
						}
						onDelete={() => handleCreationTimeframeChange({...filter.creationTimeframe, from: undefined}, true)}
					/>
				</Grid>
			)}
			{filter.creationTimeframe?.until && (
				<Grid item>
					<Chip
						label={
							<Fragment>
								{t("deliveries.createdBefore")}:{" "}
								<TemporalFormat value={ZonedDateTime.from(filter.creationTimeframe.until)} />
							</Fragment>
						}
						onDelete={() => handleCreationTimeframeChange({...filter.creationTimeframe, until: undefined}, true)}
					/>
				</Grid>
			)}
			{filter.storageTimeframe?.from && (
				<Grid item>
					<Chip
						label={
							<Fragment>
								{t("deliveries.storedAfter")}:{" "}
								<TemporalFormat value={ZonedDateTime.from(filter.storageTimeframe.from)} />
							</Fragment>
						}
						onDelete={() => handleStorageTimeframeChange({...filter.storageTimeframe, from: undefined}, true)}
					/>
				</Grid>
			)}
			{filter.storageTimeframe?.until && (
				<Grid item>
					<Chip
						label={
							<Fragment>
								{t("deliveries.storedBefore")}:{" "}
								<TemporalFormat value={ZonedDateTime.from(filter.storageTimeframe.until)} />
							</Fragment>
						}
						onDelete={() => handleStorageTimeframeChange({...filter.storageTimeframe, until: undefined}, true)}
					/>
				</Grid>
			)}
			{filter.pickupTimeframe?.from && (
				<Grid item>
					<Chip
						label={
							<Fragment>
								{t("deliveries.pickedUpAfter")}:{" "}
								<TemporalFormat value={ZonedDateTime.from(filter.pickupTimeframe.from)} />
							</Fragment>
						}
						onDelete={() => handlePickedUpTimeframeChange({...filter.pickupTimeframe, from: undefined}, true)}
					/>
				</Grid>
			)}
			{filter.pickupTimeframe?.until && (
				<Grid item>
					<Chip
						label={
							<Fragment>
								{t("deliveries.pickedUpBefore")}:{" "}
								<TemporalFormat value={ZonedDateTime.from(filter.pickupTimeframe.until)} />
							</Fragment>
						}
						onDelete={() => handlePickedUpTimeframeChange({...filter.pickupTimeframe, until: undefined}, true)}
					/>
				</Grid>
			)}
		</Grid>
	);

	return (
		<Fragment>
			{filterOptions}
			<DeliveryFilterDialog
				open={editFilter}
				onClose={() => {
					cancelFilter();
					setEditFilter(false);
					props.onDialogClosed();
				}}
				onApplyFilter={() => {
					applyFilter(filter)
					setEditFilter(false);
					props.onDialogClosed();
				}}
				needle={filter.needle}
				onNeedleChanged={handleNeedleChange}
				cubes={cubes}
				selectedCubeIds={filter.cubeIds}
				sites={sites}
				selectedSiteIds={filter.siteIds}
				onSitesSelected={(s) => handleSitesSelect(s)}
				onCubesSelected={(c) => handleCubesSelect(c)}
				selectedStates={filter.deliveryStates || []}
				onStateSelected={(state, selected) => handleStateSelect(state, selected)}
				selectedTypes={filter.deliveryTypes || []}
				onTypeSelected={handleTypeSelect}
				selectedConditions={filter.deliveryConditions || []}
				onConditionSelected={handleConditionSelect}
				creationTimeframe={filter.creationTimeframe || {}}
				storageTimeframe={filter.storageTimeframe || {}}
				pickedUpTimeframe={filter.pickupTimeframe || {}}
				onCreationTimeframeChanged={handleCreationTimeframeChange}
				onStorageTimeframeChanged={handleStorageTimeframeChange}
				onPickedUpTimeframeChanged={handlePickedUpTimeframeChange}
				tags={filter.tags || []}
				onTags={handleTags}
				onStoredOverdue={handleStoredOverdue}
				onFilterReset={handleResetFilter}
			/>
		</Fragment>
	);
};
