const DEFAULT_RING_BUFFER_SIZE = 2048;

export class RingBuffer<T> {

    readonly capacity: number;
    private buffer: T[];
    private read: number;
    private write: number;

    constructor(capacity?: number) {
        this.capacity = capacity || DEFAULT_RING_BUFFER_SIZE;
        this.buffer = new Array(this.capacity);
        this.read = 0;
        this.write = -1;
    }

    isEmpty(): boolean {
        return this.write < this.read;
    }

    get size(): number {
        if(this.isEmpty()) return 0;
        return (this.write - this.read) + 1;
    }

    put(element: T): T {
        this.write ++;
        this.buffer[this.write % this.capacity] = element;
        return element;
    }

    get(): T {
        if(this.isEmpty()) {
            throw new Error(`This RingBuffer is empty write: ${this.write}, read: ${this.read}`);
        }
        const readCursor = this.read ++;
        return this.buffer[readCursor % this.capacity];
    }

    reset() {
        this.read = 0;
        this.write = -1;
    }

}
