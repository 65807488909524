import {Button, Divider, Grid, Paper, Stack, Typography} from "@mui/material";
import {
	AuditChanges,
	Breadcrumbs,
	ContainerLayout,
	DataTable,
	FilterTimeFrame,
	Now,
	PlainDateTime,
	TextField,
	TimeframePicker,
	TimeFrameType,
	tryParsePlainDate,
	useDataTableStorage,
	useSpringPage,
	useSpringPageable,
} from "@variocube/app-ui";
import {createElement, useMemo, useState} from "react";
import {useAsync} from "react-async-hook";
import {AuditLogFilter, useAuditLog} from "../../domain/audit-log-provider";
import {useLocalization, useTimeframeMessage} from "../../i18n";
import {gs} from "../../theme";
import {BreadcrumbRouterLink} from "../BreadcrumbRouterLink";
import {HelmetTitleWrapper} from "../HelmetTitleWrapper";
import {useTenant} from "../TenantContextProvider";
import {AuditActorSelect} from "./audit-actor-select";
import {AuditCreated} from "./audit-created";
import {AuditExportButton} from "./audit-export-button";
import {AuditTypeChip} from "./audit-type-chip";
import {AuditTypeSelect} from "./audit-type-select";

function toInstantString(datetime: PlainDateTime) {
	return datetime
		.toZonedDateTime(Now.timeZone())
		.toInstant()
		.toJSON();
}

export function AuditLog() {
	const {t} = useLocalization();
	const {list} = useAuditLog();
	const {centerId} = useTenant();

	const storage = useDataTableStorage("AuditLog");
	const pageable = useSpringPageable(storage);

	const [timeFrame, setTimeFrame] = useState<FilterTimeFrame>({type: TimeFrameType.All});
	const [search, setSearch] = useState("");
	const [type, setType] = useState<string | null>(null);
	const [actor, setActor] = useState<string | null>(null);

	const nextFilter = useMemo(() => {
		const filter: AuditLogFilter = {
			from: undefined,
			until: undefined,
			search,
			type: type ?? undefined,
			actor: actor ?? undefined,
		};
		if (timeFrame.from) {
			filter.from = toInstantString(tryParsePlainDate(timeFrame.from)!.toPlainDateTime());
		}
		if (timeFrame.until) {
			filter.until = toInstantString(tryParsePlainDate(timeFrame.until)!.toPlainDateTime({
				hour: 23,
				minute: 59,
				second: 59,
			}));
		}
		return filter;
	}, [search, type, actor, timeFrame]);

	const [filter, setFilter] = useState<AuditLogFilter>(nextFilter);

	const {result, error, loading} = useAsync(async () => {
		return await list(centerId, pageable, filter);
	}, [centerId, pageable, filter]);

	const {rows, page} = useSpringPage(result);

	async function handleSubmit() {
		setFilter(nextFilter);
	}

	async function handleReset() {
		setTimeFrame({type: TimeFrameType.All, from: undefined, until: undefined});
		setSearch("");
		setType(null);
		setActor(null);
		setFilter({});
	}

	return (
		<ContainerLayout>
			<HelmetTitleWrapper pageTitle={t("audit.trail.title")} />
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<Breadcrumbs>
						<BreadcrumbRouterLink to={`/${centerId}/audit`}>{t("audit.trail.title")}</BreadcrumbRouterLink>
					</Breadcrumbs>
				</Grid>
				<Grid item xs={12}>
					<Grid container spacing={gs}>
						<Grid item style={{flexGrow: 1}}>
							<Typography variant="h2" gutterBottom>{t("audit.trail.title")}</Typography>
						</Grid>
						<Grid item>
							<AuditExportButton
								{...{centerId, filter}}
							/>
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={12}>
					<Paper>
						<Grid container spacing={3} sx={{p: 3, pt: 0}}>
							<Grid item xs={12}>
								<TimeframePicker
									value={timeFrame}
									onChange={setTimeFrame}
									i18n={useTimeframeMessage()}
								/>
							</Grid>
							<Grid item sm={4} xs={12}>
								<TextField
									fullWidth
									label={t("fulltextSearch")}
									value={search}
									onChange={setSearch}
								/>
							</Grid>
							<Grid item sm={4} xs={12}>
								<AuditTypeSelect tenantId={centerId} value={type} onChange={setType} />
							</Grid>
							<Grid item sm={4} xs={12}>
								<AuditActorSelect tenantId={centerId} value={actor} onChange={setActor} />
							</Grid>
						</Grid>
						<Divider />
						<Stack
							sx={{px: 3, py: 2}}
							gap={3}
							direction="row"
							justifyContent="flex-end"
						>
							<Button variant="contained" color="primary" disableElevation onClick={handleSubmit}>
								{t("submit")}
							</Button>
							<Button onClick={handleReset}>{t("resetFilter")}</Button>
						</Stack>
					</Paper>
				</Grid>
				<Grid item xs={12}>
					<Paper>
						<DataTable
							columns={[
								{field: "created", label: t("timeframe.title"), component: AuditCreated},
								{field: "type", label: "Type", component: AuditTypeChip},
								{field: "message", label: "Message"},
								{field: "actor", label: "Actor"},
								{
									field: "data",
									label: "Changes",
									component: ({row: {data, patch}}) => <AuditChanges data={data} patch={patch} />,
								},
							]}
							rows={rows}
							page={page}
							loading={loading}
							error={error}
							{...storage}
						/>
					</Paper>
				</Grid>
			</Grid>
		</ContainerLayout>
	);
}
