import {DialogContent, Link, Typography} from "@mui/material";
import {StyledEngineProvider} from "@mui/material/styles";
import {AppShell, VCThemeProvider} from "@variocube/app-ui";
import {ContainerSettingsContextProvider} from "@variocube/app-ui/esm/container/ContainerSettingsContext";
import {HelpSettingsContextProvider} from "@variocube/app-ui/esm/help/HelpSettingsContext";
import {NotFound} from "@variocube/app-ui/esm/layout/NotFound";
import {createElement, Fragment, PropsWithChildren, useMemo} from "react";
import {Navigate, Outlet, Route, Routes} from "react-router";
import {BrowserRouter} from "react-router-dom";
import {authCredentials} from "../auth/authCredentials";
import {BarCodeDetector} from "../barcode/BarCodeDetector";
import {BarCodeMobileScanner} from "../barcode/BarCodeMobileScanner";
import {Roles} from "../domain/Roles";
import {ErrorBoundary} from "../ErrorBoundary";
import {StorageLocalizationProvider, useLocalization} from "../i18n";
import {AuditLog} from "./audit/audit-log";
import {LoginFlow, ProcessToken} from "./auth/LoginFlow";
import {BoxDetails} from "./cubes/BoxDetails";
import {CubesList} from "./cubes/CubesList";
import {CubeView} from "./cubes/CubeView";
import {Dashboard} from "./dashboard/Dashboard";
import {NewDeliveryWizard} from "./deliveries/create";
import {StepPhoto} from "./deliveries/create/step-photo";
import {DeliveryList} from "./deliveries/DeliveryList";
import {DeliveryView} from "./deliveries/DeliveryView";
import {MailroomTaskList} from "./deliveries/MailroomTaskList";
import {DeliveryPrintViewWrapper} from "./deliveries/print/DeliveryPrintViewWrapper";
import {DispatchBookmarkCreate} from "./dispatch/DispatchBookmarkCreate";
import {DispatchBookmarks} from "./dispatch/DispatchBookmarks";
import {DispatchCreate} from "./dispatch/DispatchCreate";
import {DispatchEdit} from "./dispatch/DispatchEdit";
import {FooterBar} from "./FooterBar";
import {ManualNotification} from "./manual-notification/ManualNotification";
import {SideNav, TopNav} from "./NavBar";
import {NotificationsTest} from "./notifications/NotificationsTest";
import {PhotoInbox} from "./photo-inbox/PhotoInbox";
import {SnapPhotos} from "./photo-inbox/SnapPhotos";
import {ChangeRecipientLocale} from "./recipients/ChangeRecipientLocale";
import {RecipientCreate} from "./recipients/RecipientCreate";
import {RecipientEdit} from "./recipients/RecipientEdit";
import {RecipientSearchTest} from "./recipients/RecipientSearchTest";
import {RecipientsList} from "./recipients/RecipientsList";
import {RecipientView} from "./recipients/RecipientView";
import {Settings} from "./settings/Settings";
import {TenantSettingsEdit} from "./settings/TenantSettingsEdit";
import {ShipmentList} from "./shipments/ShipmentList";
import {ShipmentView} from "./shipments/ShipmentView";
import {TenantAutoSelection} from "./TenantAutoSelection";
import {TenantContextProvider, useTenantUser} from "./TenantContextProvider";
import {NewTenantSelection} from "./tenants/NewTenantSelection";
import {TenantSelection} from "./tenants/TenantSelection";
import {OcrTest} from "./test/OcrTest";
import {CurrentUserChangePassword} from "./users/CurrentUserChangePassword";
import {UserCreate} from "./users/UserCreate";
import {UserEdit} from "./users/UserEdit";
import {UsersList} from "./users/UsersList";
import {UserView} from "./users/UserView";
import {WebSocketConnectionContextProvider} from "./WebSocketConnectionContextProvider";

declare const VERSION: string;

export function App() {
	return (
		<StyledEngineProvider injectFirst>
			<VCThemeProvider>
				<StorageLocalizationProvider>
					<ContainerSettingsContextProvider>
						<HelpSettingsWrapper>
							<WebSocketConnectionContextProvider>
								<ErrorBoundary>
									<BrowserRouter>
										<AppRoutes />
									</BrowserRouter>
								</ErrorBoundary>
							</WebSocketConnectionContextProvider>
						</HelpSettingsWrapper>
					</ContainerSettingsContextProvider>
				</StorageLocalizationProvider>
			</VCThemeProvider>
		</StyledEngineProvider>
	);
}

function HelpSettingsWrapper({children}: PropsWithChildren<any>) {
	const {t} = useLocalization();

	const localStorageVar = "appLogisticsVersionLastSeen";
	const changeLogUrl = "https://docs.variocube.com/logistics/Logistics_Changelog";
	const dialogContent = (
		<DialogContent>
			<Typography>
				{t("version.versionChangePopupParagraph1", {
					currentVersion: VERSION,
				})}
			</Typography>
			<br />
			<Typography>
				{t("version.versionChangePopupParagraph2")}
				<Link href={changeLogUrl} target="_blank">Change Log</Link>
			</Typography>
		</DialogContent>
	);

	return (
		<HelpSettingsContextProvider
			baseUrl="https://docs.variocube.com/logistics/"
			changeLogUrl={changeLogUrl}
			localStorageVar={localStorageVar}
			currentVersion={VERSION}
			dialogTitle={t("version.versionChangePopupHeader")}
			dialogContent={dialogContent}
		>
			{children}
		</HelpSettingsContextProvider>
	);
}

function AppRoutes() {
	const isLoggedIn = useMemo<boolean>(() => authCredentials.loggedIn, []);
	const {t} = useLocalization();

	return (
		<Routes>
			<Route index element={isLoggedIn ? <TenantAutoSelection /> : <LoginFlow />} />

			<Route path="/auth/process-token" element={<ProcessToken />} />
			<Route path="/:tenantId/action/setlocale/token/:token" element={<ChangeRecipientLocale />} />

			{isLoggedIn && (
				<Route>
					<Route path="/tenants" element={<TenantSelection />} />
					<Route path="/tenants/new" element={<NewTenantSelection />} />
					<Route
						path="/:tenantId"
						element={
							<TenantContextProvider>
								<BarCodeDetector />
								<AppShell
									appName="Logistics"
									topNav={<TopNav />}
									sideNav={<SideNav />}
									sideNavFixed="lg"
									sideNavWidth={220}
									footer={<FooterBar />}
								>
									<Outlet />
								</AppShell>
							</TenantContextProvider>
						}
					>
						<Route index element={<Navigate to="deliveries" />} />

						<Route path="deliveries">
							<Route index element={<DeliveryList />} />
							<Route path="scanner" element={<BarCodeMobileScanner />} />
							<Route path="create" element={<NewDeliveryWizard />} />
							<Route path=":deliveryId" element={<DeliveryView />} />
							<Route path=":deliveryId/print" element={<DeliveryPrintViewWrapper />} />
						</Route>

						<Route path="shipments">
							<Route index element={<ShipmentList />} />
							<Route path=":shipmentUuid" element={<ShipmentView />} />
						</Route>

						<Route path="dispatch">
							<Route index element={<DispatchBookmarks />} />
							<Route path="create" element={<DispatchBookmarkCreate />} />
							<Route path=":bookmark" element={<DispatchCreate />} />
							<Route path=":bookmark/edit" element={<DispatchEdit />} />
						</Route>

						<Route path="recipients">
							<Route index element={<RecipientsList />} />
							<Route path=":recipientId" element={<RecipientView />} />
							<Route path=":recipientId/edit" element={<RecipientEdit />} />
							<Route path="create" element={<RecipientCreate />} />
							<Route path="test-search" element={<RecipientSearchTest />} />
						</Route>

						<Route path="photo-inbox">
							<Route index element={<PhotoInbox />} />
							<Route path="snap" element={<SnapPhotos />} />
						</Route>

						<Route
							path="cubes"
							element={
								<AllowedUserRoles roles={[Roles.superuser, Roles.admin, Roles.adminlight, Roles.user]}>
									<Outlet />
								</AllowedUserRoles>
							}
						>
							<Route index element={<CubesList />} />
							<Route path=":cubeId/boxes/:boxNumber" element={<BoxDetails />} />
							<Route path=":cubeId" element={<CubeView />} />
							<Route path=":cubeId/tasklist" element={<MailroomTaskList />} />
						</Route>

						<Route path="users">
							<Route index element={<UsersList />} />
							<Route path="new" element={<UserCreate />} />
							<Route path=":username/edit" element={<UserEdit />} />
							<Route path=":username" element={<UserView />} />
						</Route>

						<Route path="settings">
							<Route index element={<Settings />} />
							<Route path="tenant/edit" element={<TenantSettingsEdit isBreadcrumbVisible={true} />} />
							<Route path="change-password" element={<CurrentUserChangePassword />} />
						</Route>

						<Route path="audit" element={<AuditLog />} />

						<Route path="notifications/test" element={<NotificationsTest />} />
						<Route path="camera-test" element={<StepPhoto onPhoto={console.log} onOcr={console.log} />} />
						<Route path="ocr-test" element={<OcrTest />} />
						<Route path="dashboard" element={<Dashboard />} />

						<Route path="manual-notification" element={<ManualNotification />} />
					</Route>
				</Route>
			)}
			<Route
				path="*"
				element={
					<NotFound
						errorMsg={t("notFound.title")}
						pathMsg={t("notFound.info")}
						backToHomeMsg={t("notFound.home")}
					/>
				}
			/>
		</Routes>
	);
}

function AllowedUserRoles({roles, children}: PropsWithChildren<{ roles: Roles[] }>) {
	const {roles: userRole} = useTenantUser();
	if (!roles.includes(userRole as Roles)) {
		return <div></div>;
	}
	return (
		<Fragment>
			{children}
		</Fragment>
	);
}
