import {WebsocketConnection, WebsocketMessage, WebsocketMessageType} from "../WebsocketConnection";
import {
    createContext,
    createElement,
    Fragment,
    PropsWithChildren,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState
} from "react";
import {WebSocketTicketProvider} from "../domain/WebSocketTicketProvider";
import {authCredentials} from "../auth/authCredentials";

export interface WebSocketConnectionContextData {
    addListener: (listener: (message: WebsocketMessage) => boolean, filter?: WebsocketMessageType[]) => number;
    removeListener: (id: number) => void;
}

const emptyContext: WebSocketConnectionContextData = {
    addListener: (listener: (message: WebsocketMessage) => boolean, filter?: WebsocketMessageType[]) => -1,
    removeListener: (id: number) => { /* ignore */ }
};

export const WebSocketConnectionContext = createContext<WebSocketConnectionContextData>(emptyContext);

export function WebSocketConnectionContextProvider({children}: PropsWithChildren<any>) {
	const isLoggedIn = useMemo<boolean>(() => authCredentials.loggedIn, []);
    const [connection, setConnection] = useState<WebsocketConnection | null>(null);

    const handleConnectionClose = useCallback(() => {
        if(connection) {
            connection.close();
            setConnection(null);
        }
    }, [connection]);

    useEffect(() => {
		if(isLoggedIn) {
			console.log("WebSocketConnectionContextProvider: a user is currently logged in, connecting websocket ...");
			WebSocketTicketProvider.getUrl().then((url) =>{
				const connection = new WebsocketConnection(url);
				connection.connect();
				setConnection(connection);
				console.log("WebSocketConnectionContextProvider: Successfully connected websocket.");
			});
		}
        return () => {
            handleConnectionClose();
        };
    }, [isLoggedIn]);

    const addListener = useCallback((listener: (message: WebsocketMessage) => boolean, filter?: WebsocketMessageType[]) => {
        if (connection) {
            return connection.addListener(listener, filter);
        }
        return -1;
    }, [connection]);

    const removeListener = useCallback((id: number) => {
        if (connection) {
            connection.removeListener(id);
        }
    }, [connection]);

    const currentContext = useMemo<WebSocketConnectionContextData>(() => ({
        addListener, removeListener
    }), [addListener, removeListener]);

    return (
        <WebSocketConnectionContext.Provider value={currentContext}>
            {children}
        </WebSocketConnectionContext.Provider>
    );
}

export function useWebSocketConnection() {
    return useContext(WebSocketConnectionContext);
}

interface WebSocketMessageConsumerProps extends PropsWithChildren<{
    tenantId: string;
    path: string;
    onMessage: (message: WebsocketMessage) => void;
}> { }

export function WebSocketMessageConsumer(props: WebSocketMessageConsumerProps) {
    const {tenantId, path, onMessage, children} = props;
    const {addListener, removeListener} = useWebSocketConnection();

    useEffect(() => {
        const id = addListener((message: WebsocketMessage) => {
            if (message.data.tenantId == tenantId && message.data.path === path) {
                onMessage(message);
                return true;
            }
            return false;
        }, [WebsocketMessageType.DataChange]);
        return () => {
            removeListener(id);
        };
    }, [tenantId, path, onMessage, addListener, removeListener]);

    return (
        <Fragment>{children}</Fragment>
    );
}

