import {Grid, Typography} from "@mui/material";
import {BreadcrumbItem, Breadcrumbs} from "@variocube/app-ui";
import {createElement, useCallback, useMemo, useState} from "react";
import {useNavigate} from "react-router";
import {authCredentials} from "../../auth/authCredentials";
import {TenantUserProvider} from "../../domain/TenantUserProvider";
import {useLocalization} from "../../i18n";
import {gs} from "../../theme";
import {BoxedPaper} from "../BoxedPaper";
import {BreadcrumbRouterLink} from "../BreadcrumbRouterLink";
import {ContainerLayout} from "@variocube/app-ui";
import {SimpleButton} from "../SimpleButton";
import {SimpleTextInput} from "../SimpleTextInput";
import {SnackbarMessage, StateSnackbar} from "../StateSnackbar";
import {useTenantId} from "../TenantContextProvider";

export function CurrentUserChangePassword() {
	const {t} = useLocalization();
	const navigate = useNavigate();
	const tenantId = useTenantId();

	const [currentPassword, setCurrentPassword] = useState<string>("");
	const [nextPassword, setNextPassword] = useState<string>("");
	const [confirmPassword, setConfirmPassword] = useState<string>("");
	const [submitting, setSubmitting] = useState<boolean>(false);
	const [error, setError] = useState<SnackbarMessage | undefined>();

	const handleChangePassword = useCallback(() => {
		setSubmitting(true);
		TenantUserProvider.changeMyPassword(tenantId, currentPassword, nextPassword)
			.then((user) => {
				if (user) {
					authCredentials.login(user.username, nextPassword);
					navigate(`/${tenantId}/settings`);
				} else {
					setError({
						severity: "error",
						text: t("users.changePassword.errorCurrentPassword"),
					});
					setCurrentPassword("");
				}
			})
			.catch((e) =>
				setError({
					severity: "error",
					text: e.toString(),
				})
			)
			.finally(() => setSubmitting(false));
	}, [tenantId, currentPassword, nextPassword, confirmPassword]);

	const canSubmit = useMemo<boolean>(() => {
		return Boolean(nextPassword && nextPassword == confirmPassword && currentPassword);
	}, [currentPassword, nextPassword, confirmPassword]);

	return (
		<ContainerLayout>
			<Grid container spacing={gs}>
				<Grid item xs={12}>
					<Breadcrumbs>
						<BreadcrumbRouterLink to={`/${tenantId}/settings`}>{t("settings.plural")}</BreadcrumbRouterLink>
						<BreadcrumbItem>{t("users.changePassword.title")}</BreadcrumbItem>
					</Breadcrumbs>
				</Grid>
				<Grid item xs={12}>
					<Typography variant="h2">{t("users.changePassword.title")}</Typography>
				</Grid>
				<Grid item xs={12}>
					<BoxedPaper loading={submitting}>
						<Grid container spacing={gs}>
							<Grid item xs={6}>
								<SimpleTextInput
									label={t("users.changePassword.currentPassword")}
									type="password"
									value={currentPassword}
									onChange={setCurrentPassword}
									error={!Boolean(currentPassword)}
								/>
							</Grid>
							<Grid item xs={6} />
							<Grid item xs={6}>
								<SimpleTextInput
									label={t("users.changePassword.nextPassword")}
									type="password"
									value={nextPassword}
									onChange={setNextPassword}
									error={!Boolean(nextPassword)}
								/>
							</Grid>
							<Grid item xs={6}>
								<SimpleTextInput
									label={t("users.changePassword.confirmPasswort")}
									type="password"
									value={confirmPassword}
									onChange={setConfirmPassword}
									error={nextPassword != confirmPassword}
								/>
							</Grid>
							<Grid item xs={12}>
								<Typography align="right" component="div">
									<SimpleButton
										primary
										loading={submitting || !canSubmit}
										label={t("users.changePassword.title")}
										onClick={handleChangePassword}
									/>
								</Typography>
							</Grid>
						</Grid>
					</BoxedPaper>
				</Grid>
			</Grid>
			<StateSnackbar message={error} onClose={() => setError(undefined)} />
		</ContainerLayout>
	);
}
