import {Stack, Typography} from "@mui/material";
import {ErrorAlert} from "@variocube/app-ui";
import * as React from "react";
import {createElement} from "react";
import {useAsync} from "react-async-hook";
import {Api} from "../../Api";
import {useLocalization} from "../../i18n";
import {useRequiredParam} from "../../useRequiredParam";
import {Loading} from "../Loading";

export function ChangeRecipientLocale() {
	const {t} = useLocalization();
	const tenantId = useRequiredParam("tenantId");
	const token = useRequiredParam("token");

	const {
		loading,
		error,
		result,
	} = useAsync(async () => {
		await Api.POST(`tenants/${tenantId}/recipients/${token}/locale`);
		return true;
	}, [tenantId, token]);

	return (
		<Stack direction="row" justifyContent="center" sx={{height: "100vh", p: 2}}>
			<Stack direction="column" justifyContent="center">
				{loading && <Loading />}
				{error && <ErrorAlert error={error} />}
				{result && (
					<Typography variant="h1">
						{t("recipients.recipientLocaleChanged")}
					</Typography>
				)}
			</Stack>
		</Stack>
	);
}
