import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, LinearProgress} from "@mui/material";
import * as React from "react";
import {createElement, useEffect, useState} from "react";
import {Cube} from "../../domain/Cube";
import {Recipient} from "../../domain/Delivery";
import {useLocalization} from "../../i18n";
import {gs} from "../../theme";
import {RecipientStep} from "./create-legacy/RecipientStep";
import {Bookmark} from "../../domain/Bookmark";
import {SimpleCheckBox} from "../SimpleCheckBox";

interface RecipientAddDialogDialogProps {
	open: boolean;
	recipient?: Recipient;
	showAll: boolean;
	onSave: (recipient: Recipient | undefined, all: boolean) => void;
	onCancel: () => void;
}

export function RecipientAddDialog({open, recipient: recipientProp, showAll, onSave, onCancel}: RecipientAddDialogDialogProps) {
	const {t} = useLocalization();
	const [submitting, setSubmitting] = useState<boolean>(false);
	const [recipient, setRecipient] = useState<Recipient | undefined>(recipientProp);
	const [all,setAll] = useState<boolean>(true)
	const [bookmark,setBookmark] = useState<Bookmark>();

	useEffect(() => {
		setSubmitting(false);
	}, [open]);

	function handleSave() {
		setSubmitting(true);
		onSave(recipient, all);
	}

	function handleCancel() {
		onCancel();
	}

	const onChange = (recipient: Recipient | undefined): void => {
		setRecipient(recipient);
	};

	return (
		<Dialog open={open} onClose={handleCancel}>
			<DialogTitle>{t("deliveries.redirect.title")}</DialogTitle>
			<DialogContent>
				<Grid container spacing={gs} sx={{paddingTop: "0.5em"}}>
					<Grid item xs={12}>
						<RecipientStep
							recipient={recipient}
							onChange={onChange}
							destinationVisible={false}
							manualHandoverRequiredVisible={false}
							draftVisible={false}
						    onBookmark={setBookmark}/>
					</Grid>
					{showAll &&
						<Grid item xs={12}>
							<SimpleCheckBox label={t("deliveries.redirect.allDeliveries")} checked={all} onChange={setAll} />
						</Grid>
					}
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button variant="outlined" onClick={handleCancel}>{t("cancel")}</Button>
				<Button color="primary" variant="contained" onClick={handleSave} disabled={submitting}>
					{t("confirm")}
				</Button>
			</DialogActions>
			{submitting && <LinearProgress variant="indeterminate" />}
		</Dialog>
	);
}
