import {authCredentials} from "./auth/authCredentials";

const BaseHeaders = {
	"Content-Type": "application/json",
};

export class Api {
	static SERVICE_URL = "/api/v2";

	static buildHeaders() {
		if (authCredentials.loggedIn) {
			return {
				"Authorization": authCredentials.authHeader,
				...BaseHeaders,
			};
		}
		return BaseHeaders;
	}

	static async tryGetJson(response: Response) {
		try {
			return await response.json();
		} catch (error) {
			return;
		}
	}

	static async handleErrors(response: Response) {
		if (response.status == 401 || response.status == 403) {
			authCredentials.logout("Unauthorized Request, firing Error");
			throw new Error(`${response.status}, ${response.statusText}: Authentication error`);
		}
		if (!response.ok) {
			const errorObject = await this.tryGetJson(response);
			throw new Error(errorObject?.message || `${response.status}, ${response.statusText}`);
		}
	}

	static buildUrl(path: string) {
		if (!path.startsWith("/")) {
			path = "/" + path;
		}
		return `${Api.SERVICE_URL}${path}`;
	}

	static async GET(path: string, headers?: any): Promise<any> {
		let headerData = Api.buildHeaders();
		if (headers) headerData = {...headerData, ...headers};
		const response = await fetch(Api.buildUrl(path), {
			method: "GET",
			headers: headerData,
		});
		await this.handleErrors(response);
		if (response.status === 200) {
			try {
				return await response.json();
			} catch (err) {
				console.error("Body might be empty. Return void.");
				return;
			}
		}
	}

	static async GET_NOT_JSON(path: string, headers?: any) {
		let headerData = Api.buildHeaders();
		if (headers) headerData = {...headerData, ...headers};
		const response = await fetch(Api.buildUrl(path), {
			method: "GET",
			headers: headerData,
		});
		await this.handleErrors(response);
		return response;
	}

	static async PUT(path: string, object: any): Promise<any> {
		const response = await fetch(Api.buildUrl(path), {
			method: "PUT",
			body: JSON.stringify(object),
			headers: Api.buildHeaders(),
		});

		await this.handleErrors(response);

		if (response.status === 200) {
			try {
				return await response.json();
			} catch (err) {
				console.error("Body might be empty. Return void.");
				return;
			}
		}
	}

	static async POST(path: string, object: any = null, headers?: any, withJsonResponse = true): Promise<any> {
		let headerData = Api.buildHeaders();
		if (headers) headerData = {...headerData, ...headers};
		const response = await fetch(Api.buildUrl(path), {
			method: "POST",
			body: object != null ? JSON.stringify(object) : null,
			headers: headerData,
		});

		await this.handleErrors(response);
		if (withJsonResponse) {
			return await response.json();
		}
	}

	static async POSTstring(path: string, object: string, headers?: any): Promise<any> {
		let headerData = Api.buildHeaders();
		if (headers) headerData = {...headerData, ...headers};
		const response = await fetch(Api.buildUrl(path), {
			method: "POST",
			body: object,
			headers: headerData,
		});
		await this.handleErrors(response);
		return await response.json();
	}

	static async DELETE(path: string): Promise<any> {
		const response = await fetch(Api.buildUrl(path), {
			method: "DELETE",
			headers: Api.buildHeaders(),
		});
		await this.handleErrors(response);
		const isJson = response.headers.get("content-type")?.includes("application/json");
		if (isJson) {
			return await response.json();
		}
	}
}
