import * as React from "react";
import {createElement} from "react";
import {Chip} from "@mui/material";
import {Cloud, CloudOff} from "@mui/icons-material";
import {useLocalization} from "../../i18n";

interface CubeOnlineComponentProps {
    connected: boolean;
}

export function CubeConnectedComponent(props: CubeOnlineComponentProps) {
    const {connected} = props;
    const {t} = useLocalization();
    const icon = connected ? <Cloud /> : <CloudOff />;
    const label = t(connected ? 'cubes.connected' : 'cubes.notConnected');
    const color = connected ? '#4caf50' : '#f44336';
    return (
        <Chip label={label} style={{color: '#ffffff', backgroundColor: color}} icon={icon} />
    );
}
