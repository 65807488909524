import {useEffect} from "react";

export interface Hotkey {
	key: string;
	altKey?: boolean;
	ctrlKey?: boolean;
}

export function useHotkey(hotkey: Hotkey, callback: () => void) {
	useEffect(() => {
		const listener = (event: KeyboardEvent) => {
			if (hotkey.altKey && !event.altKey) {
				return;
			}
			if (hotkey.ctrlKey && !event.ctrlKey) {
				return;
			}
			if (event.key != hotkey.key) {
				return;
			}
			callback();
		};
		document.addEventListener("keydown", listener);
		return () => {
			document.removeEventListener("keydown", listener);
		};
	}, [hotkey, callback]);
}
