import * as React from "react";
import {createElement, useEffect} from "react";
import {MenuItem, TextField} from "@mui/material";
import {Site} from "../domain/Site";
import {SitesProvider} from "../domain/SitesProvider";
import {useLocalization} from "../i18n";
import {useAsync} from "react-async-hook";
import {useTenantId} from "./TenantContextProvider";

interface SiteSelectInputProps {
    value?: string;
    onChange: (site?: Site) => void;
    autoSelect?: boolean;
    error?: boolean;
    helperText?: string;
    allowSelectAll?: boolean;
    label?: string;
	sites?: Site[],
	disabled?: boolean
}

export function SiteSelectInput(props: SiteSelectInputProps) {
    const {
        value,
        onChange,
        autoSelect = false,
        error,
        helperText,
        allowSelectAll = false,
        label,
		disabled= false
    } = props;

    const {t} = useLocalization();
    const tenantId = useTenantId();

    const {loading, result: sites} = useAsync(async () => {
        let sites = props.sites ??    await SitesProvider.list(tenantId);
		sites = sites.filter(toFilter => toFilter.enabled || toFilter.siteId ===  value);
        return sites.sort(compareSiteName);
    }, [tenantId]);

    useEffect(() => {
        if (autoSelect && sites && sites.length == 1) {
            onChange(sites[0]);
        }
    }, [sites, autoSelect, onChange]);

    return (
        <TextField
            select
            fullWidth
            error={error}
            variant="outlined"
            label={label || t("sites.singular")}
            value={value || "__no_selection"}
            helperText={helperText}
            onChange={(e) => onChange(sites?.find(site => site.siteId == e.target.value))}
			disabled={disabled}
        >
            {allowSelectAll &&
                <MenuItem value="__no_selection" selected={!value}>{t("sites.allSites")}</MenuItem>
            }
            {loading && (
                <MenuItem disabled>{t("loading")}</MenuItem>
            )}
            {sites?.map((site) =>
                <MenuItem key={site.siteId} value={site.siteId}>{site.name}</MenuItem>
            )}
        </TextField>
    );
}

function compareSiteName(a: Site, b: Site) {
    return a.name.localeCompare(b.name);
}
