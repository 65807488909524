import {Delete} from "@mui/icons-material";
import {Grid, IconButton, Typography} from "@mui/material";
import * as React from "react";
import {createElement} from "react";
import {Company, Recipient} from "../../domain/Delivery";
import {useLocalization} from "../../i18n";
import {gs} from "../../theme";
import {SimpleButton} from "../SimpleButton";
import {SimpleTextInput} from "../SimpleTextInput";

interface DispatchCompaniesListProps {
	label: string;
	description: string;
	list: Company[];
	onChange: (list: Company[]) => void;
}

export function DispatchCompaniesList(props: DispatchCompaniesListProps) {
	const {label, description, list, onChange} = props;
	const {t} = useLocalization();

	const handleNameChanged = (index: number, value: string) => {
		const copy = {
			...list[index],
		};
		copy.name = value;
		onChange([...list.slice(0, index), copy, ...list.slice(index + 1)]);
	};

	const handleAdd = () => {
		onChange([...list, {name: ""}]);
	};

	const handleDelete = (index: number) => {
		const copy = [...list];
		copy.splice(index, 1);
		onChange(copy);
	};

	return (
		<Grid container spacing={gs}>
			<Grid item xs={12}>
				<Grid container spacing={gs}>
					<Grid item style={{flexGrow: 1}}>
						<Typography variant="h6">{label}</Typography>
					</Grid>
					<Grid item>
						<SimpleButton label={t("add")} onClick={handleAdd} />
					</Grid>
				</Grid>
			</Grid>
			<Grid item xs={12}>
				<Typography variant="body2">{description}</Typography>
			</Grid>
			{list.map((company, index) => (
				<Grid item xs={12}>
					<Grid container spacing={gs}>
						<Grid item style={{flexGrow: 1}}>
							<Grid container spacing={gs}>
								<Grid item sm={6} xs={12}>
									<SimpleTextInput
										size="small"
										label={t("recipients.name")}
										value={company.name}
										onChange={(value) => handleNameChanged(index, value)}
										error={!Boolean(company.name)}
									/>
								</Grid>
							</Grid>
						</Grid>
						<Grid item>
							<IconButton onClick={() => handleDelete(index)}>
								<Delete />
							</IconButton>
						</Grid>
					</Grid>
				</Grid>
			))}
		</Grid>
	);
}
