import {
	Box,
	Chip,
	Grid,
	MenuItem,
	Select,
	Stack,
} from "@mui/material";
import {TextField} from "@variocube/app-ui";
import {debounce} from "lodash";
import {createElement, useCallback, useContext, useEffect, useMemo, useState} from "react";
import {useAsync} from "react-async-hook";
import {DeliveriesProvider} from "../../../domain/DeliveriesProvider";
import {DeliveryCondition} from "../../../domain/Delivery";
import {OcrResponse} from "../../../domain/Ocr";
import {useLocalization} from "../../../i18n";
import {gs} from "../../../theme";
import {LabeledData} from "../../LabeledData";
import {useTenant} from "../../TenantContextProvider";
import {DeliveryTags} from "../DeliveryTags";
import {OcrInput} from "../OcrInput";
import {WizardStepContext} from "./contexts";
import {DeliveryDetailsData, LabelInfo} from "./types";
import { onKeyDownFilterEnterWhenBarcodeScan } from "../../../utils/textUtils";

interface StepDetailsProps {
	onChange: (data: DeliveryDetailsData) => void;
	onTemporallyParcelIdChange: (value: string) => void;
	defaultDetails?: DeliveryDetailsData;
	temporallyParcelId?: string;
	ocr?: OcrResponse;
	labelInfo?: LabelInfo;
}

export function StepDetails({defaultDetails, temporallyParcelId, onChange, onTemporallyParcelIdChange, ocr, labelInfo}: StepDetailsProps) {
	const {t, e} = useLocalization();
	const tenant = useTenant();
	const deliveryConfigParams = tenant.deliveryConfig?.parameters ?? {};

	const {result: learnedTags} = useAsync(() => DeliveriesProvider.computeDeliveryLearnedTags(tenant.centerId), [
		tenant.centerId,
	]);

	const [parcelId, setParcelId] = useState("");
	const [condition, setCondition] = useState(DeliveryCondition.Intact);
	const [weight, setWeight] = useState("");
	const [units, setUnits] = useState("");
	const [storageTimeMax, setStorageTimeMax] = useState(tenant.maxStorageTime);
	const [sender, setSender] = useState("");
	const [carrier, setCarrier] = useState("");
	const [topSenders, setTopSenders] = useState<string[]>([]);
	const [topCarriers, setTopCarriers] = useState<string[]>([]);

	const [description, setDescription] = useState("");
	const [deliveryNoteId, setDeliveryNoteId] = useState("");
	const [orderId, setOrderId] = useState("");
	const [foreignId, setForeignId] = useState("");
	const [callbackUrl, setCallbackUrl] = useState("");
	const [tags, setTags] = useState<string[]>([]);

	const lookupTopSenders = useCallback(
		debounce(async (search: string) => {
			try {
				setTopSenders(await DeliveriesProvider.computeDeliveryTopSender(tenant.centerId, search));
			} catch (err) {
				console.error("Failed to lookup top senders", err);
			}
		}, 200),
		[tenant.centerId],
	);

	const lookupTopCarriers = useCallback(
		debounce(async (search: string) => {
			try {
				setTopCarriers(await DeliveriesProvider.computeDeliveryTopCarrier(tenant.centerId, search));
			} catch (err) {
				console.error("Failed to lookup top senders", err);
			}
		}, 200),
		[tenant.centerId],
	);

	function handleParcelIdAdded(data: DeliveryDetailsData, newParcelId: string) {
		let allParcelIds = parcelId;
		if (parcelId && parcelId.length >= 1) {
			allParcelIds += ";" + newParcelId;
		} else {
			allParcelIds += newParcelId;
		}
		data.parcelId = allParcelIds;
		setParcelId(allParcelIds);
	}

	function handleParcelIdDeleted(newParcelId: any) {
		let data = rebuildData();
		let newIds = parcelId.replace(newParcelId, "").replace(";;", ";");
		if (newIds.indexOf(";") === 0) {
			newIds = newIds.substring(1);
		}
		data.parcelId = newIds;
		setParcelId(newIds);
		onTemporallyParcelIdChange('');
		onChange(data);
	}

	function rebuildData() {
		const data: DeliveryDetailsData = {
			condition,
			storageTimeMax,
			parcelId,
			sender,
			carrier,
			weight,
			units,
			description,
			deliveryNoteId,
			orderId,
			foreignId,
			callbackUrl,
			tags,
		};
		return data;
	}

	function handleDetailsChange(key: keyof DeliveryDetailsData, value: any) {
        let data = rebuildData();
		switch (key) {
			case "parcelId":
				handleParcelIdAdded(data, value);
				break;
			case "condition":
				console.log("condition changed", value);
				data.condition = value;
				setCondition(value);
				break;
			case "weight":
				data.weight = value.substring(0, 3);
				setWeight(value.substring(0, 3));
				break;
			case "units":
				data.units = value.substring(0, 20);
				setUnits(value.substring(0, 20));
				break;
			case "storageTimeMax":
				data.storageTimeMax = value.substring(0, 3);
				setStorageTimeMax(value.substring(0, 3));
				break;
			case "sender":
				data.sender = value.substring(0, 99);
				setSender(value.substring(0, 99));
				lookupTopSenders(value);
				break;
			case "carrier":
				data.carrier = value.substring(0, 99);
				setCarrier(value.substring(0, 99));
				lookupTopCarriers(value);
				break;
			case "description":
				data.description = value.substring(0, 999);
				setDescription(value.substring(0, 999));
				break;
			case "deliveryNoteId":
				data.deliveryNoteId = value.substring(0, 255);
				setDeliveryNoteId(value.substring(0, 255));
				break;
			case "orderId":
				data.orderId = value.substring(0, 255);
				setOrderId(value.substring(0, 255));
				break;
			case "foreignId":
				data.foreignId = value.substring(0, 255);
				setForeignId(value.substring(0, 255));
				break;
			case "callbackUrl":
				data.callbackUrl = value.substring(0, 255);
				setCallbackUrl(value.substring(0, 255));
				break;
			case "tags":
				data.tags = value;
				setTags(value);
				break;
		}

		onChange(data);
	}

	const {formReset} = useContext(WizardStepContext);

	function buildData() {
		return {
			condition: DeliveryCondition.Intact,
			parcelId: "",
			weight: "",
			units: "",
			storageTimeMax: tenant.maxStorageTime,
			sender: "",
			carrier: "",
			description: "",
			deliveryNoteId: "",
			orderId: "",
			foreignId: "",
			callbackUrl: "",
			tags: [],
		};
	}

	function resetForm() {
		const data = buildData();
		setCondition(data.condition);
		setParcelId(data.parcelId);
		setWeight(data.weight);
		setUnits(data.units);
		setStorageTimeMax(data.storageTimeMax);
		setSender(data.sender);
		setCarrier(data.carrier);

		setDescription(data.description);
		setDeliveryNoteId(data.deliveryNoteId);
		setOrderId(data.orderId);
		setForeignId(data.foreignId);
		setCallbackUrl(data.callbackUrl);
		setTags(data.tags);

		onChange(data);
		console.log("Step Details data reset.");
	}

	const showInput = useCallback((param: keyof typeof deliveryConfigParams) => {
		// always show the input when delivery config:
		// - is empty
		// - is not enabled
		// - parameters attribute is empty
		if (!tenant.deliveryConfig || !tenant.deliveryConfig.enabled || !tenant.deliveryConfig.parameters ) return true;
		// otherwise ensure the proper param is enabled
		return tenant.deliveryConfig.parameters[param]?.enabled;
	}, [tenant.deliveryConfig]);
	const requiredInput = useCallback((param: keyof typeof deliveryConfigParams) => {
		if (!tenant.deliveryConfig || !tenant.deliveryConfig.enabled || !tenant.deliveryConfig.parameters ) return false;
		return tenant.deliveryConfig.parameters[param]?.mandatory ?? false;
	}, [tenant.deliveryConfig]);
	const firstFocusField = useMemo(() => {
		const orderedFields: (keyof typeof deliveryConfigParams)[] = [
			'sender',
			'carrier',
			'parcelId',
			'condition',
			'maxStorageTime',
			'weight',
			'units',
			'tags',
			'description',
			'deliveryNumber',
			'orderNumber',
			'foreignId',
			'callbackUrl',
		];
		for (let field of orderedFields) {
			if (showInput(field)) return field;
		}
	}, [showInput])

	useEffect(() => {
		if (formReset) {
			resetForm();
		}
	}, [formReset]);

	useEffect(() => {
		const data = {
			condition: (defaultDetails?.condition ?? labelInfo?.condition) ?? condition,
			parcelId: ((defaultDetails?.parcelId || temporallyParcelId) ?? labelInfo?.parcelId) ?? parcelId,
			weight: (defaultDetails?.weight ?? labelInfo?.weight) ?? weight,
			units: (defaultDetails?.units ?? labelInfo?.units) ?? units,
			storageTimeMax: (defaultDetails?.storageTimeMax ?? labelInfo?.storageTimeMax) ?? storageTimeMax,
			sender: (defaultDetails?.sender ?? labelInfo?.sender) ?? sender,
			carrier: (defaultDetails?.carrier ?? labelInfo?.carrier) ?? carrier,
			description: (defaultDetails?.description ?? labelInfo?.description) ?? description,
			deliveryNoteId: (defaultDetails?.deliveryNoteId ?? labelInfo?.deliveryNoteId) ?? deliveryNoteId,
			orderId: (defaultDetails?.orderId ?? labelInfo?.orderId) ?? orderId,
			foreignId: (defaultDetails?.foreignId ?? labelInfo?.foreignId) ?? foreignId,
			callbackUrl: (defaultDetails?.callbackUrl ?? labelInfo?.callbackUrl) ?? callbackUrl,
			tags: (defaultDetails?.tags ?? labelInfo?.tags) ?? tags,
		};
		setCondition(data.condition);
		setParcelId(data.parcelId);
		setWeight(data.weight);
		setUnits(data.units);
		setStorageTimeMax(data.storageTimeMax);
		setSender(data.sender);
		setCarrier(data.carrier);

		setDescription(data.description);
		setDeliveryNoteId(data.deliveryNoteId);
		setOrderId(data.orderId);
		setForeignId(data.foreignId);
		setCallbackUrl(data.callbackUrl);
		setTags(data.tags);

		onChange(data);
	}, [labelInfo]);

	useEffect(() => {
		lookupTopCarriers("");
		lookupTopSenders("");
	}, []);


	return (
		<Stack direction="column" spacing={3}>
			<Grid container spacing={3} maxWidth="md">
				{showInput('sender') && (
					<Grid
						item
						xs={12}
						sm={6}
					>
						<OcrInput
							required={requiredInput('sender')}
							label={t("deliveries.sender")}
							ocr={ocr}
							value={sender}
							onChange={v => handleDetailsChange("sender", v)}
							suggestions={topSenders.map(v => ({label: v, value: v}))}
							defaultFocused={firstFocusField === 'sender'}
						/>
					</Grid>
				)}

				{showInput('carrier') && (
					<Grid
						item
						xs={12}
						sm={6}
					>
						<OcrInput
							required={requiredInput('carrier')}
							label={t("deliveries.carrier")}
							ocr={ocr}
							value={carrier}
							onChange={v => handleDetailsChange("carrier", v)}
							suggestions={topCarriers.map(v => ({label: v, value: v}))}
							defaultFocused={firstFocusField === 'carrier'}
						/>
					</Grid>
				)}

				{showInput('parcelId') && (
					<Grid
						item
						xs={12}
					>
						<Grid container spacing={gs}>
							<Grid item xs={12}>
								<OcrInput
									required={requiredInput('parcelId')}
									label={t("deliveries.parcelId")}
									ocr={ocr}
									onType={onTemporallyParcelIdChange}onChange={v => handleDetailsChange("parcelId", v)}
									fireChangeOnEnter={true}
									captureEnter
									defaultFocused={firstFocusField === 'parcelId'}
								/>
							</Grid>
							{(parcelId.split(";").filter(token => token.length > 0).length > 0) && (
								<Grid item xs={12}>
									<LabeledData label={t("deliveries.parcelIds")}>
										<Grid container spacing={1}>
											{parcelId.split(";").filter(token =>
												token.length > 0
											).map(parcelIdSingle => (
												<Grid item key={parcelIdSingle}>
													<Chip
														label={parcelIdSingle}
														onDelete={() => handleParcelIdDeleted(parcelIdSingle)}
													/>
												</Grid>
											))}
										</Grid>
									</LabeledData>
								</Grid>
							)}
						</Grid>
					</Grid>
				)}
				{showInput('condition') && (
					<Grid
						item
						xs={12}
						sm={6}
					>
						<Select
							fullWidth
							required={requiredInput('condition')}
							label={t("deliveries.condition")}
							value={condition}
							onChange={ev => handleDetailsChange("condition", ev.target.value)}
							autoFocus={firstFocusField === 'condition'}
						>
							{Object.values(DeliveryCondition).map(c => (
								<MenuItem key={c} value={c}>{e("deliveries.conditions", c)}</MenuItem>
							))}
						</Select>
					</Grid>
				)}

				{showInput('maxStorageTime') && (
					<Grid
						item
						xs={12}
						sm={6}
					>
						<OcrInput
							required={requiredInput('maxStorageTime')}
							label={t("deliveries.storage.storageTimeMax")}
							numeric
							ocr={ocr}
							value={storageTimeMax}
							onChange={v => handleDetailsChange("storageTimeMax", v)}
							defaultFocused={firstFocusField === 'maxStorageTime'}
						/>
					</Grid>
				)}

				{showInput('weight') && (
					<Grid
						item
						xs={12}
						sm={6}
					>
						<OcrInput
							required={requiredInput('weight')}
							label={t("deliveries.weight")}
							numeric
							ocr={ocr}
							value={weight}
							onChange={v => handleDetailsChange("weight", v)}
							defaultFocused={firstFocusField === 'weight'}
						/>
					</Grid>
				)}

				{showInput('units') && (
					<Grid
						item
						xs={12}
						sm={6}
					>
						<OcrInput
							required={requiredInput('units')}
							label={t("deliveries.units")}
							ocr={ocr}
							value={units}
							onChange={v => handleDetailsChange("units", v)}
							defaultFocused={firstFocusField === 'units'}
						/>
					</Grid>
				)}



				{showInput('tags') && (
					<Grid item xs={12}>
						<DeliveryTags
							required={requiredInput('tags')}
							label={t("deliveries.tags")}
							placeholder={t("deliveries.addTag")}
							tags={tags}
							options={learnedTags}
							onChange={v => handleDetailsChange("tags", v)}
							autoFocus={firstFocusField === 'tags'}
						/>
					</Grid>
				)}

				{showInput('description') && (
					<Grid item xs={12}>
						<TextField
							fullWidth
							required={requiredInput('description')}
							label={t("deliveries.order.description")}
							value={description}
							onChange={v => handleDetailsChange("description", v)}
							onKeyDown={onKeyDownFilterEnterWhenBarcodeScan}
							autoFocus={firstFocusField === 'description'}
						/>
					</Grid>
				)}

				{showInput('deliveryNumber') && (
					<Grid
						item
						xs={12}
						sm={6}
					>
						<TextField
							fullWidth
							required={requiredInput('deliveryNumber')}
							label={t("deliveries.order.deliveryNoteId")}
							value={deliveryNoteId}
							onChange={v => handleDetailsChange("deliveryNoteId", v)}
							onKeyDown={onKeyDownFilterEnterWhenBarcodeScan}
							autoFocus={firstFocusField === 'deliveryNumber'}
						/>
					</Grid>
				)}

				{showInput('orderNumber') && (
					<Grid
						item
						xs={12}
						sm={6}
					>
						<TextField
							fullWidth
							required={requiredInput('orderNumber')}
							label={t("deliveries.order.orderId")}
							value={orderId}
							onChange={v => handleDetailsChange("orderId", v)}
							onKeyDown={onKeyDownFilterEnterWhenBarcodeScan}
						/>
					</Grid>
				)}

				{showInput('foreignId') && (
					<Grid
						item
						xs={12}
						sm={6}
					>
						<TextField
							fullWidth
							required={requiredInput('foreignId')}
							label={t("deliveries.thirdParty.foreignId")}
							value={foreignId}
							onChange={v => handleDetailsChange("foreignId", v)}
							onKeyDown={onKeyDownFilterEnterWhenBarcodeScan}
							autoFocus={firstFocusField === 'foreignId'}
						/>
					</Grid>
				)}

				{showInput('callbackUrl') && (
					<Grid
						item
						xs={12}
						sm={6}
					>
						<TextField
							fullWidth
							required={requiredInput('callbackUrl')}
							label={t("deliveries.thirdParty.callbackUrl")}
							value={callbackUrl}
							onChange={v => handleDetailsChange("callbackUrl", v)}
							type="url"
							onKeyDown={onKeyDownFilterEnterWhenBarcodeScan}
							autoFocus={firstFocusField === 'callbackUrl'}
						/>
					</Grid>
				)}
			</Grid>
			<Box my={2} />
		</Stack>
	);
}
