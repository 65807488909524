import {Loading} from "./Loading";
import {ContainerLayout} from "@variocube/app-ui";
import {createElement} from "react";
import {useLocalization} from "../i18n";

export function TenantLoading() {
    const {t} = useLocalization();
    return (
        <ContainerLayout>
            <Loading message={t("tenants.loading")}/>
        </ContainerLayout>
    )
}
