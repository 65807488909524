import {Now} from "@variocube/app-ui";
import {Buffer} from "buffer";
import {authProvider} from "../domain/AuthProvider";

export interface BasicAuthUser {
	username: string;
	password: string;
}

export interface OAuth2Token {
	idToken: string;
	accessToken: string;
	refreshToken: string;
	expiresIn: string;
	invalidAfter: string;
	tokenType: string;
}

export enum CredentialsType {
	Basic = "Basic",
	Bearer = "Bearer",
}

export interface Credentials {
	type: CredentialsType;
	basicAuthUser?: BasicAuthUser;
	oauth2Token?: OAuth2Token;
}

const SESSION_STORAGE_KEY = "logistics-credentials";

class CredentialsWrapper {
	credentials?: Credentials;
	rememberMe: boolean;

	constructor() {
		this.rememberMe = false;
		let data = sessionStorage.getItem(SESSION_STORAGE_KEY);
		if (!data) {
			data = localStorage.getItem(SESSION_STORAGE_KEY);
			this.rememberMe = true;
		}
		if (data) {
			this.credentials = JSON.parse(data);
			if (this.credentials && this.credentials.type == CredentialsType.Bearer && this.credentials.oauth2Token) {
				authProvider.setupTokenRefresh();
			}
		}
	}

	setRememberMe(rememberMe: boolean) {
		this.rememberMe = rememberMe;
	}

	login(username: string, password: string) {
		this.write({
			type: CredentialsType.Basic,
			basicAuthUser: {username, password},
		});
	}

	provideToken(token: OAuth2Token) {
		this.write({
			type: CredentialsType.Bearer,
			oauth2Token: token,
		});
	}

	private write(credentials: Credentials) {
		if (this.rememberMe) {
			localStorage.setItem(SESSION_STORAGE_KEY, JSON.stringify(credentials));
		} else {
			sessionStorage.setItem(SESSION_STORAGE_KEY, JSON.stringify(credentials));
		}
		this.credentials = credentials;
	}

	logout(message?: string) {
		if (message) {
			console.error(message);
		}
		sessionStorage.removeItem(SESSION_STORAGE_KEY);
		localStorage.removeItem(SESSION_STORAGE_KEY);
		window.location.href = "/";
	}

	logoutInvalidToken(message?: string) {
		this.logout(message);
		window.location.href = "/";
	}

	get loggedIn(): boolean {
		return Boolean(this.credentials);
	}

	get authHeader(): string {
		if (this.credentials) {
			switch (this.credentials.type) {
				case CredentialsType.Basic:
					if (!this.credentials.basicAuthUser) {
						throw new Error("credentials.type is set to Basic but no basicAuthUser credentials found");
					}
					const basic = this.credentials.basicAuthUser;
					const encoded = Buffer.from(`${basic.username}:${basic.password}`).toString("base64");
					return `Basic ${encoded}`;
				case CredentialsType.Bearer:
					if (!this.credentials.oauth2Token) {
						throw new Error("credentials.type is set to Bearer but no oauth2Token found");
					}
					const now = Now.instant.toString();
					if (this.credentials.oauth2Token.invalidAfter.localeCompare(now) < 0) {
						const message = "Token expired and seemingly did not automatically refresh via authProvider";
						this.logoutInvalidToken(message);
						throw new Error(message);
					}
					return `Bearer ${this.credentials.oauth2Token.accessToken}`;
			}
		}
		throw new Error("No valid credentials found, cannot generate auth header");
	}
}

export const authCredentials = new CredentialsWrapper();
