export const computeSlug = (input: string): string => {
    return input.toLowerCase().trim()
        .replace(/[àáâãäå]/g, 'a')
        .replace(/[èéêë]/g, 'e')
        .replace(/[ìíîï]/g, 'i')
        .replace(/[òóôõö]/g, 'o')
        .replace(/[ùúûü]/g, 'u')
        .replace(/[ýÿ]/g, 'y')
        .replace(/[ñ]/g, 'n')
        .replace(/[ç]/g, 'c')
        .replace(/[ß]/g, 'sz')
        .replace(/[^a-z0-9]+/g, '-').replace(/^-|-$/g, '');
}

let lastKeyStroke  = new Date().getTime();
export function onKeyDownFilterEnterWhenBarcodeScan(e: any) {
    const timeElapsed = (new Date()).getTime() - lastKeyStroke;
    lastKeyStroke = new Date().getTime();
    // console.debug(e?.code, timeElapsed);
    if (timeElapsed < 500 && e?.code === 'Enter')
        e.preventDefault();
}