import {Api} from "../Api";
import {OutOfOffice} from "./OutOfOffice"; 
import {Recipient} from "./Delivery";

export class OutOfOfficeProvider {

    static get(tenantId: string, email: string): Promise<OutOfOffice> {
        return Api.GET(`tenants/${tenantId}/users/out-of-office/${email}`);
    }

    static put(tenantId: string, email: string,  outOfOffice: OutOfOffice): Promise<OutOfOffice> {
		return Api.POST(`tenants/${tenantId}/recipient/out-of-office/${email}`, outOfOffice);
    }

	static putCurrentUser(tenantId: string,  outOfOffice: OutOfOffice): Promise<OutOfOffice> {
		return Api.POST(`tenants/${tenantId}/users/current-user/out-of-office`, outOfOffice);
	}

	static redirect(tenantId: string, recipientOld: Recipient, recipientsNew: Recipient[] ) : Promise<number> {
		const request = {recipientOld, recipientsNew};
		return Api.POST(`tenants/${tenantId}/deliveries/recipient/redirect`, request);
	}

	static async getOutOfOfficeSubstitute(tenantId: string, recipient: string | undefined) {
		let result;
		if (recipient) {
			try {
				let outOfOffice = await OutOfOfficeProvider.get(tenantId, recipient);
				if (outOfOffice?.active && outOfOffice.substituteEmail) {
					let inTimeFrame = outOfOffice?.withTimeframe;
					if (inTimeFrame) {
						let now: Date = new Date();
						if (outOfOffice?.from) {
							let from: Date = new Date(Date.parse(outOfOffice.from));
							if (now < from) {
								inTimeFrame = false;
							}
						}
						if (outOfOffice?.to) {
							let to: Date = new Date(Date.parse(outOfOffice.to));
							if (to < now) {
								inTimeFrame = false;
							}
						}
					}
					if (outOfOffice?.withTimeframe == false || inTimeFrame) {
						result = outOfOffice.substituteEmail;
					}
				}
			} catch (ex) {
				console.log(ex);
			}
		}
		return result;
	}

	static getBySubstitute(tenantId: string, email: string): Promise<OutOfOffice[]> {
        return Api.GET(`tenants/${tenantId}/users/out-of-office/substitute/${email}`);
    }
  
}
