import * as React from "react";
import {Box, Button, Grid, Typography} from "@mui/material";
import {BreadcrumbItem, Breadcrumbs, PagingImpl} from "@variocube/app-ui";
import {createElement, Fragment, useState} from "react";
import {useAsync} from "react-async-hook";
import {CubesProvider} from "../../domain/CubesProvider";
import {DeliveryCondition, DeliveryState, DeliveryType} from "../../domain/Delivery";
import {useLocalization} from "../../i18n";
import {gs} from "../../theme";
import {useRequiredParam} from "../../useRequiredParam";
import {BreadcrumbRouterLink} from "../BreadcrumbRouterLink";
import {ContainerLayout} from "@variocube/app-ui";
import {SimpleCheckBox} from "../SimpleCheckBox";
import {useTenantId, useTenantUser, useTenantUserRole} from "../TenantContextProvider";
import {deliveryPickupColumns, deliveryShippedColumns} from "../uis";
import {DeliveryListView} from "./DeliveryListView";

export const MailroomTaskList = () => {
	const cubeId = useRequiredParam("cubeId");
	const tenantId = useTenantId();
	const user = useTenantUser();
	const {isAdmin} = useTenantUserRole();


	const {t} = useLocalization();

	const [unpaged] = useState<PagingImpl>(new PagingImpl("deliveries_unpaged", true));
	const [shipped, setShipped] = useState<boolean>(true);
	const [overdue, setOverdue] = useState<boolean>(true);

	const {result: cube} = useAsync(() => CubesProvider.get(tenantId, cubeId), [tenantId, cubeId]);

	const baseFilter = {
		needle: "",
		siteIds: isAdmin ? [] :   [user.siteId],
		cubeIds: [cubeId] as string[],
		deliveryStates: [] as DeliveryState[],
		deliveryTypes: [] as DeliveryType[],
		deliveryConditions: [] as DeliveryCondition[],
		tags: [],
	};
	const baseFilterShipped = {...baseFilter, deliveryStates: [DeliveryState.Created]};
	const baseFilterPickup = {...baseFilter, deliveryStates: [DeliveryState.Stored], overdue: true};

	async function handlePrint() {
		window.print();
	}
	return (
		<ContainerLayout>
			<Grid container spacing={gs}>
				<Grid item xs={12} displayPrint="none">
					<Breadcrumbs>
						<BreadcrumbRouterLink to={`/${tenantId}/cubes`}>{t("cubes.plural")}</BreadcrumbRouterLink>
						<BreadcrumbItem>{cube?.name}</BreadcrumbItem>
					</Breadcrumbs>
				</Grid>
				<Grid item xs={12} displayPrint="none">
					<Grid container spacing={gs}>
						<Grid item style={{flexGrow: 1}}>
							<Typography variant="h4" gutterBottom>{t("taskList.title")}</Typography>
						</Grid>
						<Grid item>
							<Button variant="outlined" onClick={handlePrint}>{t("print")}</Button>
						</Grid>
					</Grid>
					<Grid container spacing={gs}>
						<Grid item xs={6} displayPrint="none">
							<SimpleCheckBox
								label={t("taskList.shipped") + " " + cube?.name}
								checked={shipped}
								onChange={setShipped}
							/>
						</Grid>
						<Grid item xs={6} displayPrint="none">
							<SimpleCheckBox
								label={t("taskList.pickup") + " " + cube?.name}
								checked={overdue}
								onChange={setOverdue}
							/>
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={12}>
					{shipped
						&& (
							<DeliveryListView
								title={t("taskList.shipped")}
								filter={baseFilterShipped}
								cube={cube}
								paging={unpaged}
								columns={deliveryShippedColumns}
							/>
						)}
					{overdue
						&& (
							<Fragment>
								<Box sx={{margin: 5}} />
								<DeliveryListView
									title={t("taskList.pickup")}
									filter={baseFilterPickup}
									cube={cube}
									paging={unpaged}
									columns={deliveryPickupColumns}
								/>
							</Fragment>
						)}
				</Grid>
			</Grid>
		</ContainerLayout>
	);
};
