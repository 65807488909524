import {ShipmentState} from "../../domain/Shipments";
import {useLocalization} from "../../i18n";
import {Chip} from "@mui/material";
import {blueGrey, green, orange} from "@mui/material/colors";
import { createElement } from "react";

export const ShipmentStateBadge = ({state}: { state: ShipmentState }) => {
	let sx: any = {
		backgroundColor: blueGrey[50],
	}
	const {e} = useLocalization();
	switch (state) {
		case ShipmentState.WaitingForMailroomPickup:
			sx.backgroundColor = blueGrey[200];
			break;
		case ShipmentState.ProcessingInMailroom:
			sx.backgroundColor = orange[500];
			break;
		case ShipmentState.WaitingForRecipientPickup:
			sx.backgroundColor = blueGrey[400];
			break;
		case ShipmentState.PickedUp:
			sx = {
				backgroundColor: green[500],
				color: '#fff'
			};
	}
	return (
		<Chip
			label={e('shipments.states', state)}
			title={e('shipments.states', state)}
			sx={sx}
		/>
	)
}
