import * as React from "react";
import {createElement, useCallback} from "react";
import {TenantNotificationSetting} from "../domain/TenantUser";
import {MenuItem, TextField} from "@mui/material";
import {useLocalization} from "../i18n";

interface TenantNotificationSettingInputProps {
    value: TenantNotificationSetting;
    onChange: (value: TenantNotificationSetting) => void;
    label: string;
}

export function TenantNotificationSettingInput(props: TenantNotificationSettingInputProps) {
    const {value, onChange, label} = props;
    const {t} = useLocalization();

    const handleChange = useCallback((value: string) => {
        onChange(value as TenantNotificationSetting);
    }, []);

    return (
        <TextField label={label} variant="outlined" fullWidth select
                   value={value} onChange={(e) => handleChange(e.target.value)}>
            <MenuItem key={TenantNotificationSetting.All} value={TenantNotificationSetting.All}>{t('tenants.notifications.All')}</MenuItem>
            <MenuItem key={TenantNotificationSetting.Tenant} value={TenantNotificationSetting.Tenant}>{t('tenants.notifications.Tenant')}</MenuItem>
            <MenuItem key={TenantNotificationSetting.None} value={TenantNotificationSetting.None}>{t('tenants.notifications.None')}</MenuItem>
        </TextField>
    );
}
