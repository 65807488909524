import {Instant} from "@variocube/app-ui";
import {pad} from "../tools";
import {Delivery} from "./Delivery";

export interface Utilization {
	total: number;
	occupied: number;
	available: number;
	disabled: number;
}

export interface Address {
	city?: string;
	country?: string;
	houseNo?: string;
	street?: string;
	zip?: string;
}

export interface Cube {
	hostname: string;
	name: string;
	description: string;
	connected: boolean;
	latitude: number;
	longitude: number;
	utilization: Utilization;
	address: Address;
}

export interface CubeSetting {
	supportsBarcodeReader: boolean;
	checkPickedUpDeliveries: boolean;
	siteId: string;
	reserveBoxFallbacks: string;
}

export interface AccessCodes {
	readOnly: string;
	openNonOccupied: string;
	fullAccess: string;
}

export interface CubeDetails extends Cube {
	settings: CubeSetting;
	accessCodes?: AccessCodes;
}

export interface ReserveBoxFallback {
	boxType: string;
	fallbackTypes: string[];
}

export enum AppLockStatus {
	Open = "Open",
	Closed = "Closed",
	Breakin = "Breakin",
	Blocked = "Blocked",
}

export interface Occupancy {
	uuid: string;
	cubeId: string;
	boxNumber: string;
	lock: string;
	accessCode: string;
	accessKeys: string[];
	created: Instant;
	updated?: Instant;
	ended?: Instant;
	deliveryIds: string[];
	orderId?: string;
	orderDescription?: string;
	deliveries?: Delivery[];
}

export type AppBoxFeature = "Accessible" | "Cooled" | "DangerousGoods" | "Charger";

export interface CubeBox {
	number: number;
	description: string;
	lock: string;
	secondaryLock?: string;
	types: string[];
	lockStatus: AppLockStatus;
	secondaryLockStatus?: AppLockStatus;
	disabled: boolean;
	occupancies: Occupancy[];
	features: AppBoxFeature[];
	maintenanceRequiredAt?: Instant;
}

export function formattedUtilization(u: Utilization) {
	return pad(u.available, 3) + pad(u.total, 3);
}
