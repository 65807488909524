import * as React from "react";
import {createElement} from "react";
import {Box, LinearProgress, Paper} from "@mui/material";

interface BoxedPaperProps {
    p?: number;
    children?: React.ReactNode;
    color?: string;
    loading?: boolean;
}

export function BoxedPaper(props: BoxedPaperProps) {
    const padding = props.p || 2;
    const style = props.color ? { borderLeft: `0.75em solid ${props.color}` } : undefined;
    return (
        <Paper style={style}>
            <Box p={padding}>{props.children}</Box>
            {props.loading && <LinearProgress variant="indeterminate" /> }
        </Paper>
    );
}
