import {Button, Grid, MenuItem, TextField, Typography} from "@mui/material";
import {BreadcrumbItem, Breadcrumbs} from "@variocube/app-ui";
import {capitalize} from "lodash";
import {createElement, FormEvent, useCallback, useMemo, useState} from "react";
import {useNavigate} from "react-router";
import {Roles} from "../../domain/Roles";
import {TenantUserProvider} from "../../domain/TenantUserProvider";
import {useLocalization} from "../../i18n";
import {isEmail, isEmailLocalPart} from "../../tools";
import {BoxedPaper} from "../BoxedPaper";
import {BreadcrumbRouterLink} from "../BreadcrumbRouterLink";
import {ContainerLayout} from "@variocube/app-ui";
import {HelmetTitleWrapper} from "../HelmetTitleWrapper";
import {SimpleTextInput} from "../SimpleTextInput";
import {useTenantId, useTenantUserRole} from "../TenantContextProvider";

export function UserCreate() {
	const {t, e} = useLocalization();
	const tenantId = useTenantId();
	const navigate = useNavigate();

	const [username, setUsername] = useState<string>("");
	const [email, setEmail] = useState<string>("");
	const [firstName, setFirstName] = useState<string>("");
	const [lastName, setLastName] = useState<string>("");
	const [password, setPassword] = useState<string>("");
	const [repeat, setRepeat] = useState<string>("");
	const [role, setRole] = useState<string>("user");

	function handleSave(e: FormEvent) {
		e.preventDefault();
		if (tenantId) {
			TenantUserProvider.create(tenantId, {
				username,
				email,
				firstName,
				lastName,
				plaintextPassword: password,
				roles: role,
				receiveEmails: false,
				siteId: "",
			}).then(() => navigate(`/${tenantId}/users`));
		}
	}

	const handleEmailBlur = useCallback(() => {
		if (!username && email) {
			setUsername(email);
		}
		if ((!firstName || !lastName) && isEmail(email)) {
			const components = email.split("@")[0].split(".");
			if (components.length == 2) {
				const [first, last] = components;
				setFirstName(capitalize(first));
				setLastName(capitalize(last));
			}
		}
	}, [firstName, lastName, email, username]);

	const canSave = useMemo<boolean>(
		() => Boolean(username && isEmail(email) && password && password == repeat && role),
		[
			username,
			email,
			firstName,
			lastName,
			password,
			repeat,
			role,
		],
	);

	const {isSuperUser} = useTenantUserRole();
	const availableRoles = isSuperUser ? Object.values(Roles) : [Roles.user, Roles.admin, Roles.adminlight, Roles.recipient];

	return (
		<ContainerLayout>
			<HelmetTitleWrapper pageTitle={t("users.plural")} />
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<Breadcrumbs>
						<BreadcrumbRouterLink to={`/${tenantId}/users`}>{t("users.plural")}</BreadcrumbRouterLink>
						<BreadcrumbItem>{t("users.create.title")}</BreadcrumbItem>
					</Breadcrumbs>
				</Grid>
				<Grid item xs={12}>
					<Typography variant="h2">{t("users.create.title")}</Typography>
				</Grid>
				<form onSubmit={handleSave}>
					<Grid item xs={12}>
						<BoxedPaper>
							<Grid container spacing={3}>
								<Grid item xs={12} sm={6}>
									<TextField
										label={t("users.email")}
										value={email}
										onChange={x => setEmail(x.currentTarget.value)}
										type="email"
										error={!isEmail(email)}
										helperText={!isEmail(email) ? t("users.emailError") : undefined}
										onBlur={() => handleEmailBlur()}
										required
										fullWidth
									/>
								</Grid>
								<Grid item xs={12} sm={6}>
									<TextField
										label={t("users.username")}
										value={username}
										onChange={x => setUsername(x.currentTarget.value)}
										error={!isEmail(username) && !isEmailLocalPart(username)}
										helperText={(!isEmail(username) && !isEmailLocalPart(username))
											? t("users.usernameError")
											: undefined}
										required
										fullWidth
									/>
								</Grid>
								<Grid item xs={12} sm={6}>
									<SimpleTextInput
										label={t("users.firstName")}
										value={firstName}
										onChange={setFirstName}
									/>
								</Grid>
								<Grid item xs={12} sm={6}>
									<SimpleTextInput
										label={t("users.lastName")}
										value={lastName}
										onChange={setLastName}
									/>
								</Grid>
								<Grid item xs={12} sm={6}>
									<SimpleTextInput
										label={t("users.password")}
										value={password}
										onChange={setPassword}
										type="password"
									/>
								</Grid>
								<Grid item xs={12} sm={6}>
									<SimpleTextInput
										label={t("users.repeat")}
										value={repeat}
										onChange={setRepeat}
										type="password"
									/>
								</Grid>
								<Grid item xs={12} sm={6}>
									<TextField
										fullWidth
										select
										variant="outlined"
										value={role || ""}
										label={t("users.role")}
										onChange={(e) => setRole(e.target.value)}
									>
										{availableRoles.map(r => (
											<MenuItem key={r} value={r} selected={role == r}>
												{e("users.roles", r)}
											</MenuItem>
										))}
									</TextField>
								</Grid>
								<Grid item xs={12}>
									<Grid container spacing={3}>
										<Grid item style={{flexGrow: 1}}>
											<Button
												type="submit"
												variant="contained"
												color="primary"
												disabled={!canSave}
											>
												{t("save")}
											</Button>
										</Grid>
										<Grid item>
											<Button variant="outlined" onClick={() => navigate(-1)}>
												{t("cancel")}
											</Button>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</BoxedPaper>
					</Grid>
				</form>
			</Grid>
		</ContainerLayout>
	);
}
