import * as React from "react";
import {createElement, useEffect, useState} from "react";
import {Bookmark} from "../../domain/Bookmark";
import {BookmarksProvider} from "../../domain/BookmarksProvider";
import {useTenantId} from "../TenantContextProvider";

interface ReceivingDepartmentComponentProps {
	bookmarkId: string;
}

export function ReceivingDepartmentComponent(props: ReceivingDepartmentComponentProps) {
	const {bookmarkId} = props;

	const tenantId = useTenantId();

	const [bookmark, setBookmark] = useState<Bookmark | undefined>(undefined);

	useEffect(() => {
		BookmarksProvider.get(tenantId, bookmarkId).then(setBookmark);
	}, [tenantId, bookmarkId]);

	if (bookmark) {
		return <span>{bookmark.label}</span>;
	}

	return <span>{bookmarkId}</span>;
}
