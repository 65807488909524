import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, TextField} from "@mui/material";
import * as React from "react";
import {createElement, useCallback, useState} from "react";
import {OcrResponse} from "../../domain/Ocr";
import {useLocalization} from "../../i18n";

interface SelectOcrElementDialogProps {
	open: boolean;
	ocr: OcrResponse;
	onSelect: (text: string) => void;
	onCancel: () => void;
}

export function SelectOcrElementDialog({open, ocr, onSelect, onCancel}: SelectOcrElementDialogProps) {
	const [text, setText] = useState<string>("");
	const {t} = useLocalization();

	const handleConfirm = useCallback(() => {
		onSelect(text);
	}, [text]);

	return (
		<Dialog open={open} onClose={() => onCancel()}>
			<DialogTitle>{t("deliveries.create.ocr.select.title")}</DialogTitle>
			<Box sx={{paddingTop: "0.5em"}}>
				<DialogContent>
					<TextField
						variant="outlined"
						fullWidth
						select
						value={text}
						onChange={(e) => setText(e.target.value)}
						label={t("deliveries.create.ocr.select.ocrFragment")}
					>
						{ocr.blocks.map((block) => <MenuItem key={block.line} value={block.line}>{block.line}
						</MenuItem>)}
					</TextField>
				</DialogContent>
			</Box>
			<DialogActions>
				<Button variant="contained" color="primary" onClick={() => handleConfirm()} disabled={!Boolean(text)}>
					{t("confirm")}
				</Button>
				<Button variant="outlined" onClick={() => onCancel()}>{t("cancel")}</Button>
			</DialogActions>
		</Dialog>
	);
}
