import {useParams} from "react-router";
import {Params} from "react-router-dom";

export function useRequiredParam<P extends string>(param: P) {
    const params = useParams<P>() as Params<P>;
    const value = params[param];
    if (!value) {
        throw new Error("Missing required param: " + param);
    }
    return value;
}