import {
	CameraAlt,
	Dashboard,
	Drafts,
	ExitToApp,
	ExpandLess,
	ExpandMore,
	HelpOutline,
	LocalShipping,
	MultipleStop,
	Person,
	PersonTwoTone,
	QrCodeScannerRounded,
	SettingsApplications,
	ViewQuilt,
	TrackChangesRounded
} from "@mui/icons-material";
import {Box, Button, Hidden, IconButton, List, ListItemButton, ListItemIcon, ListItemText, Stack} from "@mui/material";
import * as React from "react";
import {createElement, Fragment, useCallback} from "react";
import {useAsync} from "react-async-hook";
import {generatePath, useMatch, useNavigate} from "react-router";
import {Link, Link as RouterLink} from "react-router-dom";
import {authCredentials} from "../auth/authCredentials";
import {BookmarksProvider} from "../domain/BookmarksProvider";
import {TenantsProvider} from "../domain/TenantsProvider";
import {useHotkey} from "../hotkeys";
import {useLocalization} from "../i18n";
import {LanguageSwitcher} from "./LanguageSwitcher";
import {useTenant, useTenantId, useTenantUserRole} from "./TenantContextProvider";

export function TopNav() {
	const {t} = useLocalization();
	const navigate = useNavigate();
	const tenantId = useTenantId();
	const tenant = useTenant();
	const {isAdmin, isAdminLight, isUser} = useTenantUserRole();

	const handleLogout = useCallback(() => {
		authCredentials.logout("User logged out");
		window.location.href = "/";
	}, []);

	useHotkey({key: "a", ctrlKey: true}, () => navigate(`/${tenantId}/deliveries/create`));
	useHotkey({key: "m", ctrlKey: true}, () => navigate(`/${tenantId}/deliveries/scanner`));
	useHotkey({key: "d", ctrlKey: true}, () => navigate(`/${tenantId}/deliveries`));

	return (
		<Stack direction="row" spacing={1} displayPrint="none" sx={{flex: 1}} justifyContent="flex-end">
			{(isAdmin || isAdminLight || isUser) && (
				<Fragment>
					<Hidden smUp>
						<IconButton
							component={Link}
							to={`/${tenantId}/deliveries/scanner`}
						>
							<QrCodeScannerRounded />
						</IconButton>
					</Hidden>
					<Hidden smDown lgUp>
						<ScanBarcodeButton />
					</Hidden>
					<div color="inherit" style={{flexGrow: 1}}></div>
					{tenant.manualNotificationEnabled && (isAdmin || isAdminLight || isUser) && (
						<Hidden smDown>
							<Button
								color="primary"
								component={RouterLink}
								to={`/${tenantId}/manual-notification`}
							>
								{t("manualNotification.title")}
							</Button>
						</Hidden>
					)}
					{(isAdmin || isAdminLight || isUser)
						&& (
							<Hidden smDown>
								<Button
									color="primary"
									component={RouterLink}
									to={`/${tenantId}/deliveries/create`}
								>
									{t("deliveries.create.title")}
								</Button>
							</Hidden>
						)}
					<Hidden lgDown>
						<ScanBarcodeButton />
					</Hidden>
				</Fragment>
			)}
			<LanguageSwitcher color="inherit" />
			{tenant.faqHelpUrl && (
				<IconButton color="inherit" href={tenant?.faqHelpUrl} target="_blank">
					<HelpOutline />
				</IconButton>
			)}
			<IconButton color="inherit" onClick={handleLogout}>
				<ExitToApp />
			</IconButton>
		</Stack>
	);
}

function ScanBarcodeButton() {
	const {t} = useLocalization();
	const tenantId = useTenantId();

	return (
		<Button
			color="inherit"
			component={RouterLink}
			to={`/${tenantId}/deliveries/scanner`}
			startIcon={<QrCodeScannerRounded />}
		>
			{t("deliveries.manualHandover.title")}
		</Button>
	);
}

export const SideNav = () => {
	const {t} = useLocalization();
	const tenantId = useTenantId();
	const {isAdmin, isAdminLight, isUser, isRecipient} = useTenantUserRole();

	const {result: showBookmarksMenu = false} = useAsync(async () => {
		await BookmarksProvider.list(tenantId);
		return true;
	}, [tenantId]);

	const {result: showPhotoInbox = false} = useAsync(async () => {
		const tenant = await TenantsProvider.get(tenantId);
		return tenant.photoInboxEnabled;
	}, []);

	return (
		<Box display="block" displayPrint="none">
			<List>
				<NavMenuItem primary={t("deliveries.plural")} icon={<LocalShipping />} path="/deliveries" />
				{!isRecipient
					&& <NavMenuItem primary={t("shipments.plural")} icon={<MultipleStop />} path="/shipments" />}
				{showBookmarksMenu && !isRecipient
					&& <NavMenuItem primary={t("dispatch.singular")} icon={<Drafts />} path="/dispatch" />}
				{(showPhotoInbox && (isAdmin || isAdminLight || isUser))
					&& <NavMenuItem primary={t("photoInbox.singular")} icon={<CameraAlt />} path="/photo-inbox" />}
				{(isAdmin || isAdminLight || isUser)
					&& (
						<React.Fragment>
							<NavMenuItem primary={t("cubes.plural")} icon={<ViewQuilt />} path="/cubes" />
							<NavMenuItem primary={t("recipients.plural")} icon={<PersonTwoTone />} path="/recipients" />
						</React.Fragment>
					)}
				{isAdmin
					&& <NavMenuItem primary={t("audit.trail.title")} icon={<TrackChangesRounded />} path="/audit" />
				}
				<NavMenuItem primary={t("settings.plural")} icon={<SettingsApplications />} path="/settings" />
				{(isAdmin || isAdminLight || isUser)
					&& <NavMenuItem primary={t("dashboard.singular")} icon={<Dashboard />} path="/dashboard" />}
				{isAdmin
					&& <NavMenuItem primary={t("users.plural")} icon={<Person />} path="/users" />}
			</List>
		</Box>
	);
};

interface NavMenuItemProps {
	primary: string;
	secondary?: string;
	icon: React.ReactNode;
	collapse?: boolean;
	open?: boolean;
	path: string;
}

function NavMenuItem({primary, secondary, icon, collapse, open, path}: NavMenuItemProps) {
	const tenantId = useTenantId();
	const tenantPath = generatePath("/:tenantId" + path, {tenantId});
	const match = useMatch(tenantPath);
	return (
		<ListItemButton
			selected={Boolean(match)}
			component={RouterLink}
			to={tenantPath}
		>
			<ListItemIcon>{icon}</ListItemIcon>
			<ListItemText primary={primary} secondary={secondary} />
			{collapse && (open ? <ExpandLess /> : <ExpandMore />)}
		</ListItemButton>
	);
}
