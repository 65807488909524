import {DeliveryFilterRequest} from "../../../domain/Delivery";
import {createElement, useCallback, useEffect, useState} from "react";
import {
	Alert,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	LinearProgress,
	Typography
} from "@mui/material";
import {useLocalization} from "../../../i18n";
import {gs} from "../../../theme";
import {TemplateType} from "../../../domain/TenantUser";
import {TemplateTypeInput} from "../../settings/TemplateTypeInput";
import {TenantUserProvider} from "../../../domain/TenantUserProvider";
import {useTenant} from "../../TenantContextProvider";
import {DeliveriesProvider} from "../../../domain/DeliveriesProvider";


interface ResendNotificationsDialogProps {
	open: boolean;
	onClose: () => void;
	countDeliveries: number;
	filter: DeliveryFilterRequest;
}

export function ResendNotificationsDialog(props: ResendNotificationsDialogProps) {
	const {open, onClose, countDeliveries, filter} = props;
	const {t} = useLocalization();
	const tenant = useTenant();

	const [templateType, setTemplateType] = useState<TemplateType>(TemplateType.StoredRecipient);
	const [options, setOptions] = useState<TemplateType[]>([TemplateType.StoredRecipient]);
	const [submitting, setSubmitting] = useState<boolean>(false);
	const [success, setSuccess] = useState<number | undefined>();
	const [error, setError] = useState<string | undefined>();

	useEffect(() => {
		TenantUserProvider.listNotificationTemplates(tenant.centerId)
			.then((templates) => {
				setOptions(templates.map((t) => t.templateType));
			});
	}, [tenant]);

	const handleResendNotifications = useCallback(async () => {
		try {
			setSubmitting(true);
			setSuccess(undefined);
			setError(undefined);
			const count = await DeliveriesProvider.resendNotifications(tenant.centerId, filter, templateType);
			setSuccess(count);
		} catch(e: any) {
			setError(e.toString());
		} finally {
			setSubmitting(false);
		}
	}, [tenant, filter, templateType]);

	return (
		<Dialog open={open} onClose={onClose}>
			<DialogTitle>{t("deliveries.resendNotifications.title")}</DialogTitle>
			<DialogContent>
				<Grid container spacing={gs}>
					<Grid item xs={12}>
						<Typography variant="body1">{t("deliveries.resendNotifications.info", {n: countDeliveries})}</Typography>
					</Grid>
					<Grid item xs={12}>
						<TemplateTypeInput options={options} label={t('tenants.templateType')} value={templateType} onChange={setTemplateType} />
					</Grid>
					{success != undefined &&
						<Grid item xs={12}>
							<Alert severity="success">
								<Typography variant="body1">{t("deliveries.resendNotifications.success", {n: success})}</Typography>
							</Alert>
						</Grid>
					}
					{error != undefined &&
						<Grid item xs={12}>
							<Alert severity="error">
								<Typography variant="body1">{error}</Typography>
							</Alert>
						</Grid>
					}
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button variant="outlined" onClick={onClose}>{t("cancel")}</Button>
				<Button color="primary" variant="contained" onClick={handleResendNotifications} disabled={submitting}>
					{t("deliveries.resendNotifications.title")}
				</Button>
			</DialogActions>
			{submitting && <LinearProgress />}
		</Dialog>
	);
}
