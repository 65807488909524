import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	LinearProgress,
	Typography,
} from "@mui/material";
import {createElement, Fragment, useCallback, useState} from "react";
import {useLocalization} from "../i18n";
import {gs} from "../theme";
import {SimpleTextInput} from "./SimpleTextInput";

interface ConfirmDialogProps {
	open: boolean;
	title: string;
	message: string;
	confirmLabel?: string;
	textFieldLabel?: string[];
	resolve?: (info?: string[]) => void;
	reject?: () => void;
	noRejectButton?: boolean;
}

export function ConfirmDialog(props: ConfirmDialogProps) {
	const {
		open,
		title,
		message,
		confirmLabel,
		textFieldLabel,
		resolve,
		reject,
		noRejectButton,
	} = props;

	const [submitting, setSubmitting] = useState(false);
	const [text, setText] = useState<string[]>([]);

	const {t} = useLocalization();

	const handleConfirm = useCallback(() => {
		setSubmitting(true);
		if (resolve) {
			resolve(text);
		}
		setSubmitting(false);
	}, [resolve]);

	const handleCancel = useCallback(() => {
		if (reject) {
			reject();
		}
		setSubmitting(false);
	}, [reject]);

	const textChanged = useCallback((value: string, i: number) => {
		setText(prev => {
			prev[i] = value;
			return prev;
		});
	}, []);

	return (
		<Dialog open={open} onClose={handleCancel}>
			<DialogTitle>{title}</DialogTitle>
			<DialogContent>
				<Grid container spacing={gs}>
					<Grid item xs={12}>
						<Typography variant="body1">{message}</Typography>
					</Grid>
					{textFieldLabel
						&& (
							<Fragment>
								{textFieldLabel.map((t, i) => (
									<Grid item xs={12} key={i}>
										<SimpleTextInput
											label={t}
											value={text?.[i]}
											onChange={(t) => textChanged(t, i)}
										/>
									</Grid>
								))}
							</Fragment>
						)}
				</Grid>
			</DialogContent>
			<DialogActions>
				{noRejectButton == undefined
					&& <Button variant="outlined" onClick={handleCancel}>{t("cancel")}</Button>}
				<Button color="primary" variant="contained" onClick={handleConfirm}>
					{confirmLabel || t("confirm")}
				</Button>
			</DialogActions>
			{submitting && <LinearProgress variant="indeterminate" />}
		</Dialog>
	);
}
