import {Business} from "@mui/icons-material";
import {
	Alert,
	Box,
	Chip,
	CircularProgress,
	debounce,
	Divider,
	FormControlLabel,
	Grid,
	Paper,
	Radio,
	RadioGroup,
	Typography,
} from "@mui/material";
import * as React from "react";
import {createElement, Fragment, ReactElement, useCallback, useEffect, useMemo, useRef, useState} from "react";
import {Bookmark} from "../../../domain/Bookmark";
import {BookmarksProvider} from "../../../domain/BookmarksProvider";
import {AppBoxFeature, AppLockStatus, Cube, CubeBox, CubeDetails, ReserveBoxFallback} from "../../../domain/Cube";
import {CubesProvider} from "../../../domain/CubesProvider";
import {Recipient} from "../../../domain/Delivery";
import {OcrResponse} from "../../../domain/Ocr";
import {RecipientSummary} from "../../../domain/Recipients";
import {RecipientsProvider} from "../../../domain/RecipientsProvider";
import {getRequiredFields} from "../../../domain/TenantUser";
import {useLocalization} from "../../../i18n";
import {gs} from "../../../theme";
import {CubeSelectInput} from "../../cubes/CubeSelectInput";
import {LHelpButton} from "../../LHelpButton";
import {RecipientSearchV2} from "../../recipients/RecipientSearchV2";
import {SimpleCheckBox} from "../../SimpleCheckBox";
import {SimpleTextInput} from "../../SimpleTextInput";
import {useTenant, useTenantId} from "../../TenantContextProvider";
import {BoxFeatureSelectInput} from "../../cubes/BoxFeatureSelectInput";
import {BoxTypeInput} from "../../cubes/BoxTypeInput";
import {RecipientStepSelectedDisplay} from "./RecipientStepSelectedDisplay";
import {RecipientStepNoRecipientSelected} from "./RecipientStepNoRecipientSelected";
export interface BoxReservation {boxTypes: string[], boxFeature: AppBoxFeature | undefined}

interface RecipientStepProps {
	ocr?: OcrResponse;
	onChange: (recipient?: RecipientSummary) => void;
	destination?: Cube;
	onDestination?: (cube: Cube) => void;
	onManualHandoverRequired?: (required: boolean) => void;
	onDraft?: (draft: boolean) => void;
	recipient?: Recipient | undefined;
	draft?: boolean;
	manualHandoverRequired?: boolean;
	reserveBox?: BoxReservation;
	onReserveBox?: (boxReservation: BoxReservation) => void;
	destinationVisible: boolean;
	manualHandoverRequiredVisible?: boolean;
	draftVisible?: boolean;
	bookmark?: Bookmark;
	onBookmark?: (bookmark: Bookmark | undefined) => void;
	notifyRecipients?: boolean;
	onNotifyRecipients?: (notify: boolean) => void;
}

export function RecipientStep(
	{
		ocr,
		onChange,
		onDraft,
		destination,
		onDestination,
		onManualHandoverRequired,
		onReserveBox,
		recipient: recipientProp,
		draft: draftProp,
		manualHandoverRequired: manualHandoverRequiredProp,
		reserveBox: reserveBoxProps,
		destinationVisible,
		manualHandoverRequiredVisible,
		draftVisible,
		bookmark: bookmarkProp,
		onBookmark,
		notifyRecipients,
		onNotifyRecipients,
	}: RecipientStepProps,
) {
	const {t} = useLocalization();
	const tenantId = useTenantId();
	const tenant = useTenant();

	const searchInputRef = useRef<HTMLInputElement>(null);

	const [cube, setCube] = useState<Cube | undefined>(destination);

	const [boxFeatures, setBoxFeatures] = useState<AppBoxFeature[]>([]);
	const [boxFeature, setBoxFeature] = useState<AppBoxFeature>();

	const [boxTypes, setBoxTypes] = useState<string[]>([]);
	const [boxTypesDisplay, setBoxTypesDisplay] = useState<ReactElement[]>([]);

	const [recipientSearch, setRecipientSearch] = useState<boolean>(true);

	const [lookupProcessing, setLookupProcessing] = useState<boolean>(false);
	const [recipients, setRecipients] = useState<RecipientSummary[] | undefined>();
	const [recipient, setRecipient] = useState<RecipientSummary | undefined>(recipientProp);

	const [ocrCandidates, setOcrCandidates] = useState<RecipientSummary[] | undefined>();

	const [name, setName] = useState<string>(recipientProp?.recipientName || "");
	const [email, setEmail] = useState<string>(recipientProp?.email || "");
	const [phone, setPhone] = useState<string>(recipientProp?.phone || "");
	const [pickupKey, setPickupKey] = useState<string>(recipientProp?.pickupKey || "");
	const [manualHandoverRequired, setManualHandoverRequired] = useState<boolean>(manualHandoverRequiredProp || false);
	const [draft, setDraft] = useState<boolean>(draftProp || false);
	const [bookmark, setBookmark] = useState<Bookmark | undefined>(bookmarkProp);
	const [dispatchToDepartment, setDispatchToDepartment] = useState<boolean>(bookmarkProp != undefined);
	const [reserveBox, setReserveBox] = useState<string>("");
	const [locale, setLocale] = useState<string>(recipientProp?.locale || "");
	const [availableBoxes, setAvailableBoxes] = useState<CubeBox[]>([]);

	const [fallbacks, setFallbacks] = useState<ReserveBoxFallback[]>([]);
	useEffect(() => {
		if (cube) {
			handleCube(cube);
		}
	}, [destination]);

	useEffect(() => {
		if (searchInputRef && searchInputRef.current) {
			searchInputRef.current.focus();
		}
	}, [searchInputRef]);

	const handleName = useCallback((name: string) => {
		setName(name);
		onChange({
			recipientName: name,
			email,
			phone,
			pickupKey,
			locale,
		});
	}, [onChange, email, phone, pickupKey]);

	const handleEmail = useCallback((email: string) => {
		setEmail(email);
		onChange({
			recipientName: name,
			email,
			phone,
			pickupKey,
			locale,
		});
	}, [onChange, name, phone, pickupKey]);

	function sortBoxTypes(boxTypes: string[]) {
		let order: string[] = [
			"XS",
			"S",
			"M",
			"L",
			"XL",
			"XXL",
		];
		let result: string[] = [];
		let rest: string[] = [];
		for (let type of order) {
			if (boxTypes.find(x => x == type)) {
				result.push(type);
			}
		}
		for (let type of boxTypes) {
			if (result.find(x => x == type) == undefined) {
				result.push(type);
			}
		}
		rest.sort((x, y) => x < y ? 1 : -1);
		result.push(...rest);
		return result;
	}

	async function handleCube(cube: Cube) {
		setCube(cube);
		onDestination && onDestination(cube);
		if (cube) {
			let allTypes: string[] = [];
			let allFeatures: AppBoxFeature[] = [];

			let boxes: CubeBox[] = await CubesProvider.getBoxes(tenantId, cube.hostname);

			let availableBoxes = boxes.filter(x =>
				!x.disabled && x.lockStatus === AppLockStatus.Closed && x.occupancies.length == 0
			).map(
				x => {
					allTypes.push(...x.types);
					allFeatures.push(...x.features);
					return x;
				},
			);
			setAvailableBoxes(availableBoxes);

			let typesSet = new Set(allTypes);
			let types = Array.from(typesSet);
			setBoxTypes(sortBoxTypes(types));

			let featuresSet = new Set<AppBoxFeature>(allFeatures);
			let features = Array.from<AppBoxFeature>(featuresSet);
			setBoxFeatures(features);

			let cubeDetails: CubeDetails = await CubesProvider.get(tenantId, cube.hostname);
			if (cubeDetails.settings.reserveBoxFallbacks) {
				const fBs: ReserveBoxFallback[] = JSON.parse(cubeDetails.settings.reserveBoxFallbacks);
				setFallbacks(fBs);
				let tD: ReactElement[] = [];
				types.forEach((x, index) => {
					let t2 = "";
					let fB = fBs.find(f => f.boxType == x);
					if (fB) {
						t2 = " ( fallback ";
						t2 += fB.fallbackTypes.join(", ");
						t2 += ")";
					}
					tD[index] = (
						<span>
							<span style={{fontWeight: "bold", fontSize: "1.2rem"}}>{x}</span>
							<span>{t2}</span>
						</span>
					);
				});
				setBoxTypesDisplay(tD);
			}
		}
	}

	const handlePhone = useCallback((phone: string) => {
		setPhone(phone);
		onChange({
			recipientName: name,
			email,
			phone,
			pickupKey,
			locale,
		});
	}, [onChange, name, email, pickupKey]);

	const selectRecipient = useCallback(async (selectedRecipients: RecipientSummary[]) => {
		if (!selectedRecipients || selectedRecipients.length == 0) {
			onChange(undefined);
			setRecipient(undefined);
			setName("");
			setEmail("");
			setPhone("");
			setPickupKey("");
			setLocale("");
			return;
		}
		if (selectedRecipients && selectedRecipients.length > 0) {
			const recipient = selectedRecipients[0];
			setRecipient(recipient);
			setName(recipient?.recipientName || "");
			setEmail(recipient?.email || "");
			setPhone(recipient?.phone || "");
			setPickupKey(recipient?.pickupKey || "");
			setLocale(recipient?.locale || "");

			if (onNotifyRecipients) {
				onNotifyRecipients(recipient?.receiveNotifications ?? true);
			}

			let recipientHasCube = false;
			if (recipient?.cubeId || recipient?.preferredCubeId) {
				try {
					let cubes = await CubesProvider.list(tenantId); // race condition with cubes list by ocr with useAsync
					if (cubes) {
						let cube: Cube | undefined = undefined;
						const cubeId = recipient.preferredCubeId || recipient.cubeId;
						if (cubeId) {
							cube = cubes.filter((c) => c.hostname == cubeId).pop();
						}
						if (cube) {
							handleCube(cube);
							recipientHasCube = true;
						}
					}
				} catch (err) {
					console.error("Failed to fetch cubes from tenant", err);
				}
			}
			if (tenant.mapToManualHandoverEnabled) {
				setManualHandoverRequired && setManualHandoverRequired(!recipientHasCube);
				onManualHandoverRequired && onManualHandoverRequired(!recipientHasCube);
				if (recipientHasCube) {
					setDraft(false);
					onDraft && onDraft(false);
				}
			}
			onChange(recipient ?? {recipientName: ""});
			if (tenant.deliveryCreationForDepartmentEnabled && recipient?.email) {
				BookmarksProvider.listForwardedForRecipient(tenantId, recipient.email)
					.then((bookmark) => {
						setBookmark(bookmark);
						setDispatchToDepartment(Boolean(bookmark));
						if (onBookmark) {
							onBookmark(bookmark);
						}
					});
			}
		}
	}, [recipients, tenant, onDestination, onManualHandoverRequired, onDraft, onChange]);

	function handleReserveBoxType(boxType: string) {
		setReserveBox(boxType);
		let boxTypes = [boxType];
		let fB = fallbacks.find(x => x.boxType === boxType);
		if (fB) {
			boxTypes.push(...fB.fallbackTypes);
		}
		if (onReserveBox) {
			onReserveBox({boxTypes, boxFeature});
		}
	}

	function handleFeatureBoxType(boxFeature: AppBoxFeature | undefined) {
		let allTypes: string[] = [];
		availableBoxes.filter(x => boxFeature === undefined || x.features.includes(boxFeature)).map(
			x => {
				allTypes.push(...x.types);
			},
		);

		let typesSet = new Set(allTypes);
		let boxTypes = Array.from(typesSet);
		setBoxTypes(sortBoxTypes(boxTypes));
		setBoxFeature(boxFeature);

		if (onReserveBox) {
			onReserveBox({boxTypes, boxFeature});
		}
	}

	function lookup(ocr: OcrResponse) {
		if (ocr) {
			setLookupProcessing(true);
			RecipientsProvider.lookupOcr(
				tenantId,
				ocr.blocks.filter(x => x.blockType === "WORD").map((b) => b.line),
			)
				.then((result) => {
					if (result.recipients && result.recipients.length > 0) {
						setRecipients(result.recipients);
						setOcrCandidates(result.recipients);
						selectRecipient(result.recipients);
					}
				})
				.finally(() => setLookupProcessing(false));
		}
	}

	const lookupDebounced: (ocr: OcrResponse) => void = useCallback(
		debounce((arg0: OcrResponse) => {
			lookup(arg0);
		}, 300),
		[],
	);

	useEffect(() => {
		if (ocr) {
			lookupDebounced(ocr);
		}
	}, [tenantId, ocr]);

	const requiredFields = useMemo(() => getRequiredFields(tenant), [tenant]);

	const isDepartment = useMemo<boolean>(() => recipient != undefined && recipient.departmentId != undefined, [
		recipient,
	]);

	const emailRequired = !draft && !isDepartment && requiredFields.email;
	const nameRequired = !draft && !isDepartment && requiredFields.name;
	const phoneRequired = !draft && !isDepartment && requiredFields.phone;

	function handleManualHandoverRequired(checked: boolean) {
		setManualHandoverRequired(checked);
		onManualHandoverRequired && onManualHandoverRequired(checked);
	}

	function handleDraft(checked: boolean) {
		setDraft(checked);
		onChange({
			recipientName: name,
			email,
			phone,
			pickupKey,
		});
		onDraft && onDraft(checked);
	}
	return (
		<Grid container spacing={gs}>
			<Grid item xs={12}>
				{(tenant.ocrEnabled && ocr && (!ocrCandidates || ocrCandidates.length == 0)) && (
					<Paper>
						<Alert severity="warning">
							{t("deliveries.create.recipientStep.noneFound")}
						</Alert>
					</Paper>
				)}
			</Grid>
			<Grid item xs={12}>
				<RadioGroup value={recipientSearch} row onChange={(e) => setRecipientSearch(e.target.value == "true")}>
					<FormControlLabel
						control={<Radio />}
						label={t("deliveries.create.recipientStep.selectionOptions.search")}
						value={true}
					/>
					<FormControlLabel
						control={<Radio />}
						label={t("deliveries.create.recipientStep.selectionOptions.manual")}
						value={false}
					/>
				</RadioGroup>
			</Grid>
			<Grid item xs={12}>
				<Divider />
			</Grid>
			{recipientSearch && (
				<Grid item xs={12} sx={{xs: 12, minHeight: 80, alignItems: "center"}}>
					{lookupProcessing && <CircularProgress size={40} style={{display: "block", margin: "auto"}} />}
					<Grid container spacing={gs}>
						<Grid
							item
							xs={12}
							sm={(tenant.deliveryCreationForDepartmentEnabled && onBookmark) ? 6 : 12}
							md={(tenant.deliveryCreationForDepartmentEnabled && onBookmark) ? 8 : 12}
						>
							<RecipientSearchV2
								required={!draft}
								label={t("deliveries.create.recipientStep.selection")}
								recipients={true}
								departments={true}
								multiple={false}
								values={recipientProp && recipientProp?.recipientName.length > 0
									? [recipientProp]
									: []}
								onChange={(r) => selectRecipient(r)}
								candidates={ocrCandidates}
							/>
						</Grid>
						{(tenant.deliveryCreationForDepartmentEnabled && onBookmark) && (
							<Grid item xs={12} sm={6} md={4}>
								<Grid container spacing={1}>
									<Grid item xs={12}>
										<SimpleCheckBox
											label={t("dispatch.dispatchToDepartment")}
											checked={dispatchToDepartment}
											disabled={Boolean(!bookmark)}
											onChange={(checked) => {
												setDispatchToDepartment(checked);
												onBookmark(checked ? bookmark : undefined);
											}}
										/>
										<LHelpButton
											helpPage="Delivery_Create"
											helpAnchor="Dispatch_to_Department"
											language=""
										/>
									</Grid>
									<Grid item xs={12}>
										<Box paddingLeft={3.75}>
											{bookmark && <Chip avatar={<Business />} label={bookmark.label} />}
											{!bookmark && (
												<Typography variant="body2" color="dimgrey">
													{t("dispatch.dispatchToDepartmentNoDepartment")}
												</Typography>
											)}
										</Box>
									</Grid>
								</Grid>
							</Grid>
						)}
					</Grid>
				</Grid>
			)}
			{(recipientSearch && ((nameRequired && name) || (emailRequired && email) || (phoneRequired && phone)))
				&& (
					<Grid item xs={12}>
						<RecipientStepSelectedDisplay
							name={name}
							email={email}
							phone={phone}
							department={recipient ? recipient.department : undefined}
							building={recipient ? recipient.building : undefined}
							pickupKey={recipient ? recipient.pickupKey : undefined}
							locale={recipient ? recipient.locale : undefined}
						/>
					</Grid>
				)}
			{(recipientSearch
				&& (!recipient || (nameRequired && !name) || (emailRequired && !email) || (phoneRequired && !phone)))
				&& (
					<Grid item xs={12}>
						<RecipientStepNoRecipientSelected />
					</Grid>
				)}
			{!recipientSearch && (
				<Fragment>
					<Grid item xs={12}>
						<SimpleTextInput
							label={t("deliveries.create.recipientStep.name")}
							value={name}
							onChange={handleName}
							required={nameRequired}
						/>
					</Grid>
					<Grid item sm={6} xs={12}>
						<SimpleTextInput
							label={t("deliveries.create.recipientStep.email")}
							value={email}
							onChange={handleEmail}
							required={emailRequired}
						/>
					</Grid>
					<Grid item sm={6} xs={12}>
						<SimpleTextInput
							label={t("deliveries.create.recipientStep.phone")}
							value={phone}
							onChange={handlePhone}
							required={phoneRequired}
						/>
					</Grid>
				</Fragment>
			)}
			<Grid item xs={12}>
				<Divider />
			</Grid>
			{(!manualHandoverRequired && destinationVisible) && (
				<React.Fragment>
					<Grid item xs={onReserveBox ? 5 : 12}>
						<CubeSelectInput
							value={destination}
							onChange={(cube) => {
								if (cube) handleCube(cube);
							}}
							required={!draft}
							allowUndefined={draft}
							autoSelect
						/>
					</Grid>
					{onReserveBox && (
						<Grid item xs={4}>
							<BoxFeatureSelectInput
								disabled={boxFeatures.length < 1}
								boxFeatures={boxFeatures}
								value={boxFeature}
								onChange={handleFeatureBoxType}
								label={t("deliveries.create.recipientStep.featureBox")}
							/>
						</Grid>
					)}
					{onReserveBox && (
						<Grid item xs={3}>
							<BoxTypeInput
								disabled={false}
								boxTypes={boxTypes}
								value={reserveBox}
								boxTypesDisplay={boxTypesDisplay}
								onChange={handleReserveBoxType}
								label={t("deliveries.create.recipientStep.reserveBox")}
							/>
						</Grid>
					)}
				</React.Fragment>
			)}
			{(manualHandoverRequiredVisible || manualHandoverRequiredVisible === undefined) && (
				<Grid item xs={6}>
					<SimpleCheckBox
						checked={manualHandoverRequired}
						label={t("deliveries.create.recipientStep.manualHandoverRequired")}
						onChange={handleManualHandoverRequired}
					/>
					<LHelpButton helpPage="Delivery_Create" helpAnchor="Manual_Handover_Required" language="" />
				</Grid>
			)}
			{(draftVisible || draftVisible === undefined) && (
				<Grid item xs={6}>
					<SimpleCheckBox
						checked={draft}
						label={t("deliveries.create.recipientStep.draft")}
						onChange={handleDraft}
					/>
					<LHelpButton helpPage="Delivery_Create" helpAnchor="Draft" language="" />
				</Grid>
			)}
			{tenant.recipientsNotifyingSpecificationRequired && onNotifyRecipients && (
				<Grid item xs={6}>
					<SimpleCheckBox
						checked={notifyRecipients}
						label={t("deliveries.create.recipientStep.notifyRecipients")}
						onChange={onNotifyRecipients}
					/>
					<LHelpButton helpPage="Delivery_Create" helpAnchor="Notify_Recipients" language="" />
				</Grid>
			)}
		</Grid>
	);
}
