import * as React from "react";
import {createElement, MutableRefObject, useCallback, useEffect, useRef} from "react";
import SignaturePad, {PointGroup} from "signature_pad";

interface SignatureCanvasProps extends React.DetailedHTMLProps<React.CanvasHTMLAttributes<HTMLCanvasElement>, HTMLCanvasElement> {
    onSignatureChange: (canvas: HTMLCanvasElement, empty: boolean, points: PointGroup[]) => void;
    clearRef?: MutableRefObject<(() => void) | undefined>;
    height?: string;
}

export function SignatureCanvas({onSignatureChange, clearRef, height, ...canvasProps}: SignatureCanvasProps) {
    const signaturePadRef = useRef<SignaturePad | null>(null);
    const canvasRef = useRef<HTMLCanvasElement | null>(null);

    const notifySignatureChange = useCallback(() => {
        if (canvasRef.current && signaturePadRef.current) {
            onSignatureChange(canvasRef.current, signaturePadRef.current.isEmpty(), signaturePadRef.current.toData());
        }
    }, [onSignatureChange]);

    const handleClear = useCallback(() => {
        signaturePadRef.current?.clear();
    }, []);

    useEffect(() => {
        if (clearRef) {
            clearRef.current = handleClear;
        }
    }, [clearRef, handleClear]);

    const handleResize = useCallback(() => {
        const canvas = canvasRef.current;
        if (canvas) {
            // When zoomed out to less than 100%, for some very strange reason,
            // some browsers report devicePixelRatio as less than 1
            // and only part of the canvas is cleared then.
            const ratio = Math.max(window.devicePixelRatio || 1, 1);

            // This part causes the canvas to be cleared
            canvas.width = canvas.offsetWidth * ratio;
            canvas.height = canvas.offsetHeight * ratio;
            canvas.getContext("2d")?.scale(ratio, ratio);

            // This library does not listen for canvas changes, so after the canvas is automatically
            // cleared by the browser, SignaturePad#isEmpty might still return false, even though the
            // canvas looks empty, because the internal data of this library wasn't cleared. To make sure
            // that the state of this library is consistent with visual state of the canvas, you
            // have to clear it manually.
            signaturePadRef.current?.clear();

            notifySignatureChange();
        }
    }, [notifySignatureChange]);

    useEffect(() => {
        if (canvasRef.current) {
            const pad = new SignaturePad(canvasRef.current);
			pad.addEventListener('endStroke', notifySignatureChange);
            signaturePadRef.current = pad;
            handleResize();
            notifySignatureChange();
        }
    }, [handleResize, notifySignatureChange]);

    useEffect(() => {
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, [handleResize]);

    return (
        <canvas {...canvasProps} ref={canvasRef} style={{width: '100%', height: height || '40vh'}} />
    );
}
