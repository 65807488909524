import {containerClasses} from "@mui/material";
import {createElement, useCallback, useMemo} from "react";
import {Site} from "../domain/Site";
import {MultiStringSelect} from "./MultiStringSelect";

interface SitesSelectInputProps {
	label: string;
	options: Site[];
	values: Site[];
	onChange: (newValue: Site[]) => void;
}

export function SitesSelectInput(props: SitesSelectInputProps) {
	const {label, options, values, onChange} = props;

	const mappedOptions = useMemo<string[]>(() => options.map((s) => s.siteId), [options]);
	const mappedValues = useMemo<string[]>(() => values.map((s) => s.siteId), [values]);

	const lookupLabel = useCallback((siteId: string) => {
		const site = options.find((s) => s.siteId === siteId);
		if (site) {
			return site.name;
		}
		return siteId;
	}, [options]);

	const handleChange = useCallback((newValues: string[]) => {
		const newSites: Site[] = [];
		for (const siteId of newValues) {
			const site = options.find((s) => s.siteId === siteId);
			if (site) {
				newSites.push(site);
			}
		}
		onChange(newSites);
	}, [options, onChange]);

	return (
		<MultiStringSelect
			label={label}
			options={mappedOptions}
			values={mappedValues}
			lookupLabel={lookupLabel}
			onChange={handleChange}
		/>
	);
}
