import {Business} from "@mui/icons-material";
import {
	Alert,
	Box,
	Chip,
	CircularProgress,
	Divider,
	FormControlLabel,
	Grid,
	Radio,
	RadioGroup,
	Stack,
	Typography,
} from "@mui/material";
import {Checkbox, TextField, useFlag} from "@variocube/app-ui";
import {NotFoundSvg} from "@variocube/app-ui/esm/layout/NotFoundSvg";
import {createElement, Fragment, useContext, useEffect, useMemo, useState} from "react";
import {useAsync, useAsyncCallback} from "react-async-hook";
import {Bookmark} from "../../../domain/Bookmark";
import {BookmarksProvider} from "../../../domain/BookmarksProvider";
import {AppBoxFeature, AppLockStatus, Cube, CubeBox, ReserveBoxFallback} from "../../../domain/Cube";
import {CubesProvider} from "../../../domain/CubesProvider";
import {OcrResponse} from "../../../domain/Ocr";
import {RecipientSummary} from "../../../domain/Recipients";
import {RecipientsProvider} from "../../../domain/RecipientsProvider";
import {getRequiredFields} from "../../../domain/TenantUser";
import {useLocalization} from "../../../i18n";
import {BoxFeatureSelectInput} from "../../cubes/BoxFeatureSelectInput";
import {BoxTypeInput} from "../../cubes/BoxTypeInput";
import {CubeSelectInput} from "../../cubes/CubeSelectInput";
import {LHelpButton} from "../../LHelpButton";
import {RecipientSearchV2} from "../../recipients/RecipientSearchV2";
import {useTenant, useTenantUser, useTenantUserRole} from "../../TenantContextProvider";
import {WizardStepContext} from "./contexts";
import {RecipientStepSelectedDisplay} from "./recipient-selectd-display";
import {BoxReservation, RecipientDetailsData} from "./types";
import { LabeledData } from "../../LabeledData";
import { CubeUtilizationComponent } from "../../cubes/CubeUtilizationComponent";

function sortBoxTypes(types: string[]) {
	const supportedOrder: string[] = [
		"XS",
		"S",
		"M",
		"L",
		"XL",
		"XXL",
	];
	let sorted: string[] = [];
	let unsorted: string[] = [];
	for (let type of types) {
		const orderIndex = supportedOrder.indexOf(type);
		if (orderIndex > -1) {
			sorted[orderIndex] = type;
		} else if (type) {
			unsorted.push(type);
		}
	}
	return [
		...sorted.filter(t => !!t),
		...unsorted.sort((a, b) => a.localeCompare(b)),
	];
}

interface StepRecipient {
	defaultRecipientDetailsData?: RecipientDetailsData;
	defaultDestination?: Cube;
	defaultBoxReservation?: BoxReservation;
	defaultBookmark?: Bookmark;
	onRecipientDetailsChange: (data: RecipientDetailsData) => void;
	onDestinationChange: (cube?: Cube) => void;
	onBookmarkChange: (bookmark?: Bookmark) => void;
	onBoxReservationChange: (boxReservation?: BoxReservation) => void;
	ocr?: OcrResponse;
}

export function StepRecipient(props: StepRecipient) {
	const {
		defaultRecipientDetailsData,
		defaultDestination,
		defaultBoxReservation,
		defaultBookmark,
		onRecipientDetailsChange,
		onDestinationChange,
		onBookmarkChange,
		onBoxReservationChange,
		ocr,
	} = props;

	const {t} = useLocalization();
	const tenant = useTenant();
	const user = useTenantUser();
	const {isAdmin} = useTenantUserRole();

	const [searchEnabled, setSearchEnabled, clearSearchEnabled] = useFlag(true);

	const {result: ocrRecipients, loading: lookupProcessing, set: setOcrRecipients} = useAsync(async () => {
		if (ocr && !defaultRecipientDetailsData) {
			try {
				const segments = ocr.blocks
					.filter(b => b.blockType === "WORD")
					.map(b => b.line);
				const {recipients} = await RecipientsProvider.lookupOcr(tenant.centerId, segments);
				return recipients;
			} catch (err) {
				console.error("Failed look up recipients with ocr", err);
			}
		}
	}, [ocr, tenant.centerId, defaultRecipientDetailsData]);
	const {result: cubes} = useAsync(() =>
		isAdmin
			? CubesProvider.list(tenant.centerId)
			: CubesProvider.listSiteCubes(tenant.centerId, user.siteId), [tenant.centerId, isAdmin]);

	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [phone, setPhone] = useState("");

	const [notifyRecipients, setNotifyRecipients] = useState(true);
	const [manualHandoverRequired, setManualHandoverRequired] = useState(false);
	const [isDraft, setIsDraft] = useState(false);
	const [recipient, setRecipient] = useState<RecipientSummary>();

	const [cube, setCube] = useState<Cube>();
	const [reserveBoxType, setReserveBoxType] = useState("");
	const [boxFeature, setBoxFeature] = useState<AppBoxFeature>();

	const [bookmark, setBookmark] = useState<Bookmark>();

	useEffect(() => {
		if (defaultRecipientDetailsData) {
			setManualHandoverRequired(defaultRecipientDetailsData.manualHandoverRequired);
			setNotifyRecipients(defaultRecipientDetailsData.notifyRecipients);
			setIsDraft(defaultRecipientDetailsData.draft);
			setRecipient(defaultRecipientDetailsData.recipient);

			if (defaultRecipientDetailsData.recipient) {
				setName(defaultRecipientDetailsData.recipient.recipientName);
				setEmail(defaultRecipientDetailsData.recipient.email ?? "");
				setPhone(defaultRecipientDetailsData.recipient.phone ?? "");
			}
		}

		setCube(defaultDestination);

		if (defaultBoxReservation) {
			setBoxFeature(defaultBoxReservation.boxFeature);
			setReserveBoxType(defaultBoxReservation.boxTypes.length > 0 ? defaultBoxReservation.boxTypes[0] : "");
		}

		setBookmark(defaultBookmark);
	}, []);

	const {
		result: {
			availableBoxes,
			boxFeatures,
			fallbacks,
		} = {},
	} = useAsync(async () => {
		let availableBoxes: CubeBox[] = [];
		let boxFeatures: AppBoxFeature[] = [];
		let fallbacks: ReserveBoxFallback[] = [];

		if (cube) {
			try {
				const cubeDetails = await CubesProvider.get(tenant.centerId, cube.hostname);
				if (cubeDetails.settings.reserveBoxFallbacks) {
					fallbacks = JSON.parse(cubeDetails.settings.reserveBoxFallbacks);
				}

				const boxes = await CubesProvider.getBoxes(tenant.centerId, cube.hostname);
				for (let box of boxes) {
					if (!box.disabled && box.lockStatus === AppLockStatus.Closed && box.occupancies.length === 0) {
						availableBoxes.push(box);
						boxFeatures.push(...box.features.filter(f => !boxFeatures.includes(f)));
					}
				}
			} catch (err) {
				console.error("Failed to process cube data", err);
			}
		}

		return {
			availableBoxes,
			boxFeatures,
			fallbacks,
		};
	}, [tenant.centerId, cube]);

	const {
		boxTypes,
		boxTypesDisplay,
	} = useMemo(() => {
		let boxTypes: string[] = [];

		for (let box of (availableBoxes ?? [])) {
			box.types.forEach(t => {
				if (!boxTypes.includes(t) && (!boxFeature || box.features.includes(boxFeature))) {
					boxTypes.push(t);
				}
			});
		}
		boxTypes = sortBoxTypes(boxTypes);

		const boxTypesDisplay = boxTypes.map(boxType => {
			let typeLabel: string[] = [];
			let fallback = (fallbacks ?? []).find(fb => fb.boxType === boxType);
			if (fallback) {
				typeLabel.push(" (fallback ");
				typeLabel.push(fallback.fallbackTypes.join(", "));
				typeLabel.push(")");
			}
			return (
				<span>
					<strong>{boxType}</strong>
					{typeLabel}
				</span>
			);
		});

		return {
			boxTypes,
			boxTypesDisplay,
		};
	}, [availableBoxes, boxFeature, fallbacks]);

	function handleRecipientSelect(
		{notifyRecipients, manualHandoverRequired, isDraft, cube, recipient}: RecipientChangeData,
		preventPublishing?: boolean,
	) {
		console.log("handleRecipientSelect", recipient);

		setNotifyRecipients(notifyRecipients);
		setManualHandoverRequired(manualHandoverRequired);
		setIsDraft(isDraft);
		setCube(cube);
		setRecipient(recipient);

		setName(recipient?.recipientName ?? "");
		setEmail(recipient?.email ?? "");
		setPhone(recipient?.phone ?? "");

		if (!preventPublishing) {
			onRecipientDetailsChange({
				manualHandoverRequired,
				notifyRecipients,
				draft: isDraft,
				recipient,
			});
			onDestinationChange(cube);
		}
	}

	function handleBookmarkChange(bookmark?: Bookmark) {
		setBookmark(bookmark);
		onBookmarkChange(bookmark);
	}

	function handleCubeChange(cube?: Cube) {
		setCube(cube);
		onDestinationChange(cube);
	}

	function handleBoxFeature(feature?: AppBoxFeature) {
		setBoxFeature(feature);

		onBoxReservationChange({
			boxFeature: feature,
			boxTypes: [],
		});
	}

	function handleReserveBoxType(type: string) {
		const types = [type];
		const fallback = (fallbacks ?? []).find(fb => fb.boxType === type);
		if (fallback) {
			types.push(...fallback.fallbackTypes);
		}
		setReserveBoxType(type);

		onBoxReservationChange({
			boxFeature,
			boxTypes: types,
		});
	}

	function handleRecipientDetailsChange(
		key: "name" | "email" | "phone" | "manualHandoverRequired" | "draft" | "notifyRecipients",
		value: any,
	) {
		if (!recipient) {
			setRecipient({recipientName: "", email: "", phone: ""});
		}
		const details = {
			manualHandoverRequired,
			notifyRecipients,
			draft: isDraft,
			recipient: recipient || {recipientName: ""},
			destination: cube,
		};
		switch (key) {
			case "name":
				details.recipient.recipientName = value;
				setName(value);
				break;
			case "email":
				details.recipient.email = value;
				setEmail(value);
				break;
			case "phone":
				details.recipient.phone = value;
				setPhone(value);
				break;
			case "manualHandoverRequired":
				details.manualHandoverRequired = value;
				details.destination = undefined;
				setManualHandoverRequired(value);
				break;
			case "draft":
				details.draft = value;
				setIsDraft(value);
				break;
			case "notifyRecipients":
				details.notifyRecipients = value;
				setNotifyRecipients(value);
				break;
		}

		onRecipientDetailsChange(details);
	}

	const {formReset} = useContext(WizardStepContext);

	useEffect(() => {
		if (formReset) {
			resetForm();
		}
	}, [formReset]);

	function resetForm() {
		if (!searchEnabled) {
			handleRecipientSelect({
				notifyRecipients: true,
				manualHandoverRequired: false,
				isDraft: false,
				cube: undefined,
				recipient: undefined,
			});
		} else {
			setOcrRecipients({
				result: undefined,
				error: undefined,
				loading: false,
				status: "success",
			});
		}
		handleBookmarkChange(undefined);
		console.log("Step Recipient data reset.");
	}

	const {
		emailRequired,
		nameRequired,
		phoneRequired,
	} = useMemo(() => {
		const {name, email, phone} = getRequiredFields(tenant);
		const hasDepartment = recipient && recipient.departmentId;
		return {
			emailRequired: !isDraft && !hasDepartment && email,
			nameRequired: !isDraft && !hasDepartment && name,
			phoneRequired: !isDraft && !hasDepartment && phone,
		};
	}, [tenant, isDraft, recipient]);

	const hasRequiredFields = (nameRequired && name)
		|| (emailRequired && email)
		|| (phoneRequired && phone);

	return (
		<Stack direction="column" spacing={3}>
			{lookupProcessing && (
				<Stack alignItems="center">
					<CircularProgress />
				</Stack>
			)}

			{(ocr && ocrRecipients?.length === 0) && (
				<Alert severity="warning">
					{t("deliveries.create.recipientStep.noneFound")}
				</Alert>
			)}

			<Grid container spacing={3} maxWidth="md">
				<Grid item xs={12}>
					<RadioGroup
						row
						value={searchEnabled}
						onChange={(_, v) =>
							(v === "true")
								? setSearchEnabled()
								: clearSearchEnabled()}
					>
						<FormControlLabel
							control={<Radio />}
							label={t("deliveries.create.recipientStep.selectionOptions.search")}
							value={true}
						/>
						<FormControlLabel
							control={<Radio />}
							label={t("deliveries.create.recipientStep.selectionOptions.manual")}
							value={false}
						/>
					</RadioGroup>
				</Grid>

				<Grid item xs={12}>
					<Divider />
				</Grid>

				{searchEnabled && (
					<Fragment>
						<Grid item xs={12}>
							<RecipientSearchForm
								centerId={tenant.centerId}
								allowDispatchToDepartment={tenant.deliveryCreationForDepartmentEnabled}
								mapToManualHandover={tenant.mapToManualHandoverEnabled}
								onSelect={handleRecipientSelect}
								onBookmarkChange={handleBookmarkChange}
								{...{recipient, bookmark, cubes, ocrRecipients}}
							/>
						</Grid>

						{(!recipient && !hasRequiredFields) && (
							<Grid item xs={12}>
								<Stack direction="row" justifyContent="center">
									<NotFoundSvg
										style={{
											maxWidth: "100%",
											maxHeight: 200,
										}}
									/>
								</Stack>
							</Grid>
						)}

						{hasRequiredFields && (
							<Grid item xs={12}>
								<RecipientStepSelectedDisplay
									name={name}
									email={email}
									phone={phone}
									department={recipient ? recipient.department : undefined}
									building={recipient ? recipient.building : undefined}
									pickupKey={recipient ? recipient.pickupKey : undefined}
									locale={recipient ? recipient.locale : undefined}
								/>
							</Grid>
						)}
					</Fragment>
				)}

				{!searchEnabled && (
					<Fragment>
						<Grid item xs={12}>
							<TextField
								fullWidth
								label={t("deliveries.create.recipientStep.name")}
								value={name}
								onChange={v => handleRecipientDetailsChange("name", v)}
								required={nameRequired}
							/>
						</Grid>
						<Grid item sm={6} xs={12}>
							<TextField
								fullWidth
								label={t("deliveries.create.recipientStep.email")}
								value={email}
								onChange={v => handleRecipientDetailsChange("email", v)}
								required={emailRequired}
							/>
						</Grid>
						<Grid item sm={6} xs={12}>
							<TextField
								fullWidth
								label={t("deliveries.create.recipientStep.phone")}
								value={phone}
								onChange={v => handleRecipientDetailsChange("phone", v)}
								required={phoneRequired}
							/>
						</Grid>
					</Fragment>
				)}

				<Grid item xs={12}>
					<Divider />
				</Grid>

				{!manualHandoverRequired && (
					<Fragment>
						<Grid item xs={3}>
							{cubes && (
								<CubeSelectInput
									cubes={cubes}
									value={cube}
									onChange={handleCubeChange}
									required={!isDraft}
									allowUndefined={isDraft}
									autoSelect
								/>
							)}
						</Grid>
	
						<Grid item xs={4}>
							<BoxFeatureSelectInput
								disabled={boxFeatures && boxFeatures.length === 0}
								boxFeatures={boxFeatures ?? []}
								value={boxFeature}
								onChange={handleBoxFeature}
								label={t("deliveries.create.recipientStep.featureBox")}
							/>
						</Grid>
						<Grid item xs={3}>
							<BoxTypeInput
								boxTypes={boxTypes ?? []}
								value={reserveBoxType}
								boxTypesDisplay={boxTypesDisplay}
								onChange={handleReserveBoxType}
								label={t("deliveries.create.recipientStep.reserveBox")}
							/>
						</Grid>
						{cube?.utilization && 
							<Grid item xs={2}>
								<LabeledData label={t("cubes.utilization.label")}>
									<CubeUtilizationComponent utilization={cube?.utilization} />
								</LabeledData>
							</Grid>
						}
						{cube?.utilization && (cube?.utilization.available==0) && <Grid item xs={12}>
							<Typography variant="body2" color="red">
								{t("cubes.boxes.noAvailableBoxes")}
							</Typography>
						</Grid>}
						
					</Fragment>
				)}

				<Grid item xs={6}>
					<Checkbox
						label={t("deliveries.create.recipientStep.manualHandoverRequired")}
						checked={manualHandoverRequired}
						onChange={v => handleRecipientDetailsChange("manualHandoverRequired", v)}
					/>
					<LHelpButton helpPage="Delivery_Create" helpAnchor="Manual_Handover_Required" language="" />
				</Grid>

				<Grid item xs={6}>
					<Checkbox
						checked={isDraft}
						label={t("deliveries.create.recipientStep.draft")}
						onChange={v => handleRecipientDetailsChange("draft", v)}
					/>
					<LHelpButton helpPage="Delivery_Create" helpAnchor="Draft" language="" />
				</Grid>

				{tenant.recipientsNotifyingSpecificationRequired && (
					<Grid item xs={6}>
						<Checkbox
							checked={notifyRecipients}
							label={t("deliveries.create.recipientStep.notifyRecipients")}
							onChange={v => handleRecipientDetailsChange("notifyRecipients", v)}
						/>
						<LHelpButton helpPage="Delivery_Create" helpAnchor="Notify_Recipients" language="" />
					</Grid>
				)}
			</Grid>
		</Stack>
	);
}

interface RecipientChangeData {
	notifyRecipients: boolean;
	manualHandoverRequired: boolean;
	isDraft: boolean;
	cube?: Cube;
	recipient?: RecipientSummary;
}

interface RecipientSearchFormProps {
	centerId: string;
	allowDispatchToDepartment: boolean;
	mapToManualHandover: boolean;
	onSelect: (data: RecipientChangeData) => void;
	onBookmarkChange: (bookmark?: Bookmark) => void;
	recipient?: RecipientSummary;
	bookmark?: Bookmark;
	cubes?: Cube[];
	ocrRecipients?: RecipientSummary[];
}

function RecipientSearchForm(props: RecipientSearchFormProps) {
	const {t} = useLocalization();

	const {
		centerId,
		allowDispatchToDepartment,
		mapToManualHandover,
		onSelect,
		onBookmarkChange,
		recipient: pRecipient,
		bookmark: pBookmark,
		cubes,
		ocrRecipients,
	} = props;

	const [recipient, setRecipient] = useState(pRecipient);
	const [bookmark, setBookmark] = useState<Bookmark>();
	const [dispatchToDepartment, setDispatchToDepartment, clearDispatchToDepartment] = useFlag(false);

	const {execute: handleRecipientSelection} = useAsyncCallback(
		async (recipients: RecipientSummary[], preventPropagation: boolean = false) => {
			let notifyRecipients = true;
			let cube: Cube | undefined = cubes && cubes.length === 1 ? cubes[0] : undefined;
			let manualHandoverRequired = false;
			let isDraft = false;
			let bookmark: Bookmark | undefined;

			const recipient = recipients.length === 0 ? undefined : recipients[0];
			if (recipient) {
				notifyRecipients = recipient.receiveNotifications === true;

				if (cubes && (recipient.cubeId || recipient.preferredCubeId)) {
					const cubeId = (recipient.preferredCubeId || recipient.cubeId) as string;
					cube = cubes.find(c => c.hostname === cubeId);
				}

				if (mapToManualHandover) {
					manualHandoverRequired = !cube;
				}

				if (allowDispatchToDepartment && recipient.email) {
					try {
						bookmark = await BookmarksProvider.listForwardedForRecipient(centerId, recipient.email);
						if (bookmark) {
							setDispatchToDepartment();
							// cube = undefined;
							manualHandoverRequired = !bookmark.cubeId;
						} else {
							clearDispatchToDepartment();
						}
					} catch (err) {
						console.error("Failed to find bookmark", err);
					}
				}
			}

			setRecipient(recipient);
			setBookmark(bookmark);

			if (!preventPropagation) {
				onSelect({
					notifyRecipients,
					manualHandoverRequired,
					isDraft,
					recipient,
					cube,
				});
				onBookmarkChange(bookmark);
			}
		},
	);

	useEffect(() => {
		if (pRecipient && pRecipient.recipientId !== recipient?.recipientId) {
			handleRecipientSelection([pRecipient], true)
				.then(() => {
					if (pBookmark === undefined) {
						clearDispatchToDepartment();
					}
				})
				.catch(console.error);
		}
	}, [pRecipient, pBookmark]);

	async function handleDispatchToDepartmentChange(checked: boolean) {
		if (checked) {
			setDispatchToDepartment();
		} else {
			clearDispatchToDepartment();
		}
		onBookmarkChange(checked ? bookmark : undefined);
	}

	const {formReset} = useContext(WizardStepContext);

	useEffect(() => {
		if (formReset) {
			handleRecipientSelection([])
				.then();
		}
	}, [formReset, handleRecipientSelection]);

	return (
		<Grid container spacing={3}>
			<Grid
				item
				xs={12}
				sm={allowDispatchToDepartment ? 6 : 12}
				md={allowDispatchToDepartment ? 8 : 12}
			>
				<RecipientSearchV2
					label={t("deliveries.create.recipientStep.selection")}
					recipients={true}
					departments={true}
					multiple={false}
					values={recipient && recipient.recipientName.length > 0
						? [recipient]
						: []}
					onChange={handleRecipientSelection}
					candidates={ocrRecipients}
				/>
			</Grid>
			{allowDispatchToDepartment && (
				<Grid
					item
					xs={12}
					sm={6}
					md={4}
				>
					<Checkbox
						label={t("dispatch.dispatchToDepartment")}
						checked={dispatchToDepartment}
						onChange={handleDispatchToDepartmentChange}
						disabled={!bookmark}
					/>
					<LHelpButton
						helpPage="Delivery_Create"
						helpAnchor="Dispatch_to_Department"
						language=""
					/>
					<Box pl={3.75}>
						{bookmark && <Chip avatar={<Business />} label={bookmark.label} />}
						{!bookmark && (
							<Typography variant="body2" color="dimgrey">
								{t("dispatch.dispatchToDepartmentNoDepartment")}
							</Typography>
						)}
					</Box>
				</Grid>
			)}
		</Grid>
	);
}
