import {Api} from "../Api";
import {TemplateType} from "./TenantUser";

export interface NotificationTestRequest {
	siteId: string;
	templateType: TemplateType;
}

export interface NotificationTestResponse extends NotificationTestRequest {
	recipient: string;
	renderedEmailSubject?: string;
	renderedEmailBody?: string;
	renderedSms?: string;
}

export class NotificationsTestProvider {
	static testNotification(
		tenantId: string,
		siteId: string,
		templateType: TemplateType,
	): Promise<NotificationTestResponse> {
		const request: NotificationTestRequest = {
			siteId,
			templateType,
		};
		return Api.POST(`notifications/${tenantId}/test`, request).then((raw) => {
			return raw as NotificationTestResponse;
		});
	}
}
