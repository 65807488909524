const LOCAL_PRINT_SETTINGS_KEY = "variocube-local-print-settings";

interface LocalPrintSettings {
	labelPrinter: boolean;
}

class LocalPrintSettingsWrapper {
	private getSettings(): LocalPrintSettings {
		const settings = localStorage.getItem(LOCAL_PRINT_SETTINGS_KEY);
		if (settings) {
			return JSON.parse(settings);
		}
		return {
			labelPrinter: true,
		};
	}

	get labelPrinter(): boolean {
		return this.getSettings().labelPrinter;
	}

	set labelPrinter(value: boolean) {
		const settings = this.getSettings();
		settings.labelPrinter = value;
		localStorage.setItem(LOCAL_PRINT_SETTINGS_KEY, JSON.stringify(settings));
	}
}

export const localPrintSettings = new LocalPrintSettingsWrapper();
