import {Alert, Grid, Typography} from "@mui/material";
import {createElement, useCallback, useEffect, useMemo, useState} from "react";
import {NotificationsTestProvider, NotificationTestResponse} from "../../domain/Notifications";
import {Site} from "../../domain/Site";
import {TemplateType} from "../../domain/TenantUser";
import {useLocalization} from "../../i18n";
import {gs} from "../../theme";
import {BoxedPaper} from "../BoxedPaper";
import {ContainerLayout} from "@variocube/app-ui";
import {LabeledData} from "../LabeledData";
import {Loading} from "../Loading";
import {TemplateTypeInput} from "../settings/TemplateTypeInput";
import {SimpleButton} from "../SimpleButton";
import {SiteSelectInput} from "../SiteSelectInput";
import {useTenant, useTenantUser} from "../TenantContextProvider";
import {useAsync} from "react-async-hook";
import {TenantUserProvider} from "../../domain/TenantUserProvider";

export function NotificationsTest() {
	const {t, e} = useLocalization();

	const tenant = useTenant();
	const user = useTenantUser();

	const [selectedTemplateType, setSelectedTemplateType] = useState<TemplateType>(TemplateType.StoredRecipient);
	const [selectedSiteId, setSelectedSiteId] = useState<string>();
	const [loading, setLoading] = useState<boolean>(false);

	const [response, setResponse] = useState<NotificationTestResponse>();
	const [error, setError] = useState<boolean>();

	const {result: notificationTemplates} = useAsync(() => TenantUserProvider.listNotificationTemplates(tenant.centerId), [tenant.centerId]);

	useEffect(() => {
		if (notificationTemplates && notificationTemplates.length > 0) {
			setSelectedTemplateType(notificationTemplates[0].templateType);
		}
	}, [notificationTemplates]);

	const templateTypes = useMemo<TemplateType[]>(() => {
		if (notificationTemplates) {
			return notificationTemplates.map(t => t.templateType);
		}
		return [];
	}, [notificationTemplates]);

	const handleSiteChange = useCallback((site?: Site) => {
		setSelectedSiteId(site?.siteId);
	}, []);

	const handleTest = useCallback(() => {
		if (tenant && selectedSiteId && selectedTemplateType) {
			setLoading(true);
			setError(false);
			setResponse(undefined);
			NotificationsTestProvider.testNotification(tenant.centerId, selectedSiteId, selectedTemplateType)
				.then((response) => {
					setResponse(response);
				})
				.catch(() => setError(true))
				.finally(() => setLoading(false));
		}
	}, [tenant, selectedSiteId, selectedTemplateType]);

	return (
		<ContainerLayout>
			<Grid container spacing={gs}>
				<Grid item xs={12}>
					<Typography variant="h2">{t("notifications.plural")}</Typography>
				</Grid>
				<Grid item xs={12}>
					<Typography variant="body1">{t("notifications.info", {email: user.email})}</Typography>
				</Grid>
				{templateTypes.length == 0
					&& (
						<Grid item xs={12}>
							<Alert severity="warning">{t("notifications.noTemplates")}</Alert>
						</Grid>
					)}
				{templateTypes
					&& (
						<Grid item xs={12}>
							<BoxedPaper loading={loading}>
								<Grid container spacing={gs}>
									<Grid item xs={5}>
										<TemplateTypeInput
											options={templateTypes}
											label={t("tenants.templateType")}
											value={selectedTemplateType}
											onChange={setSelectedTemplateType}
										/>
									</Grid>
									<Grid item xs={5}>
										<SiteSelectInput
											value={selectedSiteId}
											onChange={handleSiteChange}
											autoSelect={true}
										/>
									</Grid>
									<Grid item xs={2}>
										<SimpleButton
											primary
											label={t("notifications.sendTest")}
											onClick={handleTest}
											loading={loading}
										/>
									</Grid>
									<Grid item xs={12}>
										{loading && <Loading />}
										{(response)
											&& (
												<Alert severity="success">
													{t("notifications.sendOutcome", {
														template: e("tenants.templateTypes", response.templateType),
														recipient: response.recipient,
													})}
												</Alert>
											)}
										{error
											&& <Alert severity="error">{t("notifications.sendError")}</Alert>}
									</Grid>
								</Grid>
							</BoxedPaper>
						</Grid>
					)}
				{response
					&& (
						<Grid item xs={12}>
							<BoxedPaper>
								<Grid container spacing={gs}>
									<Grid item xs={12} sm={8}>
										<Grid container spacing={gs}>
											{response.renderedEmailSubject
												&& (
													<Grid item xs={12}>
														<LabeledData label={t("tenants.renderedEmailSubject")}>
															<Typography variant="body1">
																{response.renderedEmailSubject}
															</Typography>
														</LabeledData>
													</Grid>
												)}
											{response.renderedEmailBody
												&& (
													<Grid item xs={12}>
														<LabeledData label={t("tenants.renderedEmailBody")}>
															<Typography variant="body1">
																<div
																	dangerouslySetInnerHTML={{
																		__html: response.renderedEmailBody,
																	}}
																>
																</div>
															</Typography>
														</LabeledData>
													</Grid>
												)}
										</Grid>
									</Grid>
									{response.renderedSms
										&& (
											<Grid item xs={12} sm={4}>
												<LabeledData label={t("tenants.renderedSms")}>
													<Typography variant="body1">{response.renderedSms}</Typography>
												</LabeledData>
											</Grid>
										)}
								</Grid>
							</BoxedPaper>
						</Grid>
					)}
			</Grid>
		</ContainerLayout>
	);
}
