import * as React from "react";
import {createElement, useCallback} from "react";
import {TenantManualHandoverOptions, TenantNotificationSetting} from "../domain/TenantUser";
import {MenuItem, TextField} from "@mui/material";
import {useLocalization} from "../i18n";

interface TenantManualHandoverOptionsInputProps {
    value: TenantManualHandoverOptions;
    onChange: (value: TenantManualHandoverOptions) => void;
    label: string;
}

export function TenantManualHandoverOptionsInput(props: TenantManualHandoverOptionsInputProps) {
    const {value, onChange, label} = props;
    const {e} = useLocalization();

    const handleChange = useCallback((value: string) => {
        onChange(value as TenantManualHandoverOptions);
    }, []);

    return (
        <TextField label={label} variant="outlined" fullWidth select
                   value={value} onChange={(e) => handleChange(e.target.value)}>
            {Object.keys(TenantManualHandoverOptions).map((option) =>
                <MenuItem key={option} value={option}>{e('tenants.manualHandoverOptions', option)}</MenuItem>
            )}
        </TextField>
    );
}
