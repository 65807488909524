import { createElement } from "react";
import { gs } from "../../theme";
import { Grid } from "@mui/material";
import { Sender } from "../../domain/Delivery";
import { BoxedPaper } from "../BoxedPaper";
import { LabeledData } from "../LabeledData";
import { useLocalization } from "../../i18n";

interface RecipientDisplayProps {
    sender: Sender;
}

export function SenderDisplay({ sender }: RecipientDisplayProps) {
    const {t} = useLocalization();
    return (
        <BoxedPaper>
            <Grid container spacing={gs}>
                {sender.name &&
                    <Grid item sm={3} md={6} xs={12}>
                        <LabeledData label={t("recipients.name")}>
                            {sender.name}
                        </LabeledData>
                    </Grid>
                }

                {sender.email &&
                    <Grid item sm={3} md={6}  xs={12}>
                        <LabeledData label={t("recipients.email")}>
                            {sender.email}
                        </LabeledData>
                    </Grid>
                }

                {sender.phone &&
                    <Grid item sm={3} md={6} xs={12}>
                        <LabeledData label={t("recipients.phone")}>
                            {sender.phone}
                        </LabeledData>
                    </Grid>
                }

                {sender.employeeNumber &&
                    <Grid item sm={3} md={6} xs={12}>
                        <LabeledData label={t("recipients.employeeNumber")}>
                            {sender.employeeNumber}
                        </LabeledData>
                    </Grid>
                }
            </Grid>
        </BoxedPaper>
    );
}
