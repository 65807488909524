import {Link} from "react-router-dom";
import {createElement, PropsWithChildren} from "react";
import {BreadcrumbLink} from "@variocube/app-ui";

interface BreadcrumbRouterLinkProps {
    to: string;
}

export function BreadcrumbRouterLink({to, children}: PropsWithChildren<BreadcrumbRouterLinkProps>) {
    return (
        <BreadcrumbLink component={Link} to={to}>{children}</BreadcrumbLink>
    )
}