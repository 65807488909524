import * as React from "react";
import {createElement} from "react";
import {Snackbar} from "@mui/material";
import {Alert, AlertColor} from "@mui/lab";

export interface SnackbarMessage {
    severity?: AlertColor;
    text: string;
}

interface StateSnackbarProps {
    message?: SnackbarMessage;
    onClose?: () => void;
    autoHideDuration? : number;
}

export function StateSnackbar({message, onClose, autoHideDuration}: StateSnackbarProps) {
    const handleClose = () => {
        if(onClose) {
            onClose();
        }
    };

    return (
        <Snackbar open={Boolean(message)} onClose={handleClose} autoHideDuration={autoHideDuration || 30000}>
            {message &&
            <Alert severity={message.severity || 'info'} onClose={handleClose}>{message.text}</Alert>
            }
        </Snackbar>
    );
}
