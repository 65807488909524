import {
	Alert,
	AlertTitle,
	Autocomplete,
	Button,
	Card,
	CardContent,
	FormControlLabel,
	Grid,
	Radio,
	RadioGroup,
	Stack,
	Typography,
} from "@mui/material";
import {ContainerLayout, createQueryString, ErrorAlert, IntegerNumberFormat, NumberField} from "@variocube/app-ui";
import {createElement, FormEvent, useState} from "react";
import {useAsyncCallback} from "react-async-hook";
import {Api} from "../../Api";
import {RecipientSummary} from "../../domain/Recipients";
import {useLocalization} from "../../i18n";
import {gs} from "../../theme";
import {HelmetTitleWrapper} from "../HelmetTitleWrapper";
import {useTenant, useTenantId} from "../TenantContextProvider";
import {RecipientSelect} from "./RecipientSelect";

const AMOUNT_OPTIONS = Array(16).fill(0).map((_, index) => index + 1);

enum ManualNotificationType {
	Letter = "letters",
	Parcel = "parcels",
}

export function ManualNotification() {
	const {t, e} = useLocalization();
	const tenant = useTenant();
	const tenantId = useTenantId();
	const [recipient, setRecipient] = useState<RecipientSummary>();
	const [amount, setAmount] = useState<number | null>(1);
	const [currentManualNotificationType, setCurrentManualNotificationType] = useState(ManualNotificationType.Letter);

	async function handleSubmit(event: FormEvent<HTMLFormElement>) {
		event.preventDefault();
		await Api.POST(
			`tenants/${tenantId}/manual-notification?${
				createQueryString({
					recipientId: recipient?.recipientId,
					departmentId: recipient?.departmentId,
					amount: amount,
					itemType: e("manualNotification", currentManualNotificationType),
				})
			}`,
			null,
			undefined,
			false,
		);
		return true;
	}

	const {loading, error, result, execute} = useAsyncCallback(handleSubmit);

	return (
		<ContainerLayout>
			<HelmetTitleWrapper pageTitle={t("manualNotification.title")} />
			<Stack spacing={gs}>
				<Typography variant="h2">{t("manualNotification.title")}</Typography>
				{!tenant.manualNotificationEnabled && (
					<Alert severity="info">
						<AlertTitle>{t("manualNotification.notAvailable")}</AlertTitle>
					</Alert>
				)}
				<Card>
					<CardContent>
						<form onSubmit={execute}>
							<Grid container spacing={gs} alignItems="center">
								<Grid item xs={12} sm={8} md={6}>
									<RecipientSelect
										recipient={recipient}
										onChange={setRecipient}
									/>
								</Grid>
								<Grid item xs={6} sm={4} md={2}>
									<Autocomplete
										renderInput={(params) => (
											<NumberField
												{...params}
												numberFormat={IntegerNumberFormat}
												label={t("manualNotification.amount")}
												variant="outlined"
												numberValue={amount}
												onChangeNumber={setAmount}
												fullWidth
											/>
										)}
										getOptionLabel={option => `${option}`}
										options={AMOUNT_OPTIONS}
										value={amount}
										onChange={(_, value) => setAmount(value)}
									/>
								</Grid>
								<Grid item>
									<RadioGroup
										value={currentManualNotificationType}
										onChange={(event, value) =>
											setCurrentManualNotificationType(value as ManualNotificationType)}
										row
									>
										<FormControlLabel
											control={<Radio />}
											value={ManualNotificationType.Letter}
											label={e("manualNotification", ManualNotificationType.Letter)}
										/>
										<FormControlLabel
											control={<Radio />}
											value={ManualNotificationType.Parcel}
											label={e("manualNotification", ManualNotificationType.Parcel)}
										/>
									</RadioGroup>
								</Grid>
								<Grid item xs={12}>
									<Button
										variant="contained"
										disabled={loading}
										type="submit"
									>
										{t("manualNotification.submit")}
									</Button>
								</Grid>
							</Grid>
						</form>
					</CardContent>
				</Card>

				{error && <ErrorAlert error={error} />}
				{result && (
					<Alert severity="success">
						<AlertTitle>{t("manualNotification.success")}</AlertTitle>
					</Alert>
				)}
			</Stack>
		</ContainerLayout>
	);
}
