import {createElement} from 'react';
import {Helmet} from "react-helmet";
import {useLocalization} from "../i18n";

export interface HelmetTitleWrapperProps {
  pageTitle: string;
}

export function HelmetTitleWrapper(props: HelmetTitleWrapperProps){
  const {t} = useLocalization();
  const {pageTitle} = props;
  const appTitle = t('appTitle');
  const title = pageTitle ? [appTitle, pageTitle].join(" | ") : appTitle;

  return(
    <Helmet>
      <title>{title}</title>
    </Helmet>
  )
}