import * as React from "react";
import {createElement, useEffect} from "react";
import {BarCodeEvent} from "./index";
import {useNavigate} from "react-router";
import {DeliveriesProvider} from "../domain/DeliveriesProvider";
import {useTenantId} from "../components/TenantContextProvider";

export function BarCodeDetector() {
    const navigate = useNavigate();
    const tenantId = useTenantId();

    useEffect(() => {

        async function handleBarcode(e: any) {
            const barCodeEvent = e as BarCodeEvent;
            try {
                const deliveryId = await DeliveriesProvider.findByStorageCode(tenantId, barCodeEvent.detail);
                if (deliveryId) {
                    navigate(`/${tenantId}/deliveries/${deliveryId}`);
                }
            }
            catch(err) {
                console.error(err);
            }
        }

        window.addEventListener('barcode', handleBarcode);
        return () => window.removeEventListener("barcode", handleBarcode);
    }, [tenantId]);

    return null;
}
