import {DeleteForever, OpenInNew} from "@mui/icons-material";
import {Grid, IconButton, Paper, Table, TableBody, TableCell, TableHead, TableRow, Tooltip} from "@mui/material";
import * as React from "react";
import {createElement, useCallback, useMemo, useState} from "react";
import {Cube, Occupancy} from "../../domain/Cube";
import {CubesProvider} from "../../domain/CubesProvider";
import {useLocalization} from "../../i18n";
import {ConfirmDialog} from "../ConfirmDialog";
import {DeliveryIdDisplay} from "../deliveries/DeliveryIdDisplay";
import {DeliveryLink} from "../deliveries/DeliveryLink";
import {useTenantId} from "../TenantContextProvider";
import { DeliveriesProvider } from "../../domain/DeliveriesProvider";

interface OccupanciesListProps {
	cube: Cube;
	occupancies: Occupancy[];
	onReload: () => void;
}
 
export class OccupanciesList extends React.Component<OccupanciesListProps> {
	constructor(props: OccupanciesListProps) {
		super(props);
		this.state = {};
	}

	render() {
		const {cube, occupancies, onReload} = this.props;
		return (
			<Paper>
				<OccupanciesTable cube={cube} occupancies={occupancies} onReload={onReload} />
			</Paper>
		);
	}
}

interface OccupanciesTableProps {
	cube: Cube;
	occupancies: Occupancy[];
	onReload: () => void;
}

function OccupanciesTable(props: OccupanciesTableProps) {
	const {t} = useLocalization();
	const {cube, occupancies, onReload} = props;
	return (
		<Table>
			<TableHead>
				<TableRow>
					<TableCell>{t("cubes.boxes.occupancy")}</TableCell>
					<TableCell colSpan={2}>{t("deliveries.singular")}</TableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				{occupancies.map((occupancy) => (
					<OccupancyRow key={occupancy.uuid} cube={cube} occupancy={occupancy} onReload={onReload} />
				))}
			</TableBody>
		</Table>
	);
}

interface OccupancyRowProps {
	cube: Cube;
	occupancy: Occupancy;
	onReload: () => void;
}

function OccupancyRow(props: OccupancyRowProps) {
	const {t} = useLocalization();
	const tenantId = useTenantId();

	const {cube, occupancy, onReload} = props;

	const [confirmEnd, setConfirmEnd] = useState<boolean>(false);

	const href = useMemo<string>(() => {
		return `https://cubeadmin.center/cubes/${occupancy.cubeId}/occupancies/${occupancy.uuid}`;
	}, [occupancy]);

	const openInCubeAdminCenter = useCallback(() => {
		window.open(href, href);
	}, [href]);

	const handleEnd = useCallback(() => {
		if (tenantId) {
			CubesProvider.endOccupancy(tenantId, occupancy.cubeId, occupancy.boxNumber, occupancy.uuid)
				.then(onReload);
		}
	}, [tenantId, occupancy, onReload]);
 
	return (
		<TableRow>
			<TableCell>
				<DeliveryIdDisplay id={occupancy.uuid} />
			</TableCell>
			<TableCell>
				{occupancy.deliveries?.map((delivery) => <DeliveryLink key={delivery.id} delivery={delivery} />)}
			</TableCell>
			<TableCell>
				<Grid container spacing={1}>
					<Grid item>
						<Tooltip title={t("cubes.boxes.actions.openInCubeAdminCenter")}>
							<IconButton size="small" onClick={openInCubeAdminCenter}>
								<OpenInNew />
							</IconButton>
						</Tooltip>
					</Grid>
					<Grid item>
						<Tooltip title={t("cubes.boxes.actions.endOccupancy.title")}>
							<IconButton size="small" onClick={() => setConfirmEnd(true)}>
								<DeleteForever />
							</IconButton>
						</Tooltip>
					</Grid>
				</Grid>
			</TableCell>
			<ConfirmDialog
				open={confirmEnd}
				title={t("cubes.boxes.actions.endOccupancy.title")}
				message={t("cubes.boxes.actions.endOccupancy.confirm", {
					boxNumber: occupancy.boxNumber,
					cube: cube.name,
				})}
				resolve={handleEnd}
				reject={() => setConfirmEnd(false)}
			/>
		</TableRow>
	);
}
