import {
	Alert,
	Box,
	Button,
	Card,
	CardActions,
	CardContent,
	CardHeader,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControlLabel,
	FormGroup,
	FormHelperText,
	Grid,
	Link,
	Snackbar,
	Typography,
} from "@mui/material";
import {ContainerLayout} from "@variocube/app-ui";
import * as React from "react";
import {createElement, useEffect, useState} from "react";
import {useAsync} from "react-async-hook";
import {Link as RouterLink} from "react-router-dom";
import {Cube} from "../../domain/Cube";
import {CubesProvider} from "../../domain/CubesProvider";
import {OutOfOffice} from "../../domain/OutOfOffice";
import {OutOfOfficeProvider} from "../../domain/OutOfOfficeProvider";
import {RecipientsProvider} from "../../domain/RecipientsProvider";
import {Roles} from "../../domain/Roles";
import {SitesProvider} from "../../domain/SitesProvider";
import {TenantsProvider} from "../../domain/TenantsProvider";
import {User, UserType} from "../../domain/TenantUser";
import {TenantUserProvider} from "../../domain/TenantUserProvider";
import {useLocalization} from "../../i18n";
import {gs} from "../../theme";
import {BoxedPaper} from "../BoxedPaper";
import {ConfirmDialog} from "../ConfirmDialog";
import {CubeNameDisplay} from "../CubeNameDisplay";
import {HelmetTitleWrapper} from "../HelmetTitleWrapper";
import {LabeledData} from "../LabeledData";
import {LabeledItem} from "../LabeledItem";
import {LHelpButton} from "../LHelpButton";
import {Loading} from "../Loading";
import {OutOfOfficeDialog} from "../recipients/OutOfOfficeDialog";
import {OutOfOfficeView, ViewMode} from "../recipients/OutOfOfficeView";
import {SelectPreferredCubeDialog} from "../recipients/SelectPreferredCubeDialog";
import {useTenantId, useTenantUserRole} from "../TenantContextProvider";
import {RoleDisplay} from "../users/RoleDisplay";
import {LocalPrintSettingsCard} from "./LocalPrintSettingsCard";
import {TenantSettingsEdit} from "./TenantSettingsEdit";

export function Settings() {
	const {t} = useLocalization();
	const tenantId = useTenantId();
	const {isUser, isAdmin, isAdminLight, isCarrier} = useTenantUserRole();

	const [purgeRecipientModal, setPurgeRecipientModal] = useState(false);
	const [recipientsPurged, setRecipientsPurged] = useState(false);
	const [allSources, setAllSources] = useState(false);
	const [currentUser, setCurrentUser] = useState<User>();
	const [accessCodeIsRefreshing, setAccessCodeIsRefreshing] = useState<boolean>(false);
	const [outOfOfficeDialogOpen, setOutOfOfficeDialogOpen] = useState(false);
	const [outOfOfficeDialogDisabled, setOutOfOfficeDialogOpenDisabled] = useState(false);
	const [outOfOffice, setOutOfOffice] = useState<OutOfOffice>();
	const [outOfOfficeAsSubstitute, setOutOfOfficeAsSubstitute] = useState<OutOfOffice[]>();
	const [preferredCubeDialogOpen, setPreferredCubeDialogOpen] = useState(false);
	const [preferredCube, setPreferredCube] = useState<Cube>();
	const [redirectedSuccessDialogOpen, setRedirectedSuccessDialogOpen] = useState(false);
	const [redirectMessage, setRedirectMessage] = useState<string>("");

	const {result} = useAsync(() =>
		Promise.all([
			TenantsProvider.get(tenantId),
			SitesProvider.list(tenantId),
		]), [tenantId]);

	const [tenant, sites] = result ?? [];

	useAsync(async () => {
		const user = await TenantUserProvider.me(tenantId);
		setCurrentUser(user);
	}, [tenantId]);

	const handleRefreshAccessCodeClicked = () => {
		setAccessCodeIsRefreshing(true);
		TenantUserProvider.refreshMyAccessCode(tenantId).then((user) => {
			setCurrentUser(user);
			setAccessCodeIsRefreshing(false);
		});
	};

	useEffect(
		() => {
			const isRecipient = async () => {
				if (currentUser?.email) {
					try {
						let recipient = await RecipientsProvider.get(tenantId, currentUser?.email);
						if (recipient) {
							setOutOfOfficeDialogOpenDisabled(false);
							if (recipient.preferredCubeId) {
								let pc = await CubesProvider.get(tenantId, recipient.preferredCubeId);
								setPreferredCube(pc);
							}
						}
						let o: OutOfOffice = await OutOfOfficeProvider.get(tenantId, currentUser?.email);
						setOutOfOffice(o);

						let oAsSub: OutOfOffice[] = await OutOfOfficeProvider.getBySubstitute(
							tenantId,
							currentUser?.email,
						);
						setOutOfOfficeAsSubstitute(oAsSub);
					} catch (ex) {
						setOutOfOfficeDialogOpenDisabled(true);
					}
				}
			};
			isRecipient();
		},
		[currentUser?.email],
	);

	async function handlePurgeRecipients() {
		try {
			await TenantUserProvider.purgeRecipients(tenantId, allSources);
			setPurgeRecipientModal(false);
			setRecipientsPurged(true);
		} catch (err) {
			console.error("failed to purge recipients", err);
		}
	}

	async function handleOutOfOfficeSave(val: OutOfOffice) {
		setOutOfOfficeDialogOpen(false);
		await OutOfOfficeProvider.putCurrentUser(tenantId, val);
		if (val.active && val.redirectExisting) {
			let recipientOld = await RecipientsProvider.get(tenantId, currentUser?.email || "");
			let recipientsNew = await RecipientsProvider.getRecipientsByEmails(tenantId, val.substituteEmail);
			let count = await OutOfOfficeProvider.redirect(tenantId, recipientOld, recipientsNew);
			setRedirectMessage(t("recipients.shipmentsRedirected", {no: count}));
			setRedirectedSuccessDialogOpen(true);
		}
		setOutOfOffice(val);
	}

	async function handlePreferredCubeSave(cube?: Cube) {
		setPreferredCubeDialogOpen(false);
		setPreferredCube(cube);
		const recipient = await RecipientsProvider.get(tenantId, currentUser?.email || "");
		if (recipient?.id) {
			await RecipientsProvider.update(tenantId, recipient.id, {
				...recipient,
				preferredCubeId: cube?.hostname,
			});
		}
	}

	if (tenant && currentUser) {
		return (
			<ContainerLayout>
				<HelmetTitleWrapper pageTitle={t("settings.plural")} />
				<Grid container spacing={gs}>
					<Grid item xs={12}>
						<Typography variant="h2" gutterBottom>
							{t("settings.userSettings", {name: `${currentUser.lastName}, ${currentUser.firstName}`})}
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Grid container spacing={gs}>
							{(isAdmin || isAdminLight || isUser || isCarrier)
								&& (
									<Grid item xs={12} sm={6} md={4}>
										<Grid container spacing={gs}>
											<Grid item xs={12}>
												<Card>
													<CardHeader
														title={t("settings.user.accessCode")}
														action={
															<LHelpButton
																helpPage="Settings"
																helpAnchor="Access_Code"
																language=""
															/>
														}
													/>
													<CardContent>
														<Grid container spacing={gs}>
															<Grid item xs={12}>
																<Typography variant="h3" align="center">
																	{currentUser.accessCode}
																</Typography>
															</Grid>
															<Grid item xs={12}>
																<Typography variant="body1">
																	{t("settings.user.accessCodeInfo")}
																</Typography>
															</Grid>
														</Grid>
													</CardContent>
													<CardActions>
														<Button
															onClick={() => handleRefreshAccessCodeClicked()}
															disabled={accessCodeIsRefreshing}
														>
															{t("settings.user.regenerate")}
														</Button>
													</CardActions>
												</Card>
											</Grid>
											<Grid item xs={12}>
												<LocalPrintSettingsCard />
											</Grid>
										</Grid>
									</Grid>
								)}
							<Grid item xs={12} sm={6} md={8}>
								<Grid container spacing={gs}>
									<Grid item xs={12}>
										<Card>
											<CardHeader
												title={t("settings.user.userData")}
												action={
													<LHelpButton
														helpPage="Settings"
														helpAnchor="User_Data"
														language=""
													/>
												}
											/>
											<CardContent>
												<Grid container spacing={gs}>
													<Grid item xs={12} sm={6}>
														<LabeledData label={t("users.name")}>
															{`${currentUser.lastName}, ${currentUser.firstName}`}
														</LabeledData>
													</Grid>
													<Grid item xs={12} sm={6}>
														<LabeledData label={t("users.email")}>
															<Link
																color="secondary"
																href={`mailto:${currentUser.email}`}
															>
																{currentUser.email}
															</Link>
														</LabeledData>
													</Grid>
													<Grid item xs={12} sm={6}>
														<LabeledData label={t("users.username")}>
															{currentUser.username}
														</LabeledData>
													</Grid>
													<Grid item xs={12} sm={6}>
														<LabeledItem label={t("users.role")}>
															<RoleDisplay
																role={currentUser.roles as Roles}
																size={"small"}
															/>
														</LabeledItem>
													</Grid>
													<Grid item xs={12} sm={6}>
														<OutOfOfficeView
															outOfOffice={outOfOffice}
															viewMode={ViewMode.Recipient}
														/>
													</Grid>
													{preferredCube
														&& (
															<Grid item xs={12} sm={6}>
																<LabeledItem label={t("recipients.preferredCubeId")}>
																	<CubeNameDisplay cubeId={preferredCube.hostname} />
																</LabeledItem>
															</Grid>
														)}
												</Grid>
											</CardContent>
											<CardActions>
												{(currentUser && currentUser.userType == UserType.Local)
													&& (
														<Button
															component={RouterLink}
															to={`/${tenantId}/settings/change-password`}
														>
															{t("settings.user.changePassword")}
														</Button>
													)}
												<Button
													disabled={outOfOfficeDialogDisabled}
													onClick={() => setOutOfOfficeDialogOpen(true)}
												>
													{t("recipients.outOfOfficeAssistant.title")}
												</Button>
												<Button
													disabled={outOfOfficeDialogDisabled}
													onClick={() => setPreferredCubeDialogOpen(true)}
												>
													{t("recipients.setPreferredCube")}
												</Button>
											</CardActions>
										</Card>
									</Grid>
									{(outOfOfficeAsSubstitute && outOfOfficeAsSubstitute.length > 0)
										&& (
											<Grid item xs={12}>
												<Grid container spacing={gs}>
													<Grid item xs={12}>
														<Typography variant="h4">
															{t("recipients.outOfOfficeAssistant.usedAsSubstitute")}
														</Typography>
													</Grid>
													<Grid item xs={12}>
														<BoxedPaper>
															<Grid container spacing={gs}>
																{outOfOfficeAsSubstitute.map(outOfOfficeSub => (
																	<Grid item xs={12} sm={6}>
																		<OutOfOfficeView
																			outOfOffice={outOfOfficeSub}
																			viewMode={ViewMode.Substitute}
																		/>
																	</Grid>
																))}
															</Grid>
														</BoxedPaper>
													</Grid>
												</Grid>
											</Grid>
										)}
								</Grid>
							</Grid>
						</Grid>
					</Grid>
					<OutOfOfficeDialog
						open={outOfOfficeDialogOpen}
						outOfOffice={outOfOffice}
						onCancel={() => setOutOfOfficeDialogOpen(false)}
						onSave={handleOutOfOfficeSave}
					/>
					<SelectPreferredCubeDialog
						open={preferredCubeDialogOpen}
						onCancel={() => setPreferredCubeDialogOpen(false)}
						selectedCube={preferredCube}
						onSave={(cube?: Cube) => handlePreferredCubeSave(cube)}
					/>
					<ConfirmDialog
						open={redirectedSuccessDialogOpen}
						title={""}
						message={redirectMessage}
						noRejectButton
						resolve={() => setRedirectedSuccessDialogOpen(false)}
					/>
					{isAdmin && (
						<React.Fragment>
							<Grid item xs={12}>
								<Box p={2} />
								<Grid container spacing={gs}>
									<Grid item>
										<Typography variant="h2">
											{t("settings.tenantSettings", {name: tenant.name})}
										</Typography>
									</Grid>
									<Grid item>
										<LHelpButton helpPage="Tenant_Settings" helpAnchor="" language="" />
									</Grid>
								</Grid>
							</Grid>
							<Grid item xs={12}>
								<TenantSettingsEdit />
							</Grid>
						</React.Fragment>
					)}
					{isAdmin && (
						<React.Fragment>
							<Grid item xs={12}>
								<Box p={2} />
								<Typography variant="h2">{t("settings.recipients")}</Typography>
							</Grid>
							<Grid item xs={12}>
								<Grid container spacing={gs}>
									<Grid item xs={12} sm={12} md={12}>
										<Card>
											<CardHeader
												title={t("settings.recipientsSetting")}
												action={
													<LHelpButton
														helpPage="Recipient_Settings"
														helpAnchor=""
														language=""
													/>
												}
											/>
											<CardContent>
												<Button onClick={() => setPurgeRecipientModal(true)}>
													{t("settings.purgeRecipients")}
												</Button>
											</CardContent>
										</Card>
									</Grid>
								</Grid>
							</Grid>
							<Dialog open={purgeRecipientModal} onClose={() => setPurgeRecipientModal(false)}>
								<DialogTitle>{t("settings.purgeRecipients")}</DialogTitle>
								<DialogContent>
									<Typography>{t("settings.purgeRecipientsPrompt")}</Typography>
									<FormGroup>
										<FormControlLabel
											onChange={() => setAllSources(!allSources)}
											control={<Checkbox checked={allSources} />}
											label={t("settings.purgeFromAllSources")}
										/>
										<FormHelperText>{t("settings.purgeFromAllSourcesPrompt")}</FormHelperText>
									</FormGroup>
								</DialogContent>
								<DialogActions>
									<Button onClick={() => setPurgeRecipientModal(false)}>{t("cancel")}</Button>
									<Button variant="contained" onClick={handlePurgeRecipients}>{t("confirm")}</Button>
								</DialogActions>
							</Dialog>
							<Snackbar
								open={recipientsPurged}
								autoHideDuration={6000}
								onClose={() => setRecipientsPurged(false)}
							>
								<Alert
									onClose={() => setRecipientsPurged(false)}
									severity="success"
									sx={{width: "100%"}}
								>
									{t("settings.purgeRecipientsMessage")}
								</Alert>
							</Snackbar>
						</React.Fragment>
					)}
				</Grid>
			</ContainerLayout>
		);
	} else {
		return <Loading />;
	}
}
