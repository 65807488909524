import {Stage, stage} from "../stage";

const ClientIdApp = '37l2ihvub0cok1cat0hlno8ntc';
const HostedUiDomainApp = 'login.variocube.com';

const ClientIdTest = '5q1c85578ivoqhnfl6vuco87m2';
const HostedUiDomainTest = 'login-dev.variocube.com';

const ClientIdDev = '5jskolh4rodcbgs7k30d903hai';
const HostedUiDomainDev = 'login-dev.variocube.com';

function buildAppBaseUrl() {
    if(window.location.hostname === 'localhost') {
        return 'http://localhost:3000';
    }else {
        return `${window.location.protocol}//${window.location.hostname}${window.location.port}`;
    }
}

class AuthConfig {

    readonly clientId: string;
    readonly hostedUiDomain: string;
    readonly appBaseUrl: string;

    constructor() {
        const currentStage = stage();
        switch (currentStage) {
            case Stage.App:
                this.clientId = ClientIdApp;
                this.hostedUiDomain = HostedUiDomainApp;
                break;
            case Stage.App:
                this.clientId = ClientIdApp;
                this.hostedUiDomain = HostedUiDomainApp;
                break;
            case Stage.Test:
                this.clientId = ClientIdTest;
                this.hostedUiDomain = HostedUiDomainTest;
                break;
            case Stage.Dev:
            case Stage.Local:
                this.clientId = ClientIdDev;
                this.hostedUiDomain = HostedUiDomainDev;
                break;
            default:
                throw new Error(`Stage ${currentStage} not configured for authentication.`);
        }
        this.appBaseUrl = buildAppBaseUrl();
    }

    oAuth2RedirectUri(redirectPath: string): string {
        return `${this.appBaseUrl}${redirectPath}`;
    }

    loginUrl(processTokenPath: string, identityProvider?: string): string {
        if(identityProvider) {
            return `https://${this.hostedUiDomain}/oauth2/authorize?identity_provider=${identityProvider}&redirect_uri=${this.appBaseUrl}${processTokenPath}&response_type=CODE&client_id=${this.clientId}&scope=phone+openid+email+aws.cognito.signin.user.admin+profile`;
        }
        return `https://${this.hostedUiDomain}/login?client_id=${this.clientId}&response_type=code&scope=phone+openid+email+aws.cognito.signin.user.admin+profile&redirect_uri=${this.appBaseUrl}${processTokenPath}`;
    }

    logoutUrl(logoutSuccessPath: string): string {
        return `https://${this.hostedUiDomain}/logout?client_id=${this.clientId}&response_type=token&logout_uri=${this.appBaseUrl}${logoutSuccessPath}`;
    }

}

export const authConfig = new AuthConfig();
