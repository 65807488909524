import * as React from "react";
import {createElement, useState} from "react";
import {DeliveryQueueItem} from "../../domain/Delivery";
import {
    Box,
    Grid,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import {DeliveryStateDisplay} from "./DeliveryStateDisplay";
import {EventStateDisplay} from "./EventStateDisplay";
import {DateComponent} from "../DateComponent";
import {gs} from "../../theme";
import {Close} from "@mui/icons-material";
import {LabeledData} from "../LabeledData";
import {makeStyles} from "@mui/styles";
import {useLocalization} from "../../i18n";

interface DeliveryQueueItemTableProps {
    eventQueue: DeliveryQueueItem[];
}

export function DeliveryQueueItemTable(props: DeliveryQueueItemTableProps) {
    const {eventQueue} = props;
    const {t} = useLocalization();
    const [selectedRow, setSelectedRow] = useState<string|undefined>();
    const classes = useStyles();
    return (
        <Table size="small" stickyHeader>
            <TableHead>
                <TableRow>
                    <TableCell className={classes.nowrap}>{t('deliveries.deliveryState')}</TableCell>
                    <TableCell className={classes.nowrap}>{t('deliveries.eventQueue.queueState')}</TableCell>
                    <TableCell className={classes.nowrap}>{t('deliveries.eventQueue.created')}</TableCell>
                    <TableCell className={classes.nowrap}>{t('deliveries.eventQueue.retry')}</TableCell>
                    <TableCell className={classes.nowrap}>{t('deliveries.eventQueue.failureCount')}</TableCell>
                    <TableCell className={classes.nowrap}>{t('deliveries.eventQueue.delivered')}</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {eventQueue.length == 0 &&
                    <TableRow>
                        <TableCell colSpan={6}>
                            <Box p={3}>
                                <Typography variant="body1" align="center">{t('deliveries.eventQueue.empty')}</Typography>
                            </Box>
                        </TableCell>
                    </TableRow>
                }
                {eventQueue.map((e) =>
                <React.Fragment key={e.uuid}>
                    <TableRow hover onClick={() => setSelectedRow(e.uuid)}>
                        <TableCell><DeliveryStateDisplay deliveryState={e.deliveryState} size="small" /></TableCell>
                        <TableCell><EventStateDisplay queueStatus={e.status} size="small" /></TableCell>
                        <TableCell><DateComponent date={e.created} /></TableCell>
                        <TableCell>{e.retry && <DateComponent date={e.retry} />}</TableCell>
                        <TableCell>{(e.failureCount && e.failureCount > 0) && <React.Fragment>{e.failureCount}</React.Fragment>}</TableCell>
                        <TableCell className={classes.lastColumn}>
                            {e.delivered && <DateComponent date={e.delivered} />}
                            {e.expired && <DateComponent date={e.expired} />}
                        </TableCell>
                    </TableRow>
                    {selectedRow == e.uuid &&
                    <TableRow>
                        <TableCell colSpan={2}></TableCell>
                        <TableCell colSpan={4}>
                            <Box paddingTop={2} paddingBottom={2}>
                                <Grid container spacing={gs}>
                                    <Grid item style={{flexGrow: 1}}>
                                        <Grid container spacing={gs}>
                                            <Grid item xs={12}>
                                                <LabeledData label={t('deliveries.eventQueue.url')}>
                                                    {e.method}
                                                </LabeledData>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <LabeledData label={t('deliveries.eventQueue.payload')}>
                                                    <div className={classes.code}>{e.payload}</div>
                                                </LabeledData>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <LabeledData label={t('deliveries.eventQueue.response')}>
                                                    <div className={classes.code}>{e.response}</div>
                                                </LabeledData>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <IconButton size="small" onClick={() => setSelectedRow(undefined)}>
                                            <Close />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </Box>
                        </TableCell>
                    </TableRow>
                    }
                </React.Fragment>
                )}
            </TableBody>
        </Table>
    );
}

const useStyles = makeStyles((theme) => ({
    lastColumn: {
        width: '100%'
    },
    code: {
        fontFamily: 'monospace',
        overflowX: 'scroll',
        maxWidth: '100%'
    },
    nowrap: {
        whiteSpace: 'nowrap'
    }
}));
