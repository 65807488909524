import * as React from "react";
import {createElement, useCallback} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid} from "@mui/material";
import {gs} from "../../theme";
import {SimpleTextInput} from "../SimpleTextInput";
import {CubeSelectInput} from "../cubes/CubeSelectInput";
import {Cube} from "../../domain/Cube";
import {useLocalization} from "../../i18n";

interface EditCubeBuildingMappingProps {
    open: boolean;
    onClose: () => void;
    onConfirm: (cube: Cube, building: string) => void;
    onDelete?: () => void;
    cube? : Cube;
    building? : string;
}

export function EditCubeBuildingMappingDialog(props: EditCubeBuildingMappingProps) {
    const {open, onClose, onConfirm} = props;
    const {t} = useLocalization();

    const [cube, setCube] = React.useState<Cube>();
    const [building, setBuilding] = React.useState<string>(props?.building || '');

    if (props.cube)
        setCube(props.cube);


    const handleClose = useCallback(() => {
        onClose();
    }, [onClose]);


    function handleConfirm() {
       onConfirm(cube as Cube,building as string);
    }

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="lg">
            <DialogTitle>{t('settings.csvImport.cubeConfig')}</DialogTitle>
            <DialogContent>
                <Grid container spacing={gs} sx={{paddingTop:"0.5em"}}>
                    <Grid item xs={12}>
                        <CubeSelectInput value={cube} onChange={setCube} autoSelect={true}/>
                    </Grid>
                    <Grid item xs={12}>
                        <SimpleTextInput label={t('recipients.building')} value={building} onChange={setBuilding}/>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={() => handleClose()}>{t('cancel')}</Button>
                {props.onDelete &&   <Button variant="outlined" onClick={props.onDelete}>{t('delete')}</Button> }
                <Button color="primary" variant="contained" onClick={handleConfirm}>{t('confirm')}</Button>
            </DialogActions>
        </Dialog>
    );
}
