import {FormControl, FormControlLabel, FormLabel, Radio, RadioGroup} from "@mui/material";
import {createElement, useCallback, useMemo} from "react";
import {useLocalization} from "../../i18n";

interface VisibleForRadiosProps {
	visibleFor: string[];
	onChange: (visibleFor: string[]) => void;
}

export function VisibleForRadios(props: VisibleForRadiosProps) {
	const {visibleFor, onChange} = props;
	const {t} = useLocalization();

	const value = useMemo<string>(() => {
		if(visibleFor.length == 0) {
			return "all";
		}
		if(visibleFor.includes("user") && visibleFor.includes("admin")) {
			return "users";
		}
		if(visibleFor.includes("it") && visibleFor.includes("admin")) {
			return "it";
		}
		if(visibleFor.includes("admin")) {
			return "admins";
		}
		throw new Error("Invalid visibleFor value");
	}, [visibleFor]);

	const handleChange = useCallback((value: string) => {
		switch (value) {
			case "all":
				onChange([]);
				break;
			case "users":
				onChange(["admin", "user"]);
				break;
			case "it":
				onChange(["admin", "it"]);
				break;
			case "admins":
				onChange(["admin"]);
				break;
			default:
				throw new Error("Invalid visibleFor value");
		}
	}, []);

	return (
		<FormControl>
			<FormLabel>{t("dispatch.visibleFor.title")}</FormLabel>
			<RadioGroup row value={value} onChange={(e) => handleChange(e.target.value)}>
				<FormControlLabel value="all" control={<Radio />} label={t("dispatch.visibleFor.all")} />
				<FormControlLabel value="users" control={<Radio />} label={t("dispatch.visibleFor.users")} />
				<FormControlLabel value="it" control={<Radio />} label={t("dispatch.visibleFor.it")} />
				<FormControlLabel value="admins" control={<Radio />} label={t("dispatch.visibleFor.admins")} />
			</RadioGroup>
		</FormControl>
	);


}
