import * as React from "react";
import {createElement} from "react";
import {Chip, Theme} from "@mui/material";
import {green, red} from "@mui/material/colors";
import {makeStyles} from "@mui/styles";
import {useLocalization} from "../i18n";

interface BooleanDisplayProps {
    value: boolean;
    size?: 'small' | 'medium';
    reverseColors?: boolean;
}

export function BooleanDisplay(props: BooleanDisplayProps) {
    const {value, size, reverseColors} = props;
    const classes = useStyles();
    const {t} = useLocalization();
    const colorValue = reverseColors ? !value : value;
    const className = colorValue ? classes.yes : classes.no;
    return (
        <Chip label={t(value ? 'yes' : 'no')} size={size} className={className} />
    );
}

const useStyles = makeStyles((theme: Theme) => ({
    yes: {
        backgroundColor: green[400]
    },
    no: {
        backgroundColor: red[500],
        color: '#fff'
    }
}));
