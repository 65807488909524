import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid} from "@mui/material";
import {createElement, useEffect, useState} from "react";
import {Cube} from "../../domain/Cube";
import {useLocalization} from "../../i18n";
import {CubeSelectInput} from "../cubes/CubeSelectInput";
import {gs} from "../../theme";

interface SelectPreferredCubeDialogProps {
	open: boolean;
	onCancel: () => void;
	onSave: (cube?: Cube) => void;
	selectedCube: Cube | undefined;
}

export function SelectPreferredCubeDialog(props: SelectPreferredCubeDialogProps) {
	const {t} = useLocalization();
	const [selectedCube, setSelectedCube] = useState<Cube>();

	useEffect(() => {
		setSelectedCube(props.selectedCube);
	}, [props.selectedCube]);

	function handleSave() {
		props.onSave(selectedCube);
	}

	return (
		<Dialog open={props.open} PaperProps={{sx: {maxWidth: "50%"}}}>
			<DialogTitle>
				{t("recipients.setPreferredCubeDialogTitle")}
			</DialogTitle>
			<DialogContent>
				<Grid container spacing={gs}>
					<Grid item xs={12}>
						<CubeSelectInput
							onChange={setSelectedCube}
							value={selectedCube}
							allowUndefined
						/>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button variant="outlined" onClick={() => props.onCancel()}>{t("cancel")}</Button>
				<Button color="primary" variant="contained" onClick={() => handleSave()}>
					{t("confirm")}
				</Button>
			</DialogActions>
		</Dialog>
	);
}
