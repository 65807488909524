import {Page, Paging} from "@variocube/app-ui";
import {Api} from "../Api";
import {Recipient, RecipientMutation} from "./Delivery";
import {
	RecipientFilterRequest,
	RecipientImportResponse,
	RecipientLookupRequest,
	RecipientLookupResponse,
	RecipientSummary,
} from "./Recipients";

export class RecipientsProvider {
	static list(tenantId: string, paging: Paging): Promise<Page<Recipient>> {
		return Api.GET(`tenants/${tenantId}/recipients${paging.toQueryString()}`, {
			Accept: "application/vnd.deliveries.paged+json",
		})
			.then((response) => {
				return response as Page<Recipient>;
			});
	}

	static search(tenantId: string, search: string, paging: Paging): Promise<Page<Recipient>> {
		let path = `tenants/${tenantId}/recipients/search`;
		if (search) path += "/" + search;
		return Api.GET(`${path}${paging.toQueryString()}`, {
			Accept: "application/vnd.deliveries.paged+json",
		})
			.then((response) => {
				return response as Page<Recipient>;
			});
	}

	static get(tenantId: string, idOrEmail: string): Promise<Recipient> {
		return Api.GET(`tenants/${tenantId}/recipient/${idOrEmail}`);
	}

	static lookup(tenantId: string, segments: string[]): Promise<RecipientLookupResponse> {
		const request: RecipientLookupRequest = {
			segments: segments,
		};
		return Api.POST(`tenants/${tenantId}/recipients/lookup`, request).then((raw) => {
			return raw as RecipientLookupResponse;
		});
	}

	static async lookupOcr(tenantId: string, segments: string[]): Promise<RecipientLookupResponse> {
		const request: RecipientLookupRequest = {
			segments: segments,
		};
		try {
			const raw = await Api.POST(`tenants/${tenantId}/recipients/lookup-ocr`, request);
			return raw as RecipientLookupResponse;
		} catch (e) {
			console.error(e);
			return {
				recipients: [],
			};
		}
	}

	static reImport(tenantId: string): Promise<RecipientImportResponse> {
		return Api.POST(`tenants/${tenantId}/recipients/re-import`, {}).then((raw) => {
			return raw as RecipientImportResponse;
		});
	}

	static create(tenantId: string, mutation: RecipientMutation): Promise<Recipient> {
		return Api.POST(`tenants/${tenantId}/recipients`, mutation);
	}

	static update(tenantId: string, recipientId: string | number, mutation: RecipientMutation): Promise<Recipient> {
		return Api.PUT(`tenants/${tenantId}/recipients/${recipientId}`, mutation);
	}

	static delete(tenantId: string, email: string): Promise<Recipient> {
		return Api.DELETE(`tenants/${tenantId}/recipients/${email}`);
	}

	static filter(tenantId: string, request: RecipientFilterRequest): Promise<RecipientSummary[]> {
		return Api.POST(`tenants/${tenantId}/recipients/filter`, request).then((raw) => {
			return raw as RecipientSummary[];
		});
	}

	static async getRecipientsByEmails(tenantId: string, recipientEmails: string | undefined) {
		if (!recipientEmails) {
			return [];
		}

		var emails = recipientEmails.split(",");
		const allRecipients = [];
		for (var email of emails) {
			try {
				let recipient = await RecipientsProvider.get(tenantId, email);
				allRecipients.push(recipient);
			} catch (err) {
				console.error("Error while trying to fetch recipient for ", email);
			}
		}
		return allRecipients;
	}
}
