import * as React from "react";
import {createElement} from "react";
import {Chip} from "@mui/material";
import {CheckCircle, Block} from "@mui/icons-material";
import {useLocalization} from "../../i18n";

interface CubeBoxAvailableComponentProps {
    available: boolean;
}

export function CubeBoxAvailableComponent(props: CubeBoxAvailableComponentProps) {
    const {t} = useLocalization();
    const {available} = props;
    const icon = available ? <CheckCircle /> : <Block />;
    const label = t(available ? 'cubes.boxes.enabled' : 'cubes.boxes.disabled');
    const color = available ? '#4caf50' : '#f44336';
    return (
        <Chip label={label} style={{color: '#ffffff', backgroundColor: color}} icon={icon} />
    );
}
