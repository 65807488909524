import {Alert} from "@mui/lab";
import {
	Box,
	Button,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider,
	FormControlLabel,
	Grid,
	MenuItem,
	Paper,
	Snackbar,
	Stack,
	TextField,
	Typography,
} from "@mui/material";
import {BreadcrumbItem, Breadcrumbs, ContainerLayout} from "@variocube/app-ui";
import {createElement, useEffect, useState} from "react";
import {useNavigate} from "react-router";
import {Roles} from "../../domain/Roles";
import {Site} from "../../domain/Site";
import {UserType} from "../../domain/TenantUser";
import {TenantUserProvider} from "../../domain/TenantUserProvider";
import {useLocalization} from "../../i18n";
import {gs} from "../../theme";
import {useRequiredParam} from "../../useRequiredParam";
import {BreadcrumbRouterLink} from "../BreadcrumbRouterLink";
import {HelmetTitleWrapper} from "../HelmetTitleWrapper";
import {Loading} from "../Loading";
import {SiteSelectInput} from "../SiteSelectInput";
import {useTenantId, useTenantUserRole} from "../TenantContextProvider";

export function UserEdit() {
	const prevUsername = useRequiredParam("username");
	const {t, e} = useLocalization();
	const tenantId = useTenantId();
	const navigate = useNavigate();
	const {isSuperUser} = useTenantUserRole();

	const [username, setUsername] = useState<string>("");
	const [email, setEmail] = useState<string>("");
	const [firstName, setFirstName] = useState<string>("");
	const [lastName, setLastName] = useState<string>("");
	const [role, setRole] = useState<string>("");
	const [receiveEmails, setReceiveEmails] = useState<boolean>(false);
	const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);
	const [loaded, setLoaded] = useState<boolean>(false);
	const [siteId, setSiteId] = useState<string>("");
	const [deleted, setDeleted] = useState<boolean>(false);
	const [ssoUser, setSsoUser] = useState<boolean>(false);

	useEffect(() => {
		if (prevUsername) {
			TenantUserProvider.get(tenantId, prevUsername)
				.then(user => {
					setUsername(user.username);
					setEmail(user.email);
					setFirstName(user.firstName);
					setLastName(user.lastName);
					setRole(user.roles);
					setReceiveEmails(user.receiveEmails);
					setSiteId(user.siteId);
					setSsoUser(user.userType != UserType.Local);
					setLoaded(true);
				});
		}
	}, [prevUsername]);

	function handleSave() {
		TenantUserProvider.put(tenantId, prevUsername, {
			username,
			email,
			firstName,
			lastName,
			plaintextPassword: "",
			roles: role,
			receiveEmails,
			siteId,
		}).then(() => navigate(-1));
	}

	async function handleDelete() {
		try {
			setShowDeleteDialog(false);
			await TenantUserProvider.delete(tenantId, prevUsername);
			setDeleted(true);
			navigate(`/${tenantId}/users`);
		} catch (error) {
			console.error("error deleting user", error);
		}
	}

	function handleDeleted() {
		setDeleted(false);
		navigate(-1);
	}

	const availableRoles: Roles[] = isSuperUser ? Object.values(Roles) : [Roles.user, Roles.admin, Roles.adminlight, Roles.carrier, Roles.it, Roles.recipient];

	const canSave = Boolean(username && email && firstName && lastName && role);

	function onSiteChanged(site?: Site) {
		if (site) {
			setSiteId(site.siteId);
		}
	}

	if (loaded) {
		return (
			<ContainerLayout>
				<HelmetTitleWrapper pageTitle={t("users.plural")} />
				<Stack spacing={gs} direction="column">
					<Breadcrumbs>
						<BreadcrumbRouterLink to={`/${tenantId}/users`}>{t("users.plural")}</BreadcrumbRouterLink>
						<BreadcrumbRouterLink to={`/${tenantId}/users/${username}`}>{username}</BreadcrumbRouterLink>
						<BreadcrumbItem>{t("edit")}</BreadcrumbItem>
					</Breadcrumbs>
					<Typography variant="h2">
						{t("users.singular") + ": " + username}
					</Typography>
					<Paper>
						<Box p={4}>
							<Grid container spacing={3}>
								<Grid item sm={6}>
									<TextField
										disabled={ssoUser}
										variant="outlined"
										fullWidth
										label={t("users.username")}
										value={username}
										onChange={(e) => setUsername(e.target.value)}
										inputProps={{
											readOnly: Boolean(true),
											disabled: Boolean(true),
										}}
									/>
								</Grid>
								<Grid item sm={6}>
									<TextField
										fullWidth
										select
										variant="outlined"
										value={role || ""}
										label={t("users.role")}
										onChange={(e) => setRole(e.target.value)}
									>
										{availableRoles.map(u => (
											<MenuItem key={u} value={u} selected={role == u}>
												{e("users.roles", u)}
											</MenuItem>
										))}
									</TextField>
								</Grid>
								<Grid item xs={12}>
									<Divider />
								</Grid>
								<Grid item sm={6}>
									<TextField
										variant="outlined"
										fullWidth
										label={t("users.firstName")}
										value={firstName}
										onChange={(e) => setFirstName(e.target.value)}
									/>
								</Grid>
								<Grid item sm={6}>
									<TextField
										variant="outlined"
										fullWidth
										label={t("users.lastName")}
										value={lastName}
										onChange={(e) => setLastName(e.target.value)}
									/>
								</Grid>
								<Grid item sm={6}>
									<TextField
										variant="outlined"
										fullWidth
										label={t("users.email")}
										value={email}
										onChange={(e) => setEmail(e.target.value)}
									/>
								</Grid>
								<Grid item sm={6}>
									<FormControlLabel
										control={
											<Checkbox
												checked={receiveEmails}
												onChange={(event) => setReceiveEmails(event.target.checked)}
												color="primary"
											/>
										}
										label={t("users.receiveNotifications")}
									/>
								</Grid>
								<Grid item sm={6}>
									<SiteSelectInput value={siteId} onChange={onSiteChanged} autoSelect={false} />
								</Grid>
								<Grid item xs={12}>
									<Grid container spacing={3}>
										<Grid item style={{flexGrow: 1}}>
											<Button
												variant="contained"
												color="primary"
												disabled={!canSave}
												onClick={() => handleSave()}
											>
												{t("save")}
											</Button>
										</Grid>
										<Grid item>
											<Button variant="outlined" onClick={() => navigate(-1)}>
												{t("cancel")}
											</Button>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</Box>
					</Paper>
					<Paper>
						<Box p={2} display="flex" flexDirection="row" alignItems="center">
							<Box flexGrow={1}>
								<Typography>
									<strong>{t("warning.title")}</strong>
								</Typography>
								<Typography>{t("warning.hint")}</Typography>
							</Box>
							<Button variant="outlined" color="error" onClick={() => setShowDeleteDialog(true)}>
								{t("users.delete.title")}
							</Button>
						</Box>
					</Paper>
				</Stack>
				<Dialog open={showDeleteDialog} fullWidth maxWidth="sm">
					<DialogTitle>{t("users.delete.title")}</DialogTitle>
					<DialogContent>{t("users.delete.prompt")}</DialogContent>
					<DialogActions>
						<Button variant="contained" color="primary" onClick={() => handleDelete()}>{t("yes")}</Button>
						<Button onClick={() => setShowDeleteDialog(false)}>{t("no")}</Button>
					</DialogActions>
				</Dialog>
				<Snackbar open={deleted} autoHideDuration={3000} onClose={handleDeleted}>
					<Alert severity="success">{t("users.delete.success")}</Alert>
				</Snackbar>
			</ContainerLayout>
		);
	} else {
		return <Loading />;
	}
}
