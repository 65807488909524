import {Bookmark, BookmarkRequest, DispatchType} from "./Bookmark";
import {Api} from "../Api";

export class BookmarksProvider {

    static list(tenantId: string): Promise<Bookmark[]> {
        return Api.GET(`tenants/${tenantId}/bookmarks`).then((response) => {
            return response as Bookmark[];
        });
    }

	static async listForwardedForRecipient(tenantId: string, email: string): Promise<Bookmark | undefined> {
		return (await this.list(tenantId))
			.filter((b) => b.type === DispatchType.Department)
			.filter((b) => b.redirects.filter((r) => r.email === email).length > 0).pop();
	}

    static get(tenantId: string, id: string): Promise<Bookmark> {
        return Api.GET(`tenants/${tenantId}/bookmarks`)
            .then((response) => {
                return response as Bookmark[];
            })
            .then((bookmarks) => {
                const bookmark = bookmarks.filter((b) => b.id == id).pop();
                if(bookmark) {
                    return bookmark;
                }
                throw new Error(`No bookmark found with id ${id}`);
            });
    }

    static create(tenantId: string, request: BookmarkRequest) {
        return Api.POST(`tenants/${tenantId}/bookmarks`, request)
            .then(response => response as Bookmark);
    }

    static update(tenantId: string, id: string, request: BookmarkRequest) {
        return Api.PUT(`tenants/${tenantId}/bookmarks/${id}`, request)
            .then(response => response as Bookmark);
    }

    static delete(tenantId: string, id: string) {
        return Api.DELETE(`tenants/${tenantId}/bookmarks/${id}`)
            .then(response => response as Bookmark);
    }
}
