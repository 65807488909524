import {
	Box,
	Button, debounce,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, useTheme
} from "@mui/material";
import {createElement, useCallback, useEffect, useState} from "react";
import {RecipientFilterRequest, RecipientSummary} from "../../domain/Recipients";
import {RecipientsProvider} from "../../domain/RecipientsProvider";
import {useLocalization} from "../../i18n";
import {useTenantId} from "../TenantContextProvider";
import {UndrawEmpty} from "@variocube/app-ui/esm/content-table/UndrawEmpty";
import {SimpleTextInput} from "../SimpleTextInput";

export interface RecipientSearchDetailsDialogProps {
	open: boolean;
	onSelected: (selected: RecipientSummary) => void;
	onClose: () => void;
	candidates?: RecipientSummary[];
}

export function RecipientSearchDetailsDialog(props: RecipientSearchDetailsDialogProps) {
	const {open, onSelected, onClose, candidates} = props;
	const {t} = useLocalization();
	const tenantId = useTenantId();

	const [recipientName, setRecipientName] = useState("");
	const [email, setEmail] = useState("");
	const [department, setDepartment] = useState("");
	const [building, setBuilding] = useState("");
	const [pickupKey, setPickupKey] = useState("");

	const [recipients, setRecipients] = useState<RecipientSummary[]>(candidates || []);

	function getFilter() {
		let filter: RecipientFilterRequest = {recipientName, email, department, building, pickupKey};
		return filter;
	}

	function fc(a: string | undefined) {
		return a? a : "";
	}

	function sortList(filter: RecipientFilterRequest, recipients: RecipientSummary[]) {
		let compare;

		if (filter.recipientName) {
			compare = (a: RecipientSummary, b: RecipientSummary) => a.recipientName.localeCompare(b.recipientName);
		} else if (filter.email) {
			compare = (a: RecipientSummary, b: RecipientSummary) => fc(a?.email).localeCompare(fc(b?.email));
		} else if (filter.department) {
			compare = (a: RecipientSummary, b: RecipientSummary) => fc(a?.department).localeCompare(fc(b?.department));
		} else if (filter.building) {
			compare = (a: RecipientSummary, b: RecipientSummary) => fc(a?.building).localeCompare(fc(b?.building));
		} else if (filter.pickupKey) {
			compare = (a: RecipientSummary, b: RecipientSummary) => fc(a?.pickupKey).localeCompare(fc(b?.pickupKey));
		}

		return recipients.sort(compare);
	}

	async function filterList(filter: RecipientFilterRequest) {
		if(filter && (filter.recipientName || filter.email || filter.department || filter.building || filter.pickupKey)) {
			let filtered = await RecipientsProvider.filter(tenantId, filter);
			let sorted = sortList(filter, filtered);
			setRecipients(sorted);
		} else {
			setRecipients(candidates || []);
		}
	}

	const filterListDebounced: (filter: RecipientFilterRequest) => void = useCallback(
		debounce((arg0: RecipientFilterRequest) => {
			filterList(arg0);
		}, 300),
		[],
	);

	function resetFilter() {
		setRecipientName("");
		setEmail("");
		setDepartment("");
		setBuilding("");
		setPickupKey("");
        setRecipients([]);
	}

	useEffect(() => {
		setRecipients(candidates || []);
	}, [candidates]);

	useEffect(() => {
		filterListDebounced(getFilter());
	}, [recipientName, email, department, building, pickupKey]);

	const theme = useTheme();
	const headerCellSx = {padding:0.5}

	return (
		<Dialog open={open} onClose={onClose} fullWidth maxWidth="xl">
			<DialogTitle>{t("deliveries.recipients.searchRecipients")}</DialogTitle>
			<DialogContent>
				<Grid container>
					<TableContainer sx={{minHeight: "90vh"}}>
						<Table size="small">
							<TableHead>
								<TableRow>
									<TableCell sx={headerCellSx}>
										<SimpleTextInput
											label={t("recipients.name")}
											value={recipientName}
											onChange={setRecipientName}
										/>
									</TableCell >
									<TableCell sx={headerCellSx}>
										<SimpleTextInput
											label={t("recipients.email")}
											fullWidth
											value={email}
											onChange={setEmail}
										/>
									</TableCell>
									<TableCell  sx={headerCellSx}>
										<SimpleTextInput
											label={t("recipients.department")}
											fullWidth
											value={department}
											onChange={setDepartment}
										/>
									</TableCell>
									<TableCell sx={headerCellSx}>
										<SimpleTextInput
											label={t("recipients.building")}
											fullWidth
											value={building}
											onChange={setBuilding}
										/>
									</TableCell>
									<TableCell sx={headerCellSx}>
										<SimpleTextInput
											label={t("recipients.pickupKey")}
											fullWidth
											value={pickupKey}
											onChange={setPickupKey}
										/>
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{recipients.length == 0 &&
									<TableRow className={"empty"}>
										<TableCell colSpan={5}>
											<Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" p={3}>
												<Box sx={{
													height: 300,
													[theme.breakpoints.down('md')]: {
														height: 150
													},
												}}>
													<UndrawEmpty height="100%" width="auto" />
												</Box>
											</Box>
										</TableCell>
									</TableRow>
								}
								{recipients.map((row, index) => (
									<TableRow
										hover
										onClick={() => onSelected(row as RecipientSummary)}
										key={index}
										sx={{"&:last-child td, &:last-child th": {border: 0}, cursor: "pointer"}}
									>
										<TableCell component="th" scope="row">
											{row.recipientName}
										</TableCell>
										<TableCell>{row.email}</TableCell>
										<TableCell>{row.department}</TableCell>
										<TableCell>{row.building}</TableCell>
										<TableCell>{row.pickupKey}</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button onClick={resetFilter}> {t("resetFilter")} </Button>
				<Button color="primary" variant="contained" onClick={onClose}>
					{t("close")}
				</Button>
			</DialogActions>
		</Dialog>
	);
}
