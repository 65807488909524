import {makeStyles} from "@mui/styles";
import * as React from "react";
import {createElement, useEffect} from "react";
import {useBarcode} from "react-barcodes";

interface WrappedBarCodeProps {
	value: string;
	height: number;
	width: number;
	margin?: number;
	onRender: () => void;
}

export function WrappedBarCode({value, height, width, margin, onRender}: WrappedBarCodeProps) {
	const classes = useStyles();
	const {inputRef} = useBarcode({
		value: value,
		options: {
			displayValue: false,
			height: height,
			width: width,
			margin: margin || 10,
		},
	});

	useEffect(() => {
		setTimeout(onRender, 10);
	}, [value]);

	return <canvas ref={inputRef} className={classes.barCodeCentered} />;
}

const useStyles = makeStyles(theme => ({
	barCodeCentered: {
		marginLeft: "auto",
		marginRight: "auto",
		display: "block",
	},
}));
