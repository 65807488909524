import {Autocomplete, debounce} from "@mui/material";
import {TextField} from "@variocube/app-ui";
import {createElement, SyntheticEvent, useState} from "react";
import {useAsync} from "react-async-hook";
import {RecipientSummary} from "../../domain/Recipients";
import {RecipientsProvider} from "../../domain/RecipientsProvider";
import {useLocalization} from "../../i18n";
import {useTenantId} from "../TenantContextProvider";

interface RecipientSelectProps {
	recipient: RecipientSummary | undefined;
	onChange: (recipient: RecipientSummary | undefined) => void;
}

export function RecipientSelect({recipient, onChange}: RecipientSelectProps) {
	const tenantId = useTenantId();
	const {t} = useLocalization();

	const [search, setSearch] = useState<string>("");

	const handleSearchChange = debounce(setSearch, 400);

	const {loading, error, result: recipients} = useAsync(async () => {
		if (tenantId) {
			const blocks = search.split(" ");
			if (blocks && blocks.length > 0) {
				const result = await RecipientsProvider.lookup(tenantId, blocks);
				return result.recipients;
			}
		}
	}, [search]);

	function handleChange(e: SyntheticEvent, recipient: RecipientSummary | null) {
		onChange(recipient ?? undefined);
	}

	return (
		<Autocomplete
			multiple={false}
			value={recipients?.find(r =>
				(typeof r.recipientId == "number" && r.recipientId == recipient?.recipientId)
				|| (typeof r.departmentId == "string" && r.departmentId == recipient?.departmentId)
			) ?? null}
			onChange={handleChange}
			loading={loading}
			filterOptions={x => x}
			options={recipients ?? []}
			autoHighlight
			getOptionLabel={(option) => option?.recipientName || ""}
			renderOption={(props, option) => <li {...props}>{option?.recipientName || ""}</li>}
			renderInput={(params) => (
				<TextField
					{...params}
					label={t("recipients.singular")}
					variant="outlined"
					onChange={handleSearchChange}
					error={Boolean(error)}
					helperText={error?.message}
				/>
			)}
		/>
	);
}
