import {createElement, useEffect, useState} from "react";
import {Chart} from "react-google-charts";
import {useLocalization} from "../../i18n";
import {DeliveryData} from "./Dashboard";

export interface TagsChartProps {
	deliveries: DeliveryData[];
	chartType: string;
}

interface Tag {
	name: string;
	count: number;
}

export function TagsChart(props: TagsChartProps) {
	const {t} = useLocalization();
	const [chartData, setChartData] = useState([]);
	let compute = (deliveries: DeliveryData[]) => {
		let tagMap: Map<string, number> = new Map<string, number>();
		for (let delivery of deliveries) {
			for (let tag of delivery.tags ?? []) {
				let old = tagMap.get(tag) ?? 0;
				tagMap.set(tag, old + 1);
			}
		}
		const reg: RegExp = /^\?\?.+\?\?$/;
		let tagArray: Tag[] = [];
		for (let tagEntry of tagMap.entries()) {
			let tag = tagEntry[0];
			const lookup = t("tags." + tag as any);
			const tagLocal = (lookup && !reg.test(lookup)) ? lookup : tag;

			tagArray.push({name: tagLocal, count: tagEntry[1]});
		}
		return tagArray;
	};

	function getChartData(tags: Tag[]) {
		let chartData: any = [
			["Count", "Tag", {role: "style"}],
		];
		for (let u of tags) {
			chartData.push([u.name, u.count, "blue"]);
		}
		return chartData;
	}

	useEffect(() => {
		let tags = compute(props.deliveries);
		tags.sort((a, b) => a.count > b.count ? -1 : 1);
		let chartData = getChartData(tags);
		setChartData(chartData);
	}, props.deliveries);

	let options = {
		chart: {
			title: "Tags",
		},
		title: "Tags Count ",
		// width: 900,
		height: 500,
		hAxis: {
			gridlines: {count: 15},
		},
		vAxis: {
			gridlines: {color: "none"},
			minValue: 0,
		},
	};

	  if (chartData.length > 1)
		  return  (
			<Chart
				chartType={props.chartType as any}
				width="100%"
				height="400px"
				data={chartData}
				options={options}
			/>
		  )
	else
		return (
			<span></span>
		)
}
