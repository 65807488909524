import {Button} from "@mui/material";
import {ButtonPropsSizeOverrides} from "@mui/material/Button/Button";
import {OverridableStringUnion} from "@mui/types";
import * as React from "react";
import {createElement} from "react";

interface SimpleButtonProps {
	label: string;
	onClick: () => void;
	primary?: boolean;
	size?: OverridableStringUnion<"small" | "medium" | "large", ButtonPropsSizeOverrides>;
	loading?: boolean;
}

export function SimpleButton({label, onClick, primary, size, loading}: SimpleButtonProps) {
	return (
		<Button
			color={primary ? "primary" : undefined}
			variant={primary ? "contained" : "outlined"}
			size={size}
			disabled={loading}
			onClick={onClick}
		>
			{label}
		</Button>
	);
}
