import {createElement, SyntheticEvent, useCallback, useMemo} from "react";
import {Autocomplete, Checkbox, FormControlLabel, TextField} from "@mui/material";
import {useLocalization} from "../../i18n";


interface LocalesSelectionProps {
	label: string;
	values: string[];
	onChange: (value: string[]) => void;
	disabled?: boolean;
	helperText?: string;
}

const options = [
	"az",
	"bg",
	"ca",
	"cs",
	"de",
	"el",
	"en",
	"es",
	"et",
	"fa",
	"fi",
	"fr",
	"hr",
	"he",
	"hi",
	"hu",
	"hy",
	"id",
	"is",
	"it",
	"ja",
	"ko",
	"nl",
	"no",
	"pl",
	"pt",
	"ro",
	"ru",
	"sk",
	"sl",
	"sv",
	"tr",
	"th",
	"uk",
	"vi",
	"zh"
];

export function LocalesSelection(props: LocalesSelectionProps) {
	const {label, values, onChange, disabled, helperText} = props;
	const {t} = useLocalization();

	const performOptionLabelLookup = useCallback((option: string) => t(`languages.${option}` as any), [t]);

	const handleChange = useCallback((event: SyntheticEvent, newValue: string[]) => {
		onChange(newValue);
	}, [onChange]);

	return (
		<Autocomplete
			multiple fullWidth
			disabled={disabled}
			options={options}
			getOptionLabel={performOptionLabelLookup}
			value={values}
			onChange={handleChange}
			renderOption={(props, option, {selected}) => (
				<li key={option} {...props}>
					<FormControlLabel control={<Checkbox checked={selected} />} label={performOptionLabelLookup(option)} />
				</li>
			)}
			renderInput={(params) => (<TextField {...params} variant="outlined" label={label} helperText={helperText} />)}
		/>
	);
}
