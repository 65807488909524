import * as React from "react";
import {createElement, useCallback} from "react";
import {MenuItem, TextField} from "@mui/material";
import {RecipientRequired} from "../../domain/TenantUser";
import {useLocalization} from "../../i18n";

interface RecipientRequiredInputProps {
    label: string;
    value: RecipientRequired;
    onChange: (recipientRequired: RecipientRequired) => void;
}

export function RecipientRequiredInput(props: RecipientRequiredInputProps) {
    const {label, value, onChange} = props;
    const {e} = useLocalization();

    const handleChange = useCallback((str: string) => {
        onChange(str as RecipientRequired);
    }, [onChange]);

    return (
        <TextField variant="outlined" select fullWidth label={label} value={value}
                   onChange={(e) => handleChange(e.target.value)}>
            {Object.keys(RecipientRequired).map((s) =>
                <MenuItem key={s} value={s}>{e('tenants.recipientRequireds', s)}</MenuItem>
            )}
        </TextField>
    );
}
