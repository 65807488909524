import {Component, createElement} from "react";
import {Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import {OrderPosition} from "../../domain/Delivery";
import {useLocalization} from "../../i18n";

interface OrderPositionsListProps {
    orderPositions: OrderPosition[];
}

export function OrderPositionsList(props: OrderPositionsListProps) {
    const {orderPositions} = props;
    const {t} = useLocalization();
    return (
        <Table size="small" stickyHeader>
            <TableHead>
                <TableRow>
                    <TableCell style={{width: "25%"}}>{t('deliveries.order.positionId')}</TableCell>
                    <TableCell style={{width: "75%"}}>{t('deliveries.order.positionDescription')}</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {orderPositions && orderPositions.map((orderPosition) => {
                    return (<OrderPositionRow orderPosition={orderPosition} key={orderPosition.positionId}/>);
                })}
            </TableBody>
        </Table>
    );
}

interface OrderPositionRowProps {
    orderPosition: OrderPosition;
}

class OrderPositionRow extends Component<OrderPositionRowProps> {

    render() {
        const p = this.props.orderPosition;
        return(
            <TableRow key={p.positionId}>
                <TableCell>{p.positionId}</TableCell>
                <TableCell>{p.positionLabel}</TableCell>
            </TableRow>
        );
    }
}
