import * as React from "react";
import {createElement} from "react";
import {AppLockStatus} from "../../domain/Cube";
import {Chip} from "@mui/material";
import {Lock, LockOpen, Error, NotificationImportant} from "@mui/icons-material";
import {useLocalization} from "../../i18n";

interface CubeBoxLockStateComponentProps {
    lockState?: AppLockStatus;
}

export function CubeBoxLockStateComponent(props: CubeBoxLockStateComponentProps) {
    const {lockState} = props;
    const {t, e} = useLocalization();
    return(<Chip label={lockState ? e('cubes.boxes.lockState', lockState) : t('cubes.boxes.lockState.unknown')} icon={getLockStatusIcon(lockState)}/>);
}

function getLockStatusIcon(state?: AppLockStatus) {
    switch (state) {
        case AppLockStatus.Open:
            return <LockOpen />;
        case AppLockStatus.Closed:
            return <Lock />;
        case AppLockStatus.Blocked:
            return <Error />;
        case AppLockStatus.Breakin:
            return <NotificationImportant />;
        default:
            return undefined;
    }
}
