import {Search} from "@mui/icons-material";
import {
	Box,
	Button,
	debounce,
	Grid,
	IconButton,
	InputAdornment,
	Paper,
	TableCell,
	TableRow,
	TextField,
	Typography,
} from "@mui/material";
import {Breadcrumbs, ColumnType, ContainerLayout, ContentTable, Page, PagingSettings} from "@variocube/app-ui";
import {createElement, Fragment, useCallback, useEffect, useMemo, useState} from "react";
import {useNavigate} from "react-router";
import {Link} from "react-router-dom";
import {Recipient} from "../../domain/Delivery";
import {RecipientsProvider} from "../../domain/RecipientsProvider";
import {useLocalization} from "../../i18n";
import {gs} from "../../theme";
import {BreadcrumbRouterLink} from "../BreadcrumbRouterLink";
import {CubeNameDisplay} from "../CubeNameDisplay";
import {DateComponent} from "../DateComponent";
import {HelmetTitleWrapper} from "../HelmetTitleWrapper";
import {Loading} from "../Loading";
import {recipientsPaging} from "../pagings";
import {useTenant, useTenantId, useTenantUserRole} from "../TenantContextProvider";
import {recipientsColumns} from "../uis";

export function RecipientsList() {
	const {t, language} = useLocalization();
	const tenantId = useTenantId();
	const tenant = useTenant();
	const {isAdmin, isAdminLight} = useTenantUserRole();
	const [searchFor, setSearchFor] = useState<string>("");

	const baseColumns: ColumnType = useMemo<ColumnType>(() => ({
		"recipientName": {show: true, name: t("recipients.name")},
		"email": {show: true, name: t("recipients.email")},
		"phone": {show: true, name: t("recipients.phone")},
		"cubeId": {show: true, name: t("recipients.cubeId")},
		"department": {show: true, name: t("recipients.department")},
		"building": {show: true, name: t("recipients.building")},
		"pickupKey": {show: false, name: t("recipients.pickupKey")},
		"created": {show: true, name: t("recipients.created")},
		"employeeNumber": {show: false, name: t("recipients.employeeNumber")},
	}), [t]);

	const [columns, setColumns] = useState<ColumnType>();
	const [page, setPage] = useState<Page<Recipient>>();
	const [pageable, setPageable] = useState<PagingSettings<keyof typeof baseColumns>>(recipientsPaging.getSettings());
	const [inProgress, setInProgress] = useState(false);

	const loadPage = useCallback(() => {
		setInProgress(true);
		RecipientsProvider.list(tenantId, recipientsPaging)
			.then((recipients) => {
				setPage(recipients);
			})
			.finally(() => setInProgress(false));
	}, [tenantId]);

	useEffect(() => {
		let columns = recipientsColumns.get();
		if (columns == null) {
			recipientsColumns.set(baseColumns);
			columns = baseColumns;
		}
		if (tenant && !tenant.recipientPickupKeyExposed) {
			columns["pickupKey"].show = false;
		}
		setColumns(columns);
		loadPage();
	}, [loadPage, tenant]);

	useEffect(() => {
		console.log("detected language change, update table language");
		if (columns) {
			const newColumns: any = {};
			for (let k of Object.keys(columns)) {
				newColumns[k] = {
					...columns[k],
					name: baseColumns[k].name,
				};
			}
			setColumns(newColumns);
		}
	}, [language]);

	const handleSearchDebounced: (arg0: string) => void = useCallback(
		debounce((searchFor: string) => {
			handleSearch(searchFor);
		}, 300),
		[],
	);

	const handlePagingChange = (pageable: PagingSettings<any>) => {
		recipientsPaging.updateSettings(pageable);
		setPageable(pageable);
		search(searchFor);
	};

	const handleColumnsChange = useCallback((c: ColumnType) => {
		if (tenant && !tenant.recipientPickupKeyExposed) {
			c["pickupKey"].show = false;
		}
		setColumns(c);
		recipientsColumns.set(c);
	}, [tenant]);

	const handleReImport = useCallback(() => {
		if (tenant) {
			setInProgress(true);
			RecipientsProvider.reImport(tenant.centerId)
				.then(() => loadPage())
				.finally(() => setInProgress(false));
		} else {
			throw new Error("No tenant context available");
		}
	}, [tenant]);

	function search(searchFor: string) {
		setInProgress(true);
		RecipientsProvider.search(tenantId, searchFor, recipientsPaging)
			.then((recipients) => {
				setPage(recipients);
			})
			.finally(() => setInProgress(false));
	}

	function handleSearch(searchMe: string) {
		let newPageable = {...pageable, pageNumber: 0};
		recipientsPaging.updateSettings(newPageable);
		setPageable(newPageable);
		search(searchMe);
	}

	return (
		<ContainerLayout>
			<HelmetTitleWrapper pageTitle={t("recipients.plural")} />
			<Grid container spacing={gs}>
				<Grid item xs={12}>
					<Breadcrumbs>
						<BreadcrumbRouterLink to={`/${tenantId}/recipients`}>
							{t("recipients.plural")}
						</BreadcrumbRouterLink>
					</Breadcrumbs>
				</Grid>
				<Grid item xs={12}>
					<Grid container spacing={gs}>
						<Grid item style={{flexGrow: 1}}>
							<Typography variant="h2">{t("recipients.plural")}</Typography>
						</Grid>
						{(isAdmin || isAdminLight)
							&& (
								<Grid item>
									{isAdmin && (
										<Button
											variant="outlined"
											disabled={inProgress}
											onClick={() => handleReImport()}
										>
											{t("recipients.actions.reImport")}
										</Button>
									)}
									<Box component="span" marginX={1}></Box>
									<Button
										variant="contained"
										disabled={inProgress}
										component={Link}
										to={`/${tenant.centerId}/recipients/create`}
									>
										{t("recipients.actions.create")}
									</Button>
								</Grid>
							)}
					</Grid>
				</Grid>
				<Grid item xs={12}>
					<TextField
						variant="outlined"
						fullWidth
						value={searchFor}
						onChange={(e) => {
							const value = e.target.value;
							setSearchFor(value);
							handleSearchDebounced(value);
						}}
						onKeyDown={(e) => {
							if (e.key == "Enter") {
								handleSearchDebounced(searchFor);
							}
						}}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<IconButton onClick={() => handleSearchDebounced(searchFor)}>
										<Search />
									</IconButton>
								</InputAdornment>
							),
						}}
						label={t("deliveries.create.recipientStep.searchTerms.label")}
						placeholder={t("deliveries.create.recipientStep.searchTerms.placeholder")}
					/>
				</Grid>
				<Grid item xs={12}>
					<Paper>
						{!page && <Loading />}
						{(page && columns && pageable)
							&& (
								<ContentTable
									pageable={pageable}
									page={page}
									inProgress={inProgress}
									columns={columns}
									onPageableChange={handlePagingChange}
									onColumnsChange={handleColumnsChange}
									renderTableBody={
										<Fragment>
											{page.content.map((r, i) => (
												<RecipientRow key={i} recipient={r} columns={columns} />
											))}
										</Fragment>
									}
								/>
							)}
					</Paper>
				</Grid>
			</Grid>
		</ContainerLayout>
	);
}

interface RecipientRowProps {
	recipient: Recipient;
	columns: ColumnType;
}

function RecipientRow(props: RecipientRowProps) {
	const {recipient, columns} = props;
	const navigate = useNavigate();
	const tenantId = useTenantId();

	const isColumnActive = useCallback((name: string) => (columns[name] && columns[name].show), [columns]);

	const handleClick = (recipient: Recipient) => {
		if (recipient.id) {
			navigate(`/${tenantId}/recipients/${recipient.id}`);
		}
	};

	return (
		<TableRow hover onClick={() => handleClick(recipient)}>
			{isColumnActive("recipientName") && <TableCell>{recipient.recipientName}</TableCell>}
			{isColumnActive("email") && <TableCell>{recipient.email}</TableCell>}
			{isColumnActive("phone") && <TableCell>{recipient.phone}</TableCell>}
			{isColumnActive("cubeId") && (
				<TableCell>{recipient.cubeId && <CubeNameDisplay cubeId={recipient.cubeId} />}</TableCell>
			)}
			{isColumnActive("department") && <TableCell>{recipient.department}</TableCell>}
			{isColumnActive("building") && <TableCell>{recipient.building}</TableCell>}
			{isColumnActive("pickupKey") && <TableCell>{recipient.pickupKey ?? "--"}</TableCell>}
			{isColumnActive("created") && (
				<TableCell>{recipient.created ? <DateComponent date={recipient.created} /> : "--"}</TableCell>
			)}
			{isColumnActive("employeeNumber") && <TableCell>{recipient.employeeNumber}</TableCell>}
		</TableRow>
	);
}
