import {PlainDate} from "@variocube/app-ui";
import {DispatchType} from "./Bookmark";
import {CreateDeliveryRequest, Recipient} from "./Delivery";

export interface PostalAddress {
	company: string;
	name: string;
	line1: string;
	line2: string;
	zip: string;
	city: string;
	country: string;
	phone: string;
	email: string;
}

export interface DispatchRequest {
	cubeId: string;
	dispatchType: DispatchType;
	internalRecipients?: Recipient[];
	externalRecipient?: PostalAddress;
	dispatchRecipients?: Recipient[];
	label?: string;
	description?: string;
	bookmarkId?: string;
	siteId?: string;
	dispatchDetails?: DispatchDetails;
	requestDelivery? : CreateDeliveryRequest;
	manualHandoverRequired?: boolean;
	reserveBox?: string[];
}

export interface DispatchResponse {
	deliveryId: string;
}

export enum DispatchDetailsType {
	ParcelNational = "ParcelNational",
	ParcelInternational = "ParcelInternational",
	CarrierNational = "CarrierNational",
	CarrierInternational = "CarrierInternational",
}
export interface DispatchDetails {
	dispatchDetailsType: DispatchDetailsType;
	dispatchDate?: PlainDate | null;
	deliveryDate?: PlainDate | null;
	numOfParcels: number | null;
	deliveryTime?: string | null;
	costCenter: string;
}
