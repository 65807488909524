import {Api} from "../Api";
import {Site} from "./Site";

export class SitesProvider {

    static list(tenantId: string): Promise<Site[]> {
        return Api.GET(`tenants/${tenantId}/sites`).then((response) => {
            return response as Site[];
        });
    }

    static get(tenantId: string, siteId: string): Promise<Site> {
        return Api.GET(`tenants/${tenantId}/sites/${siteId}`).then((response) => {
            return response as Site;
        });
    }

    static put(tenantId: string, sites: Site[]): Promise<Site[]> {
        return Api.PUT(`tenants/${tenantId}/sites/settings`,sites).then((response) => {
            return response as Site[];
        });
    }

	static reconnect(tenantId: string, oldSite: string, newSite: string): Promise<any> {
		return Api.PUT(`tenants/${tenantId}/sites/reconnect`,{oldSite, newSite}).then((response) => {
			return ;
		});
	}
}

