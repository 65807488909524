import {PhotoSummary} from "./PhotoInbox";
import {Api} from "../Api";
import {Instant} from "@variocube/app-ui";

export class PhotoInboxProvider {

	static async list(tenantId: string): Promise<PhotoSummary[]> {
		return Api.GET(`tenants/${tenantId}/photo-inbox`)
			.then((response) => response.map(mapPhotoSummary));
	}

	static async upload(tenantId: string, dataUrl: string): Promise<PhotoSummary> {
		return Api.POST(`tenants/${tenantId}/photo-inbox`, {dataUrl}).then(mapPhotoSummary);
	}

	static async preview(tenantId: string, index: number): Promise<string> {
		return Api.GET(`tenants/${tenantId}/photo-inbox/${index}/preview`);
	}

	static async photo(tenantId: string, index: number): Promise<string> {
		return Api.GET(`tenants/${tenantId}/photo-inbox/${index}`);
	}

    static deletePhoto(tenantId: string, index: number) {
		return Api.DELETE(`tenants/${tenantId}/photo-inbox/${index}`);
    }
}

function mapPhotoSummary(p: any): PhotoSummary {
	return {
		... p,
		createdAt: Instant.from(p.createdAt)
	};
}
