import * as React from "react";
import {createElement, Fragment} from "react";
import * as ErrorStackParser from "error-stack-parser";
import {ContainerLayout} from "@variocube/app-ui";
import {BlockSvg} from "./components/BlockSvg";
import notFound from "./assets/undraw_not_found_60pq.svg";
import {Accordion, AccordionDetails, AccordionSummary, Box, Button, Grid, Typography} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export class ErrorBoundary extends React.Component {
    state = {hasError: false};
    private static context: any;

    static getContext(): any {
        let context: any = {};
        context.url = window.location.href;
        context.referrer = document.referrer;
        context.userAgent = window.navigator.userAgent;
        return context;
    }

    static getDerivedStateFromError(error: any) {
        let context = ErrorBoundary.getContext();
        context.error = error;
        ErrorBoundary.context = context;
        return {hasError: true};
    }

    componentDidCatch(error: any, errorInfo: any) {
        console.log({error, errorInfo});
    }

    getUser () {
        let result = {}
        let value = sessionStorage.getItem('logistics-credentials');
        if (value) {
            let cred = JSON.parse(value);
            let username = cred.basicAuthUser.username;
            result = {
                id: username,
                name: username,
            }
        }
        return result;
    }

    render() {
        function renderStorage(header: string, storage: Storage) {
            let lS = [];
            for (let i = 0; i < storage.length; i++) {
                let key = storage.key(i);
                let value = storage.getItem(key || '');
                if (key && value && key.indexOf('credentials') != -1) {
                    let o = JSON.parse(value);
                    o.basicAuthUser.password = "************";
                    value = JSON.stringify(o);
                }
                lS.push({key, value});
            }
            return <Fragment>
                <h3>{header}</h3>
                {lS.sort((x: any, y: any) => x.key > y.key ? 1 : -1)
                    .map((x: any) => {
                        return <div><b>{x.key}</b>: {x.value}</div>
                    })}
            </Fragment>;
        }


        const reportBugsnag = async () => {
            let stackFrames = ErrorStackParser.parse(ErrorBoundary.context.error);

            let stack = stackFrames.map(frame => {
                return {
                    file: frame.fileName,
                    lineNumber: frame.lineNumber,
                    columnNumber: frame.columnNumber,
                    method: frame.functionName

                }
            });


            let payload = {
                apiKey: '085401950bf014c07f680aea32aa2ede',
                payloadVersion: '5',
                notifier: {
                    name: 'logistics error boundary',
                    version: '1.0.11',
                    url: 'https://github.com'
                },
                events: [{
                    exceptions: [{
                        errorClass: ErrorBoundary.context.error.message,
                        stacktrace: stack,
                        type: 'browserjs'
                    }],
                    request: {
                        httpMethod: '',
                        url: ErrorBoundary.context.url,
                        referer: ErrorBoundary.context.referrer

                    },
                    user: this.getUser(),
                    metaData: {
                        userAgent: { userAgent: ErrorBoundary.context.userAgent},
                        sessionStorage: sessionStorage,
                        localStorage: localStorage
                    }
                }
                ]
            }
            let response = await fetch('https://notify.bugsnag.com/', {
                method: 'POST',
                body: JSON.stringify(payload),

            });
            if (response.ok) {
                return response.json();
            }
            throw new Error(response.statusText);
        }


        function getDetails() {
            return <Fragment>
                        <h3>Url</h3>
                        <div>{ErrorBoundary.context.url}</div>
                        <h3>Referrer</h3>
                        <div>{ErrorBoundary.context.referrer}</div>
                        <h3>User Agent</h3>
                        <div>{ErrorBoundary.context.userAgent}</div>
                        <h3>Trace</h3>
                        <div>{ErrorBoundary.context.error.stack}</div>
                        {renderStorage('Session Storage', sessionStorage)}
                        {renderStorage('Local Storage', localStorage)}
                   </Fragment>
        }

        if (this.state.hasError) {
            {reportBugsnag()}
            return <ContainerLayout>
                    <BlockSvg src={notFound} />
                    <Box p={4} />
                    <Grid container spacing={6}>
                        <Grid item xs={12}>
                            <Typography variant="h2" align="center">Oops, something went wrong!</Typography>
                            {/*{<Typography variant="h6" align="center">{ErrorBoundary.context.error.message}</Typography>}*/}
                            <Box p={2} />
                            <Box sx={{ mx: "auto", width: '60%' }}>
                            <Accordion >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                {/*<Typography>Details</Typography>*/}
                                    {<Typography variant="h6" align="center">{ErrorBoundary.context.error.message}</Typography>}
                                </AccordionSummary>
                                <AccordionDetails>
                                    {getDetails()}
                                </AccordionDetails>
                            </Accordion>
                          </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body1" align="center">
                                <Button onClick={x =>  window.location.href = '/' } > back to home</Button>
                            </Typography>
                        </Grid>
                    </Grid>
                </ContainerLayout>


        }
        return this.props.children;
    }
}
