import {Instant} from "@variocube/app-ui";
import {Delivery} from "./Delivery";

export type Shipment = {
    uuid: string,
    tenantId: string,
    createdAt: Instant,
    description?: string,
	state: ShipmentState,
}

export enum ShipmentState {
	Created = 'Created',
	WaitingForMailroomPickup = 'WaitingForMailroomPickup',
	ProcessingInMailroom = 'ProcessingInMailroom',
	WaitingForRecipientPickup = 'WaitingForRecipientPickup',
	PickedUp = 'PickedUp',
}

export type ShipmentSummary = {
    shipment: Shipment,
    inboundDelivery: Delivery,
    outboundDelivery: Delivery,
}
