import {Chip, Grid, Theme, Tooltip} from "@mui/material";
import {ChipPropsSizeOverrides} from "@mui/material/Chip/Chip";
import {makeStyles} from "@mui/styles";
import {OverridableStringUnion} from "@mui/types";
import * as React from "react";
import {createElement, useMemo} from "react";
import {useLocalization} from "../../i18n";

interface DeliveryTagsDisplayProps {
	tags?: string[];
	maxTags?: number;
	size?: OverridableStringUnion<"small" | "medium", ChipPropsSizeOverrides>;
}

function lookupTags(tag: string, lookup: (key: any, value: string) => string) {
	try {
		const result = lookup("tags", tag);
		const reg: RegExp = /^\?\?.+\?\?$/;
		return (result && !reg.test(result)) ? result : tag;
	} catch (e) {
		return tag;
	}
}

function compareTags(a: string, b: string, lookup: (key: any, value: string) => string): number {
	const fixedValues = ["overdue", "redirected", "personal", "department"];
	const aIndex = fixedValues.indexOf(a);
	const bIndex = fixedValues.indexOf(b);
	if (aIndex != -1 && bIndex != -1) {
		return aIndex - bIndex;
	}
	if (aIndex == -1 && bIndex != -1) {
		return 1;
	}
	if (aIndex != -1 && bIndex == -1) {
		return -1;
	}
	return lookupTags(a, lookup).localeCompare(lookupTags(b, lookup));
}

export function DeliveryTagsDisplay(props: DeliveryTagsDisplayProps) {
	const {tags, maxTags, size} = props;
	const {e} = useLocalization();

	const sorted = useMemo<string[]>(() => tags ? tags.sort((a, b) => compareTags(a, b, e)) : [], [tags]);
	const display = useMemo<string[]>(() => sorted.slice(0, maxTags), [sorted, maxTags]);
	const more = useMemo<number>(() => (maxTags && sorted.length > maxTags) ? sorted.length - maxTags : 0, [
		sorted,
		maxTags,
	]);

	if (!tags || tags.length === 0) {
		return null;
	}

	return (
		<Tooltip
			title={
				<Grid container spacing={2}>
					<DeliveryTagItems tags={sorted} size={size} inTooltip />
				</Grid>
			}
		>
			<Grid container spacing={1} wrap="nowrap">
				<DeliveryTagItems tags={display} size={size} />
				{more != 0
					&& (
						<Grid item>
							<Chip key="more" size={size} label={`+ ${more}`} />
						</Grid>
					)}
			</Grid>
		</Tooltip>
	);
}

interface DeliveryTagItemsProps {
	inTooltip?: boolean;
	tags?: string[];
	size?: OverridableStringUnion<"small" | "medium", ChipPropsSizeOverrides>;
}

function DeliveryTagItems(props: DeliveryTagItemsProps) {
	const {inTooltip, tags, size} = props;
	const {e} = useLocalization();

	const classes = useStyles();

	if (!tags || tags.length === 0) {
		return null;
	}

	return (
		<React.Fragment>
			{tags.map((tag, index) => (
				<Grid item key={tag}>
					<Chip
						size={size}
						label={lookupTags(tag, e)}
						className={inTooltip ? classes.tooltipChip : undefined}
					/>
				</Grid>
			))}
		</React.Fragment>
	);
}

const useStyles = makeStyles((theme: Theme) => ({
	tooltipChip: {
		background: theme.palette.background.paper,
		color: theme.palette.text.primary,
	},
}));
