import {createElement, Fragment, useContext, useMemo} from "react";
import {Divider, Hidden, Stack, Step, StepLabel, Stepper, Typography} from "@mui/material";
import {useLocalization} from "../../../i18n";
import {WizardStepContext} from "./contexts";

export function WizardStepper() {
	const {t} = useLocalization();
	const {step, setStep, photoEnabled, formLocked: disabled} = useContext(WizardStepContext);

	const stepLabel = useMemo(() => {
		switch (step) {
			case 0: return t("deliveries.create.pictureStep.title");
			case 1: return t("deliveries.create.recipientStep.title");
			case 2: return t("deliveries.create.deliveryDetails.title");
			case 3: return t("deliveries.create.summary.title");
			default:
				throw new Error(`Invalid step ${step}`);
		}
	}, [step])

	function handleStepClick(value: number) {
		setStep(value);
	}

	return (
		<Fragment>
			{/* Hide on mobile device */}
			<Hidden smDown>
				<Stepper alternativeLabel sx={{ py: 3 }}>
					{photoEnabled && (
						<Step {...{ disabled }} active={step >= 0} onClick={() => handleStepClick(0)}>
							<StepLabel>{t("deliveries.create.pictureStep.title")}</StepLabel>
						</Step>
					)}
					<Step {...{ disabled }} active={step > 0} onClick={() => handleStepClick(1)}>
						<StepLabel>{t("deliveries.create.recipientStep.title")}</StepLabel>
					</Step>
					<Step {...{ disabled }} active={step > 1} onClick={() => handleStepClick(2)}>
						<StepLabel>{t("deliveries.create.deliveryDetails.title")}</StepLabel>
					</Step>
					<Step {...{ disabled }} active={step > 2} onClick={() => handleStepClick(3)}>
						<StepLabel>{t("deliveries.create.summary.title")}</StepLabel>
					</Step>
				</Stepper>
			</Hidden>

			{/* Hide on desktop device	*/}
			<Hidden smUp>
				<Stack direction="column" justifyContent="center" spacing={2} pt={2}>
					<Typography variant="h4" align="center">{stepLabel}</Typography>
					<Divider />
				</Stack>
			</Hidden>

		</Fragment>
	)
}
