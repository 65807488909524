import {makeStyles} from "@mui/styles";
import {createElement, useEffect} from "react";
import { useAsync } from "react-async-hook";
import * as QRCode from "qrcode";

interface WrappedQRCode { 
	value: string;
	height: number;
	width: number;
	margin?: number;
	onRender: () => void;
}

export function WrappedQRCode({value, height, width, margin, onRender}: WrappedQRCode) {

	const classes = useStyles();

	useEffect(() => {
		setTimeout(onRender, 50);
	}, [value]);

	const {result} = useAsync(() => QRCode.toDataURL(value, {width}), [value]); 
    return  (<img src={result} className={classes.barCodeCentered}/>);
 
}

const useStyles = makeStyles(theme => ({
	barCodeCentered: {
		marginLeft: "auto",
		marginRight: "auto",
		display: "block",
	},
}));
