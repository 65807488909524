import * as React from "react";
import {createElement} from "react";
import {Chip} from "@mui/material";
import {DeliveryType} from "../../domain/Delivery";
import {useLocalization} from "../../i18n";
import {ChipProps} from "@mui/material/Chip/Chip";

interface DeliveryTypeDisplayProps {
    deliveryType: DeliveryType;
    size?: 'small' | 'medium';
}

export function DeliveryTypeDisplay({deliveryType, size}: DeliveryTypeDisplayProps) {
    const {e} = useLocalization();
    return (
        <Chip
            label={e('deliveries.deliveryTypes', deliveryType)}
            title={e('deliveries.deliveryTypes', deliveryType)}
            size={size}
            color={COLORS[deliveryType]}
        />
    );
}

const COLORS: Record<DeliveryType, ChipProps["color"]> = {
    [DeliveryType.Inbound]: "default",
    [DeliveryType.Outbound]: "info",
}
