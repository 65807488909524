import {createElement} from "react";
import {gs} from "../../../theme";
import {Grid, Typography} from "@mui/material";
import notFound from "../../../assets/undraw_not_found_60pq.svg";
import {BlockSvg} from "../../BlockSvg";


export function RecipientStepNoRecipientSelected() {

    return (
        <Grid container spacing={gs}>
            <Grid item xs={12}>
                <Typography align="center">
                    <BlockSvg src={notFound} />
                </Typography>
            </Grid>
        </Grid>
    );
}
