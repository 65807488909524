import {BlockSvg} from "./BlockSvg";
import loginError from "../assets/undraw_access_denied_6w73.svg";
import {Box, Button, Grid, Typography} from "@mui/material";
import {gs} from "../theme";
import {authCredentials} from "../auth/authCredentials";
import {ContainerLayout} from "@variocube/app-ui";
import {createElement} from "react";
import {useLocalization} from "../i18n";

export function TenantError() {
    const {t} = useLocalization();
    return (
        <ContainerLayout>
            <BlockSvg src={loginError}/>
            <Box p={4}/>
            <Grid container spacing={gs}>
                <Grid item xs={12}>
                    <Typography variant="h5" align="center">{t("tenants.failed")}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body1" align="center">
                        <Button onClick={() => {
                            authCredentials.logout("Could not retrieve user");
                            window.location.href = "/";
                        }}>{t("login.logout")}</Button>
                    </Typography>
                </Grid>
            </Grid>
        </ContainerLayout>
    )
}
