import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, LinearProgress} from "@mui/material";
import {createElement, useEffect, useRef, useState} from "react";
import {Cube} from "../../domain/Cube";
import {Recipient} from "../../domain/Delivery";
import {useLocalization} from "../../i18n";
import {gs} from "../../theme";
import {RecipientStep} from "./create-legacy/RecipientStep";

interface RecipientEditDialogDialogProps {
	open: boolean;
	recipient?: Recipient;
	onSave: (
		recipient: Recipient | undefined,
		draft: boolean | undefined,
		manualHandoverRequired: boolean | undefined,
		destination: string | undefined,
	) => void;
	onCancel: () => void;
	draft: boolean;
	manualHandoverRequired: boolean;
	destinationVisible: boolean;
	cube: string | undefined;
}

export function RecipientEditDialog(props: RecipientEditDialogDialogProps) {
	const {
		open,
		recipient: recipientProp,
		onSave,
		onCancel,
		draft: draftProp,
		manualHandoverRequired: manualHandoverRequiredProp,
		destinationVisible,
		cube
	} = props;
	const {t} = useLocalization();
	const [submitting, setSubmitting] = useState<boolean>(false);
	const [recipient, setRecipient] = useState<Recipient | undefined>(recipientProp);
	const [destination, setDestination] = useState<Cube|undefined>(cube ? {hostname: cube} as any : undefined);
	const [draft, setDraft] = useState<boolean | undefined>(draftProp);
	const isDraftVisible = useRef(draftProp);
	const [manualHandoverRequired, setManualHandoverRequired] = useState<boolean | undefined>(
		manualHandoverRequiredProp,
	);

	useEffect(() => {
		setSubmitting(false);
	}, [open]);

	function handleSave() {
		setSubmitting(true);
		onSave(
			recipient,
			draft,
			manualHandoverRequired,
			destination ? destination.hostname : undefined,
		);
	}

	function handleCancel() {
		onCancel();
	}

	const onChange = (recipient: Recipient | undefined): void => {
		setRecipient(recipient);
	};

	function onDestination(destination?: Cube) {
		setDestination(destination);
	}

	function onDraft(checked: boolean) {
		setDraft(checked);
	}

	function onManualHandoverRequired(checked: boolean) {
		setManualHandoverRequired(checked);
	}

	return (
		<Dialog open={open}>
			<DialogTitle>{t("deliveries.edit.title")}</DialogTitle>
			<DialogContent>
				<Grid container spacing={gs} sx={{paddingTop: "0.5em"}}>
					<Grid item xs={12}>
						<RecipientStep
							recipient={recipient}
							onChange={onChange}
							destination={destination}
							onDestination={onDestination}
							draft={draft}
							onDraft={onDraft}
							manualHandoverRequired={manualHandoverRequired}
							onManualHandoverRequired={onManualHandoverRequired}
							destinationVisible={destinationVisible}
							draftVisible={isDraftVisible.current}
						/>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button variant="outlined" onClick={handleCancel}>{t("cancel")}</Button>
				<Button color="primary" variant="contained" onClick={handleSave} disabled={submitting}>
					{t("confirm")}
				</Button>
			</DialogActions>
			{submitting && <LinearProgress variant="indeterminate" />}
		</Dialog>
	);
}
