import * as React from "react";
import {createElement, useCallback, ChangeEvent} from "react";
import {OutlinedTextFieldProps, TextField, TextFieldProps} from "@mui/material";

interface SimpleTextInputProps extends Omit<OutlinedTextFieldProps, "onChange" | "variant"> {
    onChange: (value: string) => void;
    onEnter?: () => void;
    onEscape?: () => void;
    email?: boolean;
    password?: boolean;
}

export function SimpleTextInput({onChange, onEnter, onEscape, ...props}: SimpleTextInputProps) {

    const handleCheckEnter = useCallback((key: string) => {
        if(key === 'Enter' && onEnter) {
            onEnter();
        }
        if(key === 'Escape' && onEscape) {
            onEscape();
        }
    }, [onEnter, onEscape]);

    const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        onChange(e.currentTarget.value);
    }, [onChange]);

    return (
        <TextField
            onKeyPress={(e) => handleCheckEnter(e.key)}
            onChange={handleChange}
            variant="outlined"
            fullWidth
            {...props}
        />
    );
}
