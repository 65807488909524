import {Card, CardContent, Typography} from "@mui/material";
import { createElement } from "react";

interface MetricProps {
    label: string;
    value?: number | string;
}

export function Metric({label, value}: MetricProps) {
    return (
        <Card>
            <CardContent>
                <Typography variant="overline">{label}</Typography>
                <Typography variant="h2" align="right">
                    {value}
                </Typography>
            </CardContent>
        </Card>
    );
}
