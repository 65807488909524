import {Api} from "../Api";

export class WebSocketTicketProvider {

    static async getTicket(): Promise<string> {
        return Api.POST(`/ws/ticket`, {})
            .then((response) => response.ticket);
    }

    static async getUrl() {
        const ticket = await this.getTicket();
        let baseurl = window.location.origin;
        if(window.location.hostname == "localhost") {
            baseurl = "ws://localhost:5000/data";
        }else {
            baseurl = `wss://${window.location.hostname}/data`;
        }
        return `${baseurl}?ticket=${ticket}`;
    }

}
