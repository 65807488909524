import {Api} from "../Api";
import {
    NotificationTemplate,
    Tenant
} from "./TenantUser";

export class TenantTemplateProvider {
 
    static addTemplate(tenantId: string, request: NotificationTemplate): Promise<NotificationTemplate> {
        return Api.POST(`tenants/${tenantId}/settings/template/add`, request).then((response) => {
            return response as NotificationTemplate;
        });
    }

    static updateTemplate(tenantId: string, request: NotificationTemplate): Promise<NotificationTemplate> {
        return Api.POST(`tenants/${tenantId}/settings/template/${request.id}`, request).then((response) => {
            return response as NotificationTemplate;
        });
    }

    static deleteTemplate(tenantId: string, request: NotificationTemplate): Promise<NotificationTemplate> {
        return Api.DELETE(`tenants/${tenantId}/settings/template/${request.id}`).then((response) => {
            return response as NotificationTemplate;
        });
    }
 
}

