import {Button, Dialog, DialogActions, DialogContent, Grid, Tooltip} from "@mui/material";
import {zip} from "lodash";
import * as React from "react";
import {createElement, useState} from "react";
import {Site} from "../../domain/Site";
import {useLocalization} from "../../i18n";
import {gs} from "../../theme";
import {Loading} from "../Loading";
import {SimpleCheckBox} from "../SimpleCheckBox";
import {SimpleTextInput} from "../SimpleTextInput";

interface SiteDialogProps {
	open: boolean;
	site: Site;
	onCancel: () => void;
	onConfirm: (site: Site) => void;
	onDelete?: () => void;
}

export function SiteDialog(props: SiteDialogProps) {
	const {open, site, onCancel, onConfirm, onDelete} = props;
	const {t} = useLocalization();

	const [name, setName] = useState<string>(site?.name);
	const [defaultSite, setDefaultSite] = useState<boolean>(site?.defaultSite);
	const [email, setEmail] = useState<string>(site?.email);
	const [zip, setZip] = useState<string>(site?.zip);
	const [city, setCity] = useState<string>(site?.city);
	const [street, setStreet] = useState<string>(site?.street);
	const [houseNo, setHouseNumber] = useState<string>(site?.houseNo);
	const [phone, setPhone] = useState<string>(site?.phone);
	const [enabled, setEnabled] = useState<boolean>(site?.enabled);
	const [loading, setLoading] = useState<boolean>(false);

	const handleCancel = function() {
		onCancel();
	};

	function handleConfirm() {
		let mySite: Site = {
			city,
			country: "",
			defaultSite,
			email,
			houseNo,
			name,
			phone: phone,
			siteId: site.siteId,
			street,
			zip,
			enabled,
		};
		onConfirm(mySite);
	}

	function handleChangeDefaultSite(checked: Boolean) {
		if (checked) {
			setDefaultSite(true);
			setEnabled(true);
		}
	}

	function handleChangeEnabled(checked: boolean) {
		if (site.defaultSite === false) {
			setEnabled(checked);
		}
	}

	return (
		<Dialog open={open} onClose={() => onCancel()} maxWidth="lg">
			<DialogContent>
				{site && (
					<Grid container spacing={gs}>
						<Grid item xs={12}>
							<Grid container spacing={2}>
								<Grid item xs={8}>
									<SimpleTextInput label={t("name")} value={name} onChange={setName} />
								</Grid>
								<Grid item xs={2}>
									<SimpleCheckBox
										label={t("sites.default")}
										checked={defaultSite}
										onChange={e => handleChangeDefaultSite(e)}
									/>
								</Grid>
								<Grid item xs={2}>
									<SimpleCheckBox
										disabled={site.defaultSite && site.enabled}
										label={t("sites.enabled")}
										checked={enabled}
										onChange={e => handleChangeEnabled(e)}
									/>
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={12}>
							<Grid container spacing={2}>
								<Grid item xs={9}>
									<SimpleTextInput label={t("street")} value={street} onChange={setStreet} />
								</Grid>
								<Grid item xs={3}>
									<SimpleTextInput
										label={t("houseNumber")}
										value={houseNo}
										onChange={setHouseNumber}
									/>
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={12}>
							<Grid container spacing={2}>
								<Grid item xs={3}>
									<SimpleTextInput label={t("zip")} value={zip} onChange={setZip} />
								</Grid>
								<Grid item xs={9}>
									<SimpleTextInput label={t("city")} value={city} onChange={setCity} />
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={12}>
							<Grid container spacing={2}>
								<Grid item xs={9}>
									<SimpleTextInput label={t("email")} value={email} onChange={setEmail} />
								</Grid>
								<Grid item xs={3}>
									<SimpleTextInput label={t("phone")} value={phone} onChange={setPhone} />
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				)}
			</DialogContent>
			<DialogActions>
				<Button variant="outlined" onClick={handleCancel}>{t("cancel")}</Button>
				<Tooltip title={t("sites.deleteTooltip")}>
					<span>
						<Button
							disabled={onDelete == undefined || site.defaultSite}
							variant="outlined"
							onClick={props.onDelete}
						>
							{t("delete")}
						</Button>
					</span>
				</Tooltip>
				<Button color="primary" variant="contained" onClick={handleConfirm}>{t("confirm")}</Button>
			</DialogActions>
			{loading && <Loading />}
		</Dialog>
	);
}
