import * as React from "react";
import {createElement, useCallback, useState} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid} from "@mui/material";
import {TemplateType} from "../../domain/TenantUser";
import {TemplateTypeInput} from "./TemplateTypeInput";
import {gs} from "../../theme";
import {useLocalization} from "../../i18n";

interface AddTemplateDialogProps {
    options: TemplateType[];
    open: boolean;
    onClose: () => void;
    onSelect: (templateType: TemplateType) => void;
}

export function AddTemplateDialog(props: AddTemplateDialogProps) {
    const {options, open, onClose, onSelect} = props;
    const {t} = useLocalization();

    const [templateType, setTemplateType] = useState<TemplateType>(options[0]);

    const handleClose = useCallback(() => {
        onClose();
    }, [onClose]);

    const handleConfirm = useCallback(() => {
        onSelect(templateType);
    }, [templateType, onSelect]);

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="lg">
            <DialogTitle>{t('tenants.addTemplate.title')}</DialogTitle>
            <DialogContent>
                <Grid container spacing={gs} sx={{paddingTop:'0.5em'}}>
                    <Grid item xs={12}>
                        <TemplateTypeInput options={options} label={t('tenants.templateType')} value={templateType} onChange={setTemplateType} />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={() => handleClose()}>{t('cancel')}</Button>
                <Button color="primary" variant="contained" onClick={handleConfirm}>{t('confirm')}</Button>
            </DialogActions>
        </Dialog>
    );
}
