import {Alert, Box, Button, Grid, Paper} from "@mui/material";
import {Checkbox, PlainDate, PlainDatePicker, TextField, tryParsePlainDate} from "@variocube/app-ui";
import {createElement, FormEvent, Fragment, useEffect, useState} from "react";
import {useAsync} from "react-async-hook";
import {useNavigate} from "react-router";
import {Cube} from "../../domain/Cube";
import {CubesProvider} from "../../domain/CubesProvider";
import {Recipient, RecipientMutation} from "../../domain/Delivery";
import {useLocalization} from "../../i18n";
import {gs} from "../../theme";
import {CubeSelectInput} from "../cubes/CubeSelectInput";
import {useTenant} from "../TenantContextProvider";

interface RecipientEditDetailsProps {
	onSubmit: (mutation: RecipientMutation) => void;
	recipient?: Recipient;
	alert?: string;
}

export function RecipientForm({recipient, onSubmit, alert}: RecipientEditDetailsProps) {
	const {t} = useLocalization();

	const tenant = useTenant();
	const navigate = useNavigate();

	const [mutation, setMutation] = useState<RecipientMutation>({
		email: "",
		recipientName: "",
		receiveNotifications: true,
	});
	const [deleteAfterDate, setDeleteAfterDate] = useState<PlainDate | null>(null);

	const {result: cube, set: setCube} = useAsync(
		async () =>
			!!recipient?.cubeId ? await CubesProvider.get(tenant.centerId, recipient.cubeId) as Cube : undefined,
		[recipient?.cubeId],
	);
	const {result: preferredCube, set: setPreferredCube} = useAsync(
		async () =>
			!!recipient?.preferredCubeId
				? await CubesProvider.get(tenant.centerId, recipient.preferredCubeId) as Cube
				: undefined,
		[recipient?.preferredCubeId],
	);

	useEffect(() => {
		if (recipient) {
			console.log("recipient", recipient);
			setMutation({
				...recipient,
			});
			setDeleteAfterDate(tryParsePlainDate(recipient.deleteAfter));
		}
	}, []);

	const canSubmit = !!mutation?.recipientName && !!mutation.email;

	function handleInputChange(name: keyof RecipientMutation, value: any) {
		if (mutation) {
			setMutation({
				...mutation,
				[name]: value,
			});
		}
	}

	function handleCubeChanged(cube?: Cube) {
		setCube({
			result: cube,
			loading: false,
			status: "success",
			error: undefined,
		});
	}

	function handlePreferredCubeChanged(cube?: Cube) {
		setPreferredCube({
			result: cube,
			loading: false,
			status: "success",
			error: undefined,
		});
	}

	function setDeleteAfter(value: PlainDate | null) {
		setDeleteAfterDate(value);
	}

	async function handleSave(e: FormEvent) {
		e.preventDefault();
		if (mutation) {
			onSubmit({
				...mutation,
				cubeId: cube?.hostname,
				preferredCubeId: preferredCube?.hostname,
				deleteAfter: deleteAfterDate?.toString(),
			});
		}
	}

	return (
		<form onSubmit={handleSave}>
			<Paper>
				<Box p={gs}>
					<Grid container spacing={3}>
						<Grid item xs={4}>
							<TextField
								fullWidth
								label={t("recipients.salutation")}
								value={mutation.salutation || ""}
								onChange={v => handleInputChange("salutation", v)}
							/>
						</Grid>
						<Grid item xs={4}>
							<TextField
								fullWidth
								required
								label={t("recipients.name")}
								value={mutation.recipientName || ""}
								onChange={v => handleInputChange("recipientName", v)}
								error={!mutation.recipientName}
							/>
						</Grid>
						<Grid item xs={4}>
							<TextField
								fullWidth
								required
								label={t("recipients.email")}
								value={mutation.email}
								type="email"
								onChange={v => handleInputChange("email", v)}
								error={!mutation.email}
							/>
						</Grid>
						<Grid item xs={4}>
							<TextField
								fullWidth
								label={t("recipients.phone")}
								value={mutation.phone}
								onChange={v => handleInputChange("phone", v)}
							/>
						</Grid>
						<Grid item sm={4}>
							<CubeSelectInput
								value={cube}
								allowUndefined
								onChange={handleCubeChanged}
							/>
						</Grid>
						<Grid item sm={4}>
							<CubeSelectInput
								value={preferredCube}
								label={t("cubes.selectPreferred")}
								allowUndefined
								onChange={handlePreferredCubeChanged}
							/>
						</Grid>
						<Grid item xs={4}>
							<TextField
								fullWidth
								label={t("recipients.department")}
								value={mutation.department || ""}
								onChange={v => handleInputChange("department", v)}
							/>
						</Grid>
						<Grid item xs={4}>
							<TextField
								fullWidth
								label={t("recipients.building")}
								value={mutation.building || ""}
								onChange={v => handleInputChange("building", v)}
							/>
						</Grid>
						<Grid item xs={4}>
							<TextField
								fullWidth
								label={t("recipients.position")}
								value={mutation.position || ""}
								onChange={v => handleInputChange("position", v)}
							/>
						</Grid>
						<Grid item xs={4}>
							<TextField
								fullWidth
								label={t("recipients.employeeNumber")}
								value={mutation.employeeNumber || ""}
								onChange={v => handleInputChange("employeeNumber", v)}
							/>
						</Grid>
						{tenant.recipientPickupKeyExposed && (
							<Fragment>
								<Grid item xs={4}>
									<TextField
										fullWidth
										label={t("recipients.pickupKey")}
										value={mutation.pickupKey || ""}
										onChange={v => handleInputChange("pickupKey", v)}
									/>
								</Grid>
								<Grid item xs={4}>
									<TextField
										fullWidth
										label={t("recipients.learnedPickupKey")}
										value={mutation.learnedPickupKey || ""}
										onChange={v => handleInputChange("learnedPickupKey", v)}
									/>
								</Grid>
							</Fragment>
						)}
						<Grid item sm={4}>
							<PlainDatePicker
								label={t("recipients.deleteAfter")}
								value={deleteAfterDate}
								onChange={setDeleteAfter}
								fullWidth
							/>
						</Grid>
						<Grid item sm={12}>
							<Checkbox
								label={t("recipients.receiveNotifications")}
								value={mutation.receiveNotifications || false}
								onChange={v => handleInputChange("receiveNotifications", v)}
							/>
						</Grid>
						{alert && (
							<Grid item xs={12}>
								<Alert severity="error">{alert}</Alert>
							</Grid>
						)}
						<Grid item xs={12}>
							<Grid container spacing={3}>
								<Grid item style={{flexGrow: 1}}>
									<Button
										type="submit"
										variant="contained"
										color="primary"
										disabled={!canSubmit}
									>
										{t("save")}
									</Button>
								</Grid>
								<Grid item>
									<Button variant="outlined" onClick={() => navigate(-1)}>
										{t("cancel")}
									</Button>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Box>
			</Paper>
		</form>
	);
}
