import {
	Button,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography,
} from "@mui/material";
import {createElement, useEffect, useState} from "react";
import * as uuid from "uuid";
import {BookmarksProvider} from "../../domain/BookmarksProvider";
import {Cube, CubeDetails} from "../../domain/Cube";
import {CubesProvider} from "../../domain/CubesProvider";
import {DeliveriesProvider} from "../../domain/DeliveriesProvider";
import {DeliveryFilterRequest} from "../../domain/Delivery";
import {Site} from "../../domain/Site";
import {SitesProvider} from "../../domain/SitesProvider";
import {NotificationTemplate, User} from "../../domain/TenantUser";
import {TenantUserProvider} from "../../domain/TenantUserProvider";
import {useLocalization} from "../../i18n";
import {gs} from "../../theme";
import {useTenantId} from "../TenantContextProvider";
import {SiteDialog} from "./SiteDialog";
import {SiteReconnectDialog} from "./SiteReconnectDialog";

interface SitesProps {
	sites: Site[];
	notificationTemplates: NotificationTemplate[];
	onChange: (sites: Site[]) => void;
}

export class SiteUsageStats {
	user: number = 0;
	deliveries: number = 0;
	bookmarks: number = 0;
	cubes: number = 0;
}

export function Sites(props: SitesProps) {
	const {t} = useLocalization();
	const tenantId = useTenantId();
	const [editingRow, setEditingRow] = useState<number>(-1);
	const [openDialog, setOpenDialog] = useState<boolean>(false);
	const [sites, setSites] = useState<Site[]>(props.sites.sort((a, b) => a.name < b.name ? -1 : 1));
	const [added, setAdded] = useState<boolean>(false);
	const [openDeleteDialogUnused, setOpenDeleteDialogUnused] = useState<boolean>(false);
	const [openDeleteDialogReconnect, setOpenDeleteDialogReconnect] = useState<boolean>(false);
	const [usageStats, setUsageStats] = useState<SiteUsageStats>(new SiteUsageStats());
	const [sitesToReconnect, setSitesToReconnect] = useState<Site[]>([]);

	useEffect(() => {
		(async function() {
			let s = await SitesProvider.list(tenantId);
			s = s.sort((a, b) => a.name < b.name ? -1 : 1);
			setSitesToReconnect(s);
		})();
	}, [tenantId]);

	function addSite() {
		let newSite: Site = {
			city: "",
			country: "",
			defaultSite: false,
			email: "",
			houseNo: "",
			name: "New Site",
			phone: "",
			siteId: uuid(),
			street: "",
			zip: "",
			enabled: true,
		};
		let newSites = [...sites, newSite];
		setSites(newSites);
		setEditingRow(newSites.length - 1);
		setAdded(true);
		setOpenDialog(true);
	}

	function setEditingSite(index: number) {
		setEditingRow(index);
		setAdded(false);
		setOpenDialog(true);
	}

	function onCancel() {
		setOpenDialog(false);
		if (added) {
			let newSites = [...sites];
			newSites.splice(newSites.length - 1, 1);
			setSites(newSites);
			setOpenDialog(false);
			props.onChange(newSites);
		}
	}

	function onConfirm(site: Site) {
		if (site.defaultSite) {
			for (let s of sites) {
				s.defaultSite = false;
			}
		}
		const i = sites.findIndex(s => s.siteId == site.siteId);
		let newSites = [...sites];
		newSites[i] = site;
		setSites(newSites);
		setOpenDialog(false);
		props.onChange(newSites);
	}

	async function canDelete() {
		let site = sites[editingRow];
		let filter: DeliveryFilterRequest = {
			cubeIds: [],
			deliveryConditions: [],
			deliveryStates: [],
			deliveryTypes: [],
			needle: "",
			siteIds: [site.siteId],
			tags: [],
		};
		let promiseUser: Promise<User[]> = TenantUserProvider.listTenantUsers(tenantId);
		let promiseDeliveries: Promise<number> = DeliveriesProvider.count(tenantId, filter);
		let promiseBookmark = BookmarksProvider.list(tenantId);
		let promiseCube = await CubesProvider.list(tenantId);
		let result = await Promise.all([promiseUser, promiseDeliveries, promiseBookmark, promiseCube]);
		let user: number = result[0].filter(user => user.siteId === site.siteId).length;
		let deliveries: number = result[1];
		let bookmarks: number = result[2].filter(bookmark => bookmark.siteId === site.siteId).length;

		let cubes: Cube[] = result[3];
		let promiseCubeDetails: Promise<CubeDetails>[] = cubes.map(cube => CubesProvider.get(tenantId, cube.hostname));
		let cubeDetails = await Promise.all(promiseCubeDetails);
		let cubesNo: number = cubeDetails.filter(cube => cube.settings.siteId === site.siteId).length;

		const stats: SiteUsageStats = {
			user,
			deliveries,
			bookmarks,
			cubes: cubesNo
		};

		return stats;
	}

	function deleteSite() {
		let site = sites[editingRow];
		const i = sites.findIndex(s => s.siteId == site.siteId);
		let newSites = [...sites];
		newSites.splice(i, 1);
		setSites(newSites);
		setOpenDialog(false);
		setOpenDeleteDialogUnused(false);
		setEditingRow(0);
		props.onChange(newSites);
	}

	async function onDelete() {
		let stats = await canDelete();
		console.log(stats);
		if (stats.bookmarks || stats.user || stats.deliveries || stats.cubes) {
			setUsageStats(stats as any);
			setOpenDeleteDialogReconnect(true);
		} else {
			setOpenDeleteDialogUnused(true);
		}
	}

	function reconnect(newSite: string) {
		console.log("reconnect to: " + newSite);
		SitesProvider.reconnect(tenantId, sites[editingRow].siteId, newSite);
		deleteSite();
	}

	return (
		<Grid item xs={12}>
			<Grid container spacing={gs}>
				<Grid item style={{flexGrow: 1}}>
					<Typography variant="h6">{t("sites.plural")}</Typography>
				</Grid>
				<Grid item>
					<Button variant="outlined" onClick={() => addSite()}>
						{t("tenants.addSite.title")}
					</Button>
				</Grid>
			</Grid>
			<Grid item xs={12}>
				<Table size="small">
					<TableHead>
						<TableRow>
							<TableCell>{t("name")}</TableCell>
							<TableCell>{t("sites.singular")}</TableCell>
							<TableCell>{t("sites.default")}</TableCell>
							<TableCell>{t("sites.enabled")}</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{sites.map((t, index) => {
							let adr = `${t.zip} ${t.city}, ${t.street} ${t.houseNo}`;
							return (
								<TableRow key={t.siteId} hover onClick={() => setEditingSite(index)}>
									<TableCell>{t.name}</TableCell>
									<TableCell>{adr}</TableCell>
									<TableCell>
										<Checkbox checked={t.defaultSite} />
									</TableCell>
									<TableCell>
										<Checkbox checked={t.enabled} />
									</TableCell>
								</TableRow>
							);
						})}
					</TableBody>
				</Table>
			</Grid>
			{openDialog
				&& (
					<SiteDialog
						site={sites[editingRow]}
						open={openDialog}
						onCancel={onCancel}
						onConfirm={onConfirm}
						onDelete={sites.length > 1 ? onDelete : undefined}
					/>
				)}
			<Dialog open={openDeleteDialogUnused} fullWidth maxWidth="sm">
				<DialogTitle>
					{t("sites.delete")}
				</DialogTitle>
				<DialogContent>
					{t("sites.deleteConfirm", {name: editingRow >= 0 && sites[editingRow] ? sites[editingRow].name : ""})}
				</DialogContent>
				<DialogActions>
					<Button variant="contained" color="primary" onClick={deleteSite}>
						{t("yes")}
					</Button>
					<Button onClick={() => setOpenDeleteDialogUnused(false)}>{t("no")}</Button>
				</DialogActions>
			</Dialog>
			<SiteReconnectDialog
				open={openDeleteDialogReconnect}
				site={sites[editingRow]}
				usage={usageStats}
				onCancel={() => setOpenDeleteDialogReconnect(false)}
				onCommit={(siteId: string) => {
					setOpenDeleteDialogReconnect(false);
					setOpenDialog(false);
					reconnect(siteId);
				}}
				sites={sitesToReconnect.filter((s) => s?.siteId != sites[editingRow]?.siteId && s.enabled)}
			/>
		</Grid>
	);
}
