import {
	Autocomplete,
	Button,
	Divider,
	FormControl,
	FormControlLabel,
	FormLabel,
	Grid,
	Radio,
	RadioGroup, TextField,
	Typography,
} from "@mui/material";
import {
	BreadcrumbItem,
	Breadcrumbs,
	ContainerLayout,
	CountrySelect,
	NumberField,
	PlainDate,
	PlainDatePicker,
	Selector,
} from "@variocube/app-ui";
import {NotFound} from "@variocube/app-ui/esm/layout/NotFound";
import {createElement, Fragment, useCallback, useEffect, useMemo, useState} from "react";
import {useAsync} from "react-async-hook";
import {useNavigate} from "react-router";
import {BookmarksProvider} from "../../domain/BookmarksProvider";
import {Cube} from "../../domain/Cube";
import {CubesProvider} from "../../domain/CubesProvider";
import {Recipient} from "../../domain/Delivery";
import {DispatchDetails, DispatchDetailsType, DispatchRequest, PostalAddress} from "../../domain/Dispatch";
import {DispatchProvider} from "../../domain/DispatchProvider";
import {RecipientsProvider} from "../../domain/RecipientsProvider";
import {TenantUserProvider} from "../../domain/TenantUserProvider";
import {useLocalization} from "../../i18n";
import {gs} from "../../theme";
import {useRequiredParam} from "../../useRequiredParam";
import {BoxedPaper} from "../BoxedPaper";
import {BreadcrumbRouterLink} from "../BreadcrumbRouterLink";
import {CubeSelectInput} from "../cubes/CubeSelectInput";
import {HelmetTitleWrapper} from "../HelmetTitleWrapper";
import {Loading} from "../Loading";
import {RecipientSearchV2} from "../recipients/RecipientSearchV2";
import {SimpleCheckBox} from "../SimpleCheckBox";
import {SimpleTextInput} from "../SimpleTextInput";
import {useTenantId} from "../TenantContextProvider";

export function DispatchCreate() {
	const bookmarkSlug = useRequiredParam("bookmark");
	const {t, e} = useLocalization();

	const navigate = useNavigate();
	const tenantId = useTenantId();

	const [destination, setDestination] = useState<Cube | undefined>();

	const [recipient, setRecipient] = useState<Recipient | undefined>();

	const [company, setCompany] = useState<string>("");
	const [name, setName] = useState<string>("");
	const [line1, setLine1] = useState<string>("");
	const [line2, setLine2] = useState<string>("");
	const [zip, setZip] = useState<string>("");
	const [city, setCity] = useState<string>("");
	const [country, setCountry] = useState<string>("");
	const [phone, setPhone] = useState<string>("");
	const [email, setEmail] = useState<string>("");

	const [description, setDescription] = useState<string>("");
	const [confirm, setConfirm] = useState<boolean>(false);
	const [dispatchDate, setDispatchDate] = useState<PlainDate | null>(null);
	const [deliveryDate, setDeliveryDate] = useState<PlainDate | null>(null);
	const [numOfParcels, setNumOfParcels] = useState<number | null>(null);
	const [deliveryTime, setDeliveryTime] = useState<string | null>(null);
	const [dispatchDetailsType, setDispatchDetailsType] = useState<DispatchDetailsType>(
		DispatchDetailsType.ParcelNational,
	);
	const [bookmarkCompanies, setBookmarkCompanies] = useState<{ label: string; value: string }[]>([]);
	const [costCenter, setCostCenter] = useState<string>("");

	const [loading, setLoading] = useState<boolean>(false);

	const {result: bookmark, error} = useAsync(() => BookmarksProvider.get(tenantId, bookmarkSlug), [
		tenantId,
		bookmarkSlug,
	]);

	useEffect(() => {
		const defaultDispatchCube = async () => {
			const user = await TenantUserProvider.me(tenantId);
			const recipient = await RecipientsProvider.get(tenantId, user.email);
			const cubeId = recipient.preferredCubeId || recipient.cubeId;
			if (cubeId) {
				setDestination(await CubesProvider.get(tenantId, cubeId) as Cube);
			}
		};

		defaultDispatchCube()
			.catch((e) => console.log("Error while trying to fetch a default dispatch Cube", e));
	}, []);

	useEffect(() => {
		if (destination?.address.country) {
			setCountry(destination.address.country);
		}
	}, [destination]);

	useEffect(() => {
		if (tenantId && bookmark) {
			BookmarksProvider.get(tenantId, bookmark.id)
				.then((response) => {
					let comp = response.companies.map((c) => ({...c, value: c.name, label: c.name}));
					const c = comp.map(({name, ...c}) => c);
					if (c) {
						setBookmarkCompanies(c);
					}
				});
		}
	}, [tenantId, bookmark]);

	const canSubmit = useMemo<boolean>(() => {
		if (bookmark) {
			const addressedInternally = !bookmark.requireInternalRecipient || recipient;
			const addressed = !bookmark.requireRecipientAddress || (
				(company || name) && line1 && zip && city && country && phone && email
			);
			const described = !bookmark.requireDescription || description;
			const confirmed = !bookmark.requireConfirmation || confirm;
			const details = !bookmark.requireDispatchDetails
				|| (costCenter && dispatchDate && dispatchDetailsType && deliveryDate && numOfParcels);
			return Boolean(destination && addressedInternally && addressed && described && confirmed && details);
		}
		return false;
	}, [
		bookmark,
		destination,
		recipient,
		company,
		name,
		line1,
		line2,
		zip,
		city,
		country,
		phone,
		email,
		description,
		confirm,
		costCenter,
		dispatchDate,
		dispatchDetailsType,
		deliveryDate,
		numOfParcels,
	]);

	const costCenterValid = useMemo(() => {
		if (!costCenter) return true;
		if (bookmarkCompanies.map(b => b.value).includes(costCenter)) return true;
		if (bookmark !== undefined) return !!bookmark.costCenterPattern
			? new RegExp(bookmark.costCenterPattern, 'g').test(costCenter)
			: true;
		return false;
	}, [costCenter, bookmarkCompanies, bookmark])

	const handleSubmit = useCallback(() => {
		if (bookmark && destination && costCenterValid) {
			let internalRecipients: Recipient[] | undefined;
			if (recipient) {
				internalRecipients = [recipient];
			} else {
				internalRecipients = bookmark.recipients;
			}
			let externalRecipient: PostalAddress | undefined;
			if (company || name || line1 || line2 || zip || city || phone || email) {
				externalRecipient = {
					company,
					name,
					line1,
					line2,
					zip,
					city,
					country,
					phone,
					email,
				};
			}
			let dispatchDetails: DispatchDetails | undefined;

			if (
				dispatchDetailsType || dispatchDate || deliveryDate || numOfParcels || deliveryTime
				|| costCenter
			) {
				dispatchDetails = {
					dispatchDetailsType,
					deliveryDate,
					dispatchDate,
					numOfParcels,
					deliveryTime,
					costCenter,
				};
			}
			const request: DispatchRequest = {
				cubeId: destination.hostname,
				dispatchType: bookmark.type,
				internalRecipients: internalRecipients,
				externalRecipient: externalRecipient,
				description: description,
				bookmarkId: bookmark.id,
				siteId: bookmark.siteId,
				dispatchDetails: dispatchDetails,
			};
			setLoading(true);
			DispatchProvider.dispatch(tenantId, request)
				.then((response) => {
					navigate(`/${tenantId}/deliveries/${response.deliveryId}?printLabel=true`);
				});
		}
	}, [
		tenantId,
		bookmark,
		destination,
		recipient,
		company,
		name,
		line1,
		line2,
		zip,
		city,
		country,
		phone,
		email,
		description,
		confirm,
		history,
		deliveryDate,
		dispatchDate,
		numOfParcels,
		deliveryTime,
		costCenter,
		costCenterValid
	]);

	const handleCancel = () => navigate(-1);

	if (error) {
		return (
			<NotFound errorMsg={t("notFound.title")} backToHomeMsg={t("notFound.home")} pathMsg={t("notFound.info")} />
		);
	}

	return (
		<ContainerLayout>
			<HelmetTitleWrapper pageTitle={t("dispatch.plural")} />
			<Grid container spacing={gs}>
				<Grid item xs={12}>
					<Breadcrumbs>
						<BreadcrumbRouterLink to={`/${tenantId}/dispatch`}>{t("dispatch.plural")}</BreadcrumbRouterLink>
						<BreadcrumbItem>{bookmark ? bookmark.label : t("dispatch.singular")}</BreadcrumbItem>
					</Breadcrumbs>
				</Grid>
				<Grid item xs={12}>
					<Grid container spacing={gs}>
						<Grid item style={{flexGrow: 1}}>
							<Typography variant="h2">{bookmark ? bookmark.label : t("dispatch.singular")}</Typography>
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={12}>
					{bookmark == undefined && <Loading />}
					{bookmark && (
						<Typography variant="body1">
							<p dangerouslySetInnerHTML={{__html: bookmark.description}} />
						</Typography>
					)}
				</Grid>
				{bookmark && (
					<Fragment>
						<Grid item xs={12}>
							<BoxedPaper loading={loading}>
								<Grid container spacing={gs}>
									<Grid item xs={12}>
										<CubeSelectInput
											label={t("cubes.selectDispatch")}
											value={destination}
											onChange={setDestination}
											autoSelect
										/>
									</Grid>
									{bookmark.requireInternalRecipient
										&& (
											<Grid item xs={12}>
												<RecipientSearchV2
													label={t("deliveries.create.recipientStep.selection")}
													recipients={true}
													departments={true}
													multiple={false}
													values={recipient ? [recipient] : []}
													onChange={(r) => {
														if (r && r.length > 0) {
															setRecipient(r[0]);
														}
													}}
												/>
											</Grid>
										)}
									<Grid item xs={12}>
										<Divider />
									</Grid>
									{bookmark.requireDispatchDetails && (
										<Fragment>
											<Grid item xs={12}>
												<Autocomplete
													freeSolo
													options={bookmarkCompanies.map((option) => option.value)}
													renderInput={(params) => (
														<TextField
															{...params}
															label={t("dispatch.details.costCenter")}
															onChange={ev => setCostCenter(ev.target.value)}
															error={!costCenterValid}
															helperText={!costCenterValid ? 'Invalid cost center' : undefined}
														/>
													)}
													value={costCenter}
													onChange={(_, v) => setCostCenter(v ?? '')}
												/>
											</Grid>
											<Grid item xs={6}>
												<FormControl>
													<FormLabel>{t("dispatch.details.type")}</FormLabel>
													<RadioGroup
														value={dispatchDetailsType}
														onChange={(e) => {
															setDispatchDetailsType(
																e.target.value as unknown as DispatchDetailsType,
															);
														}}
													>
														<FormControlLabel
															value={DispatchDetailsType.ParcelNational}
															control={<Radio />}
															label={e(
																"dispatch.details.types",
																DispatchDetailsType.ParcelNational,
															)}
														/>
														<FormControlLabel
															value={DispatchDetailsType.ParcelInternational}
															control={<Radio />}
															label={e(
																"dispatch.details.types",
																DispatchDetailsType.ParcelInternational,
															)}
														/>
														<FormControlLabel
															value={DispatchDetailsType.CarrierNational}
															control={<Radio />}
															label={e(
																"dispatch.details.types",
																DispatchDetailsType.CarrierNational,
															)}
														/>
														<FormControlLabel
															value={DispatchDetailsType.CarrierInternational}
															control={<Radio />}
															label={e(
																"dispatch.details.types",
																DispatchDetailsType.CarrierInternational,
															)}
														/>
													</RadioGroup>
												</FormControl>
											</Grid>
											<Grid item xs={6}>
												{dispatchDetailsType == DispatchDetailsType.CarrierNational && (
													<Selector
														options={[
															{label: "9:00", value: "9:00"},
															{label: "10:00", value: "10:00"},
															{label: "12:00", value: "12:00"},
															{label: "Samstag", value: "Samstag"},
														]}
														value={deliveryTime}
														onChange={setDeliveryTime}
														label={t("dispatch.details.deliveryTime")}
													>
													</Selector>
												)}
											</Grid>
											<Grid item xs={6}>
												<PlainDatePicker
													label={t("dispatch.details.dispatchDate")}
													value={dispatchDate}
													onChange={setDispatchDate}
													fullWidth
												/>
											</Grid>
											<Grid item xs={6}>
												<PlainDatePicker
													label={t("dispatch.details.deliveryDate")}
													value={deliveryDate}
													onChange={setDeliveryDate}
													fullWidth
												/>
											</Grid>
											<Grid item xs={6}>
												<NumberField
													label={t("dispatch.details.numOfParcels")}
													numberFormat={new Intl.NumberFormat()}
													numberValue={numOfParcels}
													onChangeNumber={setNumOfParcels}
													fullWidth
												/>
											</Grid>
										</Fragment>
									)}
									<Grid item xs={12}>
										<Divider />
									</Grid>
									{bookmark.requireRecipientAddress
										&& (
											<Fragment>
												<Grid item xs={12}>
													<SimpleTextInput
														label={t("dispatch.externalRecipient.company")}
														value={company}
														onChange={setCompany}
													/>
												</Grid>
												<Grid item xs={12}>
													<SimpleTextInput
														label={t("dispatch.externalRecipient.name")}
														value={name}
														onChange={setName}
													/>
												</Grid>
												<Grid item xs={12}>
													<SimpleTextInput
														label={t("dispatch.externalRecipient.addressLine1")}
														value={line1}
														onChange={setLine1}
													/>
												</Grid>
												<Grid item xs={12}>
													<SimpleTextInput
														label={t("dispatch.externalRecipient.addressLine2")}
														value={line2}
														onChange={setLine2}
													/>
												</Grid>
												<Grid item xs={2}>
													<SimpleTextInput
														label={t("dispatch.externalRecipient.zip")}
														value={zip}
														onChange={setZip}
													/>
												</Grid>
												<Grid item xs={7}>
													<SimpleTextInput
														label={t("dispatch.externalRecipient.city")}
														value={city}
														onChange={setCity}
													/>
												</Grid>
												<Grid item xs={3}>
													<CountrySelect
														value={country}
														onChange={(e) => setCountry(e as any)}
														label={t("dispatch.externalRecipient.country")}
													/>
												</Grid>
												<Grid item xs={6}>
													<SimpleTextInput
														label={t("dispatch.externalRecipient.phone")}
														value={phone}
														onChange={setPhone}
													/>
												</Grid>
												<Grid item xs={6}>
													<SimpleTextInput
														label={t("dispatch.externalRecipient.email")}
														value={email}
														onChange={setEmail}
													/>
												</Grid>
												<Grid item xs={12}>
													<Divider />
												</Grid>
											</Fragment>
										)}
									<Grid item xs={12}>
										<SimpleTextInput
											multiline
											rows={7}
											label={t("dispatch.description")}
											value={description}
											onChange={setDescription}
										/>
									</Grid>
								</Grid>
							</BoxedPaper>
						</Grid>
						<Grid item xs={12}>
							<Grid container spacing={gs}>
								<Grid item style={{flexGrow: 1}}>
									<Button variant="outlined" onClick={() => handleCancel()}>{t("cancel")}</Button>
								</Grid>
								<Grid item>
									<Grid container spacing={gs}>
										<Grid item style={{flexGrow: 1}}>
											{bookmark.requireConfirmation && (
												<SimpleCheckBox
													label={t("dispatch.confirm")}
													checked={confirm}
													onChange={setConfirm}
												/>
											)}
										</Grid>
										<Grid item>
											<Button
												variant="contained"
												color="primary"
												disabled={!canSubmit || loading}
												onClick={() => handleSubmit()}
											>
												{t("dispatch.dispatch")}
											</Button>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Fragment>
				)}
			</Grid>
		</ContainerLayout>
	);
}
