import * as React from "react";
import {ChangeEvent, createElement, useCallback} from "react";
import {Checkbox, FormControlLabel} from "@mui/material";

interface SimpleCheckBoxProps {
    label: string;
    checked?: boolean;
    onChange: (checked: boolean) => void;
    disabled?: boolean
}

export function SimpleCheckBox({label, checked, onChange, disabled}: SimpleCheckBoxProps) {
    const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        onChange(e.currentTarget.checked);
    }, [onChange]);
    return (
        <FormControlLabel control={
            <Checkbox disabled={disabled}  checked={checked} onChange={handleChange} color="primary" />
        } label={label} />);
}
