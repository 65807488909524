import { Paper } from "@mui/material";
import { BrowserMultiFormatReader, NotFoundException, Result } from "@zxing/library";
import { createElement, useEffect, useState } from "react";
import { useAsync } from "react-async-hook";
import { useLocalization } from "../i18n";
import { green } from "@mui/material/colors";
import { makeStyles } from "@mui/styles";
interface BarCodeScannerProps {
	onBarCode: (barCode: Result) => any;
}

declare global {
	interface MediaTrackConstraintSet {
		torch?: boolean;
	}
}

export function BarCodeScanner({ onBarCode }: BarCodeScannerProps) {

	const debug = false; 
	const classes = useStyles();
	const { t } = useLocalization();
	const [barcode, setBarcode] = useState<string | null>(null);
	const [videoElement, setVideoElement] = useState<HTMLVideoElement | null>(null);
	const [barcodeReaderStarted, setBarcodeReaderStarted] = useState<boolean>(false);
	const [barcodeReaderStopped, setBarcodeReaderStopped] = useState<boolean>(false); 

	useAsync(async () => {
		if (debug)
			console.log('Got videoElement', videoElement);
		if (videoElement) {

			const stream = await navigator.mediaDevices.getUserMedia({
				video: {
					facingMode: "environment",
					width: { ideal: 2560 },
					height: { ideal: 1920 },
					advanced: [{ torch: true }],
				},
			});
			if (debug)
				console.log('Set stream to', stream);
			(window as any).barcodeStream = stream;
			if (!(window as any).codeReader) {
				const codeReader = new BrowserMultiFormatReader();
				(window as any).codeReader = codeReader;

				await codeReader.decodeFromStream(stream, videoElement, (result, err) => {
					if (!barcodeReaderStopped) {
						setBarcodeReaderStarted(true);
						if (err && !(err instanceof NotFoundException)) {
							if (debug)
								console.log(err);
						}
						if (result) {
							if (debug)
								console.log("Found barcode: " + result.getText());
							setBarcode(result.getText());
							onBarCode(result);
						}
					}
				});
			}
		} else {
			(<p>Video not available.</p>)
		}
	}, [videoElement]);

	// stop both mic and camera
	const stopBothVideoAndAudio = (stream: MediaStream) => {
		if (debug)
			console.log('stopBothVideoAndAudio');
		stream.getTracks().forEach((track) => {
			if (debug)
				console.log('stop track', track);
			track.stop();
			/*if (track.readyState == 'live') {
				track.stop();
			}*/
		});
	}

	useEffect(() => {
		return () => {
			if (debug)
				console.log('Cleanup BarCode Scanner');
			const codeReader = (window as any).codeReader;
			if (codeReader) {
				if (debug)
					console.log('codeReader', codeReader);
				codeReader.stopContinuousDecode();
				codeReader.stopAsyncDecode();
				codeReader.reset();
				if (debug)
					console.log('codeReader reset');
			}
			setBarcodeReaderStopped(true);
			const stream = (window as any).barcodeStream;
			if (stream) {
				stopBothVideoAndAudio(stream);
			}

			delete (window as any).codeReader;
			if (debug)
				console.log('Windows codeReader deleted');
			delete (window as any).barcodeStream;
			if (debug)
				console.log('Windows barcodeStream deleted');
		}
	}, [])

	return (
		<Paper square style={{ maxWidth: 400, maxHeight: 400 }}>
			{barcode && <div className={classes.green} style={{ position: "absolute" }}>{t('BarcodeReader.BarcodeDetected')} : {barcode}</div>}
			<video ref={setVideoElement} width="100%" height="50%" style={{ display: "block" }}></video>
			{!barcodeReaderStarted && t("BarcodeReader.tip")}
		</Paper>
	);
}

const useStyles = makeStyles(() => ({
	green: {
		padding: 4,
		opacity: 0.8,
		borderRadius: 10,
		backgroundColor: green[400]
	}
}));
