import * as React from "react";
import {createElement, useCallback, useEffect, useState} from "react";
import {Button, Dialog, DialogActions, Grid, DialogContent} from "@mui/material";
import {NotificationTemplate} from "../../domain/TenantUser";
import {SimpleTextInput} from "../SimpleTextInput";
import {gs} from "../../theme";
import {useLocalization} from "../../i18n";
import { LocalesSelection } from "../inputs/LocalesSelection";

interface EditTemplateDialogProps {
    open: boolean;
    template: NotificationTemplate;
    existingTemplates: NotificationTemplate[];
    adding: boolean;
    onCancel: () => void;
    onConfirm: (template: NotificationTemplate) => void;
    onDelete: (template: NotificationTemplate) => void;
}

export function EditTemplateDialog(props: EditTemplateDialogProps) {
    const {open, template, onCancel, onConfirm, onDelete} = props;
    const {t} = useLocalization();

    const [emailSubject, setEmailSubject] = useState<string>(template.emailSubject);
    const [emailTemplate, setEmailTemplate] = useState<string>(template.emailTemplate);
    const [smsTemplate, setSmsTemplate] = useState<string>(template.smsTemplate); 
    const [locale, setLocale] = useState<string | undefined>(template.locale);
    const [existError, setExistsError] = useState<string>('');

    const handleCancel = useCallback(() => {
        onCancel();
    }, [onCancel]);


    let exists = (newLocale?: string) => {
        let errMsg : boolean | undefined;
        if (props.adding) {
            let found = props.existingTemplates.filter(t => t.templateType == template.templateType  && t.locale == newLocale);
            errMsg = found.length > 0;
        } else {
            let found = props.existingTemplates.find(t => t.templateType == template.templateType && t.locale == newLocale);
            errMsg = found && (found?.templateType == props.template.templateType &&
                found?.locale === props.template.locale) == false;
        }
        if (errMsg) {
            setExistsError(t('tenants.templateExists'));
        } else {
            setExistsError('');
        }
    }


    useEffect( () => exists(locale),[locale]);

    const handleConfirm = useCallback(() => {
        var t: NotificationTemplate = {
            id: template.id,
            center_id: template.center_id,
            templateType: template.templateType,
            emailSubject, emailTemplate, smsTemplate, locale
        };
        onConfirm(t);
    }, [template, emailSubject, emailTemplate, smsTemplate, locale, onConfirm]);

    const handleDelete = useCallback(() => {
        onDelete(template);
    }, [template, onDelete]);

    return (
        <Dialog open={open} onClose={() => onCancel()} maxWidth="xl">
            <DialogContent>
                <Grid container spacing={gs}>
                    <Grid item xs={12}>
                        <SimpleTextInput label={t('tenants.emailSubjectTemplate')} value={emailSubject} onChange={setEmailSubject} />
                    </Grid>
                    <Grid item xs={12}>
                        <SimpleTextInput label={t('tenants.emailTemplate')} value={emailTemplate} onChange={setEmailTemplate} multiline rows={10} />
                    </Grid>
                    <Grid item xs={12}>
                        <SimpleTextInput label={t('tenants.smsTemplate')} value={smsTemplate} onChange={setSmsTemplate} />
                    </Grid>
                    <Grid item xs={12}>
                        <LocalesSelection label={t("tenants.locales.singular")}
																  helperText={t('tenants.locales.helperText')}
																  values={[locale + '']}
																  onChange={(v) => v.length>0 ? setLocale(v[v.length-1]) : setLocale(undefined)} />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={handleCancel}>{t('cancel')}</Button>
                <Button variant="outlined" onClick={handleDelete}>{t('delete')}</Button>
                <Button color="primary" variant="contained" onClick={handleConfirm}  disabled={existError.length > 0} >{t('confirm')} </Button>
            </DialogActions>
        </Dialog>
    );
}
