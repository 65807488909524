import { DialogTitle, DialogContent, DialogActions, Button } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import { createElement, useState } from "react";
import { SimpleTextInput } from "../SimpleTextInput";
import { useLocalization } from "../../i18n";

export interface DeliveryNoteAddProps {
    addNote: boolean,
    onClose: (newNote: string|undefined) => void,
}
export function DeliveryNoteAdd(props: Readonly<DeliveryNoteAddProps>) {
	const {t} = useLocalization();
    const [newNote, setNewNote] = useState<string>();

    return <Dialog
        open={props.addNote}
    >
        <DialogTitle>
            {t("deliveries.notes.add")}
        </DialogTitle>
        <DialogContent>
            <SimpleTextInput
                label={t("deliveries.notes.note")}
                value={newNote}
                onChange={setNewNote}
                multiline
                rows={6}
                style={{minWidth:'400px', marginTop: '16px'}}
            />
        </DialogContent>
        <DialogActions>
        <Button
                variant="outlined"
                onClick={() => props.onClose(undefined)}
            >
                {t("cancel")}
            </Button>
            <Button
                variant="contained"
                onClick={() => props.onClose(newNote)}
            >
                {t("save")}
            </Button>
        </DialogActions>
    </Dialog>
}