import {Now} from "@variocube/app-ui";
import {authConfig} from "../auth/authConfig";
import {authCredentials, CredentialsType} from "../auth/authCredentials";

export interface AuthCodeResponse {
	idToken: string;
	accessToken: string;
	refreshToken: string;
	expiresIn: string;
	invalidAfter: string;
	tokenType: string;
}

class AuthProvider {
	private buildApiBaseUrl() {
		if (window.location.hostname === "localhost") {
			return "http://localhost:3000";
		} else {
			return `${window.location.protocol}//${window.location.hostname}:${window.location.port}`;
		}
	}

	private buildUrl(path: string) {
		if (!path.startsWith("/")) {
			path = "/" + path;
		}
		return this.buildApiBaseUrl() + path;
	}

	async processToken(code: string, redirectUri: string): Promise<AuthCodeResponse> {
		const url = this.buildUrl("/api/v2/auth");
		const request = {clientId: authConfig.clientId, code, redirectUri};
		return fetch(url, {
			method: "POST",
			body: JSON.stringify(request),
			headers: {
				"Content-Type": "application/json",
			},
		}).then((response) => {
			return this.handleResponse(response);
		}).then((token) => {
			token.invalidAfter = Now.instant().add({
				seconds: Math.ceil(token.expiresIn * 0.75),
			}).toString();
			return token;
		}).catch(() => {
			throw new Error("Auth failed");
		});
	}

	private async handleResponse(response: Response) {
		try {
			return await response.json();
		} catch (error) {
			console.warn("Body is empty. Return nothing.");
			return "";
		}
	}

	private async refreshToken() {
		const credentials = authCredentials.credentials;
		if (credentials && credentials.type == CredentialsType.Bearer && credentials.oauth2Token) {
			const token = credentials.oauth2Token;
			const now = new Date().toISOString();
			if (token.invalidAfter.localeCompare(now) < 0) {
				console.log(`Attempting token refresh`);
				const url = this.buildUrl("/api/v2/auth/refresh");
				const request = {clientId: authConfig.clientId, refreshToken: token.refreshToken};
				try {
					const response = await fetch(url, {
						method: "POST",
						body: JSON.stringify(request),
						headers: {
							"Content-Type": "application/json",
						},
					});
					const refreshedToken = await response.json();
					if (!response.ok) {
						authCredentials.logoutInvalidToken(`Response returned ${response.status}`);
						return;
					}
					refreshedToken.invalidAfter = Now.instant().add({
						seconds: Math.ceil(refreshedToken.expiresIn * 0.75),
					}).toString();
					if (!refreshedToken.refreshToken) {
						refreshedToken.refreshToken = token.refreshToken;
					}
					authCredentials.provideToken(refreshedToken);
				} catch (e) {
					console.error(`Failed to refresh token: ${e}`);
					authCredentials.logoutInvalidToken(`Failed to refresh token: ${e}`);
				}
			}
		}
	}

	setupTokenRefresh() {
		setInterval(() => {
			this.refreshToken();
		}, 5 * 60 * 1000);
	}
}

export const authProvider = new AuthProvider();
