import {Grid, Paper, TableCell, TableRow, Typography} from "@mui/material";
import {Breadcrumbs, ColumnType, ContainerLayout, ContentTable, Page, PagingSettings} from "@variocube/app-ui";
import {createElement, Fragment, useCallback, useEffect, useState} from "react";
import {shipmentColumns} from "../uis";
import {gs} from "../../theme";
import {shipmentPaging} from "../pagings";
import {Shipment} from "../../domain/Shipments";
import {ShipmentsProvider} from "../../domain/ShipmentsProvider";
import {useLocalization} from "../../i18n";
import {DateComponent, Display} from "../DateComponent";
import {HelmetTitleWrapper} from "../HelmetTitleWrapper";
import {Loading} from "../Loading";
import {useTenantId} from "../TenantContextProvider";
import {ShipmentStateBadge} from "./ShipmentStateBadge";
import {useNavigate} from "react-router";
import {BreadcrumbRouterLink} from "../BreadcrumbRouterLink";

export const ShipmentList = () => {
	const {t, language} = useLocalization();
	const tenantId = useTenantId();

	const [columns, setColumns] = useState<ColumnType>();
	const [page, setPage] = useState<Page<Shipment>>();
	const [pageable, setPageable] = useState<PagingSettings<keyof typeof baseColumns>>(shipmentPaging.getSettings());
	const [inProgress, setInProgress] = useState(false);

	const loadPage = useCallback(() => {
		console.log("load shipments");
		setInProgress(true);
		ShipmentsProvider.list(tenantId, shipmentPaging)
			.then(setPage)
			.finally(() => setInProgress(false));
	}, [tenantId, pageable]);

	const baseColumns: ColumnType = {
		"createdAt": {show: true, name: t("deliveries.createdAt")},
		"description": {show: true, name: t("deliveries.order.description")},
		"state": {show: true, name: t("state")},
	};

	useEffect(() => {
		let columns = shipmentColumns.get();
		if (columns == null) {
			shipmentColumns.set(baseColumns);
			columns = baseColumns;
		}
		setColumns(columns);
		loadPage();
	}, [loadPage]);

	useEffect(() => {
		const columns = shipmentColumns.get() || {};
		const sortedColumns = {} as any;
		for (let k of Object.keys(baseColumns)) {
			sortedColumns[k] = (!columns.hasOwnProperty(k)) ? baseColumns[k] : columns[k];
		}
		shipmentColumns.set(sortedColumns);
	}, []);

	useEffect(() => {
		console.log("detected language change, update table language");
		if (columns) {
			const newColumns: any = {};
			for (let k of Object.keys(columns)) {
				newColumns[k] = {
					...columns[k],
					name: baseColumns[k].name,
				};
			}
			setColumns(newColumns);
			shipmentColumns.set(newColumns);
		}
	}, [language]);

	const handlePagingChange = useCallback((pageable: PagingSettings<any>) => {
		shipmentPaging.updateSettings(pageable);
		setPageable(pageable);
		loadPage();
	}, [loadPage]);

	const handleColumnsChange = useCallback((c: ColumnType) => {
		setColumns(c);
		shipmentColumns.set(c);
	}, []);

	return (
		<ContainerLayout>
			<HelmetTitleWrapper pageTitle={t("shipments.plural")} />
			<Grid container spacing={gs}>
				<Grid item xs={12}>
					<Breadcrumbs>
						<BreadcrumbRouterLink to={`/${tenantId}/shipments`}>
							{t("shipments.plural")}
						</BreadcrumbRouterLink>
					</Breadcrumbs>
				</Grid>
				<Grid item xs={12}>
					<Grid container spacing={gs}>
						<Grid item style={{flexGrow: 1}}>
							<Typography variant="h2">{t("shipments.plural")}</Typography>
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={12}>
					<Paper>
						{!page && <Loading />}
						{(page && columns && pageable)
							&& (
								<ContentTable
									pageable={pageable}
									page={page}
									inProgress={inProgress}
									columns={columns}
									onPageableChange={handlePagingChange}
									onColumnsChange={handleColumnsChange}
									renderTableBody={
										<Fragment>
											{page.content.map(s => (
												<ShipmentRow key={s.uuid} shipment={s} columns={columns} />
											))}
										</Fragment>
									}
								/>
							)}
					</Paper>
				</Grid>
			</Grid>
		</ContainerLayout>
	);
};

function ShipmentRow(
	{shipment: {uuid, description, createdAt, state}, columns}: { shipment: Shipment; columns: ColumnType },
) {
	const navigate = useNavigate();
	const tenantId = useTenantId();
	const isColumnActive = useCallback((name: string) => (columns[name] && columns[name].show), [columns]);

	const {e} = useLocalization();
	return (
		<TableRow hover onClick={() => navigate(`/${tenantId}/shipments/${uuid}`)}>
			{isColumnActive("createdAt") && (
				<TableCell>
					<DateComponent date={createdAt} display={Display.Timestamp} />
				</TableCell>
			)}
			{isColumnActive("description") && <TableCell>{description || "-"}</TableCell>}
			{isColumnActive("state") && (
				<TableCell>
					<ShipmentStateBadge state={state} />
				</TableCell>
			)}
		</TableRow>
	);
}
