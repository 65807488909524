import * as React from "react";
import {createElement} from "react";
import {Typography} from "@mui/material";
import {makeStyles} from "@mui/styles";


interface Properties {
    label: string;
    children: any;
    textAlignRight?: boolean;
}

export function LabeledData(props: Properties) {

    const classes = useStyles();
    const label = props.label;
    const textAlign = props.textAlignRight ? 'right' : 'left';
    return (
        <div>
            <div style={{ textAlign: textAlign }}><Typography variant="overline">{label}</Typography></div>
            <div>{ props.children }</div>
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    label: {
        fontSize: '0.72em'
    }
}));
