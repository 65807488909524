import {Box, Grid, Typography} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {ContainerLayout} from "@variocube/app-ui";
import * as React from "react";
import {createElement, Fragment, useEffect, useState} from "react";
import {DeliveriesProvider} from "../../../domain/DeliveriesProvider";
import {Delivery} from "../../../domain/Delivery";
import {useLocalization} from "../../../i18n";
import {gs} from "../../../theme";
import {useRequiredParam} from "../../../useRequiredParam";
import {Loading} from "../../Loading";
import {PrintViewConfig} from "../../settings/PrintViewConfig";
import {SimpleButton} from "../../SimpleButton";
import {useTenantId} from "../../TenantContextProvider";
import {DeliveryPrintView} from "./DeliveryPrintView";

export function DeliveryPrintViewWrapper() {
	const tenantId = useTenantId();
	const deliveryId = useRequiredParam("deliveryId");
	const {t} = useLocalization();

	const [delivery, setDelivery] = useState<Delivery>();

	useEffect(() => {
		DeliveriesProvider.get(tenantId, deliveryId).then((delivery) => {
			setDelivery(delivery);
			// window.print();
			window.onafterprint = () => {
				window.close();
			};
		});
	}, []);

	const classes = useStyles();

	return (
		<Fragment>
			<Box display="block" displayPrint="none">
				<ContainerLayout fixedWidth="sm">
					<Grid container spacing={gs}>
						{!delivery
							&& (
								<Grid item xs={12}>
									<Loading />
								</Grid>
							)}
						{delivery
							&& (
								<Fragment>
									<Grid item xs={12}>
										<Typography align="center">
											<SimpleButton label={t("print")} primary onClick={() => window.print()} />
										</Typography>
									</Grid>
									<Grid item xs={12}>
										<Typography align="center">
											<SimpleButton label={t("close")} onClick={() => window.close()} />
										</Typography>
									</Grid>
								</Fragment>
							)}
					</Grid>
				</ContainerLayout>
			</Box>
			<Box display="none" displayPrint="block" id="printBox" className={classes.printBox}>
				{!delivery && <div>Delivery loading ...</div>}
				{delivery && (
					<DeliveryPrintView
						delivery={delivery}
						printViewConfig={PrintViewConfig.defaultConfig}
						onReadyToPrint={() => console.log("Ready to print")}
					/>
				)}
			</Box>
		</Fragment>
	);
}

const useStyles = makeStyles(theme => ({
	printBox: {
		margin: 0,
		padding: 0,
	},
}));
