import {ContainerLayout} from "@variocube/app-ui";
import {createElement, useEffect, useState} from "react";
import {Grid} from "@mui/material";
import {gs} from "../../theme";
import {RecipientSummary} from "../../domain/Recipients";
import {RecipientSearchV2} from "./RecipientSearchV2";
import {RecipientsProvider} from "../../domain/RecipientsProvider";
import {useTenantId} from "../TenantContextProvider";


export function RecipientSearchTest() {
    const tenantId = useTenantId();

	const [selectedRecipients, setSelectedRecipients] = useState<RecipientSummary[]>([]);
	const [candidates, setCandidates] = useState<RecipientSummary[]>([]);

    useEffect(() => {
		RecipientsProvider.lookup(tenantId, ["Matthias"])
			.then((result) => {
				setCandidates(result.recipients);
				// setSelectedRecipients([result.recipients[0]]);
        	})
			.catch(() => console.log("Ignore Backend error"));
    }, [tenantId]);

	return (
		<ContainerLayout>
			<Grid container spacing={gs}>
				<Grid item xs={12}>
					<RecipientSearchV2 label="Recipients" recipients departments
									   candidates={candidates}
									   values={selectedRecipients} onChange={setSelectedRecipients} />
				</Grid>
				<Grid item xs={12}>
                    {selectedRecipients.map((r, index) =>
						<li key={index}>{r.recipientName}</li>
                    )}
				</Grid>
			</Grid>
		</ContainerLayout>
	);

}
