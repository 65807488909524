import {Grid, Link, Typography} from "@mui/material";
import {ContainerWidthControl} from "@variocube/app-ui";
import {createElement} from "react";
import {Link as RouterLink} from "react-router-dom";
import {useLocalization} from "../i18n";
import {useCanSwitchTenant, useTenantUserRole} from "./TenantContextProvider";

export function FooterBar() {
	const {t} = useLocalization();
	const canSwitchTenant = useCanSwitchTenant();

	const {isRecipient} = useTenantUserRole();

	return (
		<Grid container spacing={2} sx={{px: 1, py: 0.5}} alignItems="center" displayPrint="none">
			<Grid item style={{flexGrow: 1}}>
				<Typography variant="overline" color="textSecondary">© Variocube GmbH</Typography>
			</Grid>
			{canSwitchTenant
				&& (
					<Grid item>
						<Typography variant="overline">
							<Link component={RouterLink} to="/tenants" color="secondary">
								{t("tenants.switch")}
							</Link>
						</Typography>
					</Grid>
				)}
			<Grid item>
				<Typography variant="overline">
					<ContainerWidthControl />
				</Typography>
			</Grid>
			<Grid item>
				<Typography variant="overline">
					<Link
						href="https://docs.variocube.com/variocube/logistics/de/default.htm"
						color="secondary"
						target="docs"
					>
						{t("docs")}
					</Link>
				</Typography>
			</Grid>
			{!isRecipient
				&& (
					<Grid item>
						<Typography variant="overline">
							<Link href="/api/docs" color="secondary">{t("api")}</Link>
						</Typography>
					</Grid>
				)}
			<Grid item>
				<Typography variant="overline">
					<Link href="https://www.variocube.com/kontakt/" color="secondary">
						{t("imprint")}
					</Link>
				</Typography>
			</Grid>
		</Grid>
	);
}
