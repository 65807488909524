import {Alert, Box, Container, LinearProgress, Paper, Snackbar, Stack} from "@mui/material";
import {BreadcrumbItem, Breadcrumbs, createQueryString, HelpDrawer, PageTitle, useFlag} from "@variocube/app-ui";
import {createElement, FormEvent, useCallback, useEffect, useMemo, useRef, useState} from "react";
import {useNavigate} from "react-router";
import {Bookmark, DispatchType} from "../../../domain/Bookmark";
import {Cube} from "../../../domain/Cube";
import {DeliveriesProvider} from "../../../domain/DeliveriesProvider";
import {CreateDeliveryRequest, DeliveryCondition, DeliveryImageType, DeliveryState} from "../../../domain/Delivery";
import {detectLabelInfo} from "../../../domain/DetectLabelInfo";
import {DispatchProvider} from "../../../domain/DispatchProvider";
import {OcrResponse} from "../../../domain/Ocr";
import {OutOfOfficeProvider} from "../../../domain/OutOfOfficeProvider";
import {PhotoInboxProvider} from "../../../domain/PhotoInboxProvider";
import {RecipientsProvider} from "../../../domain/RecipientsProvider";
import {useHotkey} from "../../../hotkeys";
import {useLocalization} from "../../../i18n";
import {parseNumberResilient} from "../../../tools";
import {BreadcrumbRouterLink} from "../../BreadcrumbRouterLink";
import {LHelpButton} from "../../LHelpButton";
import {useTenant} from "../../TenantContextProvider";
import {useCameraRecordingContext} from "../camera-recording-context";
import {WizardStepContext} from "./contexts";
import {WizardNavigator} from "./navigator";
import {StepDetails} from "./step-details";
import {StepPhoto} from "./step-photo";
import {StepRecipient} from "./step-recipient";
import {PRINT_LABEL_SETTING, StepSummary} from "./step-summary";
import {WizardStepper} from "./stepper";
import {BoxReservation, DeliveryDetailsData, LabelInfo, PHOTO_INBOX_PARAM, RecipientDetailsData} from "./types";

export function NewDeliveryWizard() {
	const {t} = useLocalization();
	const {CameraRecordingProvider} = useCameraRecordingContext();
	const tenant = useTenant();
	const navigate = useNavigate();
	const photoEnabled = useMemo(() => tenant.photoEnabled, [tenant]);

	const analyzeBtnRef = useRef<HTMLButtonElement>(null);

	const maxStep = 3;
	const minStep = photoEnabled ? 0 : 1;
	const [step, setStep] = useState<number>(minStep);

	const [formLocked, setFormLocked, clearFormLocked] = useFlag(false);
	const [formReset, setFormReset] = useState(0);
	const [error, setError] = useState<string>();

	/**
	 * Delivery Data
	 */
	const [ocr, setOcr] = useState<OcrResponse>();
	const [photo, setPhoto] = useState<string>();
	const [labelInfo, setLabelInfo] = useState<LabelInfo>();
	const [ocrInfo, setOcrInfo] = useState("");

	const [recipientDetails, setRecipientDetails] = useState<RecipientDetailsData>();
	const [destination, setDestination] = useState<Cube>();
	const [bookmark, setBookmark] = useState<Bookmark>();
	const [boxReservation, setBoxReservation] = useState<BoxReservation>();

	const [tempParcelId, setTempParcelId] = useState('');
	const [deliveryDetails, setDeliveryDetails] = useState<DeliveryDetailsData>();

	const [printLabel, setPrintLabel] = useState(false);

	const handleBrowserNavigation = useCallback(() => {
		setStep(prev => {
			const newStep = prev - 1;
			return newStep > -1 ? newStep : 0;
		});
	}, [step]);

	useEffect(() => {
		addEventListener("popstate", handleBrowserNavigation);
		setPrintLabel(localStorage.getItem(PRINT_LABEL_SETTING) === "true");
		return () => removeEventListener("popstate", handleBrowserNavigation);
	}, []);

	function handleOcr(ocr?: OcrResponse) {
		setOcr(ocr);
		setRecipientDetails(undefined);
		setBookmark(undefined);
		setDeliveryDetails(undefined);
		if (ocr) {
			const labelInfo = detectLabelInfo(ocr);
			if (labelInfo) {
				const info = [];
				if (labelInfo?.carrier) {
					info.push(labelInfo.carrier + " " + t("parcelLabel"));
					if (labelInfo.parcelId) {
						info.push(t("deliveries.parcelId"));
					}
					if (labelInfo.weight) {
						info.push(t("deliveries.weight"));
					}
					if (labelInfo.units) {
						info.push(t("deliveries.units"));
					}

					info.push(t("detected"));
				}
				setOcrInfo(info.join(", "));
			}
			setLabelInfo(labelInfo);
			setStep(1);
		}
	}

	function handlePhoto(imageData: string) {
		setPhoto(imageData);
		setStep(1);
	}

	function handleRecipientDetails(data: RecipientDetailsData) {
		setRecipientDetails(data);
	}

	function handleDestinationChange(cube?: Cube) {
		setDestination(cube);
	}

	function handleRecipientBookmark(bookmark?: Bookmark) {
		setBookmark(bookmark);
	}

	function handleRecipientBoxReservation(boxReservation?: BoxReservation) {
		setBoxReservation(boxReservation);
	}

	function handleDeliveryDetails(data: DeliveryDetailsData) {
		setDeliveryDetails(data);
	}

	async function handleFormSubmit(e?: FormEvent<HTMLFormElement>) {
		if (e) {
			e.preventDefault();
		}
		const newStep = step + 1;
		if (step === maxStep) {
			await handleDeliveryCreation();
		} else {
			setStep(newStep);
			history.pushState({}, "", window.location.href);
		}
	}
	useHotkey({key: "y", ctrlKey: true}, () => handleFormSubmit());

	async function handleComplete() {
		await handleDeliveryCreation();
	}

	async function handleDeliveryCreation() {
		if (
			!recipientDetails?.draft
			&& !recipientDetails?.manualHandoverRequired
			&& !destination
		) {
			console.error("Invalid form data.");
			return;
		}
		setFormLocked();
		setError(undefined);

		let deliveryId: string;
		try {
			if (bookmark) {
				console.info("Bookmark is defined. Creating dispatch...");
				const dispatch = await createDispatch();
				deliveryId = dispatch.deliveryId;
			} else {
				console.info("Creating normal delivery...");
				const delivery = await createDelivery();
				deliveryId = delivery.id;

				const recipientEmail = recipientDetails?.recipient?.email;
				if (recipientEmail) {
					await handleOutOfOffice(recipientEmail, deliveryId);
				}
			}
		} catch (err) {
			console.error("Failed to handle delivery creation", err);
			clearFormLocked();
			setError((err as any).toString());
			return;
		}

		try {
			const query = new URLSearchParams();
			const index = query.get(PHOTO_INBOX_PARAM);
			if (index) {
				await PhotoInboxProvider.deletePhoto(tenant.centerId, parseInt(index));
			}
		} catch (err) {
			console.error("Failed to delete photo inbox item", err);
		}

		navigate(`/${tenant.centerId}/deliveries/${deliveryId}?${
			createQueryString({
				printLabel,
			})
		}`);
	}

	async function createDispatch() {
		if (!bookmark) {
			throw new Error("Invalid bookmark data.");
		}
		const {manualHandoverRequired} = recipientDetails ?? {};
		const {boxTypes} = boxReservation ?? {};
		const deliveryRequest = prepareDeliveryRequest();
		return DispatchProvider.dispatch(tenant.centerId, {
			bookmarkId: bookmark.id,
			cubeId: destination?.hostname ?? "",
			label: bookmark.label,
			description: bookmark.description,
			dispatchType: DispatchType.Department,
			internalRecipients: bookmark.recipients,
			siteId: bookmark.siteId,
			manualHandoverRequired,
			requestDelivery: deliveryRequest,
			reserveBox: boxTypes,
		});
	}

	async function createDelivery() {
		const request = prepareDeliveryRequest();
		return DeliveriesProvider.create(tenant.centerId, request);
	}

	async function handleOutOfOffice(recipientEmail: string, deliveryId: string) {
		const outOfOffice = await OutOfOfficeProvider.getOutOfOfficeSubstitute(tenant.centerId, recipientEmail);

		if (outOfOffice) {
			console.info("Handle out office for recipient", recipientEmail);

			const numOfSubstitutes = outOfOffice.split(",").length;
			const recipients = await RecipientsProvider.getRecipientsByEmails(tenant.centerId, outOfOffice);

			if (recipients.length == 0) {
				const outOfOfficeObj = await OutOfOfficeProvider.get(tenant.centerId, recipientEmail);
				await OutOfOfficeProvider.put(tenant.centerId, recipientEmail, {
					...outOfOfficeObj,
					active: false,
					substituteEmail: "",
				});
			} else {
				if (recipients.length < numOfSubstitutes) {
					const outOfOfficeObj = await OutOfOfficeProvider.get(tenant.centerId, recipientEmail);
					await OutOfOfficeProvider.put(tenant.centerId, recipientEmail, {
						...outOfOfficeObj,
						substituteEmail: recipients.map((r) => r.email).toString(),
					});
				}
				for (let recipient of recipients) {
					if (recipient) {
						await DeliveriesProvider.addRecipient(tenant.centerId, deliveryId, recipient);
					}
				}
			}
		}
	}

	function prepareDeliveryRequest(): CreateDeliveryRequest {
		const {
			parcelId,
			condition = DeliveryCondition.Intact,
			weight,
			units,
			sender,
			carrier,

			orderId = "",
			deliveryNoteId,
			description = "",

			storageTimeMax = tenant.maxStorageTime,
			foreignId = "",
			callbackUrl = "",

			tags = [],
		} = deliveryDetails ?? {};

		const {
			draft,
			recipient = {recipientName: ""},
			notifyRecipients = false,
			manualHandoverRequired,
		} = recipientDetails ?? {};
		const {
			boxFeature,
			boxTypes,
		} = boxReservation ?? {};
		const metadata = (foreignId || callbackUrl)
			? {foreignId, callbackUrl}
			: undefined;
		const images = [];
		if (ocr) images.push({image: ocr.imageData, imageType: DeliveryImageType.Recipient});
		if (photo) images.push({image: photo, imageType: DeliveryImageType.Recipient});

		if (boxFeature) {
			tags.push(boxFeature);
		}

		return {
			delivery: {
				parcelId: !!parcelId ? parcelId : tempParcelId,
				condition,
				weight: parseNumberResilient(weight),
				units,
				sender,
				carrier,
				state: draft ? DeliveryState.Draft : undefined,
			},
			recipients: [recipient],
			notifyRecipients,
			receivingDepartment: recipient.departmentId,
			order: {
				orderId,
				deliveryNoteId,
				description,
				orderPositions: [],
				attachments: [],
			},
			storage: {
				storageTimeMax,
				destination: !manualHandoverRequired ? destination?.hostname : undefined,
				destinationName: !manualHandoverRequired ? destination?.name : undefined,
				destinationDescription: !manualHandoverRequired ? destination?.description : undefined,
				manualHandoverRequired,
				reserveBox: boxTypes ?? [],
				reserveBoxFeature: boxFeature,
			},
			metadata,
			images,
			tags,
		};
	}

	const wizardContextValues = useMemo(() => ({
		step,
		setStep,
		photoEnabled,
		ocrEnabled: tenant.ocrEnabled,
		formLocked,
		formReset,
		setFormReset,
	}), [step, photoEnabled, formLocked, formReset, tenant.ocrEnabled]);

	return (
		<Container maxWidth="lg">
			<CameraRecordingProvider analyzeBtnRef={analyzeBtnRef}>
				<Breadcrumbs>
					<BreadcrumbRouterLink to={`/${tenant.centerId}/deliveries`}>
						{t("deliveries.plural")}
					</BreadcrumbRouterLink>
					<BreadcrumbItem>
						{t("deliveries.create.title")}
					</BreadcrumbItem>
				</Breadcrumbs>

				<Box my={2} />

				<Stack direction="row" alignItems="center" spacing={2}>
					<PageTitle title={t("deliveries.create.title")} variant="h2" />
					<LHelpButton helpPage="Delivery_Create" helpAnchor="" language="" />
				</Stack>

				<Box my={3} />

				<Paper>
					<WizardStepContext.Provider
						value={wizardContextValues}
					>
						<form onSubmit={handleFormSubmit}>
							<WizardStepper {...{}} />

							<Container
								sx={{
									minHeight: 450,
									display: "flex",
									flexDirection: "column",
									alignItems: "center",
									justifyContent: "center",
									py: 3,
								}}
							>
								{step === 0 && (
									<StepPhoto
										onPhoto={handlePhoto}
										onOcr={handleOcr}
									/>
								)}
								{step === 1 && (
									<StepRecipient
										defaultRecipientDetailsData={recipientDetails}
										defaultDestination={destination}
										defaultBoxReservation={boxReservation}
										defaultBookmark={bookmark}
										onRecipientDetailsChange={handleRecipientDetails}
										onDestinationChange={handleDestinationChange}
										onBookmarkChange={handleRecipientBookmark}
										onBoxReservationChange={handleRecipientBoxReservation}
										ocr={ocr}
									/>
								)}
								{step === 2 && (
									<StepDetails
										defaultDetails={deliveryDetails}
										temporallyParcelId={tempParcelId}
										onChange={handleDeliveryDetails}
										onTemporallyParcelIdChange={setTempParcelId}
										ocr={ocr}
										labelInfo={labelInfo}
									/>
								)}
								{step === 3 && (
									<Box width="80%">
										<StepSummary
											printLabel={printLabel}
											onPrintLabelChange={setPrintLabel}
											recipientDetails={recipientDetails}
											boxReservation={boxReservation}
											bookmark={bookmark}
											deliveryDetails={deliveryDetails}
											imageData={ocr?.imageData ?? photo}
										/>
									</Box>
								)}
							</Container>

							{formLocked && <LinearProgress />}

							<WizardNavigator
								ref={analyzeBtnRef}
								{...{maxStep}}
								onComplete={handleComplete}
							/>
						</form>
					</WizardStepContext.Provider>
				</Paper>
			</CameraRecordingProvider>

			<Snackbar
				open={!!ocrInfo}
				autoHideDuration={10000}
				onClose={() => setOcrInfo("")}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "center",
				}}
			>
				<Alert severity="success" sx={{width: "100%"}}>
					{ocrInfo}
				</Alert>
			</Snackbar>

			{error && <Alert severity="error">{t("deliveries.error").concat(" ", `${error}`)}</Alert>}
			<HelpDrawer />
		</Container>
	);
}
