import {DispatchRequest, DispatchResponse} from "./Dispatch";
import {Api} from "../Api";


export class DispatchProvider {

    static dispatch(tenantId: string, request: DispatchRequest): Promise<DispatchResponse> {
        return Api.POST(`tenants/${tenantId}/dispatch`, request).then((response) => {
            return response as DispatchResponse;
        })
    }

}
