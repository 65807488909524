import * as React from "react";
import {createElement, useCallback, useMemo, useState} from "react";
import {ExpandMore, Translate} from "@mui/icons-material";
import {Button, Hidden, Menu, MenuItem} from "@mui/material";
import {OverridableStringUnion} from "@mui/types";
import {ButtonPropsColorOverrides} from "@mui/material/Button/Button";
import {SUPPORTED_LANGUAGES, useLocalization} from "../i18n";

interface LanguageSwitcherMenuProps {
    color?: OverridableStringUnion<
        'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning',
        ButtonPropsColorOverrides
        >;
}

export function LanguageSwitcher(props: LanguageSwitcherMenuProps) {
    const {color} = props;
    const {language, setLanguage} = useLocalization();

    const [anchorEl, setAnchorEl] = useState<HTMLAnchorElement | HTMLButtonElement | undefined>();

    const languageConfiguration = useMemo(() => {
        const config = SUPPORTED_LANGUAGES.filter((l) => l.language == language).pop();
        if(!config) {
            setLanguage('en');
            const list = SUPPORTED_LANGUAGES.map((l) => l.language).join(', ');
            throw new Error(`Selected language ${language} is not supported in ${list}`);
        }
        return config;
    }, [language, setLanguage]);

    const handleSelectLanguage = useCallback((language: string) => {
        setLanguage(language);
        setAnchorEl(undefined);
    }, [setLanguage]);

    return (
        <React.Fragment>
            <Button color={color} startIcon={<Translate/>} endIcon={<ExpandMore fontSize="small"/>}
                    onClick={(e) => setAnchorEl(e.currentTarget)}>
                <Hidden mdDown>{languageConfiguration.displayName || language.toLocaleUpperCase()}</Hidden>
                <Hidden mdUp>{language.toLocaleUpperCase()}</Hidden>
            </Button>
            <Menu open={Boolean(anchorEl)} anchorEl={anchorEl} keepMounted onClose={() => setAnchorEl(undefined)}>
                {SUPPORTED_LANGUAGES.map((l) =>
                    <MenuItem key={l.language} onClick={() => handleSelectLanguage(l.language)}>{l.displayName}</MenuItem>
                )}
            </Menu>
        </React.Fragment>
    );
}
