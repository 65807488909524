import {Page, Paging} from "@variocube/app-ui";
import {Shipment, ShipmentSummary} from "./Shipments";
import {Api} from "../Api";

export class ShipmentsProvider {

    static list(tenantId: string, paging: Paging) {
        return Api.GET(`tenants/${tenantId}/shipments${paging.toQueryString()}`)
            .then(r => (r as Page<Shipment>));
    }

    static get(tenantId: string, uuid: string) {
        return Api.GET(`tenants/${tenantId}/shipments/${uuid}`)
            .then(r => (r as ShipmentSummary));
    }

    static delete(tenantId: string, uuid: string) {
        return Api.DELETE(`tenants/${tenantId}/shipments/${uuid}`);
    }
}