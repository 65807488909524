import {OcrResponse} from "../../../domain/Ocr";
import {useLocalization} from "../../../i18n";
import {createElement, useContext, useEffect, useState} from "react";
import {useCameraRecordingContext} from "../camera-recording-context";
import {Box, Button, CircularProgress, Grid, LinearProgress, Stack} from "@mui/material";
import {LiveCamera} from "../../live-camera";
import {useTenant} from "../../TenantContextProvider";
import {OcrProvider} from "../../../domain/OcrProvider";
import {PhotoInboxProvider} from "../../../domain/PhotoInboxProvider";
import {useAsync} from "react-async-hook";
import {PHOTO_INBOX_PARAM} from "./types";

interface StepOcrProps {
	onPhoto: (imageData: string) => void;
	onOcr: (response?: OcrResponse) => void;
}

export function StepPhoto({onPhoto, onOcr}: StepOcrProps) {
	const {t} = useLocalization();
	const {setAnalyzingData, analyzingData} = useCameraRecordingContext();
	const tenant = useTenant();

	const [error, setError] = useState('');
	const [resolution, setResolution] = useState<{ width: number, height: number }>();

	useEffect(() => {
		if (tenant.ocrWidth && tenant.ocrHeight) {
			setResolution({ width: tenant.ocrWidth, height: tenant.ocrHeight });
		}
	}, []);

	const {loading: photoInboxInProgress} = useAsync(async () => {
		const query = new URLSearchParams(location.search);
		const index = query.get(PHOTO_INBOX_PARAM);
		if (index !== null) {
			try {
				const photo = await PhotoInboxProvider.photo(tenant.centerId, parseInt(index));
				await handleImage(photo);

			} catch (err) {
				setError(`An error occur when checking for photo index (${index}).`);
				console.error('Failed to check for photo index', err);
			}
		}
	}, [tenant.centerId])

	async function handleImage(data: string) {
		setAnalyzingData(data);
		if (tenant.ocrEnabled) {
			try {
				onOcr(await OcrProvider.ocr(data));
			} catch (err) {
				setError(t("deliveries.create.pictureStep.analyzing.ocrFailed"));
			}
		} else if (tenant.photoEnabled) {
			onPhoto(data);
		}
		setAnalyzingData('');
	}

	if (analyzingData) {
		return <AnalyzingFrame {...{ analyzingData }} />;
	}

	if (photoInboxInProgress) {
		return <CircularProgress />;
	}

	return (
		<LiveCamera
			allowLastScannedImage
			onImage={handleImage}
			lastError={error}
			resolution={resolution}
		/>
	)
}

interface AnalyzingFrameProps {
	analyzingData: string;
}

function AnalyzingFrame({analyzingData}: AnalyzingFrameProps) {
	const {t} = useLocalization();

	return (
		<Stack
			direction="column"
			justifyContent="center"
			spacing={3}
		>
			<Box
				component="img"
				alt={t("deliveries.create.pictureStep.analyzing.info")}
				src={analyzingData}
				sx={{
					marginLeft: "auto",
					marginRight: "auto",
					display: "block",
					filter: "grayscale(100%)",
					maxWidth: "100%",
					maxHeight: 400,
				}}
			/>
			<LinearProgress variant="indeterminate" />
		</Stack>
	)
}
