import {Component, createElement} from "react";
import {Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import {Attachment} from "../../domain/Delivery";

interface OrderAttachmentsListProps {
    attachments: Attachment[];
}

export class OrderAttachmentsList extends Component<OrderAttachmentsListProps> {

    render() {
        const attachments = this.props.attachments;
        return(
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell style={{width: '100%'}}>{ "Bezeichnung" }</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    { attachments && attachments.map((attachment) => {
                        return (<AttachmentRow attachment={attachment} key={attachment.url} />);
                    }) }
                </TableBody>
            </Table>
        );
    }
}

interface AttachmentRowProps {
    attachment: Attachment;
}

class AttachmentRow extends Component<AttachmentRowProps> {

    render() {
        const a = this.props.attachment;
        return(
            <TableRow key={a.url}>
                <TableCell><a href={a.url}> { a.name }</a></TableCell>
            </TableRow>
        );
    }
}