import {Instant} from "@variocube/app-ui";
import {Api} from "../Api";
import {Cube, CubeBox, CubeDetails, CubeSetting} from "./Cube";

export class CubesProvider {
	static list(tenantId: string): Promise<Cube[]> {
		return Api.GET(`tenants/${tenantId}/cubes`).then((response) => {
			return response as Cube[];
		});
	}

	static listDetails(tenantId: string, cubeIds: string[]): Promise<CubeDetails[]> {
		let promises: Promise<CubeDetails>[] = [];
		for (let id of cubeIds) {
			let details = CubesProvider.get(tenantId, id);
			promises.push(details);
		}
		return Promise.all(promises);
	}

	static async listSiteCubes(tenantId: string, siteId: string): Promise<Cube[]> {
		let allCubes = await CubesProvider.list(tenantId);
		let allowedCubes: Cube[] = [];
		let detailsList = await CubesProvider.listDetails(tenantId, allCubes.map(c => c.hostname));
		for (let i = 0; i < detailsList.length; i++) {
			if (detailsList[i].settings.siteId == siteId) {
				allowedCubes.push(allCubes[i]);
			}
		}
		return allowedCubes;
	}

	static get(tenantId: string, cubeId: string): Promise<CubeDetails> {
		return Api.GET(`tenants/${tenantId}/cubes/${cubeId}`).then((response) => {
			return response as CubeDetails;
		});
	}

	static getBoxes(tenantId: string, cubeId: string): Promise<CubeBox[]> {
		return Api.GET(`tenants/${tenantId}/cubes/${cubeId}/boxes`).then((response) => {
			return response as CubeBox[];
		}).then((boxes) => {
			for (let box of boxes) {
				if (box.occupancies && box.occupancies.length > 0) {
					box.occupancies = box.occupancies.map((o) => {
						return {
							...o,
							created: Instant.from(o.created),
							updated: o.updated ? Instant.from(o.updated) : undefined,
							ended: o.ended ? Instant.from(o.ended) : undefined,
						};
					});
				}
			}
			return boxes;
		});
	}

	static getBox(tenantId: string, cubeId: string, boxNumber: string | number): Promise<CubeBox> {
		return Api.GET(`tenants/${tenantId}/cubes/${cubeId}/boxes/${boxNumber}`).then((response) => {
			return response as CubeBox;
		}).then((box) => {
			if (box.occupancies && box.occupancies.length > 0) {
				box.occupancies = box.occupancies.map((o) => {
					return {
						...o,
						created: Instant.from(o.created),
						updated: o.updated ? Instant.from(o.updated) : undefined,
						ended: o.ended ? Instant.from(o.ended) : undefined,
					};
				});
			}
			return box;
		});
	}

	static getBoxAccessKeys(tenantId: string, cubeId: string, boxNumber: string): Promise<string[]> {
		return Api.GET(`tenants/${tenantId}/cubes/${cubeId}/boxes/${boxNumber}/accessKeys`).then((response) => {
			return response as string[];
		});
	}

	static setBoxAccessKeys(tenantId: string, cubeId: string, boxNumber: string, accessKeys: string[]): Promise<void> {
		return Api.PUT(`tenants/${tenantId}/cubes/${cubeId}/boxes/${boxNumber}/accessKeys`, accessKeys);
	}

	static openBox(tenantId: string, cubeId: string, boxNumber: string | number): Promise<void> {
		return Api.POST(`tenants/${tenantId}/cubes/${cubeId}/boxes/${boxNumber}/open`, {}, undefined, false).then(
			(response) => {
				if (response && response.error && response.message) {
					const message = response.message;
					throw new Error(message);
				}
				return;
			},
		);
	}

	static setBoxState(tenantId: string, cubeId: string, boxNumber: string | number, disabled: boolean): Promise<void> {
		return Api.PUT(`tenants/${tenantId}/cubes/${cubeId}/boxes/${boxNumber}/state`, {disabled: disabled}).then(
			(_response) => {
				return;
			},
		);
	}

	static endOccupancy(
		tenantId: string,
		cubeId: string,
		boxNumber: string | number,
		occupancyUuid: string,
	): Promise<void> {
		return Api.DELETE(`tenants/${tenantId}/cubes/${cubeId}/boxes/${boxNumber}/occupancies/${occupancyUuid}/end`);
	}

	static completeMaintenance(tenantId: string, cubeId: string, boxNumber: string | number): Promise<void> {
		return Api.GET(`tenants/${tenantId}/cubes/${cubeId}/boxes/${boxNumber}/completeMaintenance`);
	}

	static updateSettings(tenantId: string, cubeId: string, settings: CubeSetting): Promise<CubeDetails> {
		return Api.PUT(`tenants/${tenantId}/cubes/${cubeId}/settings`, settings).then((response) => {
			return response as CubeDetails;
		});
	}
}
