import * as React from "react";
import {useCallback, useState, createElement} from "react";
import {Button, Dialog, DialogActions, DialogContent, Grid} from "@mui/material";
import {TagFragment, TagFragmentSeverity} from "../../domain/TenantUser";
import {SimpleTextInput} from "../SimpleTextInput";
import {gs} from "../../theme";
import {SeverityInput} from "./SeverityInput";
import {useLocalization} from "../../i18n";

interface EditTagFragmentDialogProps {
    open: boolean;
    tagFragment: TagFragment;
    onCancel: () => void;
    onConfirm: (tagFragment: TagFragment) => void;
    onDelete: (tag: string) => void;
}

export function EditTagFragmentDialog(props: EditTagFragmentDialogProps) {
    const {open, tagFragment, onCancel, onConfirm, onDelete} = props;
    const {t} = useLocalization();

    const [tag, setTag] = useState<string>(tagFragment.tag);
    const [severity, setSeverity] = useState<TagFragmentSeverity>(TagFragmentSeverity.Info);
    const [title, setTitle] = useState<string>(tagFragment.title);
    const [text, setText] = useState<string>(tagFragment.text);

    const handleCancel = useCallback(() => {
        onCancel();
    }, [onCancel]);

    const handleConfirm = useCallback(() => {
        onConfirm({
            tag: tag,
            severity: severity,
            title: title,
            text: text
        });
    }, [tagFragment, tag, severity, title, text, onConfirm]);

    const handleDelete = useCallback(() => {
        onDelete(tagFragment.tag);
    }, [tagFragment, onDelete]);

    return (
        <Dialog open={open} onClose={() => onCancel()} maxWidth="xl">
            <DialogContent>
                <Grid container spacing={gs}>
                    <Grid item xs={12}>
                        <SimpleTextInput label={t('tenants.tagFragments.tag')} value={tag} onChange={setTag} />
                    </Grid>
                    <Grid item xs={12}>
                        <SeverityInput label={t('tenants.tagFragments.severity')} value={severity} onChange={setSeverity} />
                    </Grid>
                    <Grid item xs={12}>
                        <SimpleTextInput label={t('tenants.tagFragments.title')} value={title} onChange={setTitle} />
                    </Grid>
                    <Grid item xs={12}>
                        <SimpleTextInput multiline label={t('tenants.tagFragments.text')} value={text} onChange={setText} />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={handleCancel}>{t('cancel')}</Button>
                <Button variant="outlined" onClick={handleDelete}>{t('delete')}</Button>
                <Button color="primary" variant="contained" onClick={handleConfirm}>{t('confirm')}</Button>
            </DialogActions>
        </Dialog>
    );
}
