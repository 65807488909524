import {Box, Table, TableBody, TableCell, TableHead, TableRow, Typography} from "@mui/material";
import {Checkbox, TextField} from "@variocube/app-ui";
import {createElement, Fragment, useEffect, useState} from "react";
import {DeliveryFieldConfig, ReturnDeliveryConfig} from "../../domain/TenantUser";
import {useLocalization} from "../../i18n";

const defaultConfig = {enabled: true, mandatory: false, verification: false};

interface TenantReturnDeliveryConfigSettingProps {
	config: ReturnDeliveryConfig;
	onChange: (config: ReturnDeliveryConfig) => void;
}

export default function TenantReturnDeliveryConfigSetting({config, onChange}: TenantReturnDeliveryConfigSettingProps) {
	const {t} = useLocalization();

	const [enabled, setEnabled] = useState(config.enabled);
	const [returnRequiresAuthorization, setReturnRequiresAuthorization] = useState(config.returnRequiresAuthorization);
	const [authorizationProviderHint, setAuthorizationProviderHint] = useState(config.authorizationProviderHint);
	const [deliveryNoteId, setDeliveryNoteId] = useState<DeliveryFieldConfig>(
		config?.parameters?.deliveryNoteId ?? defaultConfig,
	);
	const [returnReason, setReturnReason] = useState<DeliveryFieldConfig>(
		config?.parameters?.returnReason ?? defaultConfig,
	);
	const [email, setEmail] = useState<DeliveryFieldConfig>(config?.parameters?.email ?? defaultConfig);
	const [phone, setPhone] = useState<DeliveryFieldConfig>(config?.parameters?.phone ?? defaultConfig);
	const [customerId, setCustomerId] = useState<DeliveryFieldConfig>(config?.parameters?.customerId ?? defaultConfig);
	const [positionNumber, setPositionNumber] = useState<DeliveryFieldConfig>(
		config?.parameters?.positionNumber ?? defaultConfig,
	);
	const [additionalInformation, setAdditionalInformation] = useState<DeliveryFieldConfig>(
		config?.parameters?.additionalInformation ?? defaultConfig,
	);

	const fields = [
		{field: "deliveryNoteId", value: deliveryNoteId, setter: setDeliveryNoteId},
		{field: "returnReason", value: returnReason, setter: setReturnReason},
		{field: "email", value: email, setter: setEmail},
		{field: "phone", value: phone, setter: setPhone},
		{field: "customerId", value: customerId, setter: setCustomerId},
		{field: "positionNumber", value: positionNumber, setter: setPositionNumber},
		{field: "additionalInformation", value: additionalInformation, setter: setAdditionalInformation},
	];

	useEffect(() => {
		onChange({
			enabled,
			returnRequiresAuthorization,
			authorizationProviderHint: authorizationProviderHint || undefined,
			parameters: {
				deliveryNoteId,
				returnReason,
				email,
				phone,
				customerId,
				positionNumber,
				additionalInformation,
			},
		});
	}, [
		enabled,
		returnRequiresAuthorization,
		authorizationProviderHint,
		deliveryNoteId,
		returnReason,
		email,
		phone,
		customerId,
		positionNumber,
		additionalInformation,
	]);

	return (
		<Fragment>
			<Typography variant="h5">{t("returns.config")}</Typography>
			<Box my={3} />

			<Checkbox label={t("returns.enabled")} value={enabled} onChange={setEnabled} />

			<Box my={3} />

			{enabled && (
				<Fragment>
					<Checkbox
						label={t("returns.requiresAuthorization")}
						value={returnRequiresAuthorization}
						onChange={setReturnRequiresAuthorization}
					/>
					<Box my={2} />
					<TextField
						fullWidth
						label={t("returns.authorizationHintLabel")}
						value={authorizationProviderHint ?? ""}
						onChange={setAuthorizationProviderHint}
					/>
					<Box my={2} />
					<Table size="small">
						<TableHead>
							<TableRow>
								<TableCell>Parameter</TableCell>
								<TableCell width="10%" align="center">{t("enabled")}</TableCell>
								<TableCell width="10%" align="center">{t("returns.mandatory")}</TableCell>
								<TableCell width="10%" align="center">{t("returns.verify")}</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{fields.map(({field, value, setter}, i) => (
								<TableRow key={"field-" + i}>
									<TableCell>{t("deliveries." + field as any)}</TableCell>
									<TableCell align="center">
										<Checkbox
											label={""}
											value={value.enabled}
											onChange={v => setter(current => ({...current, enabled: v}))}
										/>
									</TableCell>
									<TableCell align="center">
										<Checkbox
											label={""}
											checked={value.mandatory}
											disabled={!value.enabled}
											onChange={v => setter(current => ({...current, mandatory: v}))}
										/>
									</TableCell>
									<TableCell align="center">
										{["email", "phone"].includes(field) && (
											<Checkbox
												label={""}
												checked={value.verification}
												disabled={!value.enabled}
												onChange={v => setter(current => ({...current, verification: v}))}
											/>
										)}
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</Fragment>
			)}
		</Fragment>
	);
}
