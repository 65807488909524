import {Box, Button, Grid, Typography} from "@mui/material";
import {BreadcrumbItem, Breadcrumbs} from "@variocube/app-ui";
import {NotFound} from "@variocube/app-ui/esm/layout/NotFound";
import {ContainerLayout} from "@variocube/app-ui";
import {createElement, Fragment, useMemo, useState} from "react";
import {useAsync} from "react-async-hook";
import {useNavigate} from "react-router";
import {Link} from "react-router-dom";
import {Delivery, DeliveryType} from "../../domain/Delivery";
import {ShipmentsProvider} from "../../domain/ShipmentsProvider";
import {useLocalization} from "../../i18n";
import {gs} from "../../theme";
import {useRequiredParam} from "../../useRequiredParam";
import {BoxedPaper} from "../BoxedPaper";
import {BreadcrumbRouterLink} from "../BreadcrumbRouterLink";
import {ConfirmDialog} from "../ConfirmDialog";
import {DateComponent} from "../DateComponent";
import {DeliveryIdDisplay} from "../deliveries/DeliveryIdDisplay";
import {DeliveryStateDisplay} from "../deliveries/DeliveryStateDisplay";
import {LabeledItem} from "../LabeledItem";
import {useTenantId, useTenantUserRole} from "../TenantContextProvider";

export const ShipmentView = () => {
	const shipmentUuid = useRequiredParam("shipmentUuid");
	const {t} = useLocalization();
	const tenantId = useTenantId();
	const navigate = useNavigate();

	const [showDeleteDialog, setShowDeleteDialog] = useState(false);

	const {result: summary, error} = useAsync(() => ShipmentsProvider.get(tenantId, shipmentUuid), [tenantId, shipmentUuid]);

	const label = useMemo(() => {
		return (summary && summary.shipment.description) ? summary.shipment.description : "...";
	}, [summary]);

	const {shipment, outboundDelivery, inboundDelivery} = useMemo(() => summary || {} as any, [summary]);
	let {isRecipient, isIt} = useTenantUserRole();

	if (error) {
		return (
			<NotFound errorMsg={t("notFound.title")} backToHomeMsg={t("notFound.home")} pathMsg={t("notFound.info")} />
		);
	}

	async function handleDelete() {
		try {
			await ShipmentsProvider.delete(tenantId, shipmentUuid);
			navigate(`/${tenantId}/shipments/`);
		} catch (err) {
			console.error("failed to delete shipment");
		}
	}

	const renderDelivery = (delivery: Delivery) => {
		return (
			<BoxedPaper>
				<Grid container spacing={gs}>
					<Grid item xs={12}>
						<Typography variant="h6">
							{delivery.delivery.deliveryType === DeliveryType.Outbound
								&& (t("deliveries.deliveryTypes.Outbound"))}
							{delivery.delivery.deliveryType === DeliveryType.Inbound
								&& (t("deliveries.deliveryTypes.Inbound"))}
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Grid container spacing={gs}>
							<LabeledItem label={t("deliveries.deliveryState")}>
								<DeliveryStateDisplay deliveryState={delivery.delivery.state} />
							</LabeledItem>
							<Box mx={2} />
							<LabeledItem label={t("deliveries.deliveryId")}>
								<DeliveryIdDisplay id={delivery.id} />
							</LabeledItem>
							<Box mx={2} />
							<LabeledItem label={t("deliveries.createdAt")}>
								<DateComponent date={delivery.delivery.createdAt} />
							</LabeledItem>
							{delivery.delivery.storedAt && (
								<Fragment>
									<Box mx={2} />
									<LabeledItem label={t("deliveries.storedAt")}>
										<DateComponent date={delivery.delivery.storedAt} />
									</LabeledItem>
								</Fragment>
							)}
							{delivery.delivery.storedBy && (
								<Fragment>
									<Box mx={2} />
									<LabeledItem label={t("deliveries.storedBy")}>
										<span>{delivery.delivery.storedBy}</span>
									</LabeledItem>
								</Fragment>
							)}
							{delivery.delivery.pickedUpAt && (
								<Fragment>
									<Box mx={2} />
									<LabeledItem label={t("deliveries.pickedUpAt")}>
										<DateComponent date={delivery.delivery.pickedUpAt} />
									</LabeledItem>
								</Fragment>
							)}
							{delivery.delivery.pickedUpBy && (
								<Fragment>
									<Box mx={2} />
									<LabeledItem label={t("deliveries.pickedUpBy")}>
										<span>{delivery.delivery.pickedUpBy}</span>
									</LabeledItem>
								</Fragment>
							)}
						</Grid>
					</Grid>
					<Grid item xs={12}>
						<Button
							variant="contained"
							color="primary"
							component={Link}
							to={`/${tenantId}/deliveries/${delivery.id}`}
						>
							{t("view")}
						</Button>
					</Grid>
				</Grid>
			</BoxedPaper>
		);
	};

	return (
		<ContainerLayout>
			<Box display="block" displayPrint="none">
				<Grid container spacing={3}>
					<Grid item xs={12}>
						<Breadcrumbs>
							<BreadcrumbRouterLink to={`/${tenantId}/shipments`}>
								{t("shipments.plural")}
							</BreadcrumbRouterLink>
							<BreadcrumbItem>{label}</BreadcrumbItem>
						</Breadcrumbs>
					</Grid>
					<Grid item xs={12}>
						<Grid container>
							<Grid item style={{flexGrow: 1}}>
								<Typography variant="h2">{t("shipments.singular")}: {label}</Typography>
							</Grid>
							{!isRecipient && !isIt
								&& (
									<Grid item>
										<Button onClick={() => setShowDeleteDialog(true)}>
											{t("delete")}
										</Button>
									</Grid>
								)}
						</Grid>
					</Grid>
					{shipment && (
						<Grid item xs={12}>
							<BoxedPaper>
								<Grid container spacing={gs}>
									<Grid item>
										<LabeledItem label={t("deliveries.createdAt")}>
											<DateComponent date={shipment.createdAt} />
										</LabeledItem>
									</Grid>
								</Grid>
							</BoxedPaper>
						</Grid>
					)}
					{outboundDelivery && (
						<Grid item xs={12}>
							{renderDelivery(outboundDelivery)}
						</Grid>
					)}
					{inboundDelivery && (
						<Grid item xs={12}>
							{renderDelivery(inboundDelivery)}
						</Grid>
					)}
				</Grid>
			</Box>
			<ConfirmDialog
				open={showDeleteDialog}
				title={t("delete")}
				message={t("shipments.delete.prompt")}
				reject={() => setShowDeleteDialog(false)}
				resolve={handleDelete}
			/>
		</ContainerLayout>
	);
};
