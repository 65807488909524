export function pad(num: number, size: number) {
	var s = "000000000" + num;
	return s.substr(s.length - size);
}

export function shorten(value: string, maxLen: number, end?: string): string {
	if (value.length <= maxLen) {
		return value;
	}
	if (!end) end = " ...";
	const keep = value.substr(0, maxLen - end.length);
	return `${keep}${end}`;
}

export function enumKeys<E extends object>(e: E): (keyof E)[] {
	return Object.keys(e) as (keyof E)[];
}

export function guessCountry(): string {
	const lang = navigator.language;
	if (lang) {
		const components = lang.split("-");
		if (components.length == 2) {
			return components[1].toUpperCase();
		} else {
			return lang.toUpperCase();
		}
	}
	return "AT";
}

export function parseNumberResilient(input?: string): number {
	if (!input) {
		return 0;
	}
	return parseFloat(input.replace(/,/g, "."));
}

export function isEmailLocalPart(localPart: string): boolean {
	const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))$/;
	return re.test(localPart.toLowerCase());
}

export function isEmail(email: string): boolean {
	const re =
		/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(email.toLowerCase());
}

export function isAnyEnum<E>(e: E, ...values: E[]): boolean {
	return values.includes(e);
}
