import {Alert} from "@mui/lab";
import {Box, Grid, MenuItem, Paper, Snackbar, Typography} from "@mui/material";
import {BreadcrumbItem, Breadcrumbs} from "@variocube/app-ui";
import {ContainerLayout} from "@variocube/app-ui";
import {NotFound} from "@variocube/app-ui/esm/layout/NotFound";
import {createElement, useCallback, useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router";
import {CubeBox, CubeDetails, ReserveBoxFallback} from "../../domain/Cube";
import {CubesProvider} from "../../domain/CubesProvider";
import {DeliveriesProvider} from "../../domain/DeliveriesProvider";
import {Site} from "../../domain/Site";
import {SitesProvider} from "../../domain/SitesProvider";
import {useLocalization} from "../../i18n";
import {gs} from "../../theme";
import {BooleanDisplay} from "../BooleanDisplay";
import {BreadcrumbRouterLink} from "../BreadcrumbRouterLink";
import {CodeDisplay} from "../deliveries/CodeDisplay";
import {HelmetTitleWrapper} from "../HelmetTitleWrapper";
import {LabeledData} from "../LabeledData";
import {LabeledItem} from "../LabeledItem";
import {Loading} from "../Loading";
import {PushButtonWithMenu} from "../PushButtonWithMenu";
import {useTenantId} from "../TenantContextProvider";
import {BoxesFallbackTypesInput} from "./BoxesFallbackTypesInput";
import {BoxesList} from "./BoxesList";
import {CubeConnectedComponent} from "./CubeConnectedComponent";
import {CubeSettingsDialog} from "./CubeSettingsDialog";
import {CubeUtilizationComponent} from "./CubeUtilizationComponent";

export function CubeView() {
	const {t} = useLocalization();
	const navigate = useNavigate();
	const {cubeId} = useParams<"cubeId">();
	const tenantId = useTenantId();
	const [cube, setCube] = useState<CubeDetails>();
	const [boxes, setBoxes] = useState<CubeBox[]>([]);
	const [site, setSite] = useState<Site>();
	const [availableBoxTypes, setAvailableBoxTypes] = useState<string[]>([]);
	const [editSettings, setEditSettings] = useState<boolean>(false);
	const [fallbacks, setFallbacks] = useState<ReserveBoxFallback[]>();
	const [saved, setSaved] = useState<boolean>(false);
	const [cubeNotFound, setCubeNotFound] = useState<boolean>(false);

	useEffect(() => {
		if (tenantId && cubeId) {
			CubesProvider.get(tenantId, cubeId)
				.then((cuby) => {
					setCube(cuby);
					if (cuby && cuby.settings && cuby.settings.siteId) {
						SitesProvider.get(tenantId, cuby.settings.siteId).then(setSite);
					}
					CubesProvider.getBoxes(tenantId, cubeId).then(boxes => {
						getDeliveriesForBoxes(boxes);
						setBoxes(boxes);
						let allTypes: string[] = [];
						boxes.map(x => allTypes.push(...x.types));
						let types = new Set(allTypes);
						setAvailableBoxTypes(Array.from(types));
					});
					try {
						let fallbacks = JSON.parse(cuby.settings.reserveBoxFallbacks);
						setFallbacks(fallbacks);
					} catch (ex) {
						setFallbacks([]);
						console.log("cannot parse fallbacks");
					}
				})
				.catch((e) => {
					console.log(e);
					setCubeNotFound(true);
				});
		}
	}, [tenantId, cubeId]);

	function getDeliveriesForBoxes(boxes: CubeBox[]) {
		boxes?.forEach(cubebox => {
			cubebox.occupancies?.forEach(occupancy => {
				occupancy.deliveries = [];
				occupancy.deliveryIds?.forEach(deliveryId => {
					DeliveriesProvider.get(tenantId, deliveryId)
						.then((delivery) => {
							occupancy.deliveries?.push(delivery);
							setBoxes([]);
							setBoxes(boxes);
						})
						.catch(() => {
							console.error("cannot get deliveries for deliveryId=", deliveryId);
						});
				});
			});
		});
	}

	const handleCloseSettings = useCallback((updatedCube?: CubeDetails) => {
		if (tenantId && updatedCube) {
			setCube(updatedCube);
			SitesProvider.get(tenantId, updatedCube?.settings.siteId || "").then(setSite);
		}
		setEditSettings(false);
	}, []);

	function onFallbackSave(fallback: string) {
		if (tenantId) {
			CubesProvider.updateSettings(tenantId, cube?.hostname || "", {
				supportsBarcodeReader: cube?.settings.supportsBarcodeReader || false,
				checkPickedUpDeliveries: cube?.settings.checkPickedUpDeliveries || false,
				siteId: cube?.settings.siteId || "",
				reserveBoxFallbacks: fallback,
			})
				.then((updateCube) => setSaved(true));
		}
	}

	function onFallbackCancel() {
		if (tenantId && cubeId) {
			CubesProvider.get(tenantId, cubeId)
				.then(cube => {
					try {
						let fallbacks = JSON.parse(cube.settings.reserveBoxFallbacks);
						setFallbacks(fallbacks);
					} catch (ex) {
						setFallbacks([]);
						console.log("cannot parse fallbacks");
					}
				});
		}
	}

	if (cubeNotFound) {
		return (
			<NotFound errorMsg={t("notFound.title")} backToHomeMsg={t("notFound.home")} pathMsg={t("notFound.info")} />
		);
	}

	if (cube) {
		return (
			<ContainerLayout>
				<HelmetTitleWrapper pageTitle={cube.name} />
				<Grid container spacing={gs}>
					<Grid item xs={12}>
						<Breadcrumbs>
							<BreadcrumbRouterLink to={`/${tenantId}/cubes`}>{t("cubes.plural")}</BreadcrumbRouterLink>
							<BreadcrumbItem>{cube.name}</BreadcrumbItem>
						</Breadcrumbs>
					</Grid>
					<Grid item xs={12}>
						<Grid container spacing={gs}>
							<Grid item style={{flexGrow: 1}}>
								<Typography variant="h2">{cube.name}</Typography>
							</Grid>
							<Grid item>
								<PushButtonWithMenu
									label={t("taskList.title")}
									onClick={() => navigate(`/${tenantId}/cubes/${cube.hostname}/tasklist`)}
								>
									<MenuItem onClick={() => setEditSettings(true)}>{t("edit")}</MenuItem>
								</PushButtonWithMenu>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={12}>
						<CubeHeader cube={cube} site={site} />
					</Grid>
					<Grid item xs={12}>
						<Paper>
							<Box p={gs}>
								{fallbacks && (
									<BoxesFallbackTypesInput
										boxTypes={availableBoxTypes}
										onSave={onFallbackSave}
										fallbacks={fallbacks}
										onCancel={onFallbackCancel}
									/>
								)}
							</Box>
						</Paper>
					</Grid>
					<Grid item xs={12}>
						<BoxesList cube={cube} boxes={boxes} />
					</Grid>
				</Grid>
				<CubeSettingsDialog
					open={editSettings}
					cube={cube}
					onClose={(cubeDetails) => handleCloseSettings(cubeDetails)}
				/>
				<Snackbar open={saved} autoHideDuration={3000} onClose={() => setSaved(false)}>
					<Alert severity="success">{t("saved")}</Alert>
				</Snackbar>
			</ContainerLayout>
		);
	} else {
		return <Loading />;
	}
}

interface CubeHeaderProps {
	cube: CubeDetails;
	site?: Site;
}

function CubeHeader(props: CubeHeaderProps) {
	const {cube, site} = props;
	const {t} = useLocalization();

	return (
		<Paper>
			<Box p={gs}>
				<Grid container spacing={gs}>
					<Grid item>
						<LabeledData label={t("cubes.connectionState")}>
							<CubeConnectedComponent connected={cube.connected} />
						</LabeledData>
					</Grid>
					<Grid item>
						<LabeledData label={t("cubes.supportsBarcodeReader")}>
							<BooleanDisplay value={cube.settings.supportsBarcodeReader} />
						</LabeledData>
					</Grid>
					<Grid item>
						<LabeledData label={t("cubes.checkPickedUpDeliveries")}>
							<BooleanDisplay value={cube.settings.checkPickedUpDeliveries} />
						</LabeledData>
					</Grid>
					<Grid item>
						<LabeledData label={t("sites.singular")}>
							{site?.name}
						</LabeledData>
					</Grid>
					<Grid item style={{flexGrow: 1}}>
						<LabeledData label={t("cubes.utilization.label")}>
							<CubeUtilizationComponent utilization={cube.utilization} />
						</LabeledData>
					</Grid>
				</Grid>
				{cube.accessCodes
					&& (
						<Grid container spacing={gs}>
							<LabeledItem label={t("cubes.accessCodes.readonly")}>
								<CodeDisplay code={cube.accessCodes.readOnly} />
							</LabeledItem>
							<LabeledItem label={t("cubes.accessCodes.openNonOccupied")}>
								<CodeDisplay code={cube.accessCodes.openNonOccupied} />
							</LabeledItem>
							<LabeledItem label={t("cubes.accessCodes.fullAccess")}>
								<CodeDisplay code={cube.accessCodes.fullAccess} />
							</LabeledItem>
						</Grid>
					)}
			</Box>
		</Paper>
	);
}
