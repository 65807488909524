import * as React from "react";
import { createElement } from 'react';
import {IconButton} from "@mui/material";
import {Attachment} from "@mui/icons-material";

interface SimpleFileSelectProps {
    onFile: (file: File) => void;
    size?: 'small' | 'medium';
}

interface SimpleFileUploadState {
}

export class SimpleFileSelect extends React.Component<SimpleFileSelectProps, SimpleFileUploadState> {

    fileUploadInput: React.RefObject<HTMLInputElement>;

    constructor(props: SimpleFileSelectProps) {
        super(props);
        this.state = {
            fileUploads: []
        };
        this.fileUploadInput = React.createRef();
    }

    private handleFileSelect() {
        if(this.fileUploadInput && this.fileUploadInput.current) {
            this.fileUploadInput.current.click();
        }
    }

    private handleFileUpload(files: FileList) {
        for(let i=0; i<files.length; i++) {
            const f = files.item(i);
            this.props.onFile(f!);
        }
    }

    render() {
        const {t} = this.context;
        const {size} = this.props;
        return(
            <div>
                <div style={{ display: 'none' }}>
                    <input type="file"
                           onChange={(e) => { if(e.target.files) this.handleFileUpload(e.target.files); }}
                           ref={this.fileUploadInput}
                    />
                </div>
                <IconButton size={size} onClick={() => this.handleFileSelect()}>
                    <Attachment />
                </IconButton>
            </div>
        );
    }

}
