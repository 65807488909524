import {ExpandMore} from "@mui/icons-material";
import {Button, ButtonGroup, Menu} from "@mui/material";
import * as React from "react";
import {createElement, PropsWithChildren, useState} from "react";
import {OverridableStringUnion} from "@mui/types";
import {ButtonPropsVariantOverrides} from "@mui/material/Button/Button";

interface PushButtonWithMenuProps extends PropsWithChildren<any> {
	label: string;
	onClick: () => void;
	disabled?: boolean;
	size?: "small" | "medium" | "large";
	variant?: OverridableStringUnion<
		'text' | 'outlined' | 'contained',
		ButtonPropsVariantOverrides
	>;
}

export function PushButtonWithMenu(props: PushButtonWithMenuProps) {
	const {label, onClick, disabled, size, variant, children} = props;

	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

	return (
		<React.Fragment>
			<ButtonGroup size={size} variant={variant}>
				<Button disabled={disabled} onClick={onClick}>{label}</Button>
				<Button disabled={disabled} size="small" onClick={(e) => setAnchorEl(e.currentTarget)}>
					<ExpandMore />
				</Button>
			</ButtonGroup>
			{children
				&& (
					<Menu
						open={Boolean(anchorEl)}
						anchorEl={anchorEl}
						onClose={() => setAnchorEl(null)}
						keepMounted
						onClick={() => setAnchorEl(null)}
						anchorOrigin={{vertical: "bottom", horizontal: "center"}}
						transformOrigin={{vertical: "top", horizontal: "center"}}
					>
						{children}
					</Menu>
				)}
		</React.Fragment>
	);
}
