import {createElement} from "react";
import {Hidden} from "@mui/material";
import * as React from "react";
import {VCLogoIcon, VCLogo as Logo} from "@variocube/app-ui";

export function VCLogo() {
    return (
        <React.Fragment>
            <Hidden lgDown>
                <Logo height="3em"/>
            </Hidden>
            <Hidden lgUp>
                <VCLogoIcon height="3em"/>
            </Hidden>
        </React.Fragment>
    )
}
