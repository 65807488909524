import * as React from "react";
import {createElement, useCallback, useEffect, useState} from "react";
import {CubeDetails} from "../../domain/Cube";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, LinearProgress} from "@mui/material";
import {gs} from "../../theme";
import {SimpleCheckBox} from "../SimpleCheckBox";
import {CubesProvider} from "../../domain/CubesProvider";
import {SiteSelectInput} from "../SiteSelectInput";
import {Site} from "../../domain/Site";
import {useLocalization} from "../../i18n";
import {useTenantId} from "../TenantContextProvider";

interface CubeSettingsDialogProps {
    open: boolean;
    cube: CubeDetails;
    onClose: (provide?: CubeDetails) => void;
}

export function CubeSettingsDialog({open, cube, onClose}: CubeSettingsDialogProps) {
    const {t} = useLocalization();
    const tenantId = useTenantId();
    const [supportsBarcodeReader, setSupportsBarcodeReader] = useState<boolean>(cube.settings.supportsBarcodeReader);
	const [checkPickedUpDeliveries, setCheckPickedUpDeliveries] = useState<boolean>(cube.settings.checkPickedUpDeliveries);
    const [siteId, setSiteId] = useState<string>(cube.settings.siteId);
    const [submitting, setSubmitting] = useState<boolean>(false);

    useEffect(() => {
        setSupportsBarcodeReader(cube.settings.supportsBarcodeReader);
    }, [cube]);

    const handleSave = useCallback(() => {
        setSubmitting(true);
        CubesProvider.updateSettings(tenantId, cube.hostname, {
                supportsBarcodeReader: supportsBarcodeReader,
				checkPickedUpDeliveries: checkPickedUpDeliveries,
				siteId: siteId,
                reserveBoxFallbacks: cube.settings.reserveBoxFallbacks
            })
            .then((updateCube) => onClose(updateCube))
            .finally(() => setSubmitting(false));
    }, [tenantId, cube, onClose, supportsBarcodeReader, checkPickedUpDeliveries, siteId]);

    function onSiteChanged(newSite?: Site) {
        if (newSite) {
            setSiteId(newSite?.siteId);
        }
    }

    return (
        <Dialog open={open} onClose={() => onClose()}>
            <DialogTitle>Einstellungen bearbeiten</DialogTitle>
            <DialogContent>
                <Grid container spacing={gs}>
                    <Grid item xs={12}>
                        <SimpleCheckBox
                            label={t("cubes.supportsBarcodeReader")}
                            checked={supportsBarcodeReader}
                            onChange={setSupportsBarcodeReader}
                        />
                    </Grid>
					<Grid item xs={12}>
						<SimpleCheckBox
							label={t("cubes.checkPickedUpDeliveries")}
							checked={checkPickedUpDeliveries}
							onChange={setCheckPickedUpDeliveries}
						/>
					</Grid>
					<Grid item xs={12}>
                        <SiteSelectInput value={siteId} onChange={onSiteChanged} autoSelect={false}/>
					</Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={() => onClose()}>{t("cancel")}</Button>
                <Button color="primary" variant="contained" onClick={handleSave}>{t("confirm")}</Button>
            </DialogActions>
            {submitting && <LinearProgress variant="indeterminate"/>}
        </Dialog>
    );
}
