import * as React from "react";
import {createElement} from "react";
import {DeliveryCondition} from "../../domain/Delivery";
import {Box, Chip, Typography} from "@mui/material";
import {green, orange, red, teal} from "@mui/material/colors";
import {makeStyles} from "@mui/styles";
import {useLocalization} from "../../i18n";

interface DeliveryConditionDisplayProps {
    value: DeliveryCondition;
    size?: 'small' | 'medium';
}

export function DeliveryConditionDisplay({value, size}: DeliveryConditionDisplayProps) {
    const classes = useStyles();
    const className = classes[value];
    const {e} = useLocalization();
    return (
        <React.Fragment>
            <Box display="block" displayPrint="none">
                <Chip label={e('deliveries.conditions', value)} className={className} size={size} />
            </Box>
            <Box display="none" displayPrint="block">
                <Typography variant="body1">{e('deliveries.conditions', value)}</Typography>
            </Box>
        </React.Fragment>
    );
}

const useStyles = makeStyles(theme => ({
    Intact: {
        backgroundColor: green[500]
    },
    Opened: {
        backgroundColor: teal[500]
    },
    Damaged: {
        backgroundColor: orange[500]
    },
    Destroyed: {
        backgroundColor: red[500]
    }
}));
