import {ClearRounded} from "@mui/icons-material";
import {
	Box,
	Button,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider,
	FormControl,
	FormControlLabel,
	FormGroup,
	FormLabel,
	Grid,
	Stack,
} from "@mui/material";
import {PlainDateTime, PlainDateTimePicker, ZonedDateTime} from "@variocube/app-ui";
import * as React from "react";
import {createElement, useCallback, useEffect, useMemo, useState} from "react";
import {DeliveriesProvider} from "../../domain/DeliveriesProvider";
import {
	CubeSummary,
	DeliveryCondition,
	DeliveryFilterTimeframe,
	DeliveryState,
	DeliveryType,
} from "../../domain/Delivery";
import {Site} from "../../domain/Site";
import {useLocalization} from "../../i18n";
import {gs} from "../../theme";
import {CubeSelectInput} from "../CubeSelectInput";
import {SimpleButton} from "../SimpleButton";
import {SimpleTextInput} from "../SimpleTextInput";
import {SitesSelectInput} from "../SitesSelectInput";
import {useTenantId, useTenantUserRole} from "../TenantContextProvider";
import {DeliveryTags} from "./DeliveryTags";

const STATES = [
	DeliveryState.Created,
	DeliveryState.Reserved,
	DeliveryState.Stored,
	DeliveryState.PickedUp,
	DeliveryState.Cancelled,
	DeliveryState.ManualHandover,
	DeliveryState.Shipped,
	DeliveryState.Draft,
];
const TYPES = [DeliveryType.Inbound, DeliveryType.Outbound];
const CONDITIONS = [
	DeliveryCondition.Intact,
	DeliveryCondition.Opened,
	DeliveryCondition.Damaged,
	DeliveryCondition.Destroyed,
];
const TIMEZONE = Intl.DateTimeFormat().resolvedOptions().timeZone;

interface DeliveryFilterDialogProps {
	open: boolean;
	onClose: () => void;
	onApplyFilter: () => void;
	needle: string;
	onNeedleChanged: (needle: string) => void;
	sites: Site[];
	selectedSiteIds: string[];
	onSitesSelected: (sites: Site[]) => void;
	cubes: CubeSummary[];
	selectedCubeIds: string[];
	onCubesSelected: (cubes: string[]) => void;
	selectedStates: DeliveryState[];
	selectedTypes: DeliveryType[];
	onStateSelected: (state: DeliveryState, selected: boolean) => void;
	onTypeSelected: (type: DeliveryType, selected: boolean) => void;
	selectedConditions: DeliveryCondition[];
	onConditionSelected: (condition: DeliveryCondition, selected: boolean) => void;
	creationTimeframe: DeliveryFilterTimeframe;
	storageTimeframe: DeliveryFilterTimeframe;
	pickedUpTimeframe: DeliveryFilterTimeframe;
	onCreationTimeframeChanged: (timeframe: DeliveryFilterTimeframe) => void;
	onStorageTimeframeChanged: (timeframe: DeliveryFilterTimeframe) => void;
	onPickedUpTimeframeChanged: (timeframe: DeliveryFilterTimeframe) => void;
	onFilterReset: () => void;
	tags: string[];
	onTags: (tags: string[]) => void;
	onStoredOverdue: () => void;
}

export function DeliveryFilterDialog(props: DeliveryFilterDialogProps) {
	const {t} = useLocalization();
	const tenantId = useTenantId();  

	const [learnedTags, setLearnedTags] = useState<string[]>();

	const [creationTimeFrom, setCreationTimeFrom] = useState(
		props.creationTimeframe.from ? ZonedDateTime.from(props.creationTimeframe.from).toPlainDateTime() : null,
	);
	const [creationTimeUntil, setCreationTimeUntil] = useState(
		props.creationTimeframe.until ? ZonedDateTime.from(props.creationTimeframe.until).toPlainDateTime() : null,
	);
	const [storageTimeFrom, setStorageTimeFrom] = useState(
		props.storageTimeframe.from ? ZonedDateTime.from(props.storageTimeframe.from).toPlainDateTime() : null,
	);
	const [storageTimeUntil, setStorageTimeUntil] = useState(
		props.storageTimeframe.until ? ZonedDateTime.from(props.storageTimeframe.until).toPlainDateTime() : null,
	);
	const [pickedUpTimeFrom, setPickedUpTimeFrom] = useState(
		props.pickedUpTimeframe.from ? ZonedDateTime.from(props.pickedUpTimeframe.from).toPlainDateTime() : null,
	);
	const [pickedUpTimeUntil, setPickedUpTimeUntil] = useState(
		props.pickedUpTimeframe.until ? ZonedDateTime.from(props.pickedUpTimeframe.until).toPlainDateTime() : null,
	);

	useEffect(() => {
		DeliveriesProvider.computeDeliveryLearnedTags(tenantId).then(setLearnedTags);
	}, []);

	const handleNeedleChange = useCallback((value: string) => {
		props.onNeedleChanged(value);
	}, [props.onNeedleChanged]);

	const handleCreationTimeframeChange = (name: "from" | "until", value: PlainDateTime | null) => {
		if (value) {
			switch (name) {
				case "from":
					setCreationTimeFrom(value);
					break;
				case "until":
					setCreationTimeUntil(value);
					break;
			}
			props.onCreationTimeframeChanged({
				...{
					from: convertPlainDateTime(creationTimeFrom),
					until: convertPlainDateTime(creationTimeUntil),
				},
				[name]: convertPlainDateTime(value),
			});
		}
	};

	const handleStorageTimeframeChange = (name: "from" | "until", value: PlainDateTime | null) => {
		if (value) {
			switch (name) {
				case "from":
					setStorageTimeFrom(value);
					break;
				case "until":
					setStorageTimeUntil(value);
					break;
			}
			props.onStorageTimeframeChanged({
				...{
					from: convertPlainDateTime(storageTimeFrom),
					until: convertPlainDateTime(storageTimeUntil),
				},
				[name]: convertPlainDateTime(value),
			});
		}
	};

	const handlePickedUpTimeframeChange = (name: "from" | "until", value: PlainDateTime | null) => {
		if (value) {
			switch (name) {
				case "from":
					setPickedUpTimeFrom(value);
					break;
				case "until":
					setPickedUpTimeUntil(value);
					break;
			}
			props.onPickedUpTimeframeChanged({
				...{
					from: convertPlainDateTime(pickedUpTimeFrom),
					until: convertPlainDateTime(pickedUpTimeUntil),
				},
				[name]: convertPlainDateTime(value),
			});
		}
	};

	const handleCreationTimeframeReset = () => {
		setCreationTimeFrom(null);
		setCreationTimeUntil(null);
		props.onCreationTimeframeChanged({});
	};

	const handleStorageTimeframeReset = () => {
		setStorageTimeFrom(null);
		setStorageTimeUntil(null);
		props.onStorageTimeframeChanged({});
	};

	const handlePickedUpTimeframeReset = () => {
		setPickedUpTimeFrom(null);
		setPickedUpTimeUntil(null);
		props.onPickedUpTimeframeChanged({});
	};

	const convertPlainDateTime = (plainDateTime: PlainDateTime | null) => {
		return plainDateTime
			? plainDateTime.round("minutes").toZonedDateTime(TIMEZONE).toString()
			: undefined;
	};

	const handleResetFilter = () => {
		setCreationTimeFrom(null);
		setCreationTimeUntil(null);
		setStorageTimeFrom(null);
		setStorageTimeUntil(null);
		setPickedUpTimeFrom(null);
		setPickedUpTimeUntil(null);
		props.onFilterReset();
	};

	const selectedCubes = useMemo<CubeSummary[]>(() => {
		const selectedCubes: CubeSummary[] = [];
		if (props.selectedCubeIds && props.cubes) {
			for (const cubeId of props.selectedCubeIds) {
				const cube = props.cubes.find((c) => c.hostname === cubeId);
				if (cube) {
					selectedCubes.push(cube);
				}
			}
		}
		return selectedCubes;
	}, [props.selectedCubeIds, props.cubes]);

	const selectedSites = useMemo<Site[]>(() => {
		const selectedSites: Site[] = [];
		if (props.selectedSiteIds && props.sites) {
			for (const siteId of props.selectedSiteIds) {
				const site = props.sites.find((s) => s.siteId === siteId);
				if (site) {
					selectedSites.push(site);
				}
			}
		}
		return selectedSites;
	}, [props.selectedSiteIds, props.sites]);

	const handleCubesSelected = useCallback((cubes: CubeSummary[]) => {
		props.onCubesSelected(cubes.map((c) => c.hostname));
	}, [props.onCubesSelected]);

	const handleSitesSelected = useCallback((sites: Site[]) => {
		props.onSitesSelected(sites);
	}, [props.onSitesSelected]);

	const handleCheckEnter = useCallback((key: string) => {
        if(key === 'Enter' && props.onApplyFilter) {
            props.onApplyFilter();
        }
        if(key === 'Escape' && props.onClose) {
            props.onClose();
        }
    }, [props]);

	return (
		<Dialog open={props.open} onClose={props.onClose} maxWidth="lg" fullWidth onKeyPress={(e) => handleCheckEnter(e.key)}>
			<DialogTitle>
				{t("filter")}
			</DialogTitle>
			<DialogContent>
				<Box p={1} />
				<Grid container spacing={gs}>
					<Grid item xs={12} sm={6}>
						<SimpleTextInput
							label={t("fulltextSearch")}
							value={props.needle}
							onChange={handleNeedleChange}
							onEnter={() => props.onApplyFilter()}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<DeliveryTags
							label={t("deliveries.tags")}
							placeholder={t("deliveries.filterTag")}
							options={learnedTags}
							tags={props.tags}
							onChange={props.onTags}
						/>
					</Grid>
					<Grid item xs={12}>
						<Divider />
					</Grid>
					<Grid item xs={12}>
						<Grid container spacing={gs}>
							<Grid item xs={12} sm={6}>
								{props.cubes
									&& (
										<CubeSelectInput
											label={t("filterCubes")}
											options={props.cubes}
											values={selectedCubes}
											onChange={handleCubesSelected}
											onEnter={() => props.onApplyFilter()}
										/>
									)}
							</Grid>
							<Grid item xs={12} sm={6}>
								{props.sites && (
									<SitesSelectInput
										label={t("filterSites")}
										options={props.sites}
										values={selectedSites}
										onChange={handleSitesSelected}
									/>
								)}
							</Grid>
							<Grid item xs={12}>
								<Divider />
							</Grid>
						</Grid>
						<Box p={2} />
						<Stack spacing={gs}>
							<FormControl component="fieldset" variant="standard">
								<FormLabel>{t("filterStates")}</FormLabel>
								<FormGroup row>
									{STATES.map((state) => (
										<StateCheckbox
											key={state}
											state={state}
											checked={props.selectedStates.filter((s) => s === state).length > 0}
											onChecked={props.onStateSelected}
										/>
									))}
								</FormGroup>
							</FormControl>
							<Stack direction="row" spacing={gs}>
								<FormControl component="fieldset" variant="standard">
									<FormLabel>{t("deliveries.condition")}</FormLabel>
									<FormGroup row>
										{CONDITIONS.map((condition) => (
											<ConditionCheckbox
												key={condition}
												condition={condition}
												checked={props.selectedConditions.filter((c) => c === condition).length > 0}
												onChecked={props.onConditionSelected}
											/>
										))}
									</FormGroup>
								</FormControl>
								<FormControl component="fieldset" variant="standard">
									<FormLabel>{t("deliveries.deliveryType")}</FormLabel>
									<FormGroup row>
										{TYPES.map((type) => (
											<TypeCheckbox
												key={type}
												type={type}
												checked={props.selectedTypes.filter((s) => s === type).length > 0}
												onChecked={props.onTypeSelected}
											/>
										))}
									</FormGroup>
								</FormControl>
							</Stack>
							<FormControl component="fieldset" variant="standard">
								<FormLabel>{t("deliveries.timeframe.create")}</FormLabel>
								<Grid container spacing={2} sx={{alignItems: "center"}}>
									<Grid item xs={4}>
										<PlainDateTimePicker
											fullWidth
											value={creationTimeFrom}
											onChange={dt => handleCreationTimeframeChange("from", dt)}
										/>
									</Grid>
									<Grid item xs={4}>
										<PlainDateTimePicker
											fullWidth
											value={creationTimeUntil}
											onChange={dt => handleCreationTimeframeChange("until", dt)}
										/>
									</Grid>
									<Grid item xs={4}>
										<Button
											variant="outlined"
											startIcon={<ClearRounded />}
											onClick={handleCreationTimeframeReset}
										>
											{t("reset")}
										</Button>
									</Grid>
								</Grid>
							</FormControl>

							<FormControl component="fieldset" variant="standard">
								<FormLabel>{t("deliveries.timeframe.storage")}</FormLabel>
								<Grid container spacing={2} sx={{alignItems: "center"}}>
									<Grid item xs={4}>
										<PlainDateTimePicker
											fullWidth
											value={storageTimeFrom}
											onChange={dt => handleStorageTimeframeChange("from", dt)}
										/>
									</Grid>
									<Grid item xs={4}>
										<PlainDateTimePicker
											fullWidth
											value={storageTimeUntil}
											onChange={dt => handleStorageTimeframeChange("until", dt)}
										/>
									</Grid>
									<Grid item xs={4}>
										<Button
											variant="outlined"
											startIcon={<ClearRounded />}
											onClick={handleStorageTimeframeReset}
										>
											{t("reset")}
										</Button>
									</Grid>
								</Grid>
							</FormControl>

							<FormControl component="fieldset" variant="standard">
								<FormLabel>{t("deliveries.timeframe.pickedUp")}</FormLabel>
								<Grid container spacing={2} sx={{alignItems: "center"}}>
									<Grid item xs={4}>
										<PlainDateTimePicker
											fullWidth
											value={pickedUpTimeFrom}
											onChange={dt => handlePickedUpTimeframeChange("from", dt)}
										/>
									</Grid>
									<Grid item xs={4}>
										<PlainDateTimePicker
											fullWidth
											value={pickedUpTimeUntil}
											onChange={dt => handlePickedUpTimeframeChange("until", dt)}
										/>
									</Grid>
									<Grid item xs={4}>
										<Button
											variant="outlined"
											startIcon={<ClearRounded />}
											onClick={handlePickedUpTimeframeReset}
										>
											{t("reset")}
										</Button>
									</Grid>
								</Grid>
							</FormControl>
						</Stack>
					</Grid>
					<Grid item xs={12}>
						<Divider />
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions style={{justifyContent: "space-between"}}>
				<Grid container spacing={1}>
					<Grid item>
						<SimpleButton
							label={t("deliveries.filters.quickFilters.overdue")}
							onClick={props.onStoredOverdue}
						/>
					</Grid>
					<Grid item style={{flexGrow: 1}}>
						<SimpleButton label={t("resetFilter")} onClick={handleResetFilter} />
					</Grid>

					<Grid item>
						<SimpleButton label={t("close")} onClick={props.onClose} />
					</Grid>

					<Grid item>
						<SimpleButton primary label={t("apply")} onClick={props.onApplyFilter} />
					</Grid>
				</Grid>
			</DialogActions>
		</Dialog>
	);
}

interface StateCheckboxProps {
	state: DeliveryState;
	checked: boolean;
	onChecked: (state: DeliveryState, checked: boolean) => void;
}

function StateCheckbox({state, checked, onChecked}: StateCheckboxProps) {
	const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		onChecked(state, e.currentTarget.checked);
	}, [onChecked, state]);
	const {e} = useLocalization();
	const name = e("deliveries.deliveryStates", state);
	return (
		<FormControlLabel
			control={
				<Checkbox
					checked={checked}
					onChange={handleChange}
					color="primary"
				/>
			}
			label={name}
			title={name}
		/>
	);
}

interface TypeCheckboxProps {
	type: DeliveryType;
	checked: boolean;
	onChecked: (type: DeliveryType, checked: boolean) => void;
}

function TypeCheckbox({type, checked, onChecked}: TypeCheckboxProps) {
	const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		onChecked(type, e.currentTarget.checked);
	}, [onChecked, type]);
	const {e} = useLocalization();
	const name = e("deliveries.deliveryTypes", type);
	return (
		<FormControlLabel
			control={
				<Checkbox
					checked={checked}
					onChange={handleChange}
					color="primary"
				/>
			}
			label={name}
			title={name}
		/>
	);
}

interface ConditionCheckboxProps {
	condition: DeliveryCondition;
	checked: boolean;
	onChecked: (type: DeliveryCondition, checked: boolean) => void;
}

function ConditionCheckbox({condition, checked, onChecked}: ConditionCheckboxProps) {
	const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		onChecked(condition, e.currentTarget.checked);
	}, [onChecked, condition]);
	const {e} = useLocalization();
	const name = e("deliveries.conditions", condition);
	return (
		<FormControlLabel
			control={
				<Checkbox
					checked={checked}
					onChange={handleChange}
					color="primary"
				/>
			}
			label={name}
			title={name}
		/>
	);
}
