import {utils, WorkSheet} from "xlsx";

export function isNumber(value: any): value is number {
    return typeof value === 'number' && isFinite(value);
}

export interface Column<T> {
    name: string;
    value: (data: T) => any;
    type?: "n" | "d";
    format?: string;
    width?: number;
}

export function generateSheet<T>(data: T[], columns: Column<T>[]) {
    const headers = columns.map(column => column.name);
    const dataFlat = data.map(element => columns.map(column => column.value(element)));
    const sheet = utils.aoa_to_sheet([headers, ...dataFlat]);
    // column widths
    sheet["!cols"] = columns.map(column => ({wch: column.width || 8}));
    columns.forEach((column, columnIndex) => {
        const {type, format} = column;
        if (type && format) {
            for (let row = 1; row <= data.length; row++) {
                const value = dataFlat[row - 1][columnIndex];
                if (type == "n") {
                    if (isNumber(value)) {
                        applyFormat(sheet, row, columnIndex, type, format, value);
                    }
                }
                else {
                    applyFormat(sheet, row, columnIndex, type, format, value);
                }
            }
        }
    });
    return sheet;
}

function applyFormat<T>(sheet: WorkSheet, rowIndex: number, columnIndex: number, type: string, format: string, value: any) {
    const addr = utils.encode_cell({r: rowIndex, c: columnIndex});
    sheet[addr] = {v: value, t: type, z: format};
}
