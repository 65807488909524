import {createElement, Component, useState, useEffect} from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Typography,
    Grid,
    TextField, MenuItem
} from '@mui/material';
import {DeliveryState} from "../../domain/Delivery";
import {gs} from "../../theme";
import {useLocalization} from "../../i18n";

interface DeliveryStateDialogProps {
    title: string;
    message: string;
    confirmLabel?: string;
    deliveryState: DeliveryState;
    resolve?: (deliveryState: DeliveryState) => void;
    reject?: () => void;
}

export function DeliveryStateDialog({title, message, confirmLabel, deliveryState, resolve, reject}: DeliveryStateDialogProps) {
    const [selectedDeliveryState, setSelectedDeliveryState] = useState<DeliveryState>(deliveryState);
    const {t} = useLocalization();

    useEffect(() => {
        setSelectedDeliveryState(deliveryState);
    }, [deliveryState]);

    const handleConfirm = () => {
        if(resolve) {
            resolve(selectedDeliveryState);
        }
    };

    const handleCancel = () => {
        if(reject) {
            reject();
        }
    };

    const open = resolve != undefined;
    return(
        <Dialog open={open} onClose={() => handleCancel()}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <Grid container spacing={gs}>
                    <Grid item xs={12}>
                        <Typography variant="body1">{message}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField fullWidth select variant="outlined" value={selectedDeliveryState}
                                   label={t('deliveries.deliveryState')}
                                   onChange={(e) => setSelectedDeliveryState(e.target.value as DeliveryState)}>
                            <MenuItem key={DeliveryState.Created} value={DeliveryState.Created} selected={DeliveryState.Created == selectedDeliveryState}>{DeliveryState.Created}</MenuItem>
                            <MenuItem key={DeliveryState.Stored} value={DeliveryState.Stored} selected={DeliveryState.Stored == selectedDeliveryState}>{DeliveryState.Stored}</MenuItem>
                            <MenuItem key={DeliveryState.PickedUp} value={DeliveryState.PickedUp} selected={DeliveryState.PickedUp == selectedDeliveryState}>{DeliveryState.PickedUp}</MenuItem>
                            <MenuItem key={DeliveryState.Cancelled} value={DeliveryState.Cancelled} selected={DeliveryState.Cancelled == selectedDeliveryState}>{DeliveryState.Cancelled}</MenuItem>
                            <MenuItem key={DeliveryState.ManualHandover} value={DeliveryState.ManualHandover} selected={DeliveryState.ManualHandover == selectedDeliveryState}>{DeliveryState.ManualHandover}</MenuItem>
                            <MenuItem key={DeliveryState.Shipped} value={DeliveryState.Shipped} selected={DeliveryState.Shipped == selectedDeliveryState}>{DeliveryState.Shipped}</MenuItem>
                        </TextField>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={() => handleCancel()}>{t('cancel')}</Button>
                <Button color="primary" variant="contained" onClick={() => handleConfirm()}>{confirmLabel || t('confirm')}</Button>
            </DialogActions>
        </Dialog>
    );
}

