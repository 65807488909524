import {createElement, useEffect, useState} from "react";
import {Chart} from "react-google-charts";
import {useLocalization} from "../../i18n";
import {DeliveryData} from "./Dashboard";

export interface UtilizationChartProps {
	dates: DeliveryData[];
}

interface Utilisation {
	date: Date;
	count: number;
	boxMap: Map<string,number>;
}

export function UtilizationChart(props: UtilizationChartProps) {
	const {t} = useLocalization();
	const [chartData, setChartData] = useState([]);
	let computeUtilization = (deliveryDates: DeliveryData[]): Utilisation[] => {
		let fromMs: number = Math.min(...deliveryDates.map(x => x.storedAt?.getTime() ?? x.createdAt.getTime()));
		let toMs: number = Math.max(...deliveryDates.map(x => x.pickupAt?.getTime() ?? x.createdAt.getTime()));

		let from: Date = new Date(fromMs);
		from.setHours(0, 0, 0);
		let to: Date = new Date(toMs);

		let day = from;
		let result: Utilisation[] = [];

		while (day < to) {
			let milliseconds = day.valueOf();
			milliseconds += 24 * 60 * 60 * 1000;
			day = new Date(milliseconds);
			result.push({date: day, count: 0, boxMap: new Map<string, number>()});
		}

		for (let utilisationDay of result) {
			for (let deliveryData of deliveryDates) {
				if (
					deliveryData?.storedAt && deliveryData.storedAt < utilisationDay.date
					&& (deliveryData?.pickupAt == undefined || deliveryData.pickupAt > utilisationDay.date)
				) {
					utilisationDay.count++;
					for (let type of deliveryData.boxTypes ) {
						let old = utilisationDay.boxMap.get(type) ?? 0;
						utilisationDay.boxMap.set(type,old+1);
					}
				}

			}
		}
		return result;
	};

	let getChartData = (utilisation: Utilisation[]) => {
		let usedTypesSet = new Set<string>();
		for (let u of  utilisation)
			for (let type of u.boxMap.keys())
				usedTypesSet.add(type)

		let usedTypes: string[] = Array.from(usedTypesSet).sort((a,b) => a.localeCompare(b) )

		let firstRow: any = [
				{type: "date"},
			];
		for (let type of usedTypes)
			firstRow.push(type);

		let chartData: any = [firstRow];
		for (let u of utilisation) {
			let row:any = [u.date];
			for (let type of usedTypes)
				row.push(u.boxMap.get(type) ?? 0)
			chartData.push(row);
		}
		return chartData;
	};

	useEffect(() => {
		let util: Utilisation[] = computeUtilization(props.dates);
		setChartData(getChartData(util));
	}, props.dates);

	const options = {
		chart: {
			legend: {position: "bottom"},
		},
		title: t("dashboard.utilization"),
		height: 500,
		padding: 100,
		hAxis: {
			format: "dd.MM.yy",
			gridlines: {count: 150},
		},
		vAxis: {
			gridlines: {color: "none"},
			minValue: 0,
			title: t("dashboard.occupiedBoxes"),
		},
		isStacked: 'absolute',
	};

	if ( chartData.length > 1  && (chartData[0] as any).length > 1)
		return (
			<Chart
				chartType="AreaChart"
				width="100%"
				height="400px"
				data={chartData}
				options={options}
			/>
		);
	else
		return (
	       <span></span>
		)
}
