import {RefreshRounded} from "@mui/icons-material";
import {Alert} from "@mui/lab";
import {
	Box,
	Button,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControlLabel,
	Grid,
	LinearProgress,
	MenuItem,
	Paper,
	TableCell,
	TableRow,
	Typography,
} from "@mui/material";
import {
	Breadcrumbs,
	ColumnType,
	ContainerLayout,
	ContentTable,
	Page,
	PagingImpl,
	PagingSettings,
	useFlag,
} from "@variocube/app-ui";
import {createElement, Fragment, useEffect, useMemo, useState} from "react";
import {useNavigate} from "react-router-dom";
import {Cube} from "../../domain/Cube";
import {CubesProvider} from "../../domain/CubesProvider";
import {DeliveriesProvider} from "../../domain/DeliveriesProvider";
import {
	Delivery,
	DeliveryCondition,
	DeliveryFilterRequest,
	DeliveryState,
	DeliveryType,
	Recipient,
	ReturnDelivery,
} from "../../domain/Delivery";
import {Site} from "../../domain/Site";
import {SitesProvider} from "../../domain/SitesProvider";
import {useLocalization} from "../../i18n";
import {exportDeliveriesService} from "../../services/exportDeliveriesService";
import {gs} from "../../theme";
import {BooleanDisplay} from "../BooleanDisplay";
import {BreadcrumbRouterLink} from "../BreadcrumbRouterLink";
import {DeliveryFilterEdit} from "../dashboard/DeliveryFilterEdit";
import {DateComponent} from "../DateComponent";
import {HelmetTitleWrapper} from "../HelmetTitleWrapper";
import {Loading} from "../Loading";
import {deliveryPaging} from "../pagings";
import {PushButtonWithMenu} from "../PushButtonWithMenu";
import {useTenantId, useTenantUser, useTenantUserRole} from "../TenantContextProvider";
import {deliveryColumns, deliveryFilter} from "../uis";
import {WebSocketMessageConsumer} from "../WebSocketConnectionContextProvider";
import {DeliveryConditionDisplay} from "./DeliveryConditionDisplay";
import {DeliveryListStorage} from "./DeliveryListStorage";
import {DeliveryStateDisplay} from "./DeliveryStateDisplay";
import {DeliveryTagsDisplay} from "./DeliveryTagsDisplay";
import {DeliveryTypeDisplay} from "./DeliveryTypeDisplay";
import {ResendNotificationsDialog} from "./notifications/ResendNotificationsDialog";

const baseFilter: DeliveryFilterRequest = {
	needle: "",
	siteIds: [] as string[],
	cubeIds: [] as string[],
	deliveryStates: [] as DeliveryState[],
	deliveryTypes: [] as DeliveryType[],
	deliveryConditions: [] as DeliveryCondition[],
	tags: [],
};

function initialPaging() {
	const paging = deliveryPaging.getSettings();
	if (!paging.sort) {
		paging.sort = "delivery.createdAt";
		paging.direction = "desc";
		deliveryPaging.updateSettings(paging);
	}
	return paging;
}

export const DeliveryList = () => {
	const {t, language} = useLocalization();
	const tenantId = useTenantId();
	const user = useTenantUser();
	const navigate = useNavigate();
	const {isAdmin, isAdminLight, isUser} = useTenantUserRole();

	const [columns, setColumns] = useState<ColumnType>();
	const [page, setPage] = useState<Page<Delivery>>();
	const [pageable, setPageable] = useState<PagingSettings<keyof typeof baseColumns>>(
		initialPaging() as PagingSettings<keyof typeof baseColumns>,
	);
	const [inProgress, setInProgress] = useState(true);
	const [loadError, setLoadError] = useState(false);
	const [showExport, setShowExport] = useState(false);
	const [showResendNotifications, setShowResendNotifications] = useState(false);
	const [editFilter, setEditFilter, clearEditFilter] = useFlag(false);

	const [filter, setFilter] = useState<DeliveryFilterRequest>({
		needle: "",
		cubeIds: [],
		siteIds: [],
		deliveryStates: [],
		deliveryTypes: [],
		deliveryConditions: [],
		tags: [],
	});

	const loadPage = async (filter: DeliveryFilterRequest) => {
		setLoadError(false);
		setInProgress(true);
		try {
			const data = await DeliveriesProvider.list(tenantId, deliveryPaging, filter);
			setPage(data);
			// console.log("data:" + data.content.length + "   "+  new Date())
			new DeliveryListStorage().set(data.content.map(d => d.id));
		} catch (error) {
			console.error("failed to load page", error);
			setLoadError(true);
			setPage(undefined);
		} finally {
			setInProgress(false);
		}

	};

	useEffect(() => {
		let columns = deliveryColumns.get();
		if (columns == null) {
			deliveryColumns.set(baseColumns);
			columns = baseColumns;
		}
		setColumns(columns);

		let filter = deliveryFilter.get();
		if (filter == null) {
			deliveryFilter.set(baseFilter);
			filter = baseFilter;
			if (user.siteId) {
				filter.siteIds.push(user.siteId);
			}
		}
		setFilter(filter);

		loadPage(filter).then();
	}, []);

	// useEffect(() => {
	// 	CubesProvider.list(tenantId)
	// 		.then(setCubes);
	// 	SitesProvider.list(tenantId)
	// 		.then(setSites);
	// }, [tenantId]);

	const baseColumns: ColumnType = useMemo<ColumnType>(() => ({
		"delivery.state": {show: true, name: t("deliveries.deliveryState")},
		"delivery.deliveryType": {show: false, name: t("deliveries.deliveryType")},
		"delivery.tags": {show: true, name: t("deliveries.tags")},
		"delivery.parcelId": {show: false, name: t("deliveries.parcelId")},
		"delivery.condition": {show: false, name: t("deliveries.condition")},
		"delivery.weight": {show: false, name: t("deliveries.weight")},
		"delivery.units": {show: false, name: t("deliveries.units")},
		"delivery.sender": {show: false, name: t("deliveries.sender")},
		"delivery.carrier": {show: false, name: t("deliveries.carrier")},
		"delivery.notes": { show: false, name: t('deliveries.manualHandover.notes'), unsortable: true },
		"order.description": {show: true, name: t("deliveries.order.description")},
		"order.deliveryNoteId": {show: false, name: t("deliveries.order.deliveryNoteId")},
		"order.orderId": {show: false, name: t("deliveries.order.orderId")},
		"order.orderPositions": {show: false, name: t("positions"), unsortable: true},
		"order.attachments": {show: false, name: t("deliveries.attachments.plural"), unsortable: true},
		"metadata.foreignId": {show: false, name: t("deliveries.thirdParty.foreignId")},
		"metadata.callbackUrl": {show: false, name: t("deliveries.thirdParty.callbackUrl")},
		"recipients": {show: true, name: t("deliveries.recipients.plural"), unsortable: true},
		"recipientsDepartments": {show: false, name: t("recipients.department"), unsortable: true},
		"recipientsBuildings": {show: false, name: t("recipients.building"), unsortable: true},
		"delivery.createdAt": {show: true, name: t("deliveries.createdAt")},
		"delivery.createdBy": {show: false, name: t("deliveries.createdBy")},
		"lastUpdatedAt": {show: false, name: t("deliveries.lastUpdatedAt")},
		"lastUpdatedBy": {show: false, name: t("deliveries.lastUpdatedBy")},
		"delivery.storedAt": {show: false, name: t("deliveries.storedAt")},
		"delivery.pickedUpAt": {show: false, name: t("deliveries.pickedUpAt")},
		"receivingDepartment": {show: false, name: t("deliveries.receivingDepartment")},
		"storage.storageTimeMax": {show: false, name: t("deliveries.storage.storageTimeMax")},
		"storage.hazardousGoods": {show: false, name: t("deliveries.storage.hazardousGoods")},
		"storage.destination": {show: false, name: t("deliveries.storage.destination")},
		"storage.destinationName": {show: false, name: t("deliveries.storage.destinationName")},
		"storage.destinationDescription": {show: false, name: t("deliveries.storage.destinationDescription")},
		"storage.storageKey": {show: false, name: t("deliveries.thirdParty.storageKey")},
		"storage.storageCode": {show: false, name: t("deliveries.storageCode")},
		"storage.manualHandoverRequired": {
			show: false,
			name: t("deliveries.create.recipientStep.manualHandoverRequired"),
		},
		// 'storage.reserveBox': { show: false, name: t('reserveBox.reserveBox'), unsortable: true },
	}), [t]);

	useEffect(() => {
		const columns = deliveryColumns.get() || {};
		const sortedColumns = {} as any;
		for (let k of Object.keys(baseColumns)) {
			sortedColumns[k] = (!columns.hasOwnProperty(k)) ? baseColumns[k] : columns[k];
		}
		deliveryColumns.set(sortedColumns);
	}, []);

	useEffect(() => {
		console.log("detected language change, update table language");
		if (columns) {
			const newColumns: any = {};
			for (let k of Object.keys(columns)) {
				newColumns[k] = {
					...columns[k],
					name: baseColumns[k].name,
				};
			}
			setColumns(newColumns);
			deliveryColumns.set(newColumns);
		}
	}, [language]);

	const handleResetFilterAndReload = () => {
		deliveryPaging.resetSettings();
		setPageable(deliveryPaging.getSettings());

		deliveryColumns.clear();
		deliveryColumns.set(baseColumns);
		setColumns(baseColumns);

		let filter = baseFilter;
		deliveryFilter.set(filter);
		if (user?.siteId) {
			filter.siteIds.push(user?.siteId);
		}
		setFilter(filter);

		loadPage(filter).then();
	};

	const handlePagingChange = (pageable: PagingSettings<any>) => {
		deliveryPaging.updateSettings(pageable);
		setPageable(pageable);
		loadPage(filter || baseFilter).then();
	};

	const updateFilter = (filter: DeliveryFilterRequest) => {
		const newPageable = {
			...pageable,
			pageNumber: 0,
		};
		deliveryPaging.updateSettings(newPageable);
		setPageable(newPageable);
		deliveryFilter.set(filter);
		setFilter(filter);
	};

	const handleColumnsChange = (c: ColumnType) => {
		setColumns(c);
		deliveryColumns.set(c);
	};

	const filterOptions = (
		<DeliveryFilterEdit
			dialogOpen={editFilter}
			onFilterChanged={(filter) => {
				updateFilter(filter);
				loadPage(filter).then();
			}}
			onDialogClosed={() => {
				clearEditFilter();
			}}
			appStorage={deliveryFilter}
		/>
	);

	const renderColumnCheckbox = (key: keyof typeof baseColumns) => (
		(columns && columns[key])
			? (
				<FormControlLabel
					key={"column-setting-" + key}
					label={columns[key].name}
					color="primary"
					control={
						<Checkbox
							checked={columns[key].show}
							onChange={e =>
								handleColumnsChange({...columns, [key]: {...columns[key], show: e.target.checked}})}
						/>
					}
				/>
			)
			: null
	);

	return (
		<Fragment>
			<WebSocketMessageConsumer tenantId={tenantId} path="/deliveries" onMessage={() => loadPage(filter)}>
				<ContainerLayout>
					<HelmetTitleWrapper pageTitle={t("deliveries.plural")} />
					<Grid container spacing={gs}>
						<Grid item xs={12}>
							<Breadcrumbs>
								<BreadcrumbRouterLink to={`/${tenantId}/deliveries`}>
									{t("deliveries.plural")}
								</BreadcrumbRouterLink>
							</Breadcrumbs>
						</Grid>
						<Grid item xs={12}>
							<Grid container spacing={gs}>
								<Grid item style={{flexGrow: 1}}>
									<Typography variant="h2">{t("deliveries.plural")}</Typography>
								</Grid>
								{(isAdmin || isAdminLight || isUser) && (
									<Grid item>
										<PushButtonWithMenu
											label={t("deliveries.create.title")}
											variant="contained"
											size="small"
											onClick={() => navigate(`/${tenantId}/deliveries/create`)}
										>
											<MenuItem onClick={() => setShowExport(true)}>
												{t("deliveries.excelExport")}
											</MenuItem>
											{isAdmin && (
												<MenuItem onClick={() => setShowResendNotifications(true)}>
													{t("deliveries.resendNotifications.title")}
												</MenuItem>
											)}
										</PushButtonWithMenu>
									</Grid>
								)}
							</Grid>
						</Grid>
						<Grid item xs={12}>
							<Paper>
								{((!page && !loadError) || inProgress ) && (
									<Box py={2}>
										<Loading />
									</Box>
								)}
								{loadError && (
									<Box
										sx={{
											py: 2,
											display: "flex",
											flexDirection: "column",
											justifyContent: "center",
											alignItems: "center",
										}}
									>
										<Typography align="center">{t("loadDataError.message")}</Typography>
										<Box my={0.5} />
										<Button
											color="primary"
											variant="contained"
											startIcon={<RefreshRounded />}
											onClick={handleResetFilterAndReload}
										>
											{t("loadDataError.btn")}
										</Button>
									</Box>
								)}
								{(page && columns) && (
									<ContentTable
										pageable={pageable}
										page={page}
										inProgress={inProgress}
										columns={columns}
										onPageableChange={handlePagingChange}
										onColumnsChange={handleColumnsChange}
										renderFilterOptions={filterOptions}
										onFilterClick={setEditFilter}
										renderTableBody={
											<Fragment>
												{page.content.map(d => (
													<DeliveryRow key={d.id} delivery={d} columns={columns} />
												))}
											</Fragment>
										}
										renderColumnFilter={
											<Fragment>
												<Typography variant="overline">{t("delivery")}</Typography>
												<Box my={1} />
												{[
													"delivery.state",
													"delivery.deliveryType",
													"delivery.tags",
													"delivery.parcelId",
													"delivery.condition",
													"delivery.weight",
													"delivery.sender",
													"delivery.carrier",
													"delivery.createdAt",
													"delivery.createdBy",
													"lastUpdatedAt",
													"lastUpdatedBy",
													"delivery.storedAt",
													"delivery.pickedUpAt",
													"receivingDepartment",
													"recipients",
													"recipientsDepartments",
													"recipientsBuildings",
													"delivery.notes",
												].map(renderColumnCheckbox)}
												<Box my={3} />

												<Typography variant="overline">
													{t("deliveries.order.singular")}
												</Typography>
												<Box my={1} />
												{[
													"order.description",
													"order.deliveryNoteId",
													"order.orderId",
													"order.orderPositions",
													"order.attachments",
												].map(renderColumnCheckbox)}
												<Box my={3} />

												<Typography variant="overline">
													{t("deliveries.thirdParty.singular")}
												</Typography>
												<Box my={1} />
												{[
													"metadata.foreignId",
													"metadata.callbackUrl",
												].map(renderColumnCheckbox)}
												<Box my={3} />

												<Typography variant="overline">
													{t("deliveries.storage.singular")}
												</Typography>
												<Box my={1} />
												{[
													"storage.storageTimeMax",
													"storage.hazardousGoods",
													"storage.destination",
													"storage.destinationName",
													"storage.destinationDescription",
													"storage.storageKey",
													"storage.storageCode",
													"storage.manualHandoverRequired",
													// 'storage.reserveBox'
												].map(renderColumnCheckbox)}
												<Box my={3} />
											</Fragment>
										}
									/>
								)}
							</Paper>
						</Grid>
					</Grid>
				</ContainerLayout>
			</WebSocketMessageConsumer>
			{showExport && (
				<DeliveryExportDialog
					onClose={() => setShowExport(false)}
					tenantId={tenantId}
					filter={filter}
				/>
			)}
			<ResendNotificationsDialog
				open={showResendNotifications}
				onClose={() => setShowResendNotifications(false)}
				countDeliveries={page ? page.totalElements : 0}
				filter={filter}
			/>
		</Fragment>
	);
};

const DeliveryRow = ({delivery, columns}: { delivery: Delivery; columns: ColumnType }) => {
	const {t} = useLocalization();
	const navigate = useNavigate();
	const tenantId = useTenantId();
	const isColumnActive = (name: string) => (columns[name] && columns[name].show);

	const generateRecipientDisplay = (recipients: Recipient[]) => {
		if (!recipients || recipients.length == 0) return "";
		return recipients
			.map(r => r.recipientName || r.email || r.phone || t("deliveries.recipients.unknown"))
			.join(", ");
	};

	const generateRecipientsDepartmentsDisplay = (recipients: Recipient[]) => {
		if (!recipients || recipients.length == 0) return "";
		return recipients
			.filter(r => r.department)
			.map(r => r.department)
			.join(", ");
	};

	const generateRecipientsBuildingsDisplay = (recipients: Recipient[]) => {
		if (!recipients || recipients.length == 0) return "";
		return recipients
			.filter(r => r.building)
			.map(r => r.building)
			.join(", ");
	};

	const handleDeliveryClicked = (delivery: Delivery) => {
		navigate(`/${tenantId}/deliveries/${delivery.id}`);
	};

	return (
		<TableRow hover onClick={() => handleDeliveryClicked(delivery)}>
			{isColumnActive("delivery.state") && (
				<TableCell>
					<DeliveryStateDisplay deliveryState={delivery.delivery.state} size="small" />
				</TableCell>
			)}
			{isColumnActive("delivery.deliveryType") && (
				<TableCell>
					<DeliveryTypeDisplay deliveryType={delivery.delivery.deliveryType} size="small" />
				</TableCell>
			)}
			{isColumnActive("delivery.tags") && (
				<TableCell>
					<DeliveryTagsDisplay tags={delivery.tags} size="small" maxTags={2} />
				</TableCell>
			)}
			{isColumnActive("delivery.parcelId") && <TableCell style={{maxWidth:"200px", overflow: "hidden", textOverflow: "ellipsis" }}>{delivery.delivery.parcelId}</TableCell>}
			{isColumnActive("delivery.condition") && (
				<TableCell>
					<DeliveryConditionDisplay value={delivery.delivery.condition} />
				</TableCell>
			)}
			{isColumnActive("delivery.weight") && <TableCell>{delivery.delivery.weight}</TableCell>}
			{isColumnActive("delivery.sender") && (
				<TableCell>
					{delivery.delivery.sender ?? delivery.sender?.name ?? delivery.sender?.email ??  delivery.sender?.phone ?? delivery.sender?.employeeNumber}
				</TableCell>
			)}
			{isColumnActive("delivery.carrier") && <TableCell>{delivery.delivery.carrier}</TableCell>}
			{isColumnActive("delivery.notes") && (
				<TableCell>
					<span
						style={{
							textOverflow: 'ellipsis',
							whiteSpace: 'nowrap',
							maxWidth: 180,
							display: 'block',
							overflow: 'auto'
						}}
					>
						{delivery.notes?.map(n => n.note).join(', ')}
					</span>
				</TableCell>
			)}

			{isColumnActive("order.description")
				&& (
					<TableCell>
						{delivery.order && delivery.order.description
							&& <span>{delivery.order.description}</span>}
						{delivery.returnDelivery
							&& <span>{returnDeliveryDisplay(delivery.returnDelivery)}</span>}
					</TableCell>
				)}
			{isColumnActive("order.deliveryNoteId") && <TableCell>{delivery.order.deliveryNoteId}</TableCell>}
			{isColumnActive("order.orderId") && <TableCell>{delivery.order.orderId}</TableCell>}
			{isColumnActive("order.orderPositions") && (
				<TableCell>{delivery.order.orderPositions.map(p => p.positionLabel).join(", ")}</TableCell>
			)}
			{isColumnActive("order.attachments") && (
				<TableCell>{delivery.order.attachments.map(a => a.name).join(", ")}</TableCell>
			)}
			{isColumnActive("metadata.foreignId") && <TableCell>{delivery.metadata?.foreignId}</TableCell>}
			{isColumnActive("metadata.callbackUrl") && <TableCell>{delivery.metadata?.callbackUrl}</TableCell>}

			{isColumnActive("recipients") && <TableCell>{generateRecipientDisplay(delivery.recipients)}</TableCell>}
			{isColumnActive("recipientsDepartments") && (
				<TableCell>{generateRecipientsDepartmentsDisplay(delivery.recipients)}</TableCell>
			)}
			{isColumnActive("recipientsBuildings") && (
				<TableCell>{generateRecipientsBuildingsDisplay(delivery.recipients)}</TableCell>
			)}

			{isColumnActive("delivery.createdAt") && (
				<TableCell>
					<DateComponent date={delivery.delivery.createdAt} />
				</TableCell>
			)}
			{isColumnActive("delivery.createdBy") && <TableCell>{delivery.delivery.createdBy}</TableCell>}

			{isColumnActive("lastUpdatedAt") && (
				<TableCell>
					<DateComponent date={delivery.lastUpdatedAt} />
				</TableCell>
			)}
			{isColumnActive("lastUpdatedBy") && (
				<TableCell>{delivery.lastUpdatedBy}</TableCell>
			)}
			{isColumnActive("delivery.storedAt") && (
				<TableCell>
					{delivery.delivery.storedAt && <DateComponent date={delivery.delivery.storedAt} />}
				</TableCell>
			)}
			{isColumnActive("delivery.pickedUpAt") && (
				<TableCell>
					{delivery.delivery.pickedUpAt && <DateComponent date={delivery.delivery.pickedUpAt} />}
				</TableCell>
			)}

			{isColumnActive("receivingDepartment") && <TableCell>{delivery.receivingDepartment}</TableCell>}

			{isColumnActive("storage.storageTimeMax") && <TableCell>{delivery.storage?.storageTimeMax}</TableCell>}
			{isColumnActive("storage.hazardousGoods") && <TableCell>{delivery.storage?.hazardousGoods}</TableCell>}
			{isColumnActive("storage.destination") && <TableCell>{delivery.storage?.destination}</TableCell>}
			{isColumnActive("storage.destinationName") && <TableCell>{delivery.storage?.destinationName}</TableCell>}
			{isColumnActive("storage.destinationDescription") && (
				<TableCell>{delivery.storage?.destinationDescription}</TableCell>
			)}
			{isColumnActive("storage.storageKey") && <TableCell>{delivery.storage?.storageKey}</TableCell>}
			{isColumnActive("storage.storageCode") && <TableCell>{delivery.storage?.storageCode}</TableCell>}
			{isColumnActive("storage.manualHandoverRequired") && (
				<TableCell>
					{delivery.storage?.manualHandoverRequired !== undefined && (
						<BooleanDisplay value={delivery.storage?.manualHandoverRequired} />
					)}
				</TableCell>
			)}
		</TableRow>
	);
};

const DeliveryExportDialog = (
	{onClose, tenantId, filter}: { onClose: () => void; tenantId: string; filter?: DeliveryFilterRequest },
) => {
	const {t, language} = useLocalization();

	const [currentPage, setCurrentPage] = useState(0);
	const [totalPages, setTotalPages] = useState(0);
	const [error, setError] = useState(false);

	const PAGE_SIZE = 100;

	const progress = useMemo(() => {
		if (totalPages === 0) return 0;
		return Math.ceil(currentPage * 100 / totalPages);
	}, [currentPage, totalPages]);

	useEffect(() => {
		setCurrentPage(0);
		setTotalPages(0);

		let mounted = true;
		(async () => {
			let content: Delivery[] = [];

			let paging = new PagingImpl("deliveries_excel");
			paging.updateSettings({
				...paging.getSettings(),
				pageSize: PAGE_SIZE,
				pageNumber: 0,
			});

			filter = filter || {
				needle: "",
				siteIds: [],
				cubeIds: [],
				deliveryStates: [],
				deliveryTypes: [],
				deliveryConditions: [],
				tags: [],
			};
			let page;

			try {
				page = await DeliveriesProvider.list(tenantId, paging, filter);
				content.push(...page.content);
			} catch (err) {
				console.error("failed to fetch deliveries page", err);
				setError(true);
				return;
			}

			setTotalPages(page.totalPages);
			for (let i = 1; i <= page.totalPages && mounted; i++) {
				setCurrentPage(i);
				paging.updateSettings({
					...paging.getSettings(),
					pageNumber: i,
				});
				try {
					page = await DeliveriesProvider.list(tenantId, paging, filter);
					content.push(...page.content);
				} catch (err) {
					console.error("failed to fetch deliveries page", err);
					setError(true);
					break;
				}
			}

			if (mounted) {
				try {
					await exportDeliveriesService.export(tenantId, t("deliveries.plural"), content, language);
					console.log("Excel export success");
				} catch (err) {
					console.error(err);
					setError(true);
				}
			} else {
				console.log("component unmounted");
			}
		})();

		return () => {
			mounted = false;
		};
	}, []);

	return (
		<Dialog open={true} fullWidth maxWidth="sm">
			<DialogTitle>{t("deliveries.excelExport")}</DialogTitle>
			<DialogContent>
				<Typography align="center">
					{progress !== 100 ? t("deliveries.excelExportInProgress") : t("deliveries.excelExportComplete")}
				</Typography>
				<Box my={2} />
				{!error && <LinearProgress variant="determinate" value={progress} />}
				{error && (
					<Alert severity="error">
						{t("deliveries.excelExportError")}
					</Alert>
				)}
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose}>{progress !== 100 ? t("cancel") : t("close")}</Button>
			</DialogActions>
		</Dialog>
	);
};

function returnDeliveryDisplay(r: ReturnDelivery): string {
	return [r.description, r.deliveryNoteId, r.customerId, r.reasonForReturn]
		.filter((e) => e).join(", ");
}
