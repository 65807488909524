import {Card, CardContent, Grid, Typography} from "@mui/material";
import {createElement, Fragment, useEffect, useState} from "react";
import {useLocalization} from "../../i18n";
import {DeliveryData} from "./Dashboard";
import {DeliveryState} from "../../domain/Delivery";

interface AverageStorageTimeMetricProps {
	deliveries: DeliveryData[];
}
export function AverageStorageTimeMetric(props: AverageStorageTimeMetricProps) {
	const [averageStorageTimeCube, setAverageStorageTimeCube] = useState<string>("");
	const [averageStorageTimeManual, setAverageStorageTimeManual] = useState<string>("");

	const {t} = useLocalization();

	let getAverageStorageTime = (deliveryData: DeliveryData[]) => {
		let noCube	 = 0;
		let timesCube = 0;
		let noManual	 = 0;
		let timesManual = 0;

		for (let dates of deliveryData) {
			let storedAt = dates.state == DeliveryState.ManualHandover  ? dates.createdAt : dates.storedAt
			if (storedAt && dates.pickupAt) {
				if (dates.state !== DeliveryState.ManualHandover) {
					let timeDiff = dates.pickupAt.getTime() - storedAt.getTime();
					timesCube += timeDiff;
					noCube++;
				}
				else {
					let timeDiff = dates.pickupAt.getTime() - storedAt.getTime();
					timesManual += timeDiff;
					noManual++;
				}
			}
		}
		let resultCube = toDaysAndHours(timesCube, noCube)
		let resultManual = toDaysAndHours(timesManual, noManual)
		return {cube: resultCube, manual: resultManual};
	};


	let toDaysAndHours = (totalTime: number, no: number) => {
		let daysFloat: number = no ? totalTime / no / (60 * 60 * 24 * 1000) : 0;
		let days = Math.floor(daysFloat);
		let hours = Math.floor((daysFloat - days) * 24);
		return {days:  days, hours: hours};
	}

	useEffect(() => {
		let avs = getAverageStorageTime(props.deliveries);
		let x = t("dashboard.averageStorageTimeValue", {days: avs?.cube.days, hours: avs.cube.hours});
		setAverageStorageTimeCube(x);
		x = t("dashboard.averageStorageTimeValue", {days: avs?.manual.days, hours: avs.manual.hours});
		setAverageStorageTimeManual(x);
	}, [props.deliveries]);

	return (
		<Card>
			<CardContent>
				<Typography variant="overline">{t("dashboard.averageStorageTimeTitle")}</Typography>
				<Grid container>
					<Grid item style={{flexGrow: 1, alignContent:'center'}} >
						<Typography variant="h6">{t("dashboard.cube")}</Typography>
					</Grid>
					<Grid item>
						<Typography variant="h3" sx={{display:"inline"}} align="right">
							{averageStorageTimeCube}
						</Typography>
					</Grid>
				</Grid>


				<Grid container>
					<Grid item style={{flexGrow: 1, alignContent:'center'}} >
					<Typography variant="h6">{t("dashboard.manualHandover")}</Typography>
					</Grid>
					<Grid item>
					<Typography variant="h3" sx={{display:"inline"}} align="right">
						{averageStorageTimeManual}
					</Typography>
					</Grid>
				</Grid>

			</CardContent>
		</Card>
	);
}
