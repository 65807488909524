import * as React from "react";
import {createElement, useMemo} from "react";

interface WeightDisplayProps {
    weight: number;
}

export function WeightDisplay({weight}: WeightDisplayProps) {
    const display = useMemo<string>(() => {
        return `${weight.toFixed(2).replace('.', ',')} kg`;
    }, [weight]);

    return (
        <span>{display}</span>
    );
}
