import {Chip, Grid} from "@mui/material";
import {createElement, useState} from "react";
import {useAsync} from "react-async-hook";
import {RecipientsProvider} from "../../domain/RecipientsProvider";
import {useTenantId} from "../TenantContextProvider";

interface OutOfOfficeRecipientsViewProps {
	substituteEmail: string;
}

export function OutOfOfficeRecipientsView(props: OutOfOfficeRecipientsViewProps) {
	const {substituteEmail} = props;
	const tenantId = useTenantId();
	const [recipients, setRecipients] = useState<string[]>([]);

	useAsync(async () => {
		if (substituteEmail) {
			let recipientsNew = await RecipientsProvider.getRecipientsByEmails(tenantId, substituteEmail);
			setRecipients(recipientsNew.map(item => item.recipientName));
		}
	}, [substituteEmail]);

	return (
		<Grid item>
			{recipients.length > 0
				? <Chip label={recipients.join(", ")} title={substituteEmail} variant="filled" />
				: <Chip label={substituteEmail} variant="filled" />}
		</Grid>
	);
}
