import {Grid, Typography} from "@mui/material";
import {BreadcrumbItem, Breadcrumbs} from "@variocube/app-ui";
import {createElement, useState} from "react";
import {useNavigate} from "react-router";
import {RecipientMutation} from "../../domain/Delivery";
import {RecipientsProvider} from "../../domain/RecipientsProvider";
import {useLocalization} from "../../i18n";
import {gs} from "../../theme";
import {BreadcrumbRouterLink} from "../BreadcrumbRouterLink";
import {ContainerLayout} from "@variocube/app-ui";
import {HelmetTitleWrapper} from "../HelmetTitleWrapper";
import {useTenant} from "../TenantContextProvider";
import {RecipientForm} from "./RecipientForm";

export function RecipientCreate() {
	const tenant = useTenant();
	const navigate = useNavigate();
	const {t} = useLocalization();
	const [alert,setAlert] = useState<string>();
	
	async function handleSubmit(recipient: RecipientMutation) {
		try {
			await RecipientsProvider.create(tenant.centerId, recipient);
			navigate(-1);
		} catch (err) {
			console.error('failed to create new recipient', err);
			setAlert(t("recipients.exists"));
		}
	}

	return (
		<ContainerLayout>
			<HelmetTitleWrapper pageTitle={t('recipients.actions.create')} />
			<Grid container spacing={gs}>
				<Grid item xs={12}>
					<Breadcrumbs>
						<BreadcrumbRouterLink to={`/${tenant.centerId}/recipients`}>
							{t("recipients.plural")}
						</BreadcrumbRouterLink>
						<BreadcrumbItem>{t("create")}</BreadcrumbItem>
					</Breadcrumbs>
				</Grid>
				<Grid item flexGrow={1}>
					<Typography variant="h2">{""}</Typography>
				</Grid>
				<Grid item xs={12}>
					<RecipientForm
						onSubmit={handleSubmit}
						alert={alert}
					/>
				</Grid>
			</Grid>
		</ContainerLayout>
	);
}
