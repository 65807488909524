import {createElement} from "react";
import {isTestSystem} from "../stage";
import {HelpButton} from "@variocube/app-ui";

interface LHelpButtonProps {
	helpPage: string;
	helpAnchor: string;
	language: string;
}

export function LHelpButton(props: LHelpButtonProps) {
	const {helpPage, helpAnchor, language} = props;

	if(!isTestSystem()) {
		return null;
	}

	return (
		<HelpButton helpPage={helpPage} helpAnchor={helpAnchor} language={language} />
	);

}
