import {MenuItem} from "@mui/material";
import * as React from "react";
import {createElement} from "react";
import {PushButtonWithMenu} from "./PushButtonWithMenu";
import {SimpleButton} from "./SimpleButton";

export interface ActionMenuItem {
	label: string;
	onClick: () => void;
}

export interface ActionsMenuProps {
	actions: ActionMenuItem[];
}

export function ActionsMenu(props: ActionsMenuProps) {
	const {actions} = props;

	if (actions.length == 0) {
		return null;
	}

	if (actions.length == 1) {
		return <SimpleButton label={actions[0].label} onClick={actions[0].onClick} />;
	}

	return (
		<PushButtonWithMenu label={actions[0].label} onClick={actions[0].onClick}>
			{actions.slice(1).map((action, index) => (
				<MenuItem key={index} onClick={action.onClick}>{action.label}</MenuItem>
			))}
		</PushButtonWithMenu>
	);
}
