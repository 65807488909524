import {createElement, ReactElement, useEffect, useState} from "react";
import {MenuItem, TextField} from "@mui/material";
import {useLocalization} from "../../i18n";

interface BoxTypeInputProps {
    boxTypes: string[];
    boxTypesDisplay?: ReactElement[];
    value: string;
    onChange: (value: string) => void;
    label: string;
    disabled?: boolean;
}

export function BoxTypeInput(props: BoxTypeInputProps) {
    const {t} = useLocalization();

    const [type, setType]= useState<string>();

	useEffect(() => {
		if (props.value !== type) {
			setType(props.value)
		}
	}, [props.value]);

    const handleChange = function(str: string) {
        setType(str);
        props.onChange(str);
    };

    return (
        <TextField  disabled={props.disabled}   variant="outlined" select  label={props.label} value={type ?? ''}  fullWidth
                    onChange={(e) => handleChange(e.target.value)}>
            <MenuItem key={'undefined'} value={undefined}    >{t('none')}</MenuItem>
            {props.boxTypes.map((s, index) =>
                <MenuItem key={s} value={s}>{props?.boxTypesDisplay?.[index] ??  s }</MenuItem>
            )}
        </TextField>
    );
}
