import {createElement} from "react";
import {Chip} from "@mui/material";
import {Roles} from "../../domain/Roles";
import {useLocalization} from "../../i18n";
import {ChipProps} from "@mui/material/Chip/Chip";

interface RoleDisplayProps {
    role: Roles
    size?: 'small' | 'medium';
}

export function RoleDisplay({role, size}: RoleDisplayProps) {
    const {e} = useLocalization();

    return (
        <Chip
            label={e('users.roles', role)}
            title={e('users.roles', role)}
            size={size}
            color={COLORS[role]}
        />
    );
}

const COLORS: Record<Roles, ChipProps["color"]> = {
    [Roles.user]: "info",
    [Roles.superuser]: "primary",
    [Roles.admin]: "secondary",
    [Roles.adminlight]: "secondary",
    [Roles.carrier]: "default",
    [Roles.recipient]: "default",
    [Roles.it]: "default"
}
